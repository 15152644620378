import * as dialogs from "./dialogs";
import * as context from "../../../context";
import * as forms from "../../../lib/forms";

export interface FormFields {
	title: string;
	id_image: string;
}

export interface State {
}

export class Model {
	public dialog: dialogs.Dialog<undefined, State>;
	public form: forms.Form<FormFields>;

	constructor(private context: context.StateContext) {
		this.dialog = new dialogs.Dialog<undefined, State>({
			defaultCustomFields: {
				setValue: undefined,
				showDate: undefined,
				showDescription: undefined,
				showName: undefined,
			}
		}, context);
		this.form = new forms.Form<FormFields>({
			fields: {
				title: {
					title: "",
					defaultValue: ""
				},
				id_image: {
					title: "",
					defaultValue: ""
				}
			}
		}, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			...this.form.getStateContainers(),
			...this.dialog.getStateContainers()
		];
	}

	openDialog = async (title: string, id_image: string) => {
		this.form.clearFields();
		this.form.setField("title", title);
		this.form.setField("id_image", id_image);
		return this.dialog.open();
	}
}