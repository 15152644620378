/**
 * Detail uživatele
 */
import React from "react";
import * as state from "../../../../lib/state";
import * as apiUsers from "../users/api-users";
import * as api from "../../../../lib/api";
import * as navigation from "../../../../lib/navigation";
import Page from "../../../../components/templates/Page";
import Button from "../../../../components/controls/Button";

interface Props {
	id: string;
}

function UsersDetailPage(props: Props) {
	const { authorization } = state.useStateContext();
	const { usersDetail } = state.useStateContext();
	const form = usersDetail.form;
	const container = usersDetail.stateContainer.get();
	const userCategories = form.getField("user_categories").value;
	const userAllowedCategories = form.getField("user_allowed_categories").value;
	const role = authorization.getUserProfile()?.profile.role;

	function handleSave() {
		usersDetail.save();
	}

	return (
		<Page title="Uživatel - detail" description="">
			{authorization.isAdminOrOperator() && form.getField("id").value != api.emptyGuid &&
				<div className="page-content-container">
					<div className="ml-3 mb-3 text-dark">
						<div className="homepage-responsive-title d-inline">
							Uživatel - detail
						</div>
					</div>
					<div className="content-container m-3">
						<h3 className="font-weight-bold mt-0 ml-2">{form.getField("full_name").value}</h3>
						<hr className="mt-0 mb-2" />
						<div className="ml-2 mb-2">
							Kategorie uživatele.
						</div>
						<div className="row">
							<div className="col-12">
								{container.categories.map((i, index) =>
									<div className="d-inline mr-2">
										<div
											title={i.name}
											onClick={() => usersDetail.setUserCategory(i.id)}
											style={{ boxShadow: i.color + (userCategories.map(x => x.id_category).includes(i.id) ? "0px 0px 0px 6px inset" : "0px 0px 0px 14px inset") }}
											className="users-circle">
										</div>
										<span className="ml-2" style={{ color: i.color, fontWeight: "bold", verticalAlign: "super" }}>{i.name}</span>
									</div>
								)}
							</div>
						</div>
						<hr className="mt-0 mb-2" />
						{(role?.id == apiUsers.Role.Admin) && (form.getField("role").value?.id == apiUsers.Role.Admin || form.getField("role").value?.id == apiUsers.Role.Operator) &&
							<>
								<div className="ml-2 mb-2">
									Zobrazení dat podle kategorie.
								</div>
								<div className="row">
									<div className="col-12">
										{container.categories.map((i, index) =>
											<div className="d-inline mr-2">
												<div
													title={i.name}
													onClick={() => usersDetail.setUserAllowedCategory(i.id)}
													style={{ boxShadow: i.color + (userAllowedCategories.map(x => x.id_category).includes(i.id) ? "0px 0px 0px 6px inset" : "0px 0px 0px 14px inset") }}
													className="users-circle">
												</div>
												<span className="ml-2" style={{ color: i.color, fontWeight: "bold", verticalAlign: "super" }}>{i.name}</span>
											</div>
										)}
									</div>
								</div>
							</>
						}
						<div className="row p-2 mt-3">
							<div className="col">
								{
									<div className="d-inline float-left">
										<Button className="font-weight-bold" onClick={handleSave}>
											Uložit
										</Button>
									</div>
								}
								<div className="d-inline float-right">
									<Button className="font-weight-bold" onClick={() => navigation.to("/administrace/uzivatele")}>
										Zpět
									</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
			}
		</Page >
	);
}

export default state.bindContainers(
	UsersDetailPage,
	context => context.usersDetail
);