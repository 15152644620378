/**
 * Přidání souboru
 */
import * as React from "react";
import * as state from "../../../../../lib/state";
import * as formatting from "../../../../../lib/formatting";

import Dialog from "../../../../shared/dialogs/Dialog";
import { GeneralGraph } from "../../../../../modules/shared/graphs/GeneralGraph";

function LastMeasurementDialog() {
	const { lastMeasurementDialog } = state.useStateContext();
	const actions = [{
		title: "Odebrat widget",
		action: async () => {
		},
	},
	{
		title: "Nastavení",
		action: async () => {
		},
	}];

	const type = lastMeasurementDialog.form.getField("type").value;

	return (
		<Dialog className="last-measurement-dialog" dialog={lastMeasurementDialog.dialog}>
			<div>
				<h2 className="pb-3 dialog-title"><img className="d-inline mr-2" src="/images/chart.svg" alt="chart" /> Historie měření</h2>
				<hr className="dialog-title-hr" />
				<div className="measurement-history-page">
					<div className="mt-3 pt-2 mesurement-history-form">
						<div className="row">
							<div className="col">
								<GeneralGraph
									date_from={formatting.substractDaysFromDate(31, formatting.setTimeFromString(new Date(), 0, 0, 0))}
									date_to={formatting.setTimeFromString(new Date(), 23, 59, 59)}
									type={type as any}
									secondType={type && type.id == 8 ? { id: 9, name: "DIA" } : undefined}
									types={type && type.id == 22 ? [
										{ id: 22, name: "FEV 1" },
										{ id: 23, name: "FEV 6" },
										{ id: 24, name: "PEF" },
										{ id: 25, name: "FEF-2575" },
										{ id: 26, name: "EV 1 , FEV 6 ratio" }
									] : type && type.id == 8 ? [{ id: 8, name: "Tlak" }] : [type as any]}
									disabled={true}
									height="275px"
									showType={true}
									measurement={true}
								/>
							</div>
						</div>
					</div>
				</div >
			</div>
		</Dialog >
	);
}

export default state.bindContainers(
	LastMeasurementDialog,
	c => c.lastMeasurementDialog
);