/**
 * Odeslání emailu
 */
import Button from "../../../../components/controls/Button";
import * as React from "react";
import * as state from "../../../../lib/state";
import * as navigation from "../../../../lib/navigation";

import Dialog from "../../../shared/dialogs/Dialog";
import FormsInput from "../../../../components/controls/Forms/FormsInput";
import FormsHtmlEditor from "../../../../components/controls/Forms/FormsHtmlEditor";
import IconSvg from "../../../../components/controls/IconSvg";
import FormFileUpload from "../../../../modules/system/file/FormFileUpload";
import FormsSelect from "../../../../components/controls/Forms/FormSelect";

function EmailDialog() {
	const { emailDialog } = state.useStateContext();
	const dialog = emailDialog.dialog;
	const form = emailDialog.form;

	function handleList() {
		emailDialog.dialog.close();
		navigation.to("/emaily");
	}

	return (
		<Dialog className="email-dialog" dialog={dialog}>
			<div>
				<h2 className="pb-3 dialog-title"><img className="d-inline mr-2" src="/images/folders-1.svg" alt="folders-1" /> Odeslat email</h2>
				<hr className="dialog-title-hr" />
				<div className="row">
					<div className="col-md-6 col-xs-12">
						<FormsInput
							form={form}
							inputProps={{ readOnly: true }}
							field="mail_from"
						/>
					</div>
					<div className="col-md-6 col-xs-12">
						<FormsSelect
							form={form}
							field="mail_to"
							options={[
								{ title: "Vyberte příjemce...", value: undefined },
								...emailDialog.stateContainer.get().emails as any,
							]}
						/>
					</div>
				</div>
				<div className="row">
					<div className="col">
						<FormsInput
							form={form}
							inputProps={{ disabled: true }}
							field="subject"
						/>
					</div>
				</div>
				<div className="row mt-2 text-left">
					<div className="col">
						<FormsHtmlEditor
							form={form}
							field="body"
						/>
					</div>
				</div>
				<div className="row">
					<div className="col">
						<div><label>Přílohy</label></div>
						{emailDialog.formsFiles.get().map((formFile, index) =>
							<div key={index} className="row pl-0 mt-2">
								<div className="col-md-10 col-xs-12">
									<FormFileUpload
										form={formFile}
										simple={true}
										field="attachment"
									/>
								</div>
								<div className="col-md-2 col-xs-6">
									<Button className="d-block w-100 mb-1" onClick={() => emailDialog.removeFile(formFile)}>Odebrat</Button>
								</div>
							</div>
						)}
					</div>
				</div>
				<Button onClick={emailDialog.addFile}><IconSvg name="plus" className="mr-3" /> Přidat další přílohu</Button>
				<hr />
				<div className="mt-4 text-center">
					<Button onClick={handleList} className="button-primary mr-2 float-left">
						<strong>Seznam emailů</strong>
					</Button>
					<Button onClick={e => emailDialog.save()} className="float-right button-primary">
						<strong>Odeslat email</strong>
					</Button>
				</div>
			</div>
		</Dialog >
	);
}

export default state.bindContainers(
	EmailDialog,
	context => context.emailDialog
);