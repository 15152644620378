import { library } from "@fortawesome/fontawesome-svg-core";

import { faHome } from "@fortawesome/free-solid-svg-icons/faHome";
import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import { faAngleDoubleDown } from "@fortawesome/free-solid-svg-icons/faAngleDoubleDown";
import { faAngleDoubleUp } from "@fortawesome/free-solid-svg-icons/faAngleDoubleUp";
import { faAngleDoubleLeft } from "@fortawesome/free-solid-svg-icons/faAngleDoubleLeft";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons/faAngleDoubleRight";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons/faAngleRight";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons/faAngleLeft";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import { faSortAmountUp } from "@fortawesome/free-solid-svg-icons/faSortAmountUp";
import { faSortAmountDown } from "@fortawesome/free-solid-svg-icons/faSortAmountDown";
import { faFileUpload } from "@fortawesome/free-solid-svg-icons/faFileUpload";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons/faTrashAlt";
import { faImage } from "@fortawesome/free-solid-svg-icons/faImage";
import { faFile } from "@fortawesome/free-solid-svg-icons/faFile";
import { faCrop } from "@fortawesome/free-solid-svg-icons/faCrop";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { faUser } from "@fortawesome/free-solid-svg-icons/faUser";
import { faEdit } from "@fortawesome/free-solid-svg-icons/faEdit";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons/faSignOutAlt";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons/faSyncAlt";
import { faFillDrip } from "@fortawesome/free-solid-svg-icons/faFillDrip";
import { faEyeDropper } from "@fortawesome/free-solid-svg-icons/faEyeDropper";
import { faLock } from "@fortawesome/free-solid-svg-icons/faLock";
import { faLockOpen } from "@fortawesome/free-solid-svg-icons/faLockOpen";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons/faPlusCircle";
import { faMinusCircle } from "@fortawesome/free-solid-svg-icons/faMinusCircle";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons/faCaretDown";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons/faEllipsisV";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons/faExclamationTriangle";
import { faDownload } from "@fortawesome/free-solid-svg-icons/faDownload";
import { faMusic } from "@fortawesome/free-solid-svg-icons/faMusic";
import { faVideo } from "@fortawesome/free-solid-svg-icons/faVideo";
import { faEnvelopeOpenText } from "@fortawesome/free-solid-svg-icons/faEnvelopeOpenText";
import { faFileArchive } from "@fortawesome/free-solid-svg-icons/faFileArchive";
import { faFileAudio } from "@fortawesome/free-solid-svg-icons/faFileAudio";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons/faFilePdf";
import { faWeight } from "@fortawesome/free-solid-svg-icons/faWeight";
import { faChild } from "@fortawesome/free-solid-svg-icons/faChild";
import { faSlidersH } from "@fortawesome/free-solid-svg-icons/faSlidersH";
import { faCamera } from "@fortawesome/free-solid-svg-icons/faCamera";
import { faMicrophone } from "@fortawesome/free-solid-svg-icons/faMicrophone";
import { faCalendar } from "@fortawesome/free-solid-svg-icons/faCalendar";
import { faCalendarDay } from "@fortawesome/free-solid-svg-icons/faCalendarDay";
import { faCalendarWeek } from "@fortawesome/free-solid-svg-icons/faCalendarWeek";
import { faExpandArrowsAlt } from "@fortawesome/free-solid-svg-icons/faExpandArrowsAlt";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons/faChevronUp";
import { faLocationArrow } from "@fortawesome/free-solid-svg-icons/faLocationArrow";
import { faPhone } from "@fortawesome/free-solid-svg-icons/faPhone";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { faBookMedical } from "@fortawesome/free-solid-svg-icons/faBookMedical";
import { faDatabase } from "@fortawesome/free-solid-svg-icons/faDatabase";
import { faPersonBooth } from "@fortawesome/free-solid-svg-icons/faPersonBooth";
import { faGlobe } from "@fortawesome/free-solid-svg-icons/faGlobe";
import { faQuestion } from "@fortawesome/free-solid-svg-icons/faQuestion";
import { faComment } from "@fortawesome/free-solid-svg-icons/faComment";
import { faChartLine } from "@fortawesome/free-solid-svg-icons/faChartLine";
import { faFilePowerpoint } from "@fortawesome/free-solid-svg-icons/faFilePowerpoint";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import { faMoon } from "@fortawesome/free-solid-svg-icons/faMoon";
import { faCloud } from "@fortawesome/free-solid-svg-icons/faCloud";
import { faEye } from "@fortawesome/free-solid-svg-icons/faEye";

export type Icon = keyof typeof icon;

export const icon = {
	Home: "home",
	Search: "search",
	ExpandPanel: "angle-double-down",
	CollapsePanel: "angle-double-up",
	ModuleHelp: "info-circle",
	ImageUpload: "file-upload",
	Image: "image",
	Confirm: "check",
	Cancel: "times",
	CropImageIcon: "crop",
	FillImageBackgroundIcon: "fill-drip",
	PickImageBackgroundIcon: "eye-dropper",
	Document: "file",
	User: "user",
	Detail: "edit",
	Logout: "sign-out-alt",
	LockLocked: "lock",
	LockOpen: "lock-open",
	Add: "plus-circle",
	Remove: "minus-circle",
	ImageDelete: "trash-alt",
	SortAsc: "sort-amount-up",
	SortDesc: "sort-amount-down",
	FirstRecord: "angle-double-left",
	LastRecord: "angle-double-right",
	PrevRecord: "angle-left",
	NextRecord: "angle-right",
	Refresh: "sync-alt",
	CaretDown: "caret-down",
	ThreeDots: "ellipsis-v",
	Danger: "exclamation-triangle",
	Download: "download",
	Music: "music",
	Video: "video",
	Text: "envelope-open-text",
	Archive: "file-archive",
	FileAudio: "file-audio",
	FilePdf: "file-pdf",
	Weight: "weight",
	Child: "child",
	Slider: "sliders-h",
	Camera: "camera",
	Microphone: "microphone",
	Calendar: "calendar",
	CalendarWeek: "calendar-week",
	CalendarDay: "calendar-day",
	ExpandArrowsAlt: "expand-arrows-alt",
	ArrowUp: "chevron-down",
	ArrowDown: "chevron-up",
	SearchLocation: "location-arrow",
	Email: "envelope",
	Phone: "phone",
	BookMedical: "book-medical",
	Database: "database",
	PersonBooth: "person-booth",
	Globe: "globe",
	Question: "question",
	Comment: "comment",
	Chart: "chart-line",
	Powerpoint: "file-powerpoint",
	List: "list",
	Moon: "moon",
	Cloud: "cloud",
	Eye: "eye"

};

library.add(faHome);
library.add(faSearch);
library.add(faAngleLeft);
library.add(faAngleRight);
library.add(faAngleDoubleDown);
library.add(faAngleDoubleUp);
library.add(faAngleDoubleLeft);
library.add(faAngleDoubleRight);
library.add(faInfoCircle);
library.add(faSortAmountUp);
library.add(faSortAmountDown);
library.add(faFileUpload);
library.add(faTrashAlt);
library.add(faImage);
library.add(faFile);
library.add(faCrop);
library.add(faCheck);
library.add(faTimes);
library.add(faUser);
library.add(faEdit);
library.add(faSignOutAlt);
library.add(faSyncAlt);
library.add(faFillDrip);
library.add(faEyeDropper);
library.add(faLock);
library.add(faLockOpen);
library.add(faPlusCircle);
library.add(faMinusCircle);
library.add(faCaretDown);
library.add(faEllipsisV);
library.add(faExclamationTriangle);
library.add(faDownload);
library.add(faMusic);
library.add(faVideo);
library.add(faEnvelopeOpenText);
library.add(faFileArchive);
library.add(faFileAudio);
library.add(faFilePdf);
library.add(faWeight);
library.add(faChild);
library.add(faSlidersH);
library.add(faCamera);
library.add(faMicrophone);
library.add(faCalendar);
library.add(faCalendarWeek);
library.add(faCalendarDay);
library.add(faExpandArrowsAlt);
library.add(faChevronDown);
library.add(faChevronUp);
library.add(faLocationArrow);
library.add(faEnvelope);
library.add(faPhone);
library.add(faBookMedical);
library.add(faDatabase);
library.add(faPersonBooth);
library.add(faGlobe);
library.add(faQuestion);
library.add(faComment);
library.add(faChartLine);
library.add(faFilePowerpoint);
library.add(faList);
library.add(faMoon);
library.add(faCloud);
library.add(faEye);