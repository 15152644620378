import * as React from "react";

export interface Props {
	refVideo: React.RefObject<HTMLVideoElement>;
	width: number;
	height: number;
}

export default function VideoPlayer(props: Props) {
	return (
		<div className="video-player-container d-block mb-3">
			<video controls autoPlay className="video-player" ref={props.refVideo} width={props.width} height={props.height}></video>
		</div>
	);
}