/**
 * Detail lékaře
 */
import React from "react";
import * as navigation from "../../../../lib/navigation";
import * as state from "../../../../lib/state";
import * as formatting from "../../../../lib/formatting";
import * as doctors from "../../home/widgets/doctors/doctors";
import * as api from "../../../../lib/api";
import Page from "../../../../components/templates/Page";
import FormsInput from "../../../../components/controls/Forms/FormsInput";
import Button from "../../../../components/controls/Button";
import FormsSelect from "../../../../components/controls/Forms/FormSelect";
import Icon from "../../../../modules/system/icons/Icon";

interface Props {
	id: string;
}

function DoctorsDetailPage(props: Props) {
	const { authorization } = state.useStateContext();
	const { apiImages } = state.useStateContext();
	const { doctorsDetail } = state.useStateContext();
	const form = doctorsDetail.form;
	const image = form.getField("id_image").value;

	function handleUpload() {
		const input = formatting.createHiddenInput();
		input.onchange = async e => {
			const file = (e as any).target.files![0];
			const uploadResult = await apiImages.upload(file) as any;
			const body = document.getElementsByTagName("body")[0];
			body.removeChild(input);
			if (uploadResult) {
				form.setField("id_image", uploadResult.id);
			}
		};
		input.click();
	}

	return (
		<Page title="Lékař - detail" description="">
			{authorization.isAdminOrOperator() &&
				<div className="page-content-container">
					<div className="ml-3 mb-3 text-dark">
						<div className="homepage-responsive-title d-inline">
							Lékař - detail
						</div>
					</div>
					<div className="content-container m-3">
						<div className="row">
							<div className="col-6">
								<FormsInput
									form={form}
									field="name"
								/>
							</div>
							<div className="col-6">
								<FormsSelect
									form={form}
									field="type"
									options={[
										{
											title: doctors.DoctorType.title(1),
											value: { id: 1, name: doctors.DoctorType.title(1) }
										},
										{
											title: doctors.DoctorType.title(2),
											value: { id: 2, name: doctors.DoctorType.title(2) }
										},
										{
											title: doctors.DoctorType.title(3),
											value: { id: 3, name: doctors.DoctorType.title(3) }
										},
										{
											title: doctors.DoctorType.title(4),
											value: { id: 4, name: doctors.DoctorType.title(4) }
										},
									]}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col">
								<FormsInput
									form={form}
									field="address"
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-6">
								<FormsInput
									form={form}
									field="phone"
								/>
							</div>
							<div className="col-6">
								<FormsInput
									form={form}
									field="phone2"
								/>
							</div>
							<div className="col-6">
								<FormsInput
									form={form}
									field="email"
								/>
							</div>
							<div className="col-6">
								<FormsInput
									form={form}
									field="web"
								/>
							</div>
						</div>

						<div className="row p-2 mt-3">
							<div className="col">
								<div className="mt-3 d-inline">
									{!form.getField("imported").value &&
										<Button className="font-weight-bold" onClick={doctorsDetail.save}>
											Uložit
										</Button>
									}
								</div>
								<div className="d-inline float-right">
									<Button className="font-weight-bold" onClick={() => navigation.to("/administrace/lekari")}>
										Zpět
									</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
			}
		</Page >
	);
}

export default state.bindContainers(
	DoctorsDetailPage,
	context => context.doctorsDetail
);