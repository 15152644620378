
/**
 * MedicalRecordsInfo
 */
import React, { useRef } from "react";
import Icon from "../../../../modules/system/icons/Icon";
import * as state from "../../../../lib/state";
import * as api from "../../../../lib/api";
import * as formatting from "../../../../lib/formatting";
import * as pCardWidget from "../../p-card-admin/widgets/p-card-widget/p-card-widget";
import Checkbox from "../../../../components/controls/Checkbox";
import { GeneralGraph } from "../../../../modules/shared/graphs/GeneralGraph";
import FormsEditableDiv from "../../../../components/controls/Forms/FormsEditableDiv";
import { LastMeasurementWidget } from "../../home/widgets/last-measurement/LastMeasurementWidget";
import * as  lastRecords from "../../../../modules/pages/home/widgets/last-records/last-records";
import PieChartGraph from "../../../../modules/shared/graphs/PieChart";
import MedicalRecordHideSwitch from "./MedicalRecordHideSwitch";
interface Props {
	isSecondPage?: boolean;
}

export default function MedicalRecordsInfo(props: Props) {
	const { medicalRecords } = state.useStateContext();
	const { authorization } = state.useStateContext();
	const { apiImages } = state.useStateContext();
	const profile = authorization.getUserProfile()?.profile;
	const form = medicalRecords.form;
	const container = medicalRecords.getStateContainer();
	const measurementRef = useRef<any>(null);
	const generalRef = useRef<any>(null);
	const { addLastMeasurementDialog } = state.useStateContext();
	const { simpleAddDialog } = state.useStateContext();
	const { api: apiState } = state.useStateContext();
	const { optionalViewWidget } = state.useStateContext();
	const { doctorDialog } = state.useStateContext();
	const { pilulkoidDialog } = state.useStateContext();
	const { standardDialogs } = state.useStateContext();
	const image = profile?.id_dms_file_image;
	const birthdate = profile?.birthdate ? formatting.formatDate(profile?.birthdate) : profile?.personal_identification_number ? formatting.formatDate(formatting.parseDateStringFormat(profile?.personal_identification_number!, "YYMMDD")) : "";
	const types = container.types;

	function handleRefresh() {
		measurementRef.current.loadWidgets();
	}

	// function handleFilter(e: any) {
	// 	medicalRecords.handleFilter(e, generalRef, generalRef2, generalRef3, generalRef4);
	// }

	const meassures = [
		{ id: 1, name: "Teplota" },
		{ id: 3, name: "Heart rate" },
		{ id: 4, name: "Oximetry" },
		{ id: 8, name: "Tlak" },
	] as api.CodeBookItemWithNumberId[];

	async function handleAdd(entity: string) {
		if (entity == "vaccination" || entity == "allergies" || entity == "operations") {
			await addLastMeasurementDialog.openDialog(entity == "allergies" ? { id: 4, name: "Alergie" } : entity == "vaccination" ? { id: 5, name: "Očkování" } : { id: 3, name: "Záznam" }) as any;
		}
		if (entity == "pilulkoids") {
			await pilulkoidDialog.openDialog(undefined, undefined) as any;
		}
		if (entity == "ongoing_treatments") {
			await simpleAddDialog.openDialog("ongoing_treatments", undefined, true, true, true, undefined, undefined, "Přidat léčbu");
		}
		if (entity == "doctors") {
			await doctorDialog.openDialog({ type: 1 } as any);
		}
		medicalRecords.loadPanels();
	}

	async function removeItem(i: any, entity: string) {
		const result = await apiState.del("/" + ((entity == "vaccination" || entity == "allergies" || entity == "operations") ? "treatments" : entity == "doctors" ? "user_doctors" : entity) + "/" + i.id);
		if (result) {
			medicalRecords.loadPanels();
		}
	}

	async function handleRemove(i: any, entity: string) {
		await standardDialogs.openYesNoDialog("Opravdu chcete smazat záznam?") === "yes"
			? removeItem(i, entity)
			: undefined
	}

	async function handleOpen(i: any, entity: string) {
		if (entity == "vaccination" || entity == "allergies" || entity == "operations") {
			const item = i;
			item.type = i.type.id ? i.type : { id: i.type, name: lastRecords.MyRecordType.title(i.type) };
			await addLastMeasurementDialog.openDialog(undefined, item);
		}
		if (entity == "pilulkoids") {
			await pilulkoidDialog.openDialog(i, true);
		}
		if (entity == "ongoing_treatments") {
			await simpleAddDialog.openDialog("ongoing_treatments", undefined, true, true, true, undefined, i, "Probíhající onemocnění");
		}
		if (entity == "doctors") {
			await doctorDialog.openDialog(i);
		}
		medicalRecords.loadPanels();
	}

	return (
		<>
			{(!props.isSecondPage && (!container.types.includes(1) || !container.types.includes(2) || !container.types.includes(3))) &&
				<div className={"medical-records-panel " + ((container.types.includes(1) && container.types.includes(2) && container.types.includes(3)) ? "print-hide" : "")}>
					<div className="medical-records-panel-header">
						<div className="medical-records-panel-page">
							<img width={120} src="/images/Group 655.svg" alt="group7" />
						</div>
						<div className="medical-records-panel-header-page">
							{medicalRecords.getPageSize(1, true)}
						</div>
						<div>
							<div>Datum záznamu: {formatting.formatDate(form.getField("record_date").value)}</div>
							<div className="float-right">Datum hovoru: {formatting.formatDate(form.getField("call_date").value)}</div>
						</div>
					</div>
					{!container.types.includes(1) &&
						<>
							<div className="text-center">
								<MedicalRecordHideSwitch type={1} />
								<h2 className="medical-records-panel-title font-weight-bold d-inline">Zdravotní záznam - Kontinuální monitoring</h2>
							</div>
							<div className={types.includes(1) ? "print-hide" : ""}>
								<div className="medical-records-panel-blue medical-records-panel-main text-center medical-records-panel-blue-text mb-4">
									Základní údaje
								</div>
								<div className="d-flex mt-1">
									<div className="mr-3" style={{ width: "33%" }}>
										<div style={{ width: 360 }} className="d-flex">
											<div style={{ width: " 80%" }}>
												<div className={"medical-records-name "}>
													{profile?.full_name}
												</div>
												<div className={"medical-records-name-age d-inline "}>
													{birthdate &&
														<>
															{birthdate} ({formatting.calculateAge(new Date(profile?.birthdate ? profile?.birthdate : birthdate))} let)
														</>
													}
												</div>
											</div>
											<div className="medical-records-list-img">
												<img style={{ height: 60 }} width={60} height={60} alt="user" src={image == null || image == api.emptyGuid ? "/images/user.jpg" : apiImages.getImageUrl(profile?.id_dms_file_image!)} />
											</div>
										</div>
										<div className="d-flex mt-5">
											<div className="mr-2" style={{ width: 110 }}>
												<div className="medical-records-list-item">
													<Icon className="medical-records-list-item-icon" size="lg" picture="Child" />
													<div className={"medical-records-list-item-text "}>{profile?.height} cm</div>
												</div>
											</div>
											<div className="mr-2" style={{ width: 110 }}>
												<div className="medical-records-list-item">
													<Icon className="medical-records-list-item-icon" picture="Weight" />
													<div className={"medical-records-list-item-text"}>{profile?.weight} kg</div>
												</div>
											</div>
											<div className="mr-2" style={{ width: 110 }}>
												<div className="medical-records-list-item">
													<img className="medical-records-list-item-icon" src="/images/droplet-fill.svg" alt="droplet-fill" />
													<div className={"medical-records-list-item-text "}>{profile?.ab}</div>
												</div>
											</div>
										</div>
									</div>
									<div className="mr-3" style={{ width: "33%" }}>
										<div style={{ width: 343 }} className="medical-records-list-item-row">
											<div>
												<div style={{ height: 90 }} className="medical-records-list-item">
													<div className="medical-records-list-item-title">Adresa:</div>
													<div className={"medical-records-list-item-text "}>{profile?.address}</div></div>
											</div>
										</div>
										<div style={{ width: 343 }} className="medical-records-list-item-row mt-3">
											<div>
												<div className="medical-records-list-item">
													<div className="medical-records-list-item-title">Rodinné zázemí:</div>
													<div className={"medical-records-list-item-text "}>{pCardWidget.UserType.title(profile?.type!)}</div></div>
											</div>
										</div>
									</div>
									<div className="mr-3" style={{ width: "33%" }}>
										<div className="medical-records-list-item-row">
											<div>
												<div className="medical-records-list-item">
													<div className="medical-records-list-item-title">Rodné číslo:</div>
													<div className={"medical-records-list-item-text "}>{(profile?.personal_identification_number!) + (profile?.personal_identification_number_sufix)}</div></div>
											</div>
										</div>
										<div className="medical-records-list-item-row mb-3">
											<div>
												<div className="medical-records-list-item">
													<div className="medical-records-list-item-title">Krizový kontakt:</div>
													<div className={"medical-records-list-item-text "}>{profile?.crisis_name}</div>
												</div>
											</div>
										</div>
										<div className="medical-records-list-item-row mb-3">
											<div>
												<div className="medical-records-list-item">
													<div className="medical-records-list-item-title">Krizový telefon:</div>
													<div className={"medical-records-list-item-text "}>{profile?.crisis_phone}</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="d-flex mt-2">
									<div style={{ width: "33%" }} className="mb-3">
										<div style={{ width: 343 }} className="medical-records-list-item">
											<div style={{ height: 175 }} className="individual-report-panel-item-container">
												<div className="medical-records-list-item-title mb-1 d-inline">Alergie:</div>
												<div onClick={() => handleAdd("allergies")} className="d-inline-block widget-list-panel-add mr-2">
													Přidat <Icon picture="Add" />
												</div>
												<hr className="mt-1 mb-0 mr-2" />
												<div className="d-block mt-1">
													{container.allergies.map((x, index) =>
														<div>
															<div onClick={() => handleOpen(x, "allergies")} className="d-inline cursor-pointer" key={index}>{x.name}</div>
															<div onClick={() => handleRemove(x, "allergies")} className="float-right d-inline cursor-pointer mr-2 color-primary">
																<Icon className="print-hide" picture="Remove" />
															</div>
														</div>
													)}
												</div>
											</div>
										</div>
									</div>
									<div className=" mr-3" style={{ width: "33%" }}>
										<div className="medical-records-list-item-row mb-3">
											<div style={{ width: 360 }}>
												<Checkbox
													title={"Bez dlouhodobé medikace"}
													className="mt-0 pt-0"
													checked={profile?.no_long_term_medication}
													readOnly={true}
												/>
												<div style={{ height: 157 }} className="medical-records-list-item">
													<div style={{ height: 175 }} className="individual-report-panel-item-container">
														<div className="medical-records-list-item-title mb-1 d-inline">Užívaná medikace:</div>
														<div onClick={() => handleAdd("pilulkoids")} className="d-inline-block widget-list-panel-add mr-2">
															Přidat <Icon picture="Add" />
														</div>
														<hr className="mt-1 mb-0 mr-2" />
														<div className="d-block mt-1">
															{container.drugs.map((x, index) =>
																<div>
																	<div onClick={() => handleOpen(x, "pilulkoids")} className="d-inline cursor-pointer" key={index}>{x.name}</div>
																	<div onClick={() => handleRemove(x, "pilulkoids")} className="float-right d-inline cursor-pointer mr-2 color-primary">
																		<Icon className="print-hide" picture="Remove" />
																	</div>
																</div>
															)}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div style={{ width: "33%" }} className="mb-3">
										<Checkbox
											title={"Bez dlouhodobého onemocnění"}
											className="mt-0 pt-0"
											checked={profile?.no_long_term_medication}
											readOnly={true}
										/>
										<div style={{ height: 157 }} className="medical-records-list-item">
											<div style={{ height: 175 }} className="individual-report-panel-item-container">
												<div className="medical-records-list-item-title mb-1 d-inline">Probíhající onemocnění:</div>
												<div onClick={() => handleAdd("ongoing_treatments")} className="d-inline-block widget-list-panel-add mr-2">
													Přidat <Icon picture="Add" />
												</div>
												<hr className="mt-1 mb-0 mr-2" />
												<div className="d-block mt-1">
													{container.ongoing_treatments.map((x, index) =>
														<div>
															<div onClick={() => handleOpen(x, "ongoing_treatments")} className="d-inline cursor-pointer" key={index}>{x.name}</div>
															<div onClick={() => handleRemove(x, "ongoing_treatments")} className="float-right d-inline cursor-pointer mr-2 color-primary">
																<Icon className="print-hide" picture="Remove" />
															</div>
														</div>
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="d-flex mt-2">
									<div style={{ width: "33%" }} className="mb-3 mr-3">
										<div style={{ width: 343 }} className="medical-records-list-item">
											<div style={{ height: 175 }} className="individual-report-panel-item-container">
												<div className="medical-records-list-item-title mb-1 d-inline">Zdravotní záznamy:</div>
												<div onClick={() => handleAdd("operations")} className="d-inline-block widget-list-panel-add mr-2">
													Přidat <Icon picture="Add" />
												</div>
												<hr className="mt-1 mb-0 mr-2" />
												<div className="d-block mt-1">
													{container.operations.map((x, index) =>
														<div>
															<div onClick={() => handleOpen(x, "operations")} className="d-inline cursor-pointer" key={index}>{x.name}</div>
															<div onClick={() => handleRemove(x, "operations")} className="float-right d-inline cursor-pointer mr-2 color-primary">
																<Icon className="print-hide" picture="Remove" />
															</div>
														</div>
													)}
												</div>
											</div>
										</div>
									</div>
									<div style={{ width: "33%" }} className="mb-3 mr-3">
										<div style={{ width: 360 }} className="medical-records-list-item">
											<div style={{ height: 175 }} className="individual-report-panel-item-container">
												<div className="medical-records-list-item-title mb-1 d-inline">Ošetřující lékaři:</div>
												<div onClick={() => handleAdd("doctors")} className="d-inline-block widget-list-panel-add mr-2">
													Přidat <Icon picture="Add" />
												</div>
												<hr className="mt-1 mb-0 mr-2" />
												<div className="d-block mt-1">
													{container.doctors.map((x, index) =>
														<div>
															<div onClick={() => handleOpen(x, "doctors")} className="d-inline cursor-pointer" key={index}>{x.name}</div>
															<div onClick={() => handleRemove(x, "doctors")} className="float-right d-inline cursor-pointer mr-2 color-primary">
																<Icon className="print-hide" picture="Remove" />
															</div>
														</div>
													)}
												</div>
											</div>
										</div>
									</div>
									<div style={{ width: "33%" }} className="mb-3">
										<div style={{ width: 360 }} className="medical-records-list-item">
											<div style={{ height: 175 }} className="individual-report-panel-item-container">
												<div className="medical-records-list-item-title mb-1 d-inline">Očkování:</div>
												<div onClick={() => handleAdd("vaccination")} className="d-inline-block widget-list-panel-add mr-2">
													Přidat <Icon picture="Add" />
												</div>
												<hr className="mt-1 mb-0 mr-2" />
												<div className="d-block mt-1">
													{container.vaccination.map((x, index) =>
														<div>
															<div onClick={() => handleOpen(x, "vaccination")} className="d-inline cursor-pointer" key={index}>{x.name}</div>
															<div onClick={() => handleRemove(x, "vaccination")} className="float-right d-inline cursor-pointer mr-2 color-primary">
																<Icon className="print-hide" picture="Remove" />
															</div>
														</div>
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</>
					}
					{!container.types.includes(2) &&
						<>
							<MedicalRecordHideSwitch top={container.types.includes(1) ? "0px" : undefined} type={2} />
							<div className={types.includes(2) ? "print-hide" : ""}>
								<div>
									<div className="medical-records-panel-blue medical-records-panel-main text-center medical-records-panel-blue-text mb-4 mt-4">
										Počet měření za poslední měsíc
									</div>
									<div className="d-flex mt-2 mb-2">
										<div>
											<PieChartGraph loading={container.loadingMeasurements} data={container.all_feeling_meassurements} />
										</div>
										<div>
											<PieChartGraph loading={container.loadingMeasurements} data={container.all_meassurements} />
										</div>
									</div>
								</div>
							</div>
						</>
					}
					{!container.types.includes(3) &&
						<>
							<MedicalRecordHideSwitch top={container.types.includes(2) && !container.types.includes(1) ? "-24px" : "-36px"} type={3} />
							<div className={types.includes(3) ? "print-hide" : ""}>
								<hr className="mt-3" />
								<h3 className="mt-1 mb-1">Poslední měření
									<span onClick={handleRefresh} className="d-inline ml-2 color-primary cursor-pointer"><Icon picture="Refresh" /></span>
								</h3>
								<div className="d-flex medical-records-info-widgets-container">
									<div style={{ width: "50%" }}>
										<div className="medical-records-info-widgets">
											<LastMeasurementWidget ref={measurementRef} measurementSelected={container.measurement_selected as any} isRecord={true} />
										</div>
									</div>
									<div style={{ width: "50%" }}>
										<div style={{ width: 550, marginTop: 12 }}>
											<div className="medical-records-list-item medical-records-list-item-anamnesis">
												<div className="medical-records-list-item-title">Osobní anamnéza</div>

												<FormsEditableDiv
													field="personal_description"
													form={medicalRecords.form}
													className="mb-1"
												/>

												<div className="d-block">
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</>
					}
					<div className="medical-records-panel-footer">
						{medicalRecords.getPageSize(1, true)}
					</div>
				</div>
			}
			{props.isSecondPage && !container.types.includes(4) &&
				<div className={"medical-records-panel " + (types.includes(4) ? "print-hide" : "")}>
					<div className="medical-records-panel-header">
						<div className="medical-records-panel-page">
							<img width={120} src="/images/Group 655.svg" alt="group7" />
						</div>
						<div className="medical-records-panel-header-page">
							{medicalRecords.getPageSize(2, true)}
						</div>
						<div>
							<div>Datum záznamu: {formatting.formatDate(form.getField("record_date").value)}</div>
							<div className="float-right">Datum hovoru: {formatting.formatDate(form.getField("call_date").value)}</div>
						</div>
					</div>
					<div className="text-center">
						<MedicalRecordHideSwitch type={4} />
						<h2 style={{ width: "60 %" }} className="medical-records-panel-title font-weight-bold">Zobrazení dat</h2>
					</div>
					{/* <div className="medical-records-panel-blue medical-records-panel-main text-center medical-records-panel-blue-text mb-4">
						<span>Filrace</span>
					</div>
					<div>Filtrace pro zobrazení dat za poslední měsíc. Pomocí přepínače můžete přepínat mezi týdnem/měsícem</div>
					<div className="mb-4 mt-3">
						<Switch
							title={container.showWeek ? "Zobrazení týdne" : "Zobrazení měsíce"}
							value={container.showWeek}
							onCheckedChanged={handleFilter}
						/>
					</div> */}
					<div className="medical-records-panel-blue medical-records-panel-main text-center medical-records-panel-blue-text mb-4">
						<span>Vitální údaje</span>
					</div>
					{meassures.map((i, index) =>
						<GeneralGraph
							key={index}
							data={container.measurementData}
							ref={generalRef}
							showWeek={container.showWeek}
							width="100%"
							date_from={container.measures_date_from}
							date_to={container.measures_date_to}
							secondType={i.id == 8 ? { id: 9, name: "DIA" } : undefined}
							saving={container.saving}
							type={{ id: i.id, name: i.name }}
							types={[{ id: i.id, name: i.name }]}
							disabled={true}
							measurement={true}
						/>
					)}
					<div className="medical-records-panel-footer">
						{medicalRecords.getPageSize(2, true)}
					</div>
				</div>
			}
		</>
	);
}