/**
 * Detail reportu
 */
import React, { useRef } from "react";
import * as state from "../../../../lib/state";
import * as api from "../../../../lib/api";
import * as formatting from "../../../../lib/formatting";
import Page from "../../../../components/templates/Page";
import { PDFExport } from "@progress/kendo-react-pdf";
import Button from "../../../../components/controls/Button";
import Icon from "../../../../modules/system/icons/Icon";
import IndividualReportInfoPage from "./IndividualReportInfoPage";
import IndividualReportsChartsPage from "./IndividualReportsChartsPage";
import Spinner from "../../../../components/controls/Spinner";


function IndividualReportPage() {
	const pdfRef = useRef<any>(null);
	const { authorization } = state.useStateContext();
	const { individualReport } = state.useStateContext();

	async function handlePrint() {
		await individualReport.savePdf(pdfRef.current);
	}

	return (
		<Page title="Individuální report" description="">
			<div style={{ overflow: "auto" }} className="page-content-container page-content-medical-records-container">
				<div style={{ height: 40 }} className="text-center mt-3">
					{!individualReport.getStateContainer().showButtons &&
						<Spinner className="mr-2" small />
					}
					{individualReport.getStateContainer().showButtons &&
						<>
							<Button onClick={handlePrint} className="pt-2 pb-2 text-center mr-2">
								Stáhnout PDF <Icon className="ml-2 mb-1 color-primary" picture="Download" />
							</Button>
							<Button onClick={() => individualReport.save(pdfRef.current)} className="pt-2 pb-2 mr-2 text-center btn-success">
								Uložit report
							</Button>
						</>
					}
				</div>
				<PDFExport
					ref={pdfRef}
					scale={0.49}
					margin={{ left: -30 }}
					paperSize="A4"
					fileName={"Report_" + authorization.getUserProfile()?.profile.personal_identification_number + authorization.getUserProfile()?.profile.personal_identification_number_sufix + "_" + formatting.formatDateTime(new Date()) + ".pdf"}
				>
					<div className="individual-report-container">
						<div className="medical-records">
							<IndividualReportInfoPage />
							<IndividualReportsChartsPage />
						</div>
					</div>
				</PDFExport>
			</div>
		</Page >
	);
}

export default state.bindContainers(
	IndividualReportPage,
	context => context.individualReport
);