import * as dialogs from "../../../../shared/dialogs/dialogs";
import * as context from "../../../../../context";
import * as forms from "../../../../../lib/forms";
import * as api from "../../../../../lib/api";
import * as formatting from "../../../../../lib/formatting";
import * as notification from "../../../../../lib/notification";

export enum PainType {
	SubjectiveFeelingOfHealth = 101,
	SubjectiveFeelingOfEnergy = 102,
	SubjectiveMoodFeeling = 103,
	Sleep = 104,
	FoodAndExcretion = 105,
	Breathing = 106,
	SightSmellHearing = 107,
	Pain = 108,
	MentalHealth = 109,
	CompleteHealthEntry = 110,
}

/**
 * Metody stavu bolesti.
 */
export namespace PainType {
	export function title(v: PainType) {
		switch (v) {
			case PainType.Pain: return "Bolest";
			case PainType.MentalHealth: return "Mentální zdraví";
			case PainType.SubjectiveFeelingOfHealth: return "Subjektivní pocit zdraví";
			case PainType.SubjectiveFeelingOfEnergy: return "Subjektivní pocit energie";
			case PainType.SubjectiveMoodFeeling: return "Subjektivní pocit nálady";
			case PainType.Sleep: return "Spánek";
			case PainType.FoodAndExcretion: return "Jídlo a vylučování";
			case PainType.Breathing: return "Dýchání";
			case PainType.SightSmellHearing: return "Zrak, čich, sluch";
			case PainType.CompleteHealthEntry: return "Kompletní zdravotní záznam";
		}
	}
}

export interface FormFields {
	id: string;
	name: string;
	description: string;
	date: string;
	type?: PainType;
	disabled: boolean;
	pain_intensity: number[] | null;
	pains: PainMonitor[];
	sys_date_create: Date;
}

export interface PainMonitor {
	description: string;
	name: string;
	type?: PainType;
	pain_intensity: number[] | null;
	pain_intensity_from?: number;
}

interface FormData extends FormFields {
	pains: PainMonitor[];
}

export interface State {
}

export const labels = [
	{
		id: PainType.SubjectiveFeelingOfHealth,
		items: [
			"cítím se v úplné tělesné, duševní i sociální pohodě, nepřijdu si vůbec nijak nemocný/á.",
			"cítím se v tělesné, duševní i sociální pohodě, moje nemoci jsou dobře kompenzované.",
			"cítím se v dobře, bojuji s nějakou nemocí.",
			"necítím se v tělesné, duševní nebo sociální pohodě, přijdu si nemocný/á.",
			"Cítím se nemocný/á.",
		]
	},
	{
		id: PainType.SubjectiveFeelingOfEnergy,
		items: [
			"cítím se v úplné tělesné, duševní i sociální pohodě, nepřijdu si vůbec nijak nemocný/á.",
			"cítím se v tělesné, duševní i sociální pohodě, moje nemoci jsou dobře kompenzované.",
			"cítím se v dobře, bojuji s nějakou nemocí.",
			"necítím se v tělesné, duševní nebo sociální pohodě, přijdu si nemocný/á.",
			"cítím se nemocný/á.",
		]
	},
	{
		id: PainType.SubjectiveMoodFeeling,
		items: [
			"cítím se v úplné tělesné, duševní i sociální pohodě, nepřijdu si vůbec nijak nemocný/á.",
			"cítím se v tělesné, duševní i sociální pohodě, moje nemoci jsou dobře kompenzované.",
			"cítím se v dobře, bojuji s nějakou nemocí.",
			"necítím se v tělesné, duševní nebo sociální pohodě, přijdu si nemocný/á.",
			"cítím se nemocný/á.",
		]
	},
	{
		id: PainType.Sleep,
		items: [
			"skvěle usínám spím a budím se vyspalý/á",
			"usínám a spím dobře, budím se vyspalý/á",
			"občas usínám delší dobu, spánek je přerušovaný, budím se nevyspalý/á",
			"činí mi potíže usnout, spánek je přerušovaný, budím se nevyspalý/á",
			"nemůžu usnout bez léků, budím se, jsem nevyspalý/á",
		]
	},
	{
		id: PainType.FoodAndExcretion,
		items: [
			"mám dobrou chuť k jídlu, chutná mi, moje vylučování je v pořádku",
			"nemám potíže s příjmem potravy ani vylučováním",
			"najím se, občas nemám chuť k jídlu, hubnu nebo přibírám aniž bych chtěl/a, mám potíže s vylučováním",
			"k jídlu se musím nutit / od jídla se musím brzdit, mám potíže s vylučováním",
			"najíst se mi činí velké potíže, hubnu aniž bych chtěl/a / přejídám se / mám úporný průjem / zácpu",
		]
	},
	{
		id: PainType.Breathing,
		items: [
			"Vůbec nevím o tom, že dýchám, nečiní mi to žádné potíže",
			"Běžné činnosti zvládnu bez zadýchání, při svižné chůzi nebo běhu se zadýchám víc než dřív",
			"Při běžné námaze se mi hůře dýchá, musím se zastavit po 50 metrech chůze pro dušnost",
			"Cítím nedostatek vzduchu i při minimální námaze",
			"Cítím nedostatek vzduchu vklidu, dusím se",
		]
	},
	{
		id: PainType.SightSmellHearing,
		items: [
			"moje smysly jsou bystré, vidím, slyším, citím se skvěle",
			"moje smysly jsou v pořádku, vidím slyším a cítím se dostatečně",
			"moje smysly jsou v pořádku, mám jen lehkou vadu zraku / sluchu / čichu",
			"dělá mi potíže vidět / slyšet nebo cítit běžné věci",
			"porucha zraku, sluchu, nebo čichu je v mém životě výrazně limitující",
		]
	},
	{
		id: PainType.Pain,
		items: [
			"trápí Vás slabá bolest, můžete užívat běžné léky proti bolesti, porada s Vaším lékařem je vhodná.",
			"trpíte středně silnou bolestí, je možné, že není léčena správně. Není nutné trpět v klidu ani při pohybu. Ptejte se Vašeho lékaře, zda je léčba dostatečná a co můžete udělat pro dosažení úlevy. Můžete také vyhledat specialistu – algeziologa.",
			"trpíte silnou bolestí a pokud nemůžete v noci spát, je téměř jisté, že jste léčeni nedostatečně. Pokud Vám lékař nedokáže zajistit úlevu, vyhledejte specialistu – algeziologa. Mějte na paměti, že na bolest této intenzity je normální indikovaně užívat opioidní analgetika.",
		]
	},
];

export class Model {
	public dialog: dialogs.Dialog<undefined, State>;
	public formsPains: forms.FormCollection<PainMonitor>;
	public form: forms.Form<FormFields>;

	constructor(private context: context.StateContext) {
		this.dialog = new dialogs.Dialog<undefined, State>({
			defaultCustomFields: {
			}
		}, context);
		this.form = new forms.Form<FormFields>({
			fields: {
				id: {
					defaultValue: api.emptyGuid,
					title: ""
				},
				pains: {
					defaultValue: [],
					title: ""
				},
				type: {
					defaultValue: undefined,
					title: ""
				},
				name: {
					title: "Název",
					defaultValue: "",
					readOnly: (form) => form.getField("disabled").value,
					required: (form) => true,
					validate: (value, field, form) => form.getField("type").value == PainType.CompleteHealthEntry ? "" : (value.length == 0 ? "Vyplňte název" : "")
				},
				description: {
					title: "Popis",
					defaultValue: "",
					readOnly: (form) => form.getField("disabled").value,
				},
				pain_intensity: {
					defaultValue: null,
					title: "",
					readOnly: (form) => form.getField("disabled").value,
					required: (form) => true,
					validate: (value, field, form) => form.getField("type").value == PainType.CompleteHealthEntry ? "" : (!value ? "Vyberte hodnotu" : "")
				},
				date: {
					title: "Datum",
					defaultValue: formatting.formatDateForInputDateTime(new Date()),
					readOnly: (form) => form.getField("disabled").value,
					required: (form) => true,
					validate: (value) => value.length == 0 ? "Vyplňte datum" : ""
				},
				disabled: {
					title: "",
					defaultValue: false
				},
				sys_date_create: {
					defaultValue: new Date(),
					title: ""
				},
			}
		}, context);
		this.formsPains = new forms.FormCollection({
			fields: {
				description: {
					title: "Popis",
					defaultValue: "",
				},
				pain_intensity: {
					defaultValue: [],
					title: "",
					required: (form) => true,
					validate: (value) => !value ? "Vyberte hodnotu" : ""
				},
				type: {
					defaultValue: undefined,
					title: ""
				},
				name: {
					defaultValue: "",
					title: ""
				},
			}
		}, context, this.form);

	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			...this.form.getStateContainers(),
			...this.dialog.getStateContainers()
		];
	}

	openDialog = async (values?: FormFields, disabled?: boolean) => {
		await this.form.clearFields();
		await this.formsPains.clearFields();
		await this.formsPains.setFields([]);
		this.form.setField("disabled", disabled == undefined ? false : disabled);
		if (values) {
			await this.form.setFields(values);
			if (values.pains.length) {
				await this.formsPains.setFields(values.pains.map(x => ({ ...x, pain_intensity: [x.pain_intensity_from] as any })));
			}
		}

		return this.dialog.open();
	}

	save = async () => {
		await this.form.validate();
		await this.form.validate();
		if (this.form.isValid()) {
			let fields = this.form.getFields();
			const formPains = this.formsPains.get();
			fields.pain_intensity = fields.pain_intensity == null ? [] : [fields.pain_intensity] as any;
			if (formPains.length > 0) {
				fields.name = PainType.title(PainType.CompleteHealthEntry);
				fields.date = formatting.formatDate(new Date(), { format: "iso" });
			}

			const data: FormData = {
				...fields,
				pains: formPains.map(f => f.getFields()).map(x => ({ ...x, pain_intensity: [x.pain_intensity] as any })),
			};

			await this.context.api.put("/pain_monitors/" + api.emptyGuid, data);
			this.context.painMonitor.loadData();
			this.context.calendarPage.loadData();
			return this.dialog.close();
		}
		else {
			await notification.errorMessage("Formulář obsahuje neúplné informace nebo chyby. Opravte prosím červeně vyznačené položky a poté akci opakujte.");
		}
	}

	remove = async () => {
		const id = this.form.getField("id").value;
		const result = await this.context.api.del("/pain_monitors/" + id);
		if (result) {
			notification.successMessage("Záznam byl úspěšně odebrán.");
			this.context.painMonitor.loadData();
			this.dialog.close();
		}
	}
}