/**
 * Widget Pain monitor
 */
import * as React from "react";
import * as state from "../../../../../lib/state";
import Slider from "react-slick";
import * as painMonDialog from "./pain-monitor-dialog";
import * as formatting from "../../../../../lib/formatting";
import MyRequestAction from "../../../../../components/controls/MyRequestAction";
import Help from "../../../../../components/controls/Help";
import Icon from "../../../../../modules/system/icons/Icon";


export function PainMonitor() {
	const { painMonitor: model } = state.useStateContext();
	const { painMonitorDialog } = state.useStateContext();

	const actions = [{
		title: "Přidat záznam",
		icon: "Add",
		action: async () => {
			handleAdd();
		},
	}];

	function handleAdd() {
		painMonitorDialog.openDialog(undefined, false);
	}

	function handleDialog(i: painMonDialog.FormFields) {
		painMonitorDialog.openDialog(i as any, true);
	}

	function handleIndex(index: number) {
		model.form.setField("index", index);
	}

	var settings = {
		slidesToShow: 3,
		slidesToScroll: 1,
		arrows: false,
		swipeToSlide: true,
		focusOnSelect: true,
		speed: 250,
		afterChange: handleIndex
	};

	const currentIndex = model.form.getField("index").value;

	return (
		<div>
			<div className="pain-monitor-container">
				<div className="homepage-widget-header">
					<img className="d-inline mr-2" src="/images/Group 651.svg" alt="qr-code" />
					<h2 className="d-inline">Zdravotní deník</h2>
					<Help id="pain-monitor-help" text="Pravidelně si zaznamenávejte jak se cítíte a v jaké jste kondici." />
					{/* <MyRequestAction actions={actions as any} /> */}
					<span onClick={handleAdd} className="cursor-pointer float-right color-primary"><Icon picture="Add" /></span>
				</div>
				<div className="slider-container mt-2">
					<Slider {...settings}>
						<div>
							<div className={"last-records-item " + (currentIndex == 0 ? "last-records-item-active" : "")}>
								Vitalita
							</div>
						</div>
						<div>
							<div className={"last-records-item " + (currentIndex == 1 ? "last-records-item-active" : "")}>
								Energie
							</div>
						</div>
						<div>
							<div className={"last-records-item " + (currentIndex == 2 ? "last-records-item-active" : "")}>
								Nálada
							</div>
						</div>
						<div>
							<div className={"last-records-item " + (currentIndex == 3 ? "last-records-item-active" : "")}>
								Spánek
							</div>
						</div>
						<div>
							<div className={"last-records-item " + (currentIndex == 4 ? "last-records-item-active" : "")}>
								Jídlo a vylučování
							</div>
						</div>
						<div>
							<div className={"last-records-item " + (currentIndex == 5 ? "last-records-item-active" : "")}>
								Dýchání
							</div>
						</div>
						<div>
							<div className={"last-records-item " + (currentIndex == 6 ? "last-records-item-active" : "")}>
								Smysly
							</div>
						</div>
						<div>
							<div className={"last-records-item " + (currentIndex == 7 ? "last-records-item-active" : "")}>
								Bolest
							</div>
						</div>
						<div>
							<div className={"last-records-item " + (currentIndex == 8 ? "last-records-item-active" : "")}>
								Mentální zdraví
							</div>
						</div>
						<div>
							<div className={"last-records-item " + (currentIndex == 9 ? "last-records-item-active" : "")}>
								Kompletní záznam
							</div>
						</div>
						<div>
							<div className={"last-records-item " + (currentIndex == 10 ? "last-records-item-active" : "")}>
								Vše
							</div>
						</div>
					</Slider>
				</div>
				<div className="mt-3 pain-monitor-list-container">
					<div className="row">
						<div className="col-sm-12 mb-3">
							<div>
								{model.form.getField("items").value.map((i, index) =>
									<React.Fragment key={index}>
										{((i.type == currentIndex + 101) || currentIndex == 10) &&
											<div onClick={() => handleDialog(i as any)} key={index} className="pain-monitor-list-item">
												<div className="pain-monitor-list-item-text d-inline">{i.name}</div>
												<span className="pain-monitor-list-item-date">{formatting.formatDate(i.date)}</span>
											</div>
										}
									</React.Fragment>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default state.bindContainers(
	PainMonitor,
	c => c.painMonitor
);