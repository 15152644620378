import * as React from "react";
import * as state from "../../../lib/state";

import { Button } from "reactstrap";
import Dialog from "../../shared/dialogs/Dialog";
import ImageEdit from "./ImageEdit";

export function ImageEditDialog() {
	const { images } = state.useStateContext();

	return (
		<>
			<Dialog
				dialog={images.dialog}>

				<ImageEdit
					id={images.getEditingImageId()}
					onChange={(imageId: any) => images.setDialogImageId(imageId)}
				/>
				<div className="mt-4 text-center">
					<Button onClick={e => images.confirmEditDialog()} className="pt-2 pb-2 float-right button button-secondary">
						<strong>Uložit</strong>
					</Button>
				</div>
			</Dialog>
		</>
	);
}

export default state.bindContainers(
	ImageEditDialog,
	context => context.images
);