import * as dialogs from "../dialogs/dialogs";
import * as context from "../../../context";
import * as apiHome from "../../pages/home/api-home";

interface State {
	content?: apiHome.Content;
	contentItem?: apiHome.ContentItem;
	fullscreen: boolean;
	isMobile: boolean;
}

export class Model {
	public dialog: dialogs.Dialog<undefined, State>;

	constructor(private context: context.StateContext) {
		this.dialog = new dialogs.Dialog<undefined, State>({
			defaultCustomFields: {
				content: undefined,
				contentItem: undefined,
				fullscreen: false,
				isMobile: false
			}
		}, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			...this.dialog.getStateContainers()
		];
	}

	getContentItem = () => {
		return this.dialog.getCustomField("contentItem");
	}

	getContent = () => {
		return this.dialog.getCustomField("content");
	}

	getIsMobile = () => {
		return this.dialog.getCustomField("isMobile");
	}

	openDialog = async (contentItem: apiHome.ContentItem, isMobile: boolean, content: apiHome.Content) => {
		this.dialog.setCustomField("content", content);
		this.dialog.setCustomField("contentItem", contentItem);
		this.dialog.setCustomField("fullscreen", false);
		this.dialog.setCustomField("isMobile", isMobile);
		return this.dialog.open();
	}

	save = async () => {
		return this.dialog.close();
	}
}