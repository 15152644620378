/**
 * Seznam uživatelů
 */
import React, { useState } from "react";
import * as state from "../../../../lib/state";
import * as navigation from "../../../../lib/navigation";
import * as formatting from "../../../../lib/formatting";
import * as apiLib from "../../../../lib/api";
import Page from "../../../../components/templates/Page";
import List from "../../../../modules/system/lists/List";
import MyRequestAction from "../../../../components/controls/MyRequestAction";
import Icon from "../../../../modules/system/icons/Icon";
import Button from "../../../../components/controls/Button";
import Link from "../../../../components/controls/Link";
import Spinner from "../../../../components/controls/Spinner";
import Checkbox from "../../../../components/controls/Checkbox";

function DoctorsListPage() {
	const { doctorsList } = state.useStateContext();
	const { api } = state.useStateContext();
	const { authorization } = state.useStateContext();
	const { standardDialogs } = state.useStateContext();
	const [loadImport, setLoadImport] = useState(false);

	function actions(item: any) {
		const items = [
			{
				title: "Smazat lékaře",
				action: async (item: any) => {
					await standardDialogs.openYesNoDialog("Opravdu chcete smazat lékaře?") === "yes"
						? doctorsList.remove(item.id)
						: undefined
				},
			},
		];
		if (item.id_state == 2) {
			items.push({
				title: "Schválit lékaře",
				action: async (item: any) => {
					await standardDialogs.openYesNoDialog("Opravdu chcete schválit lékaře?") === "yes"
						? doctorsList.accept(item)
						: undefined
				},
			})
		}
		return items as any;
	};

	async function handleImport() {
		const input = formatting.createHiddenInput();
		input.onchange = async e => {
			setLoadImport(true);
			const file = (e as any).target.files![0];
			let formData = new FormData();
			formData.append("file", file);
			const uploadResult = await api.post("/doctors/import", formData) as any;
			const body = document.getElementsByTagName("body")[0];
			body.removeChild(input);
			if (uploadResult) {
				doctorsList.list.load();
			}
			setLoadImport(false);
		};
		input.click();
	}

	async function handleChecked() {
		await doctorsList.stateContainer.merge(() => ({ checked: !doctorsList.getChecked() }));
		doctorsList.list.load();
	}

	async function handleClear() {
		await doctorsList.stateContainer.merge(() => ({ checked: undefined }));
		await doctorsList.list.clearFilter();
	}

	return (
		<Page title="Seznam lékařů" description="">
			{authorization.isAdminOrOperator() &&
				<div className="page-content-container users-list-page">
					<div className="text-light ml-3 mb-4">
						<div className="homepage-responsive-title d-inline">
							Seznam lékařů
						</div>
					</div>
					<div className="ml-3 mr-3">
						<div className="mt-3 mb-3">
							<Button className="d-inline-block mr-2 font-weight-bold" onClick={() => navigation.to("/administrace/lekari/" + apiLib.emptyGuid)}>
								Přidat lékaře
							</Button>
							<Button disabled={loadImport} className="d-inline-block font-weight-bold" onClick={handleImport}>
								{loadImport &&
									<Spinner className="spinner-border-sm mr-2" />
								}
								Import
							</Button>
						</div>
						<div className="form-filter u-mb-md">
							<div id="records" className="form-filter__inner row">
								<div className="col-md-5 col-xs-12 mb-3">
									<label htmlFor="filter-search" className="inp-label u-vhide">
										Zadejte jméno
									</label>
									<span className="inp-fix">
										<input
											type="text"
											id="inp-filter-search"
											placeholder={doctorsList.list.options.quickFilterPlaceholder}
											className="inp-text inp-text--filter"
											value={doctorsList.list.getFilterFulltextPhrase()}
											onChange={(e) => doctorsList.list.setFilterFulltextPhrase(e.target.value)}
										/>
									</span>
								</div>
								<div className="col-md-3 col-xs-12">
									<Checkbox className="mt-0  inp-text--filter" checked={doctorsList.getChecked()} title={"Ke schválení"} onCheckedChanged={handleChecked} />
								</div>
								<div className="col-xs-4 col-12 col-md-auto u-mb-md ml-md-auto">
									<div className="d-inline mr-2">
										<button type="submit" className="btn btn--filter" onClick={doctorsList.list.loadWithResetPage}>
											<span className="btn__text">
												Vyhledat
											</span>
										</button>
									</div>
									<div className="d-inline">
										<button type="submit" className="btn btn--filter" onClick={handleClear}>
											<span className="btn__text">
												Reset
											</span>
										</button>
									</div>
								</div>
							</div>
						</div>
						<List
							itemNode={(item, field) => {
								switch (field) {
									case "imported": return item.imported ? "Ano" : "Ne";
									case "name": return <Link to={"/administrace/lekari/" + item.id}>{item[field]}</Link>;
									case "action" as any: return <MyRequestAction className="ml-2 user-list-page-action-sync" id="users" item={item} actions={actions(item)} />;
									default: return undefined;
								}
							}}
							simpleTable list={doctorsList.list} />
					</div>
				</div>
			}
		</Page >
	);
}

export default state.bindContainers(
	DoctorsListPage,
	context => context.doctorsList
);