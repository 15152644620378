import * as React from "react";
import * as forms from "../../../lib/forms";
import * as common from "../../../lib/common";

import FormItem, { FormItemOptions } from "./FormItem";
import CheckBox, { CheckBoxProps } from "../Checkbox";


export interface FormsInputProps<TFormFields> {
	form: forms.Form<TFormFields>;
	field: keyof common.SubType<TFormFields, boolean | undefined>;
	className?: string;
	checkBoxProps?: CheckBoxProps;
	formItemOptions?: FormItemOptions;
	enhancedTitle?: React.ReactNode;
	onSave?: (field?: any, value?: string) => void;
	disabled?: boolean;
}

export default function FormsCheckBox<TFormFields>(props: FormsInputProps<TFormFields>) {
	const field = props.form.getField(props.field);
	return (
		<FormItem
			form={props.form}
			field={props.field}
			options={{ ...props.formItemOptions, hideTitle: true }}
			className={props.className}
		>
			<CheckBox
				{...props.checkBoxProps}
				field={props.field}
				onSave={props.onSave}
				title={props.enhancedTitle ? props.enhancedTitle : props.form.getFieldTitle(props.field)}
				checked={props.form.getField(props.field).value as any}
				readOnly={field.readOnly || props.disabled}
				onCheckedChanged={value => props.form.setField(props.field, value as any)}
			/>
		</FormItem>
	);
}