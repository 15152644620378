/**
 * Widget pca card widget
 */
import React from "react";
import Icon from "../../../../system/icons/Icon";
import * as state from "../../../../../lib/state";
import * as formatting from "../../../../../lib/formatting";
import * as pCardForm from "./p-card-widget";
import FormImageEdit from "../../../../../components/controls/Forms/FormImageEdit";
import Button from "../../../../../components/controls/Button";
import FormsEditableDiv from "../../../../../components/controls/Forms/FormsEditableDiv";
import FormsInput from "../../../../../components/controls/Forms/FormsInput";
import FormsSelect from "../../../../../components/controls/Forms/FormSelect";

interface Props {
	isEdit: boolean;
	isView: boolean;
}

function PCardWidget(props: Props) {
	const { pCardWidget } = state.useStateContext();
	const { changePasswordDialog } = state.useStateContext();
	const form = pCardWidget.form;
	const firstName = pCardWidget.form.getField("first_name").value;
	const lastName = pCardWidget.form.getField("last_name").value;
	const birthdate = pCardWidget.form.getField("birthdate").value;
	const height = pCardWidget.form.getField("height").value;
	const weight = pCardWidget.form.getField("weight").value;
	const ab = pCardWidget.form.getField("ab").value;
	const personal_identification_number = pCardWidget.form.getField("personal_identification_number").value;
	const personal_identification_numberSf = pCardWidget.form.getField("personal_identification_number_sufix").value;
	const phone = pCardWidget.form.getField("crisis_phone").value;
	const address = pCardWidget.form.getField("address").value;
	const name = pCardWidget.form.getField("crisis_name").value;
	const type = pCardWidget.form.getField("type").value;

	function handleNavigation() {
		(window as any).location = "/";
	}

	function handleChangePassword() {
		changePasswordDialog.dialog.open();
	}

	return (
		<div>
			<div className="p-card-widget-page-container">
				<div className="homepage-widget-header p-card-widget-header">
					<img className="d-inline mr-2" src="/images/edit.svg" alt="qr-code" />
					<h2 className="d-inline">{props.isEdit ? "Editace DZK" : "Náhled DZK"} <span className="font-weight-bold float-right" style={{ fontSize: 14 }}></span> {props.isView && <Button onClick={handleNavigation} className="pt-1 pb-1 float-right">
						<strong>Přejít do aplikace</strong>
					</Button>}</h2>
				</div>
				<div className="row mt-1">
					<div className="col-8">
						{!props.isEdit &&
							<div className={"p-card-widget-name"}>
								{firstName + " " + lastName}
							</div>
						}
						{props.isEdit &&
							<>
								<FormsEditableDiv
									preventHTML={true}
									field="first_name"
									onSave={pCardWidget.saveUser}
									form={form}
									className="p-card-widget-name p-card-edited p-card-widget-edited-border mt-3"
								/>
								<FormsEditableDiv
									preventHTML={true}
									field="last_name"
									onSave={pCardWidget.saveUser}
									form={form}
									className="p-card-widget-name p-card-edited p-card-widget-edited-border mt-3"
								/>
							</>
						}
						{!props.isEdit &&
							<div className={"p-card-widget-name-age"}>
								{birthdate &&
									<>{formatting.formatDate(new Date(birthdate))} ({formatting.calculateAge(new Date(birthdate))} let)</>
								}
							</div>
						}
						{props.isEdit &&
							<>
								<div className="color-primary">Datum narození:</div>
								<FormsInput
									className={"input medical-records-custom-inp text-dark medical-records-custom-inp-div d-inline-block p-card-widget-edited-border ml-0"}
									form={form}
									onSave={pCardWidget.saveUser}
									inputProps={{ type: "date", style: { width: 140 } }}
									field="birthdate" />
							</>
						}
					</div>
					<div className="col-4">
						<FormImageEdit
							className={"p-card-img " + (props.isEdit ? "p-card-edited" : "")}
							showBtn={props.isEdit}
							defaultSrc={props.isEdit ? "/images/user.jpg" : undefined}
							onSave={(value) => pCardWidget.saveUser("id_dms_file_image", value)}
							classNameIcon={"p-card-widget-img-icon p-card-img-profile "}
							form={form}
							field="id_dms_file_image" />
					</div>
				</div>
				{props.isEdit &&
					<div className="row p-card-widget-list-item-row mt-2">
						<div className="col-md-4 color-primary">
							Výška:
						</div>
						<div className="col-md-4 color-primary">
							Váha:
						</div>
						<div className="col-md-4 color-primary">
							Kr. skupina:
						</div>
					</div>
				}
				<div className="row p-card-widget-list-item-row mt-2">
					<div className="col-md-4 mb-1">
						<div className="p-card-widget-list-item">
							<Icon className="p-card-widget-list-item-icon" size="lg" picture="Child" />
							{!props.isEdit &&
								<div className={"p-card-widget-list-item-text "}>{height} cm</div>
							}
							{props.isEdit &&
								<FormsEditableDiv
									preventHTML={true}
									field="height"
									onSave={pCardWidget.saveUser}
									form={form}
									className="p-card-widget-list-item-text p-card-edited p-card-widget-edited-sm "
								/>
							}
						</div>
					</div>
					<div className="col-md-4 mb-1">
						<div className="p-card-widget-list-item">
							<Icon className="p-card-widget-list-item-icon" picture="Weight" />
							{!props.isEdit &&
								<div className={"p-card-widget-list-item-text"}>{weight} kg</div>
							}
							{props.isEdit &&
								<FormsEditableDiv
									preventHTML={true}
									field="weight"
									onSave={pCardWidget.saveUser}
									form={form}
									className="p-card-widget-list-item-text p-card-edited p-card-widget-edited-sm "
								/>
							}
						</div>
					</div>
					<div className="col-md-4 mb-1">
						<div className="p-card-widget-list-item">
							<img className="p-card-widget-list-item-icon" src="/images/droplet-fill.svg" alt="droplet-fill" />
							{!props.isEdit &&
								<div className={"p-card-widget-list-item-text"}>{ab}</div>
							}
							{props.isEdit &&
								<FormsEditableDiv
									preventHTML={true}
									field="ab"
									onSave={pCardWidget.saveUser}
									form={pCardWidget.form}
									className="p-card-widget-list-item-text p-card-edited p-card-widget-edited-sm "
								/>
							}
						</div>
					</div>
				</div>
				<div className="mb-3">
					<div className="row p-card-widget-list-item-row">
						<div className="col-md-6">
							<div className="p-card-widget-list-item">
								<div className="p-card-widget-list-item-title">Rč:</div>
								{!props.isEdit &&
									<div className={"p-card-widget-list-item-text "}>{personal_identification_number}</div>
								}
								{props.isEdit &&
									<>
										<FormsEditableDiv
											preventHTML={true}
											field="personal_identification_number"
											onSave={pCardWidget.saveUser}
											form={form}
											className="p-card-widget-list-item-text p-card-edited p-card-widget-edited-lg"
										/>
									</>
								}
							</div>
						</div>
						<div className="col-md-1 mt-2">
							/
						</div>
						<div className="col-md-5">
							<div className="p-card-widget-list-item">
								<div className="p-card-widget-list-item-title"></div>
								{!props.isEdit &&
									<div className={"p-card-widget-list-item-text "}>{personal_identification_numberSf}</div>
								}
								{props.isEdit &&
									<>
										<FormsEditableDiv
											preventHTML={true}
											field="personal_identification_number_sufix"
											onSave={pCardWidget.saveUser}
											form={form}
											className="p-card-widget-list-item-text p-card-edited p-card-widget-edited-lg"
										/>
									</>
								}
							</div>
						</div>
					</div>
					<div className="row p-card-widget-list-item-row mb-3">
						<div className="col-md-12">
							<div className="p-card-widget-list-item">
								<div className="p-card-widget-list-item-title">Krizový kontakt:</div>
								{!props.isEdit &&
									<div className={"p-card-widget-list-item-text "}>{name}</div>
								}
								{props.isEdit &&
									<FormsEditableDiv
										preventHTML={true}
										field="crisis_name"
										onSave={pCardWidget.saveUser}
										form={form}
										className="p-card-widget-list-item-text p-card-edited p-card-widget-edited-lg"
									/>
								}
							</div>
						</div>
					</div>
					<div className="row p-card-widget-list-item-row mb-3">
						<div className="col-md-12">
							<div className="p-card-widget-list-item">
								<div className="p-card-widget-list-item-title">Krizový telefon:</div>
								{!props.isEdit &&
									<div className={"p-card-widget-list-item-text "}>{phone}</div>
								}
								{props.isEdit &&
									<FormsEditableDiv
										preventHTML={true}
										field="crisis_phone"
										onSave={pCardWidget.saveUser}
										form={form}
										className="p-card-widget-list-item-text p-card-edited p-card-widget-edited-lg"
									/>
								}
							</div>
						</div>
					</div>
					<div className="row p-card-widget-list-item-row mb-3">
						<div className="col-md-12">
							<div style={{ height: 60 }} className="p-card-widget-list-item">
								<div className="p-card-widget-list-item-title">Adresa:</div>
								{!props.isEdit &&
									<div className={"p-card-widget-list-item-text "}>{address}</div>
								}
								{props.isEdit &&
									<FormsEditableDiv
										preventHTML={true}
										field="address"
										onSave={pCardWidget.saveUser}
										form={form}
										className="p-card-widget-list-item-text p-card-edited p-card-widget-edited-lg"
									/>
								}
							</div>
						</div>
					</div>
					{!props.isEdit &&
						<div className="row p-card-widget-list-item-row mb-2">
							<div className="col-md-12">
								<div className="p-card-widget-list-item">
									<div className="p-card-widget-list-item-title">Rodinné zázemí:</div>
									{!props.isEdit &&
										<div className={"p-card-widget-list-item-text "}>{type?.name}</div>
									}
								</div>
							</div>
						</div>
					}
					{props.isEdit &&
						<FormsSelect
							field="type"
							onSave={pCardWidget.saveUser}
							form={form}
							className="p-card-widget-list-item-text p-card-widget-list-item-family p-card-edited"
							options={[
								{
									title: "Vyberte rodinné zázemí...",
									value: null
								},
								{
									title: pCardForm.UserType.title(pCardForm.UserType.Apartment),
									value: { id: pCardForm.UserType.Apartment, name: pCardForm.UserType.title(pCardForm.UserType.Apartment) }
								},
								{
									title: pCardForm.UserType.title(pCardForm.UserType.House),
									value: { id: pCardForm.UserType.House, name: pCardForm.UserType.title(pCardForm.UserType.House) }
								},
								{
									title: pCardForm.UserType.title(pCardForm.UserType.NursingHome),
									value: { id: pCardForm.UserType.NursingHome, name: pCardForm.UserType.title(pCardForm.UserType.NursingHome) }
								},
								{
									title: pCardForm.UserType.title(pCardForm.UserType.RetirementHome),
									value: { id: pCardForm.UserType.RetirementHome, name: pCardForm.UserType.title(pCardForm.UserType.RetirementHome) }
								},
							]}
						/>
					}
					<div className={"p-card-widget-card p-card-edited-insurance-card"}>
						<h3 onClick={() => form.setField("showCard", !form.getField("showCard").value)} className="widget-list-panel-title mb-2 cursor-pointer">Průkaz pojištěnce</h3>
						<div className="p-card-edited mt-2">
							<FormImageEdit
								classNameIcon={"p-card-widget-img-icon "}
								className="p-card-widget-insurance"
								showBtn={props.isEdit}
								onSave={(value) => pCardWidget.saveUser("id_insurance_card", value)}
								form={form}
								field="id_insurance_card" />
						</div>
					</div>
					<div>

					</div>
				</div>
			</div>
		</div >
	);
}

export default state.bindContainers(
	PCardWidget,
	c => c.pCardWidget
);