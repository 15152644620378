/**
 * Stránka pro widget last mesurement
 */
import Spinner from "../../../../../components/controls/Spinner";
import MyRequestAction from "../../../../../components/controls/MyRequestAction";
import React, { useEffect, useState, useImperativeHandle } from "react";
import * as state from "../../../../../lib/state";
import * as apiLib from "../../../../../lib/api";
import Icon from "../../../../system/icons/Icon";
import * as apiHome from "../../api-home";
import * as medicalRecords from "../../../../pages/administration/medical_records/medical-records";


export interface Props {
	isRecord?: boolean;
	measurementSelected?: medicalRecords.Graph[];
	data?: apiHome.Widget[]
}
export const LastMeasurementWidget = React.forwardRef(
	function LastMeasurementWidget(props: Props, ref: any) {
		const [widgets, setWidgets] = useState([] as apiHome.Widget[]);
		const [loading, setLoading] = useState(true);
		const { api } = state.useStateContext();
		const { lastMeasurement } = state.useStateContext();
		const { authorization } = state.useStateContext();
		const { lastMeasurementSelectDialog } = state.useStateContext();
		const selected = props.measurementSelected;

		const actions = [{
			title: "Vybrat měření",
			action: async (value: medicalRecords.Graph) => {
				lastMeasurementSelectDialog.openDialog(value.type);
			},
		}];

		useImperativeHandle(ref, () => ({
			async loadWidgets() {
				setLoading(true);
				setWidgets([]);
				const measures = props.data ? props.data : await api.get("/users/last_measurement") as apiHome.Widget[];
				const data = props.data ? props.data : lastMeasurement.getData(measures);
				setWidgets(data);
				setLoading(false);
			}
		}))
		useEffect(() => {
			const loggedIn = authorization.userLoggedIn();
			if (ref && loggedIn) {
				ref.current.loadWidgets();
			}
		}, []);


		const { lastMeasurementDialog } = state.useStateContext();
		function handleDialog(item: any) {
			lastMeasurementDialog.openDialog(item);
		}

		return (
			<div>
				{loading &&
					<Spinner small />
				}
				{!loading && widgets.length == 0 &&
					<div className="font-weight-bold">Nejsou k dispozici žádna data.</div>
				}
				{widgets.map((i, index) => {
					const sel = selected && selected.find(x => x.type == i.type);
					return <React.Fragment key={index}>
						<div onClick={() => handleDialog(i)} style={{ boxSizing: "border-box" }} className={"last-measurement-col d-inline-block" + (index & 1 ? "" : " mr-3")}>
							<div className={"last-measurement-item d-flex " + (sel ? "last-measurement-widget-panel-selected" : "")}>
								<div className="d-inline last-measurement-item-icon">
									<div>
										{!i.icon &&
											<>
												{i.src &&
													<img src={i.src} alt={i.name} />
												}
												{!i.src &&
													<Icon picture="Database" />
												}
											</>
										}
										{i.icon &&
											<Icon picture={i.icon as any} />
										}
									</div>
								</div>
								<div className="d-inline-block mt-1 ml-2">
									<div className="d-inline last-measurement-title">
										{i.name}
									</div>
									<div className={"last-measurement-second-title "}>
										<span className={i.type == 1 && Number(i.value) > 36.9 ? "text-danger" : ""}>
											{sel &&
												<span className="ml-1 text-success font-weight-bold">{(sel?.value as any) == "Zobrazit graf" ? "Zobrazit graf" : sel?.type != 5 ? (sel.value + (sel.value2 ? "/" + sel.value2 : "")) : sel?.data}</span>
											}
											{((selected && !selected.find(x => x.type == i.type)) || !selected) &&
												<span>
													{i.value == "Zobrazit graf" ? "Zobrazit graf" : i.type != 5 ? (i.value + (i.value2 ? "/" + i.value2 : "")) : (i as any).data}
												</span>
											}
										</span>
										{i.type == 1 && Number(i.value) > 36.9 &&
											<Icon className="text-danger ml-1" picture="Danger" />
										}
									</div>
								</div>
								{props.isRecord &&
									<div className="d-inline-block last-measurement-select-action">
										<MyRequestAction className="last-measurement-select-action-widget" item={i} id="last-measurement-select-action" actions={actions as any} />
									</div>
								}
							</div>
						</div>
					</React.Fragment>
				}
				)}
			</div>
		);
	}
);