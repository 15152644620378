import React, { useRef, useEffect, useState } from "react";
import * as forms from "../../../lib/forms";
import * as common from "../../../lib/common";

import FormItem, { FormItemOptions } from "./FormItem";


export interface FormsInputProps<TFormFields> {
	form: forms.Form<TFormFields>;
	field: keyof common.SubType<TFormFields, string>;
	className?: string;
	formItemOptions?: FormItemOptions;
	onSave?: (field?: any, value?: string) => void;
	disabled?: boolean;
	preventHTML?: boolean;
	id?: string;
}

export default function FormsEditableDiv<TFormFields>(props: FormsInputProps<TFormFields>) {
	const hiddenContentRef = useRef<any>(null);
	const field = props.form.getField(props.field);

	function handleOnChange(e: any) {
		const value = props.preventHTML ? e.currentTarget.textContent : e.currentTarget.innerHTML;
		clearTimeout(hiddenContentRef.current._timer);
		hiddenContentRef.current._timer = setTimeout(() => {
			props.form.setField(props.field, value as any);
		}, 1000);
		if (props.onSave) {
			clearTimeout(hiddenContentRef.current._timer);
			hiddenContentRef.current._timer = setTimeout(() => {
				props.onSave!(props.field, value);
			}, 1000);
		}
	}

	function handleOnPress(event: any) {
		if (event.which == "13" && props.preventHTML) {
			event.preventDefault();
			event.stopPropagation();
		}
	}

	useEffect(() => {
		if (!hiddenContentRef.current.innerText || props.preventHTML) {
			if (props.preventHTML) {
				hiddenContentRef.current.textContent = field.value;
			}
			else {
				hiddenContentRef.current.innerHTML = field.value;
			}
		}
	}, [field.value]);

	return (
		<FormItem
			form={props.form}
			field={props.field}
			options={props.formItemOptions}
		>
			<div
				ref={hiddenContentRef}
				id={props.id ? props.id : undefined}
				onInput={handleOnChange}
				onKeyPress={handleOnPress}
				style={{ pointerEvents: props.disabled ? "none" : undefined }}
				// defaultValue={field.value as any}
				contentEditable="true"
				className={"forms-editable-div " + (props.className == undefined ? "" : props.className)}>
			</div>
		</FormItem>
	);
}