import * as React from "react";
import * as routing from "./lib/routing";
import { StateContext } from "./context";

import HomePage from "./modules/pages/home/HomePage";
import AdministrationPage from "./modules/pages/administration/AdministrationPage";
import UsersListPage from "./modules/pages/administration/users/UsersListPage";
import PCardAdminPage from "./modules/pages/p-card-admin/PCardAdminPage";
import WaitingRoomPage from "./modules/pages/waiting-room/WaitingRoomPage";
import MedicalRecordsPage from "./modules/pages/administration/medical_records/MedicalRecordsPage";
import WaitingRoomListPage from "./modules/pages/administration/waiting-room/WaitingRoomListPage";
import WaitingRoomDetailPage from "./modules/pages/administration/waiting-room/WaitingRoomDetailPage";
import DoctorsListPage from "./modules/pages/administration/doctors/DoctorsListPage";
import DoctorsDetailPage from "./modules/pages/administration/doctors/DoctorsDetailPage";
import UserProfile from "./components/sections/UserProfile";
import EmailListPage from "./modules/pages/administration/emails/EmailListPage";
import EmailsDetailPage from "./modules/pages/administration/emails/EmailsDetailPage";
import EmailsUserDetailPage from "./modules/pages/administration/emails/EmailsUserDetailPage";
import EmailUsersListPage from "./modules/pages/administration/emails/EmailUsersListPage";
import IndividualReportPage from "./modules/pages/administration/reports/IndividualReportPage";
import MedicalRecordsListPage from "./modules/pages/administration/medical-records-list/MedicalRecordsListPage";
import MedicalRecordsListFilePage from "./modules/pages/administration/medical-records-list/MedicalRecordsListFilePage";
import CategoriesDetailPage from "./modules/pages/administration/categories/CategoriesDetailPage";
import CategoriesListPage from "./modules/pages/administration/categories/CategoriesListPage";
import UsersDetailPage from "./modules/pages/administration/users/UsersDetailPage";

export default function createRoutes(context: StateContext, hostname: string): routing.Route[] {
	async function loadHomepage() {
		context.home.loadData();
	}

	async function loadPCardAdmin() {
		context.pCardAdmin.loadData();
	}

	async function loadData(fnc: (id?: string) => void, id?: string) {
		const profile = context.authorization.getUserProfile();
		if (context.authorization.userLoggedIn() && profile) {
			fnc(id);
		}
		else if (!profile) {
			setTimeout(function () { loadData(fnc, id) }, 250)
		}
	}

	return [
		{
			route: "/",
			component: HomePage,
			loadData: () => loadData(loadHomepage)
		},
		{
			route: "/prihlaseni",
			component: HomePage,
			loadData: () => loadData(loadHomepage)
		},
		{
			route: "/id/:id",
			component: HomePage,
			loadData: () => loadData(loadHomepage)
		},
		{
			route: "/domu",
			component: HomePage,
			loadData: () => loadData(loadHomepage)
		},
		{
			route: "/recepce",
			component: PCardAdminPage,
			loadData: () => loadData(loadPCardAdmin)
		},
		{
			route: "/cekarna",
			component: WaitingRoomPage,
			loadData: () => loadData(context.waitingRoom.loadData)
		},
		{
			route: "/administrace",
			component: AdministrationPage,
		},
		{
			route: "/administrace/uzivatele",
			component: UsersListPage,
			loadData: () => loadData(context.users.loadData)
		},
		{
			route: "/administrace/cekarna",
			component: WaitingRoomListPage,
			loadData: () => loadData(context.waitingRoomList.loadData)
		},
		{
			route: "/administrace/zdravotni-zaznam/:id",
			render: (route) => <MedicalRecordsPage id={(route as any).match.params.id} />,
			loadData: (match) => loadData(context.medicalRecords.loadData, (match.params as any).id)
		},
		{
			route: "/administrace/cekarna/:id",
			render: (route) => <WaitingRoomDetailPage id={(route as any).match.params.id} />,
			loadData: match => loadData(context.waitingRoomDetail.loadData, (match.params as any).id)
		},
		{
			route: "/administrace/lekari",
			component: DoctorsListPage,
			loadData: () => loadData(context.doctorsList.loadData)
		},
		{
			route: "/administrace/lekari/:id",
			render: (route) => <DoctorsDetailPage id={(route as any).match.params.id} />,
			loadData: match => loadData(context.doctorsDetail.loadData, (match.params as any).id)
		},
		{
			route: "/profil",
			component: UserProfile,
			loadData: () => loadData(context.userProfile.loadData)
		},
		{
			route: "/administrace/systemove-emaily",
			component: EmailListPage,
			loadData: () => loadData(context.emailsList.loadData)
		},
		{
			route: "/emaily",
			component: EmailUsersListPage,
			loadData: () => loadData(context.emailUsersList.loadData)
		},
		{
			route: "/administrace/emaily/:id",
			render: (route) => <EmailsDetailPage id={(route as any).match.params.id} />,
			loadData: match => loadData(context.emailsDetail.loadData, (match.params as any).id)
		},
		{
			route: "/administrace/uzivatel/emaily/:id",
			render: (route) => <EmailsUserDetailPage id={(route as any).match.params.id} />,
			loadData: match => loadData(context.emailsDetail.loadData, (match.params as any).id)
		},
		{
			route: "/administrace/individualni-report",
			component: IndividualReportPage,
			loadData: () => loadData(context.individualReport.loadData)
		},
		{
			route: "/administrace/zaznamy-dr-digital",
			component: MedicalRecordsListPage,
			loadData: () => loadData(context.medicalRecordsList.loadData)
		},
		{
			route: "/administrace/zaznamy-dr-digital-soubory/:id",
			render: (route) => <MedicalRecordsListFilePage id={(route as any).match.params.id} />,
			loadData: match => loadData(context.medicalRecordsListFile.loadData, (match.params as any).id)
		},
		{
			route: "/administrace/uzivatelske-kategorie",
			component: CategoriesListPage,
			loadData: () => loadData(context.categoriesList.loadData)
		},
		{
			route: "/administrace/uzivatelske-kategorie/:id",
			render: (route) => <CategoriesDetailPage id={(route as any).match.params.id} />,
			loadData: match => loadData(context.categoriesDetail.loadData, (match.params as any).id)
		},
		{
			route: "/administrace/uzivatele/:id",
			render: (route) => <UsersDetailPage id={(route as any).match.params.id} />,
			loadData: match => loadData(context.usersDetail.loadData, (match.params as any).id)
		},
	];
}