/**
 * Zobrazení obrázku z databáze
 */
import * as React from "react";
import * as apiImages from "./api-images";
import * as api from "../../../lib/api";
import * as state from "../../../lib/state";

import Icon from "../icons/Icon";

/**
 * Vlastnosti obrázku
 */
export type ImageProps =
	Omit<React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>, "id" | "src">
	& apiImages.ImageSrcOptions
	& { id: string; };

export default function Image(props: ImageProps) {
	const { apiImages } = state.useStateContext();

	return (
		<>
			{(props.defaultSrc != undefined || (props.id != api.emptyGuid && props.id)) &&
				<img alt={props.alt}
					className={props.className || ""}
					style={props.style}
					crossOrigin={props.crossOrigin}
					ref={props.ref}
					src={(props.defaultSrc == undefined || (props.id && props.id != api.emptyGuid)) ? apiImages.getImageUrl(props.id) : props.defaultSrc}
				/>
			}
			{props.showBtn &&
				<div className={"image-editable-image-icon  " + (props.classNameIcon == undefined ? "" : props.classNameIcon)} style={props.style}>
					<Icon picture="Image" size="2x" />
					<div className="text-center">
						{props.title && <>{props.title}</>}
					</div>
				</div>
			}
		</>
	);
}