import * as React from "react";
import * as forms from "../../../lib/forms";
import * as common from "../../../lib/common";
import Slider from 'rc-slider';

import FormItem, { FormItemOptions } from "./FormItem";


export interface FormsInputProps<TFormFields> {
	form: forms.Form<TFormFields>;
	field: keyof common.SubType<TFormFields, number | number[] | null>;
	className?: string;
	formItemOptions?: FormItemOptions;
	maxValue: number;
	range?: boolean;
	disabled?: boolean;
}

export default function FormsSlider<TFormFields>(props: FormsInputProps<TFormFields>) {
	const field = props.form.getField(props.field);
	const fieldOptions = props.form.getFieldOptions(props.field);
	return (
		<FormItem
			form={props.form}
			field={props.field}
			options={props.formItemOptions}
		>
			<div className="forms-slider-container">
				<Slider
					min={1}
					range={props.range}
					disabled={props.disabled}
					onChange={(e) => props.form.setField(props.field, e as any)}
					max={props.maxValue}
					value={field.value as any}
					marks={props.maxValue == 5 ? { 1: 1, 2: 2, 3: 3, 4: 4, 5: 5 } : { 1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6, 7: 7, 8: 8, 9: 9, 10: 10 }} />
			</div>
		</FormItem>
	);
}