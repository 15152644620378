/**
 * Čekárna
 */
import React from "react";
import * as state from "../../../lib/state";
import Page from "../../../components/templates/Page";
import ContentItems from "../../../modules/shared/contents/ContentItems";
import Spinner from "../../../components/controls/Spinner";

function WaitingRoomPage() {
	const { waitingRoom } = state.useStateContext();
	const items = waitingRoom.form.getField("showList").value;
	const loading = waitingRoom.stateContainer.get().loading;

	function setContentItems(id: any) {
		if (items.includes(id)) {
			waitingRoom.form.setField("showList", items.filter(x => x != id));
		}
		else {
			items.push(id);
			waitingRoom.form.setField("showList", items);
		}
	}

	return (
		<Page title="Čekárna" description="">
			<div className="page-content-container">
				<div className="text-light ml-3">
					<div className="homepage-responsive-title d-inline">
						<span className="mr-2">Čekárna</span>
						{loading &&
							<Spinner />
						}
					</div>
				</div>
				<div className="homepage-widgets">
					<div className={"pl-1 pr-1 home-widgets-responsive"}>
						<div className="row mr-0 ml-0 waiting-room">
							{waitingRoom.form.getField("contents").value.map((i, index) =>
								<div key={index} className="col-xl-4 col-lg-6 homepage-widget-height widget-max-width max-width-840-width-100 max-width-1514-width-50">
									<div className="homepage-widget">
										<div className="homepage-widget-header">
											<img className="d-inline mr-2" src="/images/folders.svg" alt="folders" />
											<h2 className="d-inline">{i.name}</h2>
										</div>
										<ContentItems ids={items} setShowList={setContentItems} showOtherContent={true} content={i} />
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</Page >
	);
}

export default state.bindContainers(
	WaitingRoomPage,
	context => context.waitingRoom
);