import * as dialogs from "../../../../shared/dialogs/dialogs";
import * as context from "../../../../../context";
import * as forms from "../../../../../lib/forms";
import * as api from "../../../../../lib/api";
import * as notification from "../../../../../lib/notification";

export interface FormFields {
	id: string;
	name: string;
	description: string;
	id_image: string;
	dosage: string;
	on_prescription: boolean;
	expiration_date: string;
	disabled: boolean;
	sys_date_create: Date;

}

interface FormData extends FormFields {
}

export interface Pilulkoid {
	id: string;
	name: string;
	description: string;
	dosage: string;
	id_image: string;
}

export interface State {
}

export class Model {
	public dialog: dialogs.Dialog<undefined, State>;
	public form: forms.Form<FormFields>;

	constructor(private context: context.StateContext) {
		this.dialog = new dialogs.Dialog<undefined, State>({
			defaultCustomFields: {
			}
		}, context);
		this.form = new forms.Form<FormFields>({
			fields: {
				id: {
					defaultValue: api.emptyGuid,
					title: ""
				},
				expiration_date: {
					defaultValue: "",
					title: "Datum expirace"
				},
				on_prescription: {
					defaultValue: false,
					title: "Na předpis"
				},
				dosage: {
					defaultValue: "",
					title: "Dávkování",
					readOnly: (form) => form.getField("disabled").value,
					required: (form) => true,
					validate: (value) => value.length == 0 ? "Vyplňte popis" : ""
				},
				name: {
					title: "Název",
					defaultValue: "",
					readOnly: (form) => form.getField("disabled").value,
					required: (form) => true,
					validate: (value) => value.length == 0 ? "Vyplňte název" : ""
				},
				description: {
					title: "Popis",
					defaultValue: "",
					readOnly: (form) => form.getField("disabled").value,
					required: (form) => true,
					validate: (value) => value.length == 0 ? "Vyplňte popis" : ""
				},
				id_image: {
					title: "",
					defaultValue: api.emptyGuid,
					readOnly: (form) => form.getField("disabled").value,
				},
				disabled: {
					title: "",
					defaultValue: false
				},
				sys_date_create: {
					defaultValue: new Date(),
					title: ""
				},
			}
		}, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			...this.form.getStateContainers(),
			...this.dialog.getStateContainers()
		];
	}

	openDialog = (values?: FormFields, disabled?: boolean) => {
		this.form.clearFields();
		this.form.setField("disabled", disabled == undefined ? false : disabled);
		if (values) {
			if (values.id_image == null) {
				values.id_image = api.emptyGuid;
			}
			this.form.setFields(values);
		}
		return this.dialog.open();
	}

	save = async () => {
		await this.form.validate();
		if (this.form.isValid()) {
			const data: FormData = {
				...this.form.getFields(),
			};
			if (data.id_image == api.emptyGuid) {
				data.id_image = null as any;
			}
			await this.context.api.put("/pilulkoids/" + api.emptyGuid, data);
			await this.context.pilulkoid.loadData();
			this.context.calendarPage.loadData();
			return this.dialog.close();
		}
		else {
			await notification.errorMessage("Formulář obsahuje neúplné informace nebo chyby. Opravte prosím červeně vyznačené položky a poté akci opakujte.");
		}
	}

	remove = async () => {
		await this.context.api.del("/pilulkoids/" + this.form.getField("id").value);
		await this.context.pilulkoid.loadData();
		return this.dialog.close();
	}
}