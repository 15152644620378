/**
 * Seznam uživatelů
 */

import * as state from "../../../../lib/state";
import * as context from "../../../../context";
import * as lists from "../../../system/lists/lists";
import * as apiCategories from "./api-categories";
import * as api from "../../../../lib/api";

/**
 * Stav modulu
 */
export interface State {
	checked?: boolean;
}

export class Model implements state.StateModel {
	list: lists.StandardList<apiCategories.Category>;
	stateContainer: state.StateContainer<State>;

	constructor(private context: context.StateContext) {
		this.list = new lists.StandardList<apiCategories.Category>({
			context: context,
			title: "Seznam kategorií",
			quickFilterPlaceholder: "Zadejte název...",
			itemActionsList: { actions: [] },
			standardEntityApi: context.apiCategories.categories as any,
			sortingFields: [],
			grid: {
				minWidth: "1000px",
				columns: [
					{
						field: "name" as any,
						type: "string",
						title: "Název",
					},
					{
						field: "color" as any,
						type: "string",
						title: "Barva",
					},
					{
						field: "hidden" as any,
						type: "boolean",
						title: "Nezobrazovat",
					},
					{
						field: "action" as any,
						width: "80px",
						type: "string",
						title: "Akce",
						preventSorting: true
					},
				]
			},
			defaultPageSize: 40,
			hideTabFilter: true,
			hideQueryMode: true
		});
		this.stateContainer = new state.StateContainer<State>({ checked: undefined }, context);
	}

	/**
	* Vrací kolekci stavových kontejnerů
	*/
	getStateContainers = () => [
		this.stateContainer,
		...this.list.getStateContainers(),
	]

	getChecked = () => {
		return this.stateContainer.get().checked;
	}

	loadData = async () => {
		await this.list.loadWithResetPage();
	}

	remove = async (id: string) => {
		await this.context.apiCategories.remove(id);
		await this.list.load();
	}

	accept = async (item: any) => {
		item.id_state = 1;
		await this.context.apiCategories.save(item.id, item);
		await this.list.load();
	}
}