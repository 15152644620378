/**
 * Modul domovské stránky
 */

import * as state from "../../../../lib/state";
import * as api from "../../../../lib/api";
import * as formatting from "../../../../lib/formatting";
import * as apiHome from "../../home/api-home";
import * as medicalRecords from "../../administration/medical_records/medical-records";
import * as lastRecords from "../../home/widgets/last-records/last-records";
import * as context from "../../../../context";
import * as forms from "../../../../lib/forms";
import * as notification from "../../../../lib/notification";
import { drawDOM, exportPDF } from "@progress/kendo-drawing";

/**
 * Stav modulu
 */

interface MeasurementsCount {
	name: string;
	type: number;
	value: number;
}

interface State {
	saving: boolean;
	drugs: api.CodeBookItem[];
	pilulkoids: api.CodeBookItem[];
	ongoing_treatments: api.CodeBookItem[];
	allergies: api.CodeBookItem[];
	operations: api.CodeBookItem[];
	vaccination: api.CodeBookItem[];
	doctors: api.CodeBookItem[];
	loaded: boolean;
	measures_date_from: Date;
	measures_date_to: Date;
	showWeek: boolean;
	all_feeling_meassurements: MeasurementsCount[];
	all_meassurements: MeasurementsCount[];
	loadingMeasurements: boolean;
	showButtons: boolean;
	measurementData: medicalRecords.MeasurementData[];
	lastMeasurementData: medicalRecords.MeasurementData | null;
}

export interface FormFields {
}

function defaultState() {
	return {
		saving: false,
		drugs: [],
		pilulkoids: [],
		ongoing_treatments: [],
		allergies: [],
		operations: [],
		vaccination: [],
		doctors: [],
		measures_date_from: formatting.substractDaysFromDate(31, formatting.setTimeFromString(new Date(), 0, 0, 0)),
		measures_date_to: formatting.setTimeFromString(new Date(), 23, 59, 59),
		loaded: false,
		showWeek: false,
		all_feeling_meassurements: [],
		all_meassurements: [],
		loadingMeasurements: true,
		showButtons: false,
		measurementData: [],
		lastMeasurementData: null
	} as State;
}

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	public stateContainer: state.StateContainer<State>;
	public form: forms.Form<FormFields>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>(defaultState(), context);
		this.form = new forms.Form<FormFields>({
			fields: {
			}
		}, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			this.stateContainer,
			...this.form.getStateContainers()
		];
	}

	getStateContainer = () => {
		return this.stateContainer.get();
	}

	getLoaded = () => {
		return this.stateContainer.get().loaded;
	}

	getShowWeek = () => {
		return this.stateContainer.get().showWeek;
	}

	setShowWeek = async (value: boolean) => {
		await this.stateContainer.merge(() => ({
			showWeek: value
		}));
	}


	handleFilter = async (value: boolean, ref: any, ref2: any, ref3: any, ref4: any, ref5: any, ref6: any, ref7: any, ref8: any, ref9: any, ref10: any, ref11: any, ref12: any, ref13: any) => {
		await this.stateContainer.merge(() => ({
			measures_date_from: formatting.substractDaysFromDate(value ? 7 : 31, formatting.setTimeFromString(new Date(), 0, 0, 0)),
			measures_date_to: formatting.setTimeFromString(new Date(), 23, 59, 59),
		}));
		await this.setShowWeek(value);
		ref.current.changeDateFrom(value);
		ref2.current.changeDateFrom(value);
		ref3.current.changeDateFrom(value);
		ref4.current.changeDateFrom(value);
		ref5.current.changeDateFrom(value);
		ref6.current.changeDateFrom(value);
		ref7.current.changeDateFrom(value);
		ref8.current.changeDateFrom(value);
		ref9.current.changeDateFrom(value);
		ref10.current.changeDateFrom(value);
		ref11.current.changeDateFrom(value);
		ref12.current.changeDateFrom(value);
		ref13.current.changeDateFrom(value);
	}

	loadData = async () => {
		await this.reset();

		const all_meassurements = await this.context.api.post("/users/all_meassurements", { date_from: formatting.substractDaysFromDate(31, formatting.setTimeFromString(new Date(), 0, 0, 0)), date_to: formatting.setTimeFromString(new Date(), 23, 59, 59) }) as any;
		const all_feeling_meassurements = await this.context.api.post("/users/all_feeling_meassurements", { date_from: formatting.substractDaysFromDate(31, formatting.setTimeFromString(new Date(), 0, 0, 0)), date_to: formatting.setTimeFromString(new Date(), 23, 59, 59) }) as any;
		const sort = this.context.lastMeasurement.sortBy(this.context.lastMeasurement.groupBy(all_meassurements.data, "type")) as any;
		const lastmeasurementData = this.context.lastMeasurement.getData(sort as any) as any;

		await this.stateContainer.merge(() => ({
			all_feeling_meassurements: all_feeling_meassurements,
			all_meassurements: all_meassurements.statistics,
			measurementData: all_meassurements.data.sort((a: any, b: any) => (new Date(a.data) as any) - (new Date(b.data) as any)),
			lastMeasurementData: lastmeasurementData,
			loadingMeasurements: false
		}));

		const drugs = await this.context.api.loadList<api.CodeBookItem>("/pilulkoids", { sort_fields_desc: ["sys_date_create" as any], sort_fields: ["sys_date_create" as any] }) as any;
		const my_files = await this.context.api.loadList("/treatments", { sort_fields_desc: ["sys_date_create"] as any, sort_fields: ["sys_date_create"] as any }) as any;
		let data = my_files.data as apiHome.Record[];
		const dataDrugs = await this.context.api.loadList<api.CodeBookItem>("/pilulkoids", { sort_fields_desc: ["sys_date_create"] as any, sort_fields: ["sys_date_create"] as any }) as any;
		const treatements = await this.context.api.loadList<api.CodeBookItem>("/ongoing_treatments", { sort_fields_desc: ["sys_date_create"] as any, sort_fields: ["sys_date_create"] as any }) as any;
		const doctors = await this.context.api.loadList("/user_doctors", { sort_fields_desc: ["sys_date_create"], sort_fields: ["sys_date_create"] }) as any;
		await this.stateContainer.merge(() => ({
			drugs: drugs.data,
			pilulkoids: dataDrugs.data,
			ongoing_treatments: treatements.data,
			allergies: data.filter(x => x.type == lastRecords.MyRecordType.Allergy),
			operations: data.filter(x =>
				x.type == lastRecords.MyRecordType.Operation ||
				x.type == lastRecords.MyRecordType.Entry ||
				x.type == lastRecords.MyRecordType.Illness
			),
			vaccination: data.filter(x => x.type == lastRecords.MyRecordType.Vaccination),
			doctors: doctors.data
		}));

		setTimeout(() =>
			this.stateContainer.merge(() => ({
				showButtons: true
			})
			), 100);
	}

	savePdf = async (print: any) => {
		await this.stateContainer.merge(() => ({ saving: true }));
		await print.save();
		const that = this;
		setTimeout(async function () {
			await that.stateContainer.merge(() => ({ saving: false }));
		}, 1000);

	}

	save = async (print: any) => {
		await this.stateContainer.merge(() => ({ saving: true }));
		const fn = this.context.authorization.getUserProfile()?.profile.first_name;
		const ln = this.context.authorization.getUserProfile()?.profile.last_name;

		let report = null as any;
		const drawPdf = await drawDOM(print.rootElForPDF, {
			scale: 0.49,
			margin: { left: -30 },
			paperSize: "A4"
		});
		const exportPD = await exportPDF(drawPdf);
		const name = "Report_" + fn + ln + "_" + formatting.formatDate(new Date()).replace(/\  /g, ' ').replace(/ /g, "_").replace(":", "_").replace(/\./g, '') + ".pdf";
		var file = formatting.dataURLtoFile(exportPD, name);

		const uploadResult = await this.context.apiFile.upload(file);
		report = { id: uploadResult.id as string, name: name };

		const that = this;
		setTimeout(async function () {
			const result = await that.context.api.put("/medical_records/individual_report/" + api.emptyGuid, { individual_report_attachment: report });
			if (result) {
				await that.reset();
				notification.successMessage("Individuální report byl uložen.")
				that.context.authorization.logout();
			}
		}, 1000);
	}

	getPageSize = (page: number) => {
		const maxPageSize = 5;
		return page + "/" + maxPageSize;
	}

	/**
 * Provede reset formuláře
 */
	reset = async () => {
		await this.form.clearFields();
		await this.stateContainer.merge(() => (defaultState()));
	}
}