import Switch from "../../../../components/controls/Switch";
import * as state from "../../../../lib/state";
import React from "react";

interface Props {
	type: number;
	className?: string;
	top?: string;
};

export default function MedicalRecordHideSwitch(props: Props) {
	const { medicalRecords } = state.useStateContext();
	const container = medicalRecords.getStateContainer();

	function handleType(e: any) {
		e.preventDefault();
		medicalRecords.handleTypes(props.type);
	}

	return (
		<div className="print-hide">
			<div
				onClick={handleType}
				style={{ marginTop: props.top ? props.top : "-14px", cursor: "pointer", position: "absolute", left: 10 }}
				className={(props.className ? props.className : "") + " d-inline mb-2"}
			>
				<label className={"checkbox-radio checkbox-radio--"}
				>
					<input
						className="checkbox-radio__input"
						type={"checkbox"}
						checked={container.types.includes(props.type)}>
					</input>
					<span className={"checkbox-radio__checkmark mr-1 "}></span>
					Schovat
				</label>
			</div>
		</div>
	);
}