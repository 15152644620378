/**
 * Stránka GeneralGraph
 */
import React, { useState, useImperativeHandle } from "react";
import * as apiLib from "../../../lib/api";
import * as formatting from "../../../lib/formatting";
import * as medicalRecords from "../../pages/administration/medical_records/medical-records";
import Spinner from "../../../components/controls/Spinner";
import LineChart from "./LineChart";
import LineChart2 from "./LineChart2";
import EKGChart from "./EKGChart";

interface Props {
	type: apiLib.CodeBookItemWithNumberId;
	date_from: Date;
	date_to: Date;
	types?: any[];
	disabled?: boolean;
	hideHeader?: boolean;
	height?: string;
	saving?: boolean;
	measurement?: boolean;
	showType?: boolean;
	secondType?: apiLib.CodeBookItemWithNumberId;
	width?: string;
	showWeek?: boolean;
	data?: medicalRecords.MeasurementData[];
}

export const GeneralGraph = React.forwardRef(
	function GeneralGraph(props: Props, ref: any) {
		const [loading, setLoading] = useState(true);
		const [data, setData] = useState([] as medicalRecords.Graph[]);
		const [measurementType, setMeasurementType] = useState(props.type.id);
		const [type, setType] = useState(props.type.id == 5 ? 4 : 2);
		const [dateFrom, setDateFrom] = useState(props.type.id == 5 ? formatting.setTimeFromString(new Date(), 0, 0, 0) : props.date_from);
		const [dateTo] = useState(props.date_to);

		useImperativeHandle(ref, () => ({
			async changeDateFrom(showWeek: boolean) {
				setDateFrom(props.date_from);
			}
		}))

		async function handleOnChange(index: number) {
			const type = [1, 2, 3, 4][index];
			switch (type) {
				case 1:
					setDateFrom(formatting.substractMonthsFromDate(12, new Date()));
					break;
				case 2:
					setDateFrom(formatting.substractDaysFromDate(31, formatting.setTimeFromString(new Date(), 0, 0, 0)));
					break;
				case 3:
					setDateFrom(formatting.substractDaysFromDate(7, formatting.setTimeFromString(new Date(), 0, 0, 0)));
					break;
				case 4:
					setDateFrom(formatting.substractDaysFromDate(1, formatting.setTimeFromString(new Date(), 0, 0, 0)));
					break;
			}
			setType(type);
		}

		function handleMeasurementType(value: string) {
			setMeasurementType(Number(value));
		}

		return (
			<>
				{!props.hideHeader &&
					<div className="row">
						<div className="col">
							<select
								disabled={props.types && props.types.length == 1 ? props.disabled : false}
								onChange={(e) => handleMeasurementType(e.target.value)}
								className={"custom-select general-graph-select"}>

								{props.types!.map((i, index) =>
									<option key={index} value={i.id} defaultValue={(measurementType == i.value) as any}>{i.name}</option>
								)}
							</select>
						</div>
						<div className="col">
							{!props.saving &&
								<input
									type="date"
									className={"input general-graph-select"}
									disabled={props.type.id == 5 ? false : props.disabled}
									onChange={(e) => setDateFrom(new Date(e.target.value))}
									value={formatting.formatDate(dateFrom, { format: "iso" })}
								/>
							}
							{props.saving &&
								<div className="input general-graph-select">{formatting.formatDate(dateFrom, { format: "DD. MM. YYYY" as any })}</div>
							}
						</div>
						<div className="col">
							{!props.saving && props.type.id != 5 &&
								<input
									type="date"
									className={"input general-graph-select"}
									disabled={props.disabled}
									value={formatting.formatDate(dateTo, { format: "iso" })}
								/>
							}
							{props.saving &&
								<div className="input general-graph-select">{formatting.formatDate(dateTo, { format: "DD. MM. YYYY" as any })}</div>
							}
						</div>
						{props.showType && measurementType != 5 &&
							<div className="col">
								<select
									onChange={(e) => handleOnChange(e.currentTarget.selectedIndex)}
									className={"custom-select general-graph-select"}>
									<option key={1} selected={(type == 1)}>Poslední rok</option>
									<option key={2} selected={(type == 2)}>Poslední měsíc</option>
									<option key={3} selected={(type == 3)}>Poslední týden</option>
									<option key={4} selected={(type == 4)}>Posledních 24h</option>
								</select>
							</div>
						}
					</div>
				}
				<div style={{ width: props.width ? props.width : "105%", height: (props.height == undefined ? "220px" : props.height), marginTop: "20px" }}>
					{props.data == undefined && loading &&
						<Spinner className="mr-2" small />
					}
					{data.length == 0 && (!loading || props.data != undefined) &&
						<div className="general-grap-minus-margin d-inline">
							Nejsou k dispozici žádna data.
						</div>
					}
					{measurementType != 5 &&
						<>
							<>
								{!props.measurement &&
									<LineChart2
										data={props.data}
										date_from={dateFrom}
										date_to={dateTo}
										setData={setData}
										setLoading={setLoading}
										type={props.type} />
								}
							</>
							<>
								{props.measurement && (props.data == undefined || (props.data != undefined && props.data.length > 0)) &&
									<LineChart
										data={props.data}
										width={props.width}
										date_from={dateFrom}
										date_to={dateTo}
										setData={setData}
										setLoading={setLoading}
										secondType={props.secondType}
										measurementType={measurementType}
										dateType={type}
										loading={loading}
										type={props.type} />
								}
							</>
						</>
					}
					{measurementType == 5 &&
						<EKGChart
							date_from={dateFrom}
							date_to={dateTo}
							setData={setData}
							setLoading={setLoading}
							loading={loading}
							type={props.type} />
					}
				</div>
			</>
		);
	}
)