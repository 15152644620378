/**
 * Domovská stránka
 */
import React, { useState } from "react";
import * as state from "../../../lib/state";

import Page from "../../../components/templates/Page";
import PCardWidget from "./widgets/p-card-widget/PCardWidget";
import OptionalViewWidget from "./widgets/optional-view-widget/OptionalViewWidget";
import ExpandedViewWidget from "./widgets/expanded-view-widget/ExpandedViewWidget";
import Icon from "../../../modules/system/icons/Icon";

function PCardAdminPage() {
	const { pCardAdmin } = state.useStateContext();
	const { pCardWidget } = state.useStateContext();
	const isEdit = pCardAdmin.stateContainer.get().isOpen;

	function setIsEdit(value: boolean) {
		pCardAdmin.stateContainer.merge(() => ({
			isOpen: value,
		}));
	}

	function handleSave() {
		setIsEdit(false);
	}

	return (
		<Page title="Recepce" description="">
			<div className="page-content-container">
				<div className="text-light ml-3 p-card-admin-header">
					<div className="homepage-responsive-title d-inline">
						Recepce
					</div>
					{!isEdit &&
						<div onClick={() => setIsEdit(true)} className="mr-4 mt-2 text-light cursor-pointer pc-card-admin-edit">
							Editovat <Icon picture="Detail" />
						</div>
					}
					{isEdit &&
						<div onClick={handleSave} className="mr-4 mt-2 text-light cursor-pointer">
							Uložit <Icon picture="Confirm" />
						</div>
					}
				</div>
				<div className="row p-card-admin pl-1 pr-1">
					<div className={"col-sm-4 max-width-1514-width-50 max-width-840-width-100 widget-max-width"}>
						<div className="p-card-admin-widget">
							<PCardWidget isView={false} isEdit={isEdit} />
						</div>
					</div>
					<div className={"col-sm-4 max-width-1514-width-50 max-width-840-width-100 widget-max-width"}>
						<div className="p-card-admin-widget">
							<OptionalViewWidget isView={false} isEdit={isEdit} />
						</div>
					</div>
					<div className={"col-sm-4 max-width-1514-width-50 max-width-840-width-100 widget-max-width"}>
						<div className="p-card-admin-widget">
							<ExpandedViewWidget isView={false} isEdit={isEdit} />
						</div>
					</div>
				</div>
			</div>
		</Page >
	);
}

export default state.bindContainers(
	PCardAdminPage,
	context => context.pCardAdmin
);