/**
 * Modul domovské stránky
 */

import * as state from "../../../../lib/state";
import * as api from "../../../../lib/api";
import * as notification from "../../../../lib/notification";
import * as navigation from "../../../../lib/navigation";
import * as context from "../../../../context";
import * as forms from "../../../../lib/forms";

/**
 * Stav modulu
 */
interface State {
}

export interface FormFields {
	id: string;
	name: string;
	show_one_entry: boolean;
	show_all: boolean;
	sys_date_create: Date;
}

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	private stateContainer: state.StateContainer<State>;
	public form: forms.Form<FormFields>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({}, context);
		this.form = new forms.Form<FormFields>({
			fields: {
				id: {
					title: "",
					defaultValue: api.emptyGuid
				},
				name: {
					title: "Kategorie",
					defaultValue: "",
					required: true,
					validate: (value) => value.trim().length === 0 ? "Vyplňte pole" : ""
				},
				show_one_entry: {
					title: "Zobrazit pouze jeden záznam",
					defaultValue: false
				},
				show_all: {
					title: "Zobrazit vše",
					defaultValue: false
				},
				sys_date_create: {
					defaultValue: new Date(),
					title: ""
				},
			}
		}, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			this.stateContainer,
			...this.form.getStateContainers()
		];
	}

	loadData = async (id: string) => {
		if (id != api.emptyGuid) {
			const content = await this.context.api.get("/contents/" + id);
			if (content) {
				this.form.setFields(content as any);
			}
			this.context.waitingRoomItemList.loadData();
		}
		else {
			this.form.clearFields();
		}
	}

	save = async () => {
		await this.form.validate();
		if (this.form.isValid()) {
			const content = await this.context.api.put("/contents/" + this.form.getField("id").value, this.form.getFields());
			if (content) {
				await notification.successMessage("Kategorie byla úspěšně uložena.");
				navigation.to("/administrace/cekarna/" + content.id);
			}
		}
		else {
			await notification.errorMessage("Formulář obsahuje neúplné informace nebo chyby. Opravte prosím červeně vyznačené položky a poté akci opakujte.");
		}
	}

	/**
	 * Provede reset formuláře
	 */
	reset = async () => {
		await Promise.all([
			this.form.clearFields(),
		]);
	}
}