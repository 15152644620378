/**
 * Detail profilu
 */
import React from "react";
import * as navigation from "../../lib/navigation";
import * as state from "../../lib/state";
import * as formatting from "../../lib/formatting";
import Page from "../../components/templates/Page";
import FormsInput from "../../components/controls/Forms/FormsInput";
import Button from "../../components/controls/Button";
import ButtonAdvanced from "../controls/ButtonAdvanced";

interface Props {
	id: string;
}

function UserProfile(props: Props) {
	const { authorization } = state.useStateContext();
	const { userProfile } = state.useStateContext();
	const form = userProfile.form;

	return (
		<Page title="Změna hesla" description="">
			<div className="page-content-container">
				<div className="ml-3 mb-3 text-dark">
					<div className="homepage-responsive-title d-inline">
						Změna hesla
					</div>
				</div>
				<div className="content-container m-3">
					<div className="row">
						<div className="col-md-5">
							<FormsInput
								form={form}
								inputProps={{ type: "password", autoComplete: "new-password" }}
								field="old_password"
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-md-5">
							<FormsInput
								form={form}
								inputProps={{ type: "password", autoComplete: "new-password" }}
								field="password"
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-md-5">
							<FormsInput
								form={form}
								inputProps={{ type: "password", autoComplete: "new-password" }}
								field="confirm_password"
							/>
						</div>
					</div>
					<div className="row p-2 mt-3">
						<div className="col">
							<div className="mt-3 d-inline">
								<ButtonAdvanced className="font-weight-bold float-right" onClick={userProfile.save}>
									Uložit
								</ButtonAdvanced>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Page >
	);
}

export default state.bindContainers(
	UserProfile,
	context => context.userProfile
);