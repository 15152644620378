import * as forms from "../../../lib/forms";
import * as state from "../../../lib/state";
import * as api from "../../../lib/api";
import * as common from "../../../lib/common";
import storage from "store";
import * as formatting from "../../../lib/formatting";
import * as context from "../../../context";
import * as navigation from "../../../lib/navigation";
import * as validations from "../../../lib/validations";
import * as notification from "../../../lib/notification";

interface State {
	redirectRoute: string;
}

interface LoginFields {
	username: string;
	password: string;
	register_username: string;
	register_firstname: string;
	register_lastname: string;
	register_password: string;
	register_confirm_password: string;
	register_personal_identification_number: string;
	register_personal_identification_number_sufix: string;
	save: boolean;
	isLogin: boolean;
	personal_id: string;
	register_phone: string;
}

export class Model {
	public stateContainer: state.StateContainer<State>;
	public form: forms.Form<LoginFields>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({
			redirectRoute: "",
		}, context);

		this.form = new forms.Form<LoginFields>({
			fields: {
				isLogin: {
					title: "",
					defaultValue: true
				},
				username: {
					title: "Email",
					required: true,
					defaultValue: "",
					validate: (value, model, form) => value.trim().length === 0 && this.getIsLogin() && !validations.isEmailAddress(value) ? "Vyplňte pole" : ""
				},
				password: {
					title: "Heslo",
					required: true,
					defaultValue: "",
					validate: (value) => {
						if (value.trim().length === 0 && this.getIsLogin()) {
							return "Vyplňte pole"
						}
						return "";
					}
				},
				register_password: {
					title: "Heslo",
					required: true,
					defaultValue: "",
					validate: (value) => value.trim().length === 0 && !this.getIsLogin() ? "Vyplňte pole" : ""
				},
				register_confirm_password: {
					title: "Potvrďte heslo",
					required: true,
					defaultValue: "",
					validate: (value, model, form) => {
						if (value != form.getField("register_password").value && !this.getIsLogin()) {
							return "Heslo pro potvrzení musí být stejné";
						}

						if (value.trim().length == 0 && !this.getIsLogin()) {
							return "Vyplňte pole";
						}
						if (!this.getIsLogin()) {
							return this.validatePassword(value);
						}
						return "";
					}
				},
				register_username: {
					title: "Email",
					required: true,
					defaultValue: "",
					validate: (value) => (value.trim().length === 0 && !this.getIsLogin() || (!validations.isEmailAddress(value) && !this.getIsLogin())) ? "Neplatný formát emailové adresy" : ""
				},
				register_firstname: {
					title: "Jméno",
					required: true,
					defaultValue: "",
					validate: (value) => value.trim().length === 0 && !this.getIsLogin() ? "Vyplňte pole" : ""
				},
				register_lastname: {
					title: "Příjmení",
					required: true,
					defaultValue: "",
					validate: (value) => value.trim().length === 0 && !this.getIsLogin() ? "Vyplňte pole" : ""
				},
				register_phone: {
					title: "Telefon",
					defaultValue: "",
					validate: value => !validations.isPhoneNumber(value) && !this.getIsLogin() ? "Telefon je v chybném formát" : ""
				},
				register_personal_identification_number: {
					title: "Rodné číslo",
					required: true,
					defaultValue: "",
					onChange: (value, field, form) => form.validate(),
					validate: value => value.trim().length === 0 && !this.getIsLogin() ? "Vyplňte pole" : formatting.identificationNumberValidation(value + this.form.getField("register_personal_identification_number_sufix").value)!,
				},
				register_personal_identification_number_sufix: {
					title: "&nbsp;",
					required: true,
					defaultValue: "",
					onChange: (value, field, form) => form.validate(),
					validate: value => value.trim().length === 0 && !this.getIsLogin() ? "Vyplňte pole" : formatting.identificationNumberValidation(this.form.getField("register_personal_identification_number").value + value)!,
				},
				personal_id: {
					title: "Osobní identifikační číslo",
					defaultValue: "",
				},
				save: {
					title: "Zapamatovat",
					defaultValue: false
				}
			}
		}, context);

	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			this.stateContainer,
			...this.form.getStateContainers()
		];
	}

	setRedirectRoute = async (redirectRoute: string) => {
		await this.stateContainer.merge(_ => ({
			redirectRoute: redirectRoute
		}));
	}

	/**
	 * Validuje password
	 */
	validatePassword = (value: string) => {
		return validations.isValidPassword(value);
	}

	getIsLogin = () => {
		return this.form.getField("isLogin").value;
	}

	setIsLogin = async (value: boolean) => {
		this.form.setField("isLogin", value);
	}

	/**
	 * Provede přihlášení přes Link2stars
	 */
	doLogin = async () => {
		await this.form.validate();
		if (this.form.isValid()) {
			const userName = this.form.getField("username").value;
			const password = this.form.getField("password").value;
			try {
				await this.context.authorization.login(userName, password);
				storage.remove("delegateIdUser");
				(window as any).gtag("event", "login", {
					event_category: "access",
					event_label: "login"
				});
				if (navigation.getCurrent() === "/prihlaseni") {
					const redirectRoute = this.stateContainer.get().redirectRoute;
					if (redirectRoute !== "") {
						navigation.to(redirectRoute, { replaceHistory: true });
						await this.stateContainer.merge(_ => ({
							redirectRoute: ""
						}));
					}
				}

			} catch (error) {
				if (api.isOperationResponse(error)) {
					await this.form.clearValidations();
				}
			}
		}
	}

	doRegister = async () => {
		await this.form.validate();
		if (this.form.isValid()) {
			const userName = this.form.getField("register_username").value;
			const firstname = this.form.getField("register_firstname").value;
			const lastname = this.form.getField("register_lastname").value;
			const password = this.form.getField("register_password").value;
			const idNumber = this.form.getField("register_personal_identification_number").value;
			const sufix = this.form.getField("register_personal_identification_number_sufix").value;
			const phone = this.form.getField("register_phone").value;

			const result = await this.context.api.post("/registration", { first_name: firstname, last_name: lastname, password: password, personal_identification_number: idNumber, personal_identification_number_sufix: sufix, email: userName, phone: phone })
			if (result) {
				notification.successMessage("Registrace proběhla úspěšně, můžete se přihlásit");
				this.setIsLogin(true);
			}
		}
		else {
			await notification.errorMessage("Formulář obsahuje neúplné informace nebo chyby. Opravte prosím červeně vyznačené položky a poté akci opakujte.");
		}
	}
}