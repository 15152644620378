/**
 * Přidání souboru
 */
import Icon from "../../../../../modules/system/icons/Icon";
import * as React from "react";
import * as state from "../../../../../lib/state";

import Dialog from "../../../../shared/dialogs/Dialog";

function lastRecordContentDialog() {
	const { lastRecordContentDialog } = state.useStateContext();
	const { apiFile } = state.useStateContext();
	const file = lastRecordContentDialog.dialog.getCustomField("file");
	const fullscreen = lastRecordContentDialog.dialog.getCustomField("fullscreen");

	function handleDownload() {
		if (file) {
			if (file.text_content) {
				var element = document.createElement('a');
				element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(file.text_content));
				element.setAttribute('download', file.name);
				element.style.display = 'none';
				document.body.appendChild(element);
				element.click();
				document.body.removeChild(element);
			}
			else {
				apiFile.download(file.id, file.name);
			}
		}
	}

	function handleFullScreen() {
		const selector = document.querySelector(".last-record-content-dialog") as any;
		const selector2 = document.querySelector(".last-record-content-container") as any;
		if (selector && selector2) {
			if (!fullscreen) {
				selector.style.width = window.innerWidth - 140 + "px";
				selector.style.height = window.innerHeight - 90 + "px";
				selector2.style.height = window.innerHeight - 210 + "px";
			}
			else {
				selector.style.width = "650px";
				selector.style.height = "initial";
				selector2.style.height = "initial";
			}
			lastRecordContentDialog.dialog.setCustomField("fullscreen", !fullscreen);
		}
	}

	return (
		<Dialog className="last-record-content-dialog" dialog={lastRecordContentDialog.dialog}>
			{file != undefined &&
				<div>
					<h2 className="pb-3 dialog-title"><img className="d-inline mr-2" src="/images/folders-1.svg" alt="folders-1" />{file.name}</h2>
					<hr className="dialog-title-hr" />
					<div className="last-record-content-container">
						{file.text_content &&
							<div className="pb-2 last-record-content-container-content ">
								{file.text_content}
							</div>
						}
						<hr />
					</div>
					<div>
						<div onClick={handleFullScreen} className="d-inline color-primary cursor-pointer">Fullscreen<Icon className="ml-2" picture="ExpandArrowsAlt" /></div>
						<div className="d-inline color-primary float-right cursor-pointer" onClick={handleDownload}>Stáhnout soubor <Icon className="ml-2" picture="Download" /></div>
					</div>
				</div>
			}
		</Dialog >
	);
}

export default state.bindContainers(
	lastRecordContentDialog,
	context => context.lastRecordContentDialog.dialog
);