import * as React from "react";
import { BrowserRouter, StaticRouter, Route, Switch, withRouter, RouteComponentProps } from "react-router-dom";
import "regenerator-runtime/runtime";
import * as state from "./lib/state";
import * as common from "./lib/common";
import * as navigation from "./lib/navigation";
import * as routing from "./lib/routing";
import AppContext, { StateContext } from "./context";

import Page404 from "./modules/pages/page404/Page404";
import InformationDialog from "./modules/shared/dialogs/InformationDialog";
import YesNoDialog from "./modules/shared/dialogs/YesNoDialog";
import LastRecordsAddDialog from "./modules/pages/home/widgets/last-records/AddDialog";
import LastRecordConentDialog from "./modules/pages/home/widgets/last-records/LastRecordConentDialog";
import LastMeasurementDialog from "./modules/pages/home/widgets/last-measurement/LastMeasurementDialog";
import PainMonitorDialog from "./modules/pages/home/widgets/pain-monitor/PainMonitorDialog";
import LastMeasurementWidgetSelect from "./modules/pages/home/widgets/last-measurement/LastMeasurementWidgetSelect";
import SimpleAddDialog from "./modules/shared/dialogs/SimpleAddDialog";
import ImageEditDialog from "./modules/system/images/ImageEditDialog";
import ContentDialog from "./modules/shared/contents/ContentDialog";
import CalendarDialog from "./modules/pages/home/widgets/calendar/CalendarDialog";
import CalendarListDialog from "./modules/pages/home/widgets/calendar/CalendarListDialog";
import ResetPasswordDialog from "./modules/system/authorization/ResetPasswordDialog";
import CaptureDialog from "./modules/shared/dialogs/CaptureDialog";
import PilulkoidDialog from "./modules/pages/home/widgets/pilulkoid-page/PilulkoidDialog";
import DoctorDialog from "./modules/shared/dialogs/DoctorDialog";
import ShowImageDialog from "./modules/shared/dialogs/ShowImageDialog";
import MedicalRecordDescriptionDialog from "./modules/pages/administration/medical_records/MedicalRecordDescriptionDialog";
import WaitingRoomItemDialog from "./modules/pages/administration/waiting-room/WaitingRoomItemDialog";
import ChangePasswordDialog from "./modules/system/authorization/ChangePasswordDialog";
import EmailDialog from "./modules/pages/administration/emails/EmailDialog";
import UsersMessageDialog from "./modules/pages/administration/users/UsersMessageDialog";
import MedicalRecordShowDataDialog from "./modules/pages/administration/medical_records/MedicalRecordShowDataDialog";

// Komponenta pro odchycení a registraci history objektu pro práci s HTML5 history API
const HistoryRegistration = withRouter(class extends React.Component<RouteComponentProps> {
	constructor(props: RouteComponentProps) {
		super(props);
	}

	componentDidMount() {
		navigation.registerHistory(this.props.history as any);
	}

	render() {
		return null;
	}
});

// Komponenta pro "odskrolování nahoru" při aktivaci stránky v react routeru
const ScrollToTop = withRouter(class extends React.Component<RouteComponentProps> {
	componentDidUpdate(prevProps: RouteComponentProps) {
		if (this.props.history.location.pathname !== prevProps.location.pathname) {
			window.scrollTo(0, 0);
		}
	}
	render() {
		return this.props.children;
	}
});

// Komponenta pro načtení dat modulu
const LoadDataTriggerComponent = withRouter(class extends React.Component<RouteComponentProps & { routes: routing.Route[] }> {
	componentDidMount = async () => {
		await routing.loadData(window.location.pathname, this.props.routes, true);
	}

	componentDidUpdate = async (prevProps: RouteComponentProps) => {
		if (this.props.history.location.pathname !== prevProps.location.pathname) {
			await routing.loadData(window.location.pathname, this.props.routes, false);
		}
	}

	render = () => null;
});

/**
 * Komponenta pro reakci na změnu property
 */
interface WatcherProps {
	watchExpression: boolean;
	onChange: (expressionValue: boolean) => void;
}

class Watcher extends React.Component<WatcherProps> {
	componentDidUpdate = (prevProps: WatcherProps) => {
		if (prevProps.watchExpression !== this.props.watchExpression) {
			this.props.onChange(this.props.watchExpression);
		}
	}
	render = () => null;
}

/**
 * Abstrakce routeru pro clienta a server
 */
function Router(props: any) {
	return common.serverExecution()
		? <StaticRouter location={props.url}>{props.children}</StaticRouter>
		: <BrowserRouter>{props.children}</BrowserRouter>;
}

interface AppProps {
	url?: string;
	context: StateContext;
	routes: routing.Route[];
}

function AppWithStateContext(props: AppProps) {
	const stateContext = state.useStateContext();
	const { authorization } = stateContext;

	async function onAutorizationChanged() {
		await routing.loadData(window.location.pathname, props.routes);
	}

	return (
		<Router url={props.url}>
			<>
				<Watcher watchExpression={authorization.userLoggedIn()} onChange={onAutorizationChanged} />
				<InformationDialog />
				<LastRecordsAddDialog />
				<LastRecordConentDialog />
				<YesNoDialog />
				<LastMeasurementDialog />
				<LastMeasurementWidgetSelect />
				<HistoryRegistration />
				<SimpleAddDialog />
				<PainMonitorDialog />
				<ImageEditDialog />
				<ContentDialog />
				<CalendarDialog />
				<CalendarListDialog />
				<ResetPasswordDialog />
				<ChangePasswordDialog />
				<CaptureDialog />
				<PilulkoidDialog />
				<DoctorDialog />
				<ShowImageDialog />
				<MedicalRecordDescriptionDialog />
				<WaitingRoomItemDialog />
				<EmailDialog />
				<UsersMessageDialog />
				<MedicalRecordShowDataDialog />

				<LoadDataTriggerComponent routes={props.routes} />
				<ScrollToTop>
					<Switch>
						{props.routes.map((i, index) => <Route key={index}
							exact
							path={i.route}
							component={i.component}
							render={i.render}
						/>)}
						{props.routes.map(i => i.aliases?.map((a, index) => <Route key={index}
							exact
							path={a}
							component={i.component}
							render={i.render}
						/>))}

						{/* Fallback pro neexistující stránky */}
						<Route component={Page404} />
					</Switch>
				</ScrollToTop>
			</>
		</Router>
	);
}

const AppWithStateContextBound = state.bindContainers(
	AppWithStateContext,
	c => ({
		getStateContainers: () => {
			return [
				c.dialogs.stateContainer,
				c.authorization.stateContainer,
			];
		}
	})
);

export default function App(props: AppProps) {
	return (
		<AppContext.Provider value={props.context}>
			<AppWithStateContextBound {...props} />
		</AppContext.Provider>
	);
}
