/**
 * Přidání souboru
 */
import * as React from "react";
import * as state from "../../../lib/state";

import Dialog from "../dialogs/Dialog";
import HtmlContent from "../../../components/controls/HtmlContent";

function ContentDialog() {
	const { contentDialog } = state.useStateContext();
	const { apiImages } = state.useStateContext();
	const contentItem = contentDialog.getContentItem();
	const fullscreen = contentDialog.dialog.getCustomField("fullscreen");

	function handleFullScreen() {
		const selector = document.querySelector(".content-dialog") as any;
		const selector2 = document.querySelector(".content-items-container") as any;
		if (selector && selector2) {
			if (!fullscreen) {
				selector.style.width = window.innerWidth - 140 + "px";
				selector.style.height = window.innerHeight - 90 + "px";
				selector2.style.height = window.innerHeight - 210 + "px";
				selector2.style.width = window.innerWidth - 210 + "px";
			}
			else {
				selector.style.width = "600px";
				selector.style.height = "initial";
				selector2.style.height = "initial";
				selector2.style.width = "initial";
			}
			contentDialog.dialog.setCustomField("fullscreen", !fullscreen);
		}
	}

	return (
		<Dialog className="content-dialog" dialog={contentDialog.dialog}>
			<div>
				<h2 className="pb-3 dialog-title"><img className="d-inline mr-2" src="/images/folders.svg" alt="chart" /> {contentItem?.title}</h2>
				<hr className="dialog-title-hr" />
				<div className="content-items-container">
					<p className="mb-3 content-items-header">
						{contentItem?.url &&
							<iframe
								frameBorder={"none"}
								height="1100px"
								width="100%"
								src={contentItem?.url}>
							</iframe>
						}
						{!contentItem?.url &&
							<HtmlContent content={contentItem?.description} />
						}
					</p>
					{contentItem?.show_image &&
						<img
							className="content-items-item"
							style={{ maxHeight: "96%", width: "100%" }}
							alt="content"
							src={apiImages.getImageUrl(contentItem?.show_image)}
						/>
					}
				</div>
				{contentDialog.getIsMobile() && contentItem?.show_ambulance &&
					<div style={{ fontSize: 14 }}>
						Jestliže máte již aplikaci "Záchranka" nainstalovanou. Můžete ji spustit
						<a className="font-weight-bold ml-1" href="zachranka://alarm">zde</a>.
					</div>
				}
			</div>
			{/* <div onClick={handleFullScreen} className="content-dialog-fullscreen color-primary mr-2 mt-3">Fullscreen<Icon className="ml-2" picture="ExpandArrowsAlt" /></div> */}
		</Dialog >
	);
}

export default state.bindContainers(
	ContentDialog,
	context => context.contentDialog.dialog
);