/**
 * Modul detailu lékaře
 */

import * as state from "../../lib/state";
import * as navigation from "../../lib/navigation";
import * as validations from "../../lib/validations";
import * as context from "../../context";
import * as forms from "../../lib/forms";

/**
 * Stav modulu
 */
interface State {
}

export interface FormFields {
	password: string;
	confirm_password: string;
	old_password: string;
}

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	private stateContainer: state.StateContainer<State>;
	public form: forms.Form<FormFields>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({}, context);
		this.form = new forms.Form<FormFields>({
			fields: {
				password: {
					defaultValue: "",
					required: true,
					title: "Nové heslo",
					validate: value => this.validatePassword(value)
				},
				confirm_password: {
					defaultValue: "",
					required: true,
					title: "Potvrďte heslo",
					validate: value => this.validateConfirmPassword(value)
				},
				old_password: {
					defaultValue: "",
					required: true,
					title: "Staré heslo",
					validate: value => this.validateOldPassword(value)
				},
			}
		}, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			this.stateContainer,
			...this.form.getStateContainers()
		];
	}

	/**
	 * Validuje password
	 */
	validateOldPassword = (value: string) => {
		return value.trim().length == 0 ? "Vyplňte prosím heslo" : "";
	}

	/**
	 * Validuje password
	 */
	validatePassword = (value: string) => {
		return validations.isValidPassword(value);
	}

	/**
	 * Validuje password
	 */
	validateConfirmPassword = (value: string) => {
		if (value != this.form.getField("password").value) {
			return "Heslo pro potvrzení musí být stejné";
		}

		return value.trim().length == 0 ? "Vyplňte prosím heslo" : "";
	}

	loadData = async () => {
		this.form.clearFields();
	}

	save = async () => {
		await this.form.validate();
		if (this.form.isValid()) {
			const result = await this.context.api.post("/auth/change_password", { new_password: this.form.getField("password").value, old_password: this.form.getField("old_password").value });
			if (result) {
				this.form.clearFields();
				await this.context.standardDialogs.openInformationDialog("Přihlašovací údaje byly změněny.");
				navigation.to("/");
			}
		} else {
			await this.context.standardDialogs.openInformationDialog("Nejdříve je třeba potvrdit všechny kontroly.");
		}
	}
}