import * as dialogs from "./dialogs";
import * as context from "../../../context";
import * as forms from "../../../lib/forms";
import * as api from "../../../lib/api";
import * as notification from "../../../lib/notification";

export interface FormFields {
	name: string;
	description: string;
	date: string;
	disabled: boolean;
	title: string;
	id_user?: string;
}

export interface State {
	showName?: boolean;
	showDescription?: boolean;
	showDate?: boolean;
	setValue?: (value: api.CodeBookItemWithDate) => void;
	entity: string;
}

export class Model {
	public dialog: dialogs.Dialog<boolean, State>;
	public form: forms.Form<FormFields>;

	constructor(private context: context.StateContext) {
		this.dialog = new dialogs.Dialog<boolean, State>({
			defaultCustomFields: {
				setValue: undefined,
				showDate: undefined,
				showDescription: undefined,
				showName: undefined,
				entity: ""
			}
		}, context);
		this.form = new forms.Form<FormFields>({
			fields: {
				name: {
					title: "Název",
					defaultValue: "",
					readOnly: (form) => form.getField("disabled").value,
					required: (form) => this.dialog.getCustomField("showName") ? form.getField("name").value.length == 0 ? true : false : false,
					validate: (value) => this.dialog.getCustomField("showName") ? value.length == 0 ? "Vyplňte název" : "" : ""
				},
				title: {
					defaultValue: "",
					title: ""
				},
				id_user: {
					defaultValue: undefined,
					title: ""
				},
				description: {
					title: "Popis",
					defaultValue: "",
					readOnly: (form) => form.getField("disabled").value,
					required: (form) => this.dialog.getCustomField("showDescription") ? form.getField("description").value.length == 0 ? true : false : false,
					validate: (value) => this.dialog.getCustomField("showDescription") ? value.length == 0 ? "Vyplňte popis" : "" : ""
				},
				date: {
					title: "Datum",
					defaultValue: "",
					readOnly: (form) => form.getField("disabled").value,
					required: (form) => this.dialog.getCustomField("showDate") ? form.getField("date").value.length == 0 ? true : false : false,
					validate: (value) => this.dialog.getCustomField("showDate") ? value.length == 0 ? "Vyplňte datum" : "" : ""
				},
				disabled: {
					title: "",
					defaultValue: false
				},
			}
		}, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			...this.form.getStateContainers(),
			...this.dialog.getStateContainers()
		];
	}

	openDialog = (entity: string, setValue?: (value: api.CodeBookItem) => void, showName?: boolean, showDescription?: boolean, showDate?: boolean, disabled?: boolean, values?: FormFields, title?: string) => {
		if (!entity) {
			return;
		}
		this.form.clearFields();
		this.dialog.setCustomField("entity", entity);
		this.dialog.setCustomField("setValue", setValue);
		this.dialog.setCustomField("showName", showName);
		this.dialog.setCustomField("showDescription", showDescription);
		this.dialog.setCustomField("showDate", showDate);
		this.form.setField("title", title as any);
		this.form.setField("disabled", disabled == undefined ? false : disabled);
		if (values) {
			this.form.setFields(values);
		}

		return this.dialog.open();
	}

	save = async () => {
		await this.form.validate();
		if (this.form.isValid()) {
			const set = this.dialog.getCustomField("setValue");
			if (set) {
				set({ id: api.emptyGuid, name: this.form.getField("name").value, description: this.form.getField("description").value, date: this.form.getField("date").value });
			}
			const entity = this.dialog.getCustomField("entity");
			await this.context.api.put("/" + entity + "/" + api.emptyGuid, { id: api.emptyGuid, name: this.form.getField("name").value, description: this.form.getField("description").value, date: this.form.getField("date").value, id_user: this.form.getField("id_user").value })
			return this.dialog.close(true);
		}
		else {
			await notification.errorMessage("Formulář obsahuje neúplné informace nebo chyby. Opravte prosím červeně vyznačené položky a poté akci opakujte.");
		}
	}
}