/**
 * Reset hesla
 */
import Button from "../../../components/controls/Button";
import * as React from "react";
import * as state from "../../../lib/state";

import Dialog from "../../shared/dialogs/Dialog";
import FormsInput from "../../../components/controls/Forms/FormsInput";
import ButtonAdvanced from "../../../components/controls/ButtonAdvanced";

function ResetPasswordDialog() {
	const { resetPasswordDialog } = state.useStateContext();
	const dialog = resetPasswordDialog.dialog;

	return (
		<Dialog className="simple-add-dialog" dialog={dialog}>
			<div>
				<h2 className="pb-3 dialog-title">Reset hesla</h2>
				<hr className="dialog-title-hr" />
				<FormsInput
					inputProps={{ type: "text" }}
					form={resetPasswordDialog.form}
					field="email"
				/>
				<div className="mt-4 text-center">
					<ButtonAdvanced onClick={resetPasswordDialog.save} className="pt-2 pb-2 float-right">
						<strong>Resetovat heslo</strong>
					</ButtonAdvanced>
				</div>
			</div>
		</Dialog >
	);
}

export default state.bindContainers(
	ResetPasswordDialog,
	context => context.resetPasswordDialog
);