/**
 * MedicalRecordsExamination
 */
import React, { useRef } from "react";
import * as state from "../../../../lib/state";
import * as formatting from "../../../../lib/formatting";
import * as painMonitor from "../../home/widgets/pain-monitor/pain-monitor-dialog";
import { GeneralGraph } from "../../../../modules/shared/graphs/GeneralGraph";
import FormsEditableDiv from "../../../../components/controls/Forms/FormsEditableDiv";
import FormsSlider from "../../../../components/controls/Forms/FormsSlider";
import MedicalRecordHideSwitch from "./MedicalRecordHideSwitch";

export default function MedicalRecordsExamination2() {
	const { medicalRecords } = state.useStateContext();
	const form = medicalRecords.form;
	const container = medicalRecords.getStateContainer();
	const generalRef = useRef<any>(null);
	const generalRef2 = useRef<any>(null);
	const generalRef3 = useRef<any>(null);
	const generalRef4 = useRef<any>(null);

	function setField(e: any) {
		form.setField("food_and_excretion_description", e.currentTarget.textContent!)
	}

	return (
		<>
			{!container.types.includes(7) &&
				<div className={"medical-records-panel medical-records-examination2 " + (container.types.includes(7) ? "print-hide" : "")}>
					<div className="medical-records-panel-header">
						<div className="medical-records-panel-page">
							<img width={120} src="/images/Group 655.svg" alt="group7" />
						</div>
						<div className="medical-records-panel-header-page">
							{medicalRecords.getPageSize(5)}
						</div>

						<div>
							<div>Datum záznamu: {formatting.formatDate(form.getField("record_date").value)}</div>
							<div className="float-right">Datum hovoru: {formatting.formatDate(form.getField("call_date").value)}</div>
						</div>
					</div>
					<div className="text-center">
						<MedicalRecordHideSwitch type={7} />
						<h2 className="medical-records-panel-title font-weight-bold">Tele vyšetření - subjektivní hodnocení pacienta</h2>
					</div>
					<div className="d-flex mb-4">
						<div className="medical-records-examination2-panel mr-4">
							<div className="mr-0 medical-records-panel-blue medical-records-panel-main text-center medical-records-panel-blue-text">
								<div>Spánek</div>
							</div>
							<div className="medical-records-examination2-panel-inner">
								<div>
									<div className="margin-records-slider-margin mr-2 ml-2">
										<FormsSlider form={form} field="sleep" maxValue={5} />
									</div>
								</div>
								<div className="d-flex mt-3">
									<div>Trend</div>
								</div>
								<div>

									<div className="d-flex">
										<div style={{ width: "100%" }}>
											<GeneralGraph
												ref={generalRef}
												showWeek={container.showWeek}
												date_from={container.evaluation_date_from}
												date_to={container.evaluation_date_to}
												saving={container.saving}
												type={{ id: 104, name: "" }}
												hideHeader={true}
												height="155px"
											/>
										</div>
									</div>

									<FormsEditableDiv
										field="sleep_description"
										form={medicalRecords.form}
										className="medical-records-panel-blue medical-records-panel-main mb-4 medical-records-examination-panel-height"
									/>

									<div>
										<div style={{ height: 220 }} className="medical-records-list-item medical-records-labels d-block p-2">
											{painMonitor.labels.find(x => x.id == painMonitor.PainType.Sleep)?.items.map((i, index) =>
												<div key={index} className="d-flex mb-2 mt-2">
													<div className="medical-records-examination-order">
														{index + 1}
													</div>
													<div>
														{i}
													</div>
												</div>
											)}
										</div>
									</div>

								</div>
							</div>
						</div>
						<div className="medical-records-examination2-panel">
							<div className="mr-0 medical-records-panel-blue medical-records-panel-main text-center medical-records-panel-blue-text">
								<div>Jídlo a vylučování</div>
							</div>
							<div className="medical-records-examination2-panel-inner">
								<div>
									<div className="margin-records-slider-margin mr-2 ml-2">
										<FormsSlider form={form} field="food_and_excretion" maxValue={5} />
									</div>
								</div>
								<div className="d-flex mt-3">
									<div>Trend</div>
								</div>
								<div>

									<div className="d-flex">
										<div style={{ width: "100%" }}>
											<GeneralGraph
												ref={generalRef2}
												showWeek={container.showWeek}
												date_from={container.evaluation_date_from}
												date_to={container.evaluation_date_to}
												saving={container.saving}
												hideHeader={true}
												type={{ id: 105, name: "" }}
												height="155px"
											/>
										</div>
									</div>

									<FormsEditableDiv
										field="food_and_excretion_description"
										form={medicalRecords.form}
										className="medical-records-panel-blue medical-records-panel-main mb-4 medical-records-examination-panel-height"
									/>

									<div>
										<div style={{ height: 220 }} className="medical-records-list-item medical-records-labels d-block p-2">
											{painMonitor.labels.find(x => x.id == painMonitor.PainType.FoodAndExcretion)?.items.map((i, index) =>
												<div key={index} className="d-flex mb-2 mt-2">
													<div className="medical-records-examination-order">
														{index + 1}
													</div>
													<div>
														{i}
													</div>
												</div>
											)}
										</div>
									</div>

								</div>
							</div>
						</div>
					</div>
					<div className="d-flex">
						<div className="medical-records-examination2-panel mr-4">
							<div className="mr-0 medical-records-panel-blue medical-records-panel-main text-center medical-records-panel-blue-text">
								<div>Dýchání</div>
							</div>
							<div className="medical-records-examination2-panel-inner">
								<div>
									<div className="margin-records-slider-margin mr-2 ml-2">
										<FormsSlider form={form} field="breathing" maxValue={5} />
									</div>
								</div>
								<div className="d-flex mt-3">
									<div>Trend</div>
								</div>
								<div>

									<div className="d-flex">
										<div style={{ width: "100%" }}>
											<GeneralGraph
												ref={generalRef3}
												showWeek={container.showWeek}
												date_from={container.evaluation_date_from}
												date_to={container.evaluation_date_to}
												saving={container.saving}
												hideHeader={true}
												type={{ id: 106, name: "" }}
												height="155px"
											/>
										</div>
									</div>

									<FormsEditableDiv
										field="breathing_description"
										form={medicalRecords.form}
										className="medical-records-panel-blue medical-records-panel-main mb-4 medical-records-examination-panel-height"
									/>

									<div>
										<div style={{ height: 220 }} className="medical-records-list-item medical-records-labels d-block p-2">
											{painMonitor.labels.find(x => x.id == painMonitor.PainType.Breathing)?.items.map((i, index) =>
												<div key={index} className="d-flex mb-2 mt-2">
													<div className="medical-records-examination-order">
														{index + 1}
													</div>
													<div>
														{i}
													</div>
												</div>
											)}
										</div>
									</div>

								</div>
							</div>
						</div>
						<div className="medical-records-examination2-panel">
							<div className="mr-0 medical-records-panel-blue medical-records-panel-main text-center medical-records-panel-blue-text">
								<div>Zrak, čich, sluch</div>
							</div>
							<div className="medical-records-examination2-panel-inner">
								<div>
									<div className="margin-records-slider-margin mr-2 ml-2">
										<FormsSlider form={form} field="sight_smell_hearing" maxValue={5} />
									</div>
								</div>
								<div className="d-flex mt-3">
									<div>Trend</div>
								</div>
								<div>
									<div className="d-flex">
										<div style={{ width: "100%" }}>
											<GeneralGraph
												ref={generalRef4}
												showWeek={container.showWeek}
												date_from={container.evaluation_date_from}
												date_to={container.evaluation_date_to}
												saving={container.saving}
												hideHeader={true}
												type={{ id: 107, name: "" }}
												height="155px"
											/>
										</div>
									</div>

									<FormsEditableDiv
										field="sight_smell_hearing_description"
										form={medicalRecords.form}
										className="medical-records-panel-blue medical-records-panel-main mb-4 medical-records-examination-panel-height"
									/>

									<div>
										<div style={{ height: 220 }} className="medical-records-list-item medical-records-labels d-block p-2">
											{painMonitor.labels.find(x => x.id == painMonitor.PainType.SightSmellHearing)?.items.map((i, index) =>
												<div key={index} className="d-flex mb-2 mt-2">
													<div className="medical-records-examination-order">
														{index + 1}
													</div>
													<div>
														{i}
													</div>
												</div>
											)}
										</div>
									</div>

								</div>
							</div>
						</div>
					</div>
					<div className="medical-records-panel-footer">
						{medicalRecords.getPageSize(5)}
					</div>
				</div>
			}
		</>
	)
}
