/**
 * Administrace
 */
import React from "react";
import * as navigation from "../../../lib/navigation";
import * as state from "../../../lib/state";
import * as api from "../../../lib/api";
import * as apiUsers from "../administration/users/api-users";
import Page from "../../../components/templates/Page";
import Icon from "../../../modules/system/icons/Icon";
import storage from "store";

export default function AdministrationPage() {
	const { authorization } = state.useStateContext();
	const profile = authorization.getUserProfile()?.profile;
	function handleNavigation(url: string) {
		navigation.to(url);
	}

	return (
		<Page title="Administrace Dr. Digital" description="">
			{authorization.isAdminOrOperator() &&
				<div className="page-content-container">
					<div className="ml-3">
						<div className="text-light ml-3 mb-3">
							<div className="homepage-responsive-title d-inline">
								Administrace Dr. Digital
							</div>
						</div>
						<div onClick={() => handleNavigation("administrace/uzivatele")} className="administration ml-2">
							<div className="text-center mt-4">
								<Icon picture="User" className="administration-icon color-primary" />
							</div>
							<div className="administration-title color-primary">Seznam uživatelů</div>
						</div>
						{storage.get("delegateIdUser") &&
							<div onClick={() => handleNavigation("administrace/zdravotni-zaznam/" + api.emptyGuid)} className="administration ml-2 ">
								<div className="text-center mt-4">
									<Icon picture="BookMedical" className="administration-icon color-primary" />
								</div>
								<div className="administration-title color-primary">Zdravotní záznam</div>
							</div>
						}
						<div onClick={() => handleNavigation("administrace/cekarna")} className="administration ml-2 ">
							<div className="text-center mt-4">
								<Icon picture="PersonBooth" className="administration-icon color-primary" />
							</div>
							<div className="administration-title color-primary">Čekárna</div>
						</div>
						<div onClick={() => handleNavigation("administrace/lekari")} className="administration ml-2 ">
							<div className="text-center mt-4">
								<Icon picture="BookMedical" className="administration-icon color-primary" />
							</div>
							<div className="administration-title color-primary">Seznam lékařů</div>
						</div>
						<div onClick={() => handleNavigation("/emaily")} className="administration ml-2 ">
							<div className="text-center mt-4">
								<Icon picture="Email" className="administration-icon color-primary" />
							</div>
							<div className="administration-title color-primary">Seznam emailů</div>
						</div>
						<div onClick={() => handleNavigation("administrace/systemove-emaily")} className="administration ml-2 ">
							<div className="text-center mt-4">
								<Icon picture="Database" className="administration-icon color-primary" />
							</div>
							<div className="administration-title color-primary">Seznam systémových emailů</div>
						</div>
						<div onClick={() => handleNavigation("administrace/individualni-report")} className="administration ml-2 ">
							<div className="text-center mt-4">
								<Icon picture="Powerpoint" className="administration-icon color-primary" />
							</div>
							<div className="administration-title color-primary">Individuální report</div>
						</div>
						<div onClick={() => handleNavigation("administrace/zaznamy-dr-digital")} className="administration ml-2 ">
							<div className="text-center mt-4">
								<Icon picture="List" className="administration-icon color-primary" />
							</div>
							<div className="administration-title color-primary">Seznam záznamů Dr. Digital</div>
						</div>
						{profile?.role.id == apiUsers.Role.Admin &&
							<div onClick={() => handleNavigation("administrace/uzivatelske-kategorie")} className="administration ml-2 ">
								<div className="text-center mt-4">
									<Icon picture="User" className="administration-icon color-primary" />
								</div>
								<div className="administration-title color-primary">Uživatelské kategorie</div>
							</div>
						}
					</div>
				</div>
			}
		</Page >
	);
}