/**
 * MedicalRecordsConclusion
 */
import * as React from "react";
import FormsInput from "../../../../components/controls/Forms/FormsInput";
import * as state from "../../../../lib/state";
import * as formatting from "../../../../lib/formatting";
import * as api from "../../../../lib/api";
import FormsEditableDiv from "../../../../components/controls/Forms/FormsEditableDiv";
import MedicalRecordHideSwitch from "./MedicalRecordHideSwitch";

export default function MedicalRecordsConclusion() {
	const { medicalRecords } = state.useStateContext();
	const { apiImages } = state.useStateContext();
	const { authorization } = state.useStateContext();
	const profile = authorization.getUserProfile()?.profile;
	const container = medicalRecords.getStateContainer();

	return (
		<>
			{!container.types.includes(9) &&
				<div className={"medical-records-panel " + (container.types.includes(9) ? "print-hide" : "")}>
					<div className="medical-records-panel-header">
						<div className="medical-records-panel-page">
							<img width={120} src="/images/Group 655.svg" alt="group7" />
						</div>
						<div className="medical-records-panel-header-page ">
							{medicalRecords.getPageSize(7)}
						</div>
						<div>
							<div>Datum záznamu: {formatting.formatDate(medicalRecords.form.getField("record_date").value)}</div>
							<div className="float-right">Datum hovoru: {formatting.formatDate(medicalRecords.form.getField("call_date").value)}</div>
						</div>
					</div>
					<div className="text-center">
						<MedicalRecordHideSwitch type={9} />
						<h2 className="medical-records-panel-title font-weight-bold">Závěr</h2>
					</div>
					<div className="medical-records-panel-blue medical-records-panel-main text-center medical-records-panel-blue-text mb-2">
						Zhodnocení CC:
					</div>
					<FormsEditableDiv
						field="evaluation"
						form={medicalRecords.form}
						className="medical-records-panel-blue medical-records-panel-main mb-4 medical-records-panel-blue-min-height"
					/>

					<div className="medical-records-panel-blue medical-records-panel-main text-center medical-records-panel-blue-text mb-2">
						Závěr:
					</div>
					<FormsEditableDiv
						field="conclusion"
						form={medicalRecords.form}
						className="medical-records-panel-blue medical-records-panel-main mb-4 medical-records-panel-blue-min-height"
					/>
					<div className="medical-records-panel-blue medical-records-panel-main text-center medical-records-panel-blue-text mb-2">
						Doporučení:
					</div>
					<FormsEditableDiv
						field="recommendation"
						form={medicalRecords.form}
						className="medical-records-panel-blue medical-records-panel-main mb-4 medical-records-panel-blue-min-height"
					/>
					<div className="medical-records-panel-bottom">
						<h4>Poučení:</h4>
						Vyšetření proběhlo v danou chvíli v nejvyšší dostupné kvalitě se snahou o docílení optimálních podmínek. Distanční medicína s sebou nese limitace vyplývající z kvality přenosu, technických omezení a spolupráce pacienta - laika a nenahrazuje plnohodnotně vyšetření lékařem. V případě náhlého zhoršení zdravotního stavu navštivte nejbližší vhodné zdravotnické zařízení nebo volejte tísňovou linku 155.
					</div>
					<div className="d-flex mb-3">
						<div style={{ width: 130 }}>
							Zprávu vystavil:
						</div>
						<FormsInput inputProps={{ width: 275 }} className="medical-records-panel-input " form={medicalRecords.form} field="report_issued_by" />
					</div>
					<div className="d-flex mb-2">
						<div style={{ width: 130 }}>
							Zprávu připravil:
						</div>
						<FormsInput inputProps={{ width: 275 }} className="medical-records-panel-input " form={medicalRecords.form} field="report_created_by" />
					</div>
					<div className="d-flex">
						<div style={{ width: 160 }}>
							Datum záznamu:
						</div>
						<div>
							{formatting.formatDate(medicalRecords.form.getField("record_date").value)}
						</div>
					</div>
					<div className="d-flex">
						<div style={{ width: 160 }}>
							Datum hovoru:
						</div>
						<div>
							{formatting.formatDate(medicalRecords.form.getField("call_date").value)}
						</div>
					</div>
					<div className="medical-records-panel-footer">
						{medicalRecords.getPageSize(7)}
					</div>
				</div>
			}
			{!container.types.includes(10) &&
				<div className={"medical-records-panel " + (container.types.includes(10) ? "print-hide" : "")}>
					<div className="medical-records-panel-header">
						<div className="medical-records-panel-page">
							<img width={120} src="/images/Group 655.svg" alt="group7" />
						</div>
						<div className="medical-records-panel-header-page ">
							{medicalRecords.getPageSize(8)}
						</div>
					</div>
					<MedicalRecordHideSwitch top={"-25px"} type={10} />
					<div className="medical-records-panel-blue medical-records-panel-main text-center medical-records-panel-blue-text mb-2 mt-5">
						Dopis od lékaře:
					</div>
					<FormsEditableDiv
						id="message_from_doctor"
						field="message_from_doctor"
						form={medicalRecords.form}
						className="medical-records-panel-blue medical-records-panel-main mb-4 medical-records-panel-blue-min-height"
					/>
					<div className="medical-records-panel-blue medical-records-panel-main text-center medical-records-panel-blue-text mb-4 mt-3">
						Dokumenty
					</div>
					<div className="d-flex mt-2">
						{profile?.id_insurance_card && profile?.id_insurance_card != api.emptyGuid &&
							<div style={{ width: "33%" }} className="mb-3 mr-3">
								<div className="medical-records-list-item-title mb-1">Průkaz pojištěnce:</div>
								<div className="d-block">
									<img style={{ height: 190, width: 335 }} alt="pojistenec" src={apiImages.getImageUrl(profile?.id_insurance_card!)} />
								</div>
							</div>
						}
						{profile?.id_covid_certificate && profile?.id_covid_certificate != api.emptyGuid &&
							<div style={{ width: "33%" }} className="mb-3 mr-3">
								<div className="medical-records-list-item-title mb-1">Covid očkování:</div>
								<div className="d-block">
									<img style={{ height: 190, width: 335 }} alt="covid" src={apiImages.getImageUrl(profile?.id_covid_certificate!)} />
								</div>
							</div>
						}
					</div>
					<div className="medical-records-panel-footer">
						<div style={{ float: "left", marginLeft: 25 }} className="d-inline">
							<a target="_blank" href="https://www.drdigital.cz">www.drdigital.cz</a>
						</div>
						{medicalRecords.getPageSize(8)}
						<div className="medical-records-conclusion-drdigital">
							<img src="/images/drdigital.jpg" />
						</div>
					</div>
				</div>
			}
		</>
	);
}
