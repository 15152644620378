import * as React from "react";
import * as forms from "../../../lib/forms";
import * as common from "../../../lib/common";

import FormItem, { FormItemOptions } from "./FormItem";
import CheckBox, { CheckBoxProps } from "../Checkbox";
import Switch from "../Switch";


export interface FormsInputProps<TFormFields> {
	form: forms.Form<TFormFields>;
	field: keyof common.SubType<TFormFields, boolean | undefined>;
	className?: string;
	checkBoxProps?: CheckBoxProps;
	formItemOptions?: FormItemOptions;
	enhancedTitle?: React.ReactNode;
}

export default function FormGroupSwitch<TFormFields>(props: FormsInputProps<TFormFields>) {
	const field = props.form.getField(props.field);
	return (
		<FormItem
			form={props.form}
			field={props.field}
			options={{ ...props.formItemOptions, hideTitle: true }}
			className={props.className}
		>
			<Switch
				{...props.checkBoxProps}
				title={props.enhancedTitle ? props.enhancedTitle : props.form.getFieldTitle(props.field)}
				checked={props.form.getField(props.field).value as any}
				value={field.value}
				readOnly={field.readOnly}
				onCheckedChanged={(value: any) => props.form.setField(props.field, value as any)}
			/>
		</FormItem>
	);
}