
import React from "react";
import { HTMLReactParserOptions, domToReact } from "html-react-parser";

import Link from "./Link";

export interface HtmlContentProps {
	content?: React.ReactNode;
	htmlKey?: string;
	className?: string;
}

const parseOptions: HTMLReactParserOptions = {
	replace: (node: any) => {
		if (node.name === "a") {
			const { class: className, style, href, ...restAttribs } = node.attribs;
			return <Link className={className ?? ""} href={href}>{domToReact(node.children, parseOptions)}</Link>;
		}
	}
};

export default function HtmlContent(props: HtmlContentProps) {
	return (
		<>
			<div className={props.className + " d-inline " + (props.htmlKey == undefined ? "" : " custom-content ")} dangerouslySetInnerHTML={{ __html: props.content as any }}></div>
		</>
	);
}