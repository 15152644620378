import * as api from "../../../../lib/api";
import * as context from "../../../../context";
import * as apiCodeList from "../../../system/code-list/api-code-list";

export interface Category extends api.EntityReadBase {
	id: string;
	name: string;
	color: string;
	hidden: boolean;
}

export class Api {
	categories: api.EntityApi<Category, Category>;

	constructor(private context: context.StateContext) {
		this.categories = new api.EntityApi<Category, Category>({
			api: context.api,
			path: "/categories"
		});
	}

	loadData = () => {
		return this.context.api.loadList<apiCodeList.CodeList>("/categories", { per_page: Number.MAX_SAFE_INTEGER });
	}

	remove = (id: string) => {
		return this.context.api.del<apiCodeList.CodeList>("/categories/" + id);
	}

	loadDetail(id: string) {
		return this.context.api.get<Category>("/categories/" + id);
	}

	save(id: string, user: Category) {
		return this.context.api.put<Category, Category>("/categories/" + id, user);
	}
}