/**
 * Modul detailu mailu
 */

import * as state from "../../../../lib/state";
import * as api from "../../../../lib/api";
import * as context from "../../../../context";
import * as forms from "../../../../lib/forms";

/**
 * Stav modulu
 */
interface State {
}

export interface FormFields {
	id: string;
	id_user?: string;
	id_user_to?: string;
	subject: string;
	mail_from: string;
	mail_to: string;
	error: string;
	body: string;
	readed: boolean;
	new_attachments: api.CodeBookItem[];
}

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	private stateContainer: state.StateContainer<State>;
	public form: forms.Form<FormFields>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({}, context);
		this.form = new forms.Form<FormFields>({
			fields: {
				id: {
					title: "",
					defaultValue: api.emptyGuid
				},
				id_user: {
					title: "",
					defaultValue: api.emptyGuid
				},
				new_attachments: {
					defaultValue: [],
					title: ""
				},
				readed: {
					defaultValue: false,
					title: ""
				},
				subject: {
					title: "Subjekt",
					readOnly: true,
					defaultValue: "",
				},
				mail_from: {
					title: "Odesílatel",
					readOnly: true,
					defaultValue: "",
				},
				mail_to: {
					title: "Příjemce",
					readOnly: true,
					defaultValue: "",
				},
				body: {
					title: "Tělo",
					readOnly: true,
					defaultValue: "",
				},
				error: {
					title: "Chyba",
					readOnly: true,
					defaultValue: "",
				},
			}
		}, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			this.stateContainer,
			...this.form.getStateContainers()
		];
	}

	loadData = async (id: string) => {
		const email = await this.context.api.get("/emails/" + id) as any;
		if (email.id != api.emptyGuid && !email.readed) {
			this.context.api.post("/emails/readed/" + email.id, {});
		}
		this.form.setFields(email as any);
	}

	/**
	 * Provede reset formuláře
	 */
	reset = async () => {
		await Promise.all([
			this.form.clearFields(),
		]);
	}
}