/**
 * Administrace
 */
import React, { useRef } from "react";
import * as state from "../../../../lib/state";
import * as formatting from "../../../../lib/formatting";
import * as api from "../../../../lib/api";
import storage from "store";
import * as medicalRecordsModule from "./medical-records";
import Page from "../../../../components/templates/Page";
import Button from "../../../../components/controls/Button";
import Icon from "../../../../modules/system/icons/Icon";
import { PDFExport } from "@progress/kendo-react-pdf";
import MedicalRecordsPain from "./MedicalRecordsPain";
import MedicalRecordsInfo from "./MedicalRecordsInfo";
import MedicalRecordsExamination from "./MedicalRecordsExamination";
import MedicalRecordsEvaluation from "./MedicalRecordsEvaluation";
import MedicalRecordsConclusion from "./MedicalRecordsConclusion";
import MedicalRecordsExamination2 from "./MedicalRecordsExamination2";
import Spinner from "../../../../components/controls/Spinner";
import UsersMessage from "../users/UsersMessage";

interface Props {
	id: string;
}

function MedicalRecordsPage(props: Props) {
	const { medicalRecords, medicalRecordShowDataDialog } = state.useStateContext();
	const { authorization } = state.useStateContext();
	const { standardDialogs } = state.useStateContext();
	const pdfRef = useRef<any>(null);
	const pains = medicalRecords.formsPain.get();
	const idState = medicalRecords.form.getField("id_state").value;

	async function handlePrint() {
		await medicalRecords.savePdf(pdfRef.current);
	}

	async function handleSaveToConcept() {
		medicalRecords.save(pdfRef.current, medicalRecordsModule.MedicalRecordState.InConcept)
	}

	async function handleSave() {
		medicalRecords.save(pdfRef.current, medicalRecordsModule.MedicalRecordState.ApprovedByOperator)
	}

	async function handleDoctorAuth() {
		await standardDialogs.openYesNoDialog("Opravdu chcete odeslat záznam ke schválení lékaře? V Případě potvrzení, bude moci záznam dále změnit již pouze lékař/admin.") === "yes"
			? medicalRecords.save(pdfRef.current, medicalRecordsModule.MedicalRecordState.ApprovedByOperator)
			: undefined
	}

	async function handleLock() {
		await standardDialogs.openYesNoDialog("Opravdu chcete záznam schválit a zamknout? V Případě potvrzení již nebude možné záznam změnit.") === "yes"
			? medicalRecords.save(pdfRef.current, medicalRecordsModule.MedicalRecordState.ApprovedByDoctor)
			: undefined
	}

	async function handleDelete() {
		await standardDialogs.openYesNoDialog("Opravdu chcete smazat zdravotní záznam.") === "yes"
			? medicalRecords.remove(props.id)
			: undefined
	}

	async function handleShowData() {
		medicalRecordShowDataDialog.openDialog(medicalRecords.stateContainer.get().types);
	}

	return (
		<Page title="Zdravotní záznam - Kontinuální monitoring" description="">
			{(authorization.isAdminOrOperator() || storage.get("delegateIdUser")) &&
				<div style={{ overflow: "auto" }} className="page-content-container page-content-medical-records-container">
					<div style={{ height: 40 }} className="text-center mt-3">
						{props.id != api.emptyGuid &&
							<Button onClick={handleDelete} className="pt-2 pb-2 text-center mr-2 btn-danger">
								Smazat
							</Button>
						}
						{!medicalRecords.getStateContainer().showButtons &&
							<Spinner className="ml-2" small />
						}
						{medicalRecords.getStateContainer().showButtons &&
							<>
								<Button onClick={handleShowData} className="pt-2 pb-2 text-center mr-2">
									Zobrazení
								</Button>
								<Button onClick={handlePrint} className="pt-2 pb-2 text-center mr-2">
									Stáhnout PDF <Icon className="ml-2 mb-1 color-primary" picture="Download" />
								</Button>
								{(idState == medicalRecordsModule.MedicalRecordState.InConcept) &&
									<Button onClick={handleSaveToConcept} className="pt-2 pb-2 text-center mr-2">
										Uložit do konceptu
									</Button>
								}
								{(idState == medicalRecordsModule.MedicalRecordState.InConcept) &&
									<Button onClick={handleDoctorAuth} className="pt-2 pb-2 text-center mr-2 btn-success">
										Odeslat ke schválení lékaři
									</Button>
								}
								{(idState == medicalRecordsModule.MedicalRecordState.ApprovedByOperator) &&
									<Button onClick={handleSave} className="pt-2 pb-2 mr-2 text-center">
										Uložit
									</Button>
								}
								{(idState == medicalRecordsModule.MedicalRecordState.ApprovedByOperator) &&
									<Button onClick={handleLock} className="pt-2 pb-2 mr-2 text-center btn-success">
										Shválit a zamknout
									</Button>
								}
							</>
						}
					</div>
					<PDFExport
						ref={pdfRef}
						scale={0.49}
						margin={{ left: -30 }}
						paperSize="A4"
						fileName={"ZZ_" + authorization.getUserProfile()?.profile.personal_identification_number + authorization.getUserProfile()?.profile.personal_identification_number_sufix + "_" + formatting.formatDateTime(new Date()) + ".pdf"}
					>
						<div className="medical-records-container">

							<div className="medical-records">
								<MedicalRecordsInfo isSecondPage={false} />
								<MedicalRecordsInfo isSecondPage={true} />

								<MedicalRecordsPain isSecondPage={false} />
								{pains.length > 3 &&
									<MedicalRecordsPain isSecondPage={true} />
								}

								<MedicalRecordsExamination />
								<MedicalRecordsExamination2 />

								<MedicalRecordsEvaluation />

								<MedicalRecordsConclusion />

							</div>

						</div>
					</PDFExport>
					<div className="text-center mb-3">
						{props.id != api.emptyGuid &&
							<Button onClick={handleDelete} className="pt-2 pb-2 text-center mr-2 btn-danger">
								Smazat
							</Button>
						}
						{!medicalRecords.getStateContainer().showButtons &&
							<Spinner className="ml-2" small />
						}
						{medicalRecords.getStateContainer().showButtons &&
							<>
								<Button onClick={handleShowData} className="pt-2 pb-2 text-center mr-2">
									Zobrazení
								</Button>
								<Button onClick={handlePrint} className="pt-2 pb-2 text-center mr-2">
									Stáhnout PDF <Icon className="ml-2 mb-1 color-primary" picture="Download" />
								</Button>
								{(idState == medicalRecordsModule.MedicalRecordState.InConcept) &&
									<Button onClick={handleSaveToConcept} className="pt-2 pb-2 text-center mr-2">
										Uložit do konceptu
									</Button>
								}
								{(idState == medicalRecordsModule.MedicalRecordState.InConcept) &&
									<Button onClick={handleDoctorAuth} className="pt-2 pb-2 text-center mr-2 btn-success">
										Odeslat ke schválení lékaři
									</Button>
								}
								{(idState == medicalRecordsModule.MedicalRecordState.ApprovedByOperator) &&
									<Button onClick={handleSave} className="pt-2 pb-2 mr-2 text-center">
										Uložit
									</Button>
								}
								{(idState == medicalRecordsModule.MedicalRecordState.ApprovedByOperator) &&
									<Button onClick={handleLock} className="pt-2 pb-2 mr-2 text-center btn-success">
										Shválit a zamknout
									</Button>
								}
							</>
						}
					</div>
				</div>
			}
			<div style={{ position: "absolute", left: "-2000px", top: "0" }}>
				<UsersMessage />
			</div>
		</Page >
	);
}

export default state.bindContainers(
	MedicalRecordsPage,
	c => c.medicalRecords
);