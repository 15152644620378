/*
 * Komponenta checkboxu.
 */
import * as React from "react";
import CheckboxOriginal, { CheckBoxRadioProps } from "./CheckboxOriginal";

export type CheckBoxProps = Omit<CheckBoxRadioProps, "type">;

export default function Checkbox(props: CheckBoxProps) {
	return (
		<CheckboxOriginal type="checkbox" {...props} />
	);
}