import * as dialogs from "../../../shared/dialogs/dialogs";
import * as context from "../../../../context";
import * as forms from "../../../../lib/forms";
import * as api from "../../../../lib/api";
import * as formatting from "../../../../lib/formatting";
import * as notification from "../../../../lib/notification";
import { drawDOM, exportPDF, pdf } from "@progress/kendo-drawing";

export interface FormFields {
	id_user: string;
	page1: string;
	page2: string;
	id_dms_file_doctor_attachment: string;
}

export interface State {
}

export class Model {
	public dialog: dialogs.Dialog<boolean, State>;
	public form: forms.Form<FormFields>;

	constructor(private context: context.StateContext) {
		this.dialog = new dialogs.Dialog<boolean, State>({
			defaultCustomFields: {
				setValue: undefined,
				showDate: undefined,
				showDescription: undefined,
				showName: undefined,
				entity: ""
			}
		}, context);
		this.form = new forms.Form<FormFields>({
			fields: {
				id_user: {
					title: "",
					defaultValue: api.emptyGuid,
				},
				page1: {
					title: "",
					defaultValue: "",
					required: (form) => true,
					validate: (value) => value.length == 0 ? "Vyplňte popis" : ""
				},
				page2: {
					title: "",
					defaultValue: "",
				},
				id_dms_file_doctor_attachment: {
					title: "",
					defaultValue: api.emptyGuid
				}
			}
		}, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			...this.form.getStateContainers(),
			...this.dialog.getStateContainers()
		];
	}

	openDialog = (values?: FormFields) => {
		this.form.clearFields();
		if (values) {
			this.form.setFields(values);
		}

		return this.dialog.open();
	}

	save = async () => {
		await this.form.validate();
		if (this.form.isValid()) {
			const messageName = "Vzkaz_od_lékaře_" + formatting.formatDate(new Date()).replace(/\  /g, ' ').replace(/ /g, "_").replace(":", "_").replace(/\./g, '') + ".pdf";
			const values = this.form.getFields();
			let id_dms_file_doctor_attachment = undefined;

			if (values.id_dms_file_doctor_attachment) {
				const doctorEl = document.getElementById("message-doctor-dialog") as any;
				const exportPDDoctor = await exportPDF(await drawDOM(doctorEl, {
					scale: 0.49,
					margin: { bottom: 0 },
					paperSize: "A4"
				}));

				var fileDoctor = formatting.dataURLtoFile(exportPDDoctor, messageName);
				const uploadResultDoctor = await this.context.apiFile.upload(fileDoctor);
				id_dms_file_doctor_attachment = uploadResultDoctor.id;
			}

			await this.context.api.put("/medical_records/doctor_message/" + api.emptyGuid, {
				id: api.emptyGuid,
				id_user: this.form.getField("id_user").value,
				id_dms_file_doctor_attachment: id_dms_file_doctor_attachment,
				message_from_doctor: messageName
			})
			return this.dialog.close(true);
		}
		else {
			await notification.errorMessage("Formulář obsahuje neúplné informace nebo chyby. Opravte prosím červeně vyznačené položky a poté akci opakujte.");
		}
	}
}