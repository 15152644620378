/**
 * Modul domovské stránky
 */

import * as state from "../../../../../lib/state";
import * as api from "../../../../../lib/api";
import * as formatting from "../../../../../lib/formatting";
import * as context from "../../../../../context";
import * as forms from "../../../../../lib/forms";

export interface PainMonitor {
	id: string;
	name: string;
	description: string;
	cause: string;
	duration: string;
	date: Date;
	pain_relief: string;
	pain_intensity: any;
	pain_intensity_from: number;
	pain_intensity_to: number;
	type: number;
}

/**
 * Stav modulu
 */
interface State {
}

export interface FormFields {
	items: PainMonitor[];
	index: number;
}

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	private stateContainer: state.StateContainer<State>;
	public form: forms.Form<FormFields>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({}, context);
		this.form = new forms.Form<FormFields>({
			fields: {
				items: {
					title: "",
					defaultValue: []
				},
				index: {
					title: "",
					defaultValue: 0
				}
			}
		}, context);
	}

	handleUpload = (value: api.CodeBookItem | undefined) => {
		if (value) {
			const records = this.form.getField("items").value as any;
			records.push({
				id: api.emptyGuid,
				name: value.name
			})
			this.form.setField("items", records);
		}
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			this.stateContainer,
			...this.form.getStateContainers()
		];
	}

	loadData = async () => {
		const pain_monitor = await this.context.api.loadList<PainMonitor>("/pain_monitors", { sort_fields_desc: ["sys_date_create" as any], sort_fields: ["sys_date_create" as any] });
		let data = pain_monitor.data;
		data = data.map(p => {
			const description = [];
			if (p.duration) {
				description.push("Doba trvání: " + p.duration);
			}
			if (p.cause) {
				description.push("Příčina: " + p.cause);
			}
			if (p.pain_relief) {
				description.push("Úleva od bolesti: " + p.pain_relief);
			}
			if (p.description) {
				description.push(p.description);
			}
			p.description = description.join("\n");
			p.pain_intensity = p.pain_intensity_from;
			return p;
		});

		this.form.setField("items", data);
	}

	/**
	 * Provede reset formuláře
	 */
	reset = async () => {
		await Promise.all([
			this.form.clearFields(),
		]);
	}
}