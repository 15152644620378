/*
 * Modul pro práci s obrázky
 */
import * as dialogs from "../../shared/dialogs/dialogs";
import { AspectRatio } from "./api-images";
import * as context from "../../../context";
import * as api from "../../../lib/api";

/**
 * Re-exports
 */
export {
	AspectRatio
};

/**
 * Výsledek dialogu pro úpravu obrázku
 */
export interface ImageEditDialogResult {
	imageId: string;
}

interface State {
	imageId: string;
	aspectRatio: AspectRatio | null;
}

export class Model {
	public dialog: dialogs.Dialog<undefined, State>;

	constructor(private context: context.StateContext) {
		this.dialog = new dialogs.Dialog<undefined, State>({
			defaultCustomFields: {
				aspectRatio: null,
				imageId: api.emptyGuid
			}
		}, context);
	}


	/**
 * Vrací kolekci stavových kontejnerů
 */
	getStateContainers = () => {
		return [
			...this.dialog.getStateContainers()
		];
	}
	/**
	 * Nechá uživatele editovat obrázek a vrací výsledné imageId
	 */
	letUserEditImage = async (imageId: string, aspectRatio?: AspectRatio) => {
		this.dialog.setCustomField("imageId", imageId);
		this.dialog.setCustomField("aspectRatio", aspectRatio ? aspectRatio : null);
		const result = await this.dialog.open() as any;
		if (!result)
			return undefined;

		return result.imageId;
	}

	open = async (imageId: string) => {
		await this.dialog.setCustomField("imageId", imageId);
		this.dialog.open();
	}

	/**
	 * Vrací ID obrázku editovaného v dialogu
	 */
	getEditingImageId = () => {
		return this.dialog.getCustomField("imageId");
	}

	/**
	 * Vrací poměr stran obrázku editovaného v dialogu
	 */
	getEditingAspectRatio = () => {
		return this.dialog.getCustomField("aspectRatio");
	}

	/**
	 * Nastavuje editovaný obrázek
	 */
	setDialogImageId = (imageId: string) => {
		this.dialog.setCustomField("imageId", imageId);
	}

	/**
	 * Potvrdí změny v editačním dialogu a uzavře ho
	 */
	confirmEditDialog = async () => {
		if (this.imageEditConfirmMethod) {
			await this.imageEditConfirmMethod();
		}
		this.dialog.close({ imageId: this.dialog.getCustomField("imageId") } as any);
	}

	/**
	 * Stornuje změny v editačním dialogu a uzavře ho
	 */
	stornoEditDialog = () => {
		this.dialog.close();
	}

	public imageEditConfirmMethod: (() => Promise<void>) | null;

	/**
	 * Nastaví metodu pro potvrzení změn v komponentě ImageEdit 
	 */
	setConfirmGetter = (getter: () => Promise<void>) => {
		this.imageEditConfirmMethod = getter;
	}
}