/**
 * MedicalRecordsPain
 */
import * as React from "react";
import Button from "../../../../components/controls/Button";
import * as state from "../../../../lib/state";
import * as formatting from "../../../../lib/formatting";
import FormsInput from "../../../../components/controls/Forms/FormsInput";
import FormsEditableDiv from "../../../../components/controls/Forms/FormsEditableDiv";
import FormsSlider from "../../../../components/controls/Forms/FormsSlider";
import MedicalRecordHideSwitch from "./MedicalRecordHideSwitch";

interface Props {
	isSecondPage?: boolean;
}

export default function MedicalRecordsPain(props: Props) {
	const { medicalRecords } = state.useStateContext();
	const pains = medicalRecords.formsPain.get();
	const container = medicalRecords.getStateContainer();

	async function addPain() {
		await medicalRecords.formsPain.add();
	}

	return (
		<>
			{!container.types.includes(5) &&
				<div className={"medical-records-panel medical-records-pain " + (container.types.includes(5) ? "print-hide" : "")}>
					<div className="medical-records-panel-header">
						<div className="medical-records-panel-page">
							<img width={120} src="/images/Group 655.svg" alt="group7" />
						</div>
						<div className="medical-records-panel-header-page">
							{medicalRecords.getPageSize(props.isSecondPage ? 4 : 3, true)}
						</div>
						<div>
							<div>Datum záznamu: {formatting.formatDate(medicalRecords.form.getField("record_date").value)}</div>
							<div className="float-right">Datum hovoru: {formatting.formatDate(medicalRecords.form.getField("call_date").value)}</div>
						</div>
					</div>
					<div className="text-center">
						<MedicalRecordHideSwitch type={5} />
						<h2 className="medical-records-panel-title font-weight-bold">Bolesti</h2>
					</div>
					<div>
						{pains.length == 0 &&
							<div className="medical-records-panel-blue medical-records-panel-main text-center medical-records-panel-blue-text mb-2">
								Žádná bolest není vyplněna
							</div>
						}
						{pains.slice(props.isSecondPage ? 3 : 0, props.isSecondPage ? 6 : 3).map((formPain, index) => {
							const currentIndex = props.isSecondPage ? index + 3 : index;

							return <React.Fragment key={currentIndex}>
								<div className="medical-records-panel-blue medical-records-panel-main text-center medical-records-panel-blue-text mb-4">
									Bolest č. {currentIndex + 1}
									<Button
										onClick={() => medicalRecords.removePain(formPain)}
										className="pt-2 pb-2 medical-records-remove">
										Smazat
									</Button>
								</div>
								<div className="d-flex mb-4">
									<div className="mr-5" style={{ width: "45%" }}>
										<div className="medical-records-list-item medical-records-list-item-pain">
											<div className="d-flex medical-records-pain-line-height">
												<div className="medical-records-pain-title">
													Datum nahlášení:
												</div>
												{!container.saving &&
													<div style={{ width: "320px", marginLeft: "-8px" }}>
														<FormsInput
															className={"input medical-records-custom-inp text-dark medical-records-custom-inp-div d-inline-block"}
															form={formPain}
															inputProps={{ type: "date", style: { width: 140 } }}
															field="date" />
													</div>
												}
												{container.saving &&
													<div style={{ width: 140 }} className="medical-records-custom-inp-div medical-records-custom-inp-div-border ml-0">
														{formatting.formatDate(new Date(formPain.getField("date").value))}
													</div>
												}
											</div>
											<div className="d-flex medical-records-pain-line-height">
												<div className="medical-records-pain-title">
													Oblast:
												</div>
												<div style={{ width: "320px" }}>
													<FormsEditableDiv
														field="name"
														form={formPain}
														className="mr-2 medical-records-custom-inp-div medical-records-custom-inp-div-border"
													/>
												</div>
											</div>
											<div className="d-flex medical-records-pain-line-height">
												<div className="medical-records-pain-title">
													Doba trvání:
												</div>
												<div style={{ width: "320px" }}>
													<FormsEditableDiv
														field="duration"
														form={formPain}
														className="mr-2 medical-records-custom-inp-div medical-records-custom-inp-div-border d-inline-block"
													/>
												</div>
											</div>
											<div className="d-flex medical-records-pain-line-height">
												<div className="medical-records-pain-title">
													Příčina:
												</div>
												<div style={{ width: "320px" }}>
													<FormsEditableDiv
														field="cause"
														form={formPain}
														className="mr-2 medical-records-custom-inp-div medical-records-custom-inp-div-border"
													/>
												</div>
											</div>
											<div className="d-flex medical-records-pain-line-height mb-5">
												<div className="medical-records-pain-title">
													Úleva od bolesti:
												</div>
												<div style={{ width: "320px" }}>
													<FormsEditableDiv
														field="pain_relief"
														form={formPain}
														className="mr-2 medical-records-custom-inp-div medical-records-custom-inp-div-border"
													/>
												</div>
											</div>
										</div>
									</div>
									<div className="mr-5" style={{ width: "45%" }}>
										<div className="mb-1 font-weight-bold">
											Intenzita bolesti
										</div>
										<div>
											<FormsSlider range={true} form={formPain} field="pain_intensity" maxValue={10} />
										</div>
										<div className="mt-5 mb-1 font-weight-bold">
											Popis
										</div>
										<div>
											<FormsEditableDiv
												field="description"
												form={formPain}
												className="medical-records-panel-blue medical-records-panel-main medical-records-pain-description-min-height"
											/>
										</div>
									</div>
								</div>
							</React.Fragment>
						}
						)}
						{pains.length < (props.isSecondPage ? 6 : 4) &&
							<div>
								<Button onClick={addPain} className="pt-2 pb-2 mt-3">
									Přidat bolest
								</Button>
							</div>
						}

					</div>
					<div className="medical-records-panel-footer">
						{medicalRecords.getPageSize(props.isSecondPage ? 4 : 3, true)}
					</div>
				</div >
			}
		</>
	);
}
