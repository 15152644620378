/**
 * Modul detailu uživatele
 */

import * as state from "../../../../lib/state";
import * as api from "../../../../lib/api";
import * as notification from "../../../../lib/notification";
import * as navigation from "../../../../lib/navigation";
import * as context from "../../../../context";
import * as forms from "../../../../lib/forms";
import * as apiUsers from "./api-users";

/**
 * Stav modulu
 */

export interface Category {
	id: string;
	name: string;
	color: string;
	category_id: string;
}

interface State {
	id: string;
	categories: Category[];
}

export interface FormFields {
	id: string;
	full_name: string;
	role?: api.CodeBookItemWithNumberId;
	user_categories: apiUsers.UserCategory[];
	user_allowed_categories: apiUsers.UserCategory[];
}

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	public stateContainer: state.StateContainer<State>;
	public form: forms.Form<FormFields>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({ categories: [], id: "" }, context);
		this.form = new forms.Form<FormFields>({
			fields: {
				id: {
					title: "",
					defaultValue: api.emptyGuid
				},
				full_name: {
					defaultValue: "",
					title: ","
				},
				role: {
					title: "",
					defaultValue: undefined
				},
				user_categories: {
					title: "",
					defaultValue: [],
				},
				user_allowed_categories: {
					title: "",
					defaultValue: [],
				},
			}
		}, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			this.stateContainer,
			...this.form.getStateContainers()
		];
	}

	loadData = async (id: string) => {
		const categories = await this.context.api.loadList("/categories", { sort_fields: ["sys_date_create"] }) as any;
		await this.stateContainer.merge(() => ({
			categories: categories.data
		}));
		const user = await this.context.api.get("/users/detail/" + id) as apiUsers.User;
		this.form.setFields(user);
	}

	save = async () => {
		const data = this.form.getFields();
		const category = await this.context.api.put("/categories/user/" + this.form.getField("id").value, data);
		if (category) {
			await notification.successMessage("Kategorie byla úspěšně uložena.");
			navigation.to("/administrace/uzivatele");
		}
	}

	setUserCategory = (id: string) => {
		const categories = this.form.getField("user_categories").value;
		if (!categories.map(x => x.id_category).includes(id)) {
			categories.push({ id: api.emptyGuid, id_user: this.form.getField("id").value, id_category: id, name: "" });
			this.form.setField("user_categories", categories);
		}
		else {
			this.form.setField("user_categories", categories.filter(x => x.id_category != id));
		}
	}

	setUserAllowedCategory = (id: string) => {
		const categories = this.form.getField("user_allowed_categories").value;
		if (!categories.map(x => x.id_category).includes(id)) {
			categories.push({ id: api.emptyGuid, id_user: this.form.getField("id").value, id_category: id, name: "" });
			this.form.setField("user_allowed_categories", categories);
		}
		else {
			this.form.setField("user_allowed_categories", categories.filter(x => x.id_category != id));
		}
	}


	/**
	 * Provede reset formuláře
	 */
	reset = async () => {
		await Promise.all([
			this.form.clearFields(),
		]);
	}
}