import * as dialogs from "./dialogs";
import * as api from "../../../lib/api";
import * as context from "../../../context";
import * as forms from "../../../lib/forms";
import * as state from "../../../lib/state";
import * as file from "../../system/file/api-file";

interface FormFields {
	file?: file.File;
	state: "" | "recording" | "saved";
	init: boolean;
}

export class Model {
	public dialog: dialogs.Dialog<file.File, FormFields>;
	public form: forms.Form<FormFields>;

	constructor(private context: context.StateContext) {
		this.form = new forms.Form<FormFields>({
			fields: {
				state: {
					defaultValue: "",
					title: ""
				},
				init: {
					title: "",
					defaultValue: false,
				},
				file: {
					title: "",
					defaultValue: undefined,
					readOnly: true
				}
			}
		}, context);
		this.dialog = new dialogs.Dialog<file.File, FormFields>({
			defaultCustomFields: {
				file: undefined,
				state: "",
				init: false
			}
		}, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			...this.form.getStateContainers(),
			...this.dialog.getStateContainers()
		];
	}

	openDialog = async () => {
		this.form.setField("file", undefined);
		this.form.setField("state", "");
		this.form.setField("init", true);
		await this.dialog.open();
	}

	/**
	 * Provede reset formuláře
		 */
	reset = async () => {
		await Promise.all([
			this.form.clearFields(),
		]);
	}
}