/**
 * Modul detailu kategorie
 */

import * as state from "../../../../lib/state";
import * as api from "../../../../lib/api";
import * as notification from "../../../../lib/notification";
import * as navigation from "../../../../lib/navigation";
import * as context from "../../../../context";
import * as forms from "../../../../lib/forms";
import * as apiCategories from "./api-categories";

/**
 * Stav modulu
 */
interface State {
}

export interface FormFields {
	id: string;
	name: string;
	color: string;
	hidden: boolean;
}

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	private stateContainer: state.StateContainer<State>;
	public form: forms.Form<FormFields>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({}, context);
		this.form = new forms.Form<FormFields>({
			fields: {
				id: {
					title: "",
					defaultValue: api.emptyGuid
				},
				name: {
					title: "Název",
					defaultValue: "",
					required: true,
					validate: (value) => value.trim().length === 0 ? "Vyplňte pole" : "",
				},
				color: {
					title: "Barva",
					defaultValue: "",
					required: true,
					validate: (value) => value.trim().length === 0 ? "Vyplňte pole" : "",
				},
				hidden: {
					title: "Nezobrazovat",
					defaultValue: false,
				},
			}
		}, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			this.stateContainer,
			...this.form.getStateContainers()
		];
	}

	loadData = async (id: string) => {
		if (id != api.emptyGuid) {
			const category = await this.context.api.get("/categories/" + id) as apiCategories.Category;
			if (category) {
				this.form.setFields(category as any);
			}
		}
		else {
			this.form.clearFields();
		}
	}

	save = async () => {
		await this.form.validate();
		if (this.form.isValid()) {
			const data = this.form.getFields();
			const category = await this.context.api.put("/categories/" + this.form.getField("id").value, data);
			if (category) {
				await notification.successMessage("Kategorie byla úspěšně uložena.");
				navigation.to("/administrace/uzivatelske-kategorie");
			}
		}
		else {
			await notification.errorMessage("Formulář obsahuje neúplné informace nebo chyby. Opravte prosím červeně vyznačené položky a poté akci opakujte.");
		}
	}

	/**
	 * Provede reset formuláře
	 */
	reset = async () => {
		await Promise.all([
			this.form.clearFields(),
		]);
	}
}