import React, { useRef, useState, useEffect } from "react"
import * as state from "../../lib/state";

const HtmlEditor = ({ value, onChange, disabled, simpleMode }: any) => {
	const { apiImages } = state.useStateContext();
	const editor = useRef(null);
	const [isLoaded, setIsLoaded] = useState(false);
	const [Editor, setEditor] = useState(null as any);
	const [tmpEditor, setTmpEditor] = useState(null as any);
	const image = useRef(null as any);

	useEffect(() => {
		import("jodit-react").then(res => {
			setEditor(res.default);
			setIsLoaded(true)
		});
	}, []);
	const [config] = useState({
		readonly: disabled,
		toolbar: simpleMode ? false : true,
		showPlaceholder: false,
		language: "cs_cz",
		cleanHTML: {
			fillEmptyParagraph: false
		},
		buttons: [
			...[
				"bold",
				"strikethrough",
				"underline",
				"italic", "|",
				"ul",
				"ol", "|",
				"outdent", "indent", "|",
				"font",
				"fontsize",
				"brush",
				"paragraph", "|",
				"image",
				"video",
				"table",
				"link", "|",
				"align", "undo", "redo", "|",
				"hr",
				"eraser",
				"copyformat", "|",
				"symbol",
				"print"
			],
			{
				tooltip: 'Vloží obrázek z průzkumníku',
				icon: "image",
				exec: (editor: any) => {
					setTmpEditor(editor);
					image.current!.click()
				}
			}
		],
		removeButtons: ["about", "fullsize"],
		enter: "div",
		i18n: {
			cs_cz: {
				"Type something": "Napište něco",
				"Your code is similar to HTML. Keep as HTML?": "Váš kód vypadá jako HTML. Ponechat jako HTML?",
				"Paste as HTML": "Kopírovat jako HTML",
				"Keep": "Ponechat",
				"Insert as Text": "Vložit jako text",
				"Cancel": "Zrušit",
				"Insert only Text": "Vložit pouze text",
				"Chars": "Znaků",
				"Words": "Slov"
			}
		}
	});
	if (!isLoaded) {
		return null;
	}
	else {
		setTimeout(onBlur, 200);
	}

	const handleBlur = (content: string) => {
		onChange(content);
	}

	function onBlur() {
		const editorRef = editor as any;
		const doc = editorRef.current.parentElement.querySelector(".ace_text-input") as any;
		if (doc) {
			doc.onblur = function () {
				const value = editorRef.current.parentElement.querySelector(".jodit-wysiwyg")!.innerHTML;
				handleBlur(value);
			}
		}
	}

	async function handleImageChange(e: React.ChangeEvent<HTMLInputElement>) {
		const file = e.target.files![0];
		e.target.value = "";
		const result = await apiImages.upload(file);
		const item = { id: result.id, name: file.name };
		if (item && tmpEditor) {
			tmpEditor.s.insertHTML(`<img class="list-item-body-image" src="${apiImages.getImageUrl(item.id as any)}"/>`);
		}
	}

	return (
		<>
			<Editor
				ref={editor}
				value={value}
				config={config as any}
				onBlur={handleBlur}
			/>
			<input
				className="d-none"
				accept="image/*"
				type="file"
				onChange={handleImageChange}
				ref={image}>
			</input>
		</>
	)
}

export default HtmlEditor