/**
 * Přidání poznámky
 */
import Button from "../../../../components/controls/Button";
import * as React from "react";
import * as state from "../../../../lib/state";

import Dialog from "../../../shared/dialogs/Dialog";
import FormTextArea from "../../../../components/controls/Forms/FormsTextArea";

function MedicalRecordDescriptionDialog() {
	const { medicalRecordDescriptionDialog } = state.useStateContext();
	const dialog = medicalRecordDescriptionDialog.dialog;

	return (
		<Dialog className="simple-add-dialog" dialog={dialog}>
			<div>
				<h2 className="pb-3 dialog-title"><img className="d-inline mr-2" src="/images/folders-1.svg" alt="folders-1" />Nastavit popis</h2>
				<hr className="dialog-title-hr" />
				<FormTextArea form={medicalRecordDescriptionDialog.form} field="description" />
				<div className="mt-4 text-center">
					<Button onClick={e => medicalRecordDescriptionDialog.save()} className="pt-2 pb-2 float-right">
						<strong>Vložit</strong>
					</Button>
				</div>
			</div>
		</Dialog >
	);
}

export default state.bindContainers(
	MedicalRecordDescriptionDialog,
	context => context.medicalRecordDescriptionDialog
);