/**
 * Stránka pro widget last mesurement
 */
import Help from "../../../../../components/controls/Help";
import React, { useRef } from "react";
import * as state from "../../../../../lib/state";
import Icon from "../../../../../modules/system/icons/Icon";
import { LastMeasurementWidget } from "./LastMeasurementWidget";

export function LastMeasurementPage() {
	const measurementRef = useRef<any>(null);

	function handleRefresh() {
		measurementRef.current.loadWidgets();
	}

	return (
		<div className="last-measurement-page-container">
			<div className="homepage-widget-header">
				<img className="d-inline mr-2" src="/images/Path 9.svg" alt="windy-strong" />
				<h2 className="d-inline">Vitální funkce</h2>
				<Help id="vital-function-help" text="Zde se Vám ukládají všechny hodnoty naměřené přes aplikaci Dr. Digital monitoring." />

				<div onClick={handleRefresh} className="d-inline float-right color-primary cursor-pointer"><Icon picture="Refresh" /></div>
			</div>
			<div className="d-flex">
				<LastMeasurementWidget ref={measurementRef} />
			</div>
		</div >
	);
}

export default state.bindContainers(
	LastMeasurementPage,
	c => c.lastMeasurement
);