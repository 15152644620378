import { Moment } from "moment";

export enum CalendarView {
	Day = 1,
	Week = 2,
	Month = 3
}

export enum CalendarType {
	Morning = 1,
	Noon = 2,
	Night = 3
}

export interface Calendar {
	date: Moment;
	items: CalendarItem[];
}

export interface CalendarItem {
	id: string;
	date: Date;
	description: string;
	type?: CalendarType;
	day?: string;
	id_pilulkoid?: string;
	id_pain_monitor?: string;
	id_doctor?: string;
	id_treatment?: string;
}
