/**
 * Seznam uživatelů
 */

import * as state from "../../../../lib/state";
import * as context from "../../../../context";
import * as api from "../../../../lib/api";
import * as lists from "../../../system/lists/lists";
import * as apiEmails from "./api-emails";

/**
 * Stav modulu
 */
export interface State {
	is_readed: boolean;
}

export class Model implements state.StateModel {
	list: lists.StandardList<apiEmails.Email>;
	stateContainer: state.StateContainer<State>;

	constructor(private context: context.StateContext) {
		this.list = new lists.StandardList<apiEmails.Email>({
			context: context,
			title: "Seznam emailů",
			quickFilterPlaceholder: "Zadejte název...",
			itemActionsList: { actions: [] },
			standardEntityApi: context.apiEmails.userEmails as any,
			filterSystem: _ => [
				api.qp("deleted" as any, "=", false),
				api.qp("readed" as any, "=", this.getIsReaded() ? false : undefined),
			],
			defaultSorting: { field: "date_of_create" as any, order: 1 },
			sortingFields: [],
			grid: {
				minWidth: "1000px",
				columns: [
					{
						field: "mail_from" as any,
						type: "string",
						title: "Odesílatel",
					},
					{
						field: "mail_to" as any,
						type: "string",
						title: "Příjemce",
					},
					{
						field: "subject" as any,
						type: "string",
						title: "Subjekt",
					},
					{
						field: "date_of_create" as any,
						type: "string",
						title: "Datum vytvoření",
						width: "170px"
					},
					{
						field: "readed" as any,
						width: "120px",
						type: "boolean",
						title: "Přečtené",
					},
					{
						field: "action" as any,
						width: "80px",
						type: "string",
						title: "Akce",
						preventSorting: true
					},
				]
			},
			defaultPageSize: 40,
			hideTabFilter: true,
			hideQueryMode: true
		});
		this.stateContainer = new state.StateContainer<State>({ is_readed: false }, context);
	}

	/**
	* Vrací kolekci stavových kontejnerů
	*/
	getStateContainers = () => [
		this.stateContainer,
		...this.list.getStateContainers(),
	]

	getIsReaded = () => {
		return this.stateContainer.get().is_readed;
	}

	setIsReaded = async (value: boolean) => {
		await this.stateContainer.merge(() => ({
			is_readed: value
		}));
		this.list.loadWithResetPage();
	}

	loadData = async () => {
		await this.list.loadWithResetPage();
	}
}