import * as dialogs from "../../../../shared/dialogs/dialogs";
import * as api from "../../../../../lib/api";
import * as context from "../../../../../context";
import * as forms from "../../../../../lib/forms";
import * as formatting from "../../../../../lib/formatting";
import * as file from "../../../../system/file/api-file";
import * as notification from "../../../../../lib/notification";
import * as state from "../../../../../lib/state";

interface Document {
	id: string;
	name: string;
	id_dms_file: string;
}

interface FormFields {
	id: string;
	file?: file.File;
	custom_text: boolean;
	name: string;
	description: string;
	date: string;
	note_description: string;
	note_name: string;
	type?: api.CodeBookItemWithNumberId;
	documents: Document[];
	id_state: number;
}

interface State {
	showRecord: boolean;
	showAddFile: boolean;
	type: "audio" | "video" | "photo" | "";
	state: "" | "recording" | "saved";
	isMobile?: boolean;
	disabled: boolean;
}

export function defaultState(isMobile?: boolean) {
	return {
		showRecord: false,
		showAddFile: false,
		type: "",
		state: "",
		isMobile: isMobile,
		disabled: false
	} as State
}
export class Model {
	public stateContainer: state.StateContainer<State>;
	public dialog: dialogs.Dialog<boolean, State>;
	public form: forms.Form<FormFields>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>(defaultState(), context);
		this.form = new forms.Form<FormFields>({
			fields: {
				id: {
					defaultValue: api.emptyGuid,
					title: ""
				},
				id_state: {
					defaultValue: 1,
					title: ""
				},
				date: {
					title: "Datum",
					defaultValue: "",
					required: (form) => true,
					validate: (value) => value.length == 0 ? "Vyplňte datum" : "",
					readOnly: (form) => this.stateContainer.get().disabled,
				},
				custom_text: {
					title: "",
					defaultValue: false
				},
				description: {
					title: "Popis",
					defaultValue: "",
				},
				name: {
					title: "Název",
					defaultValue: "",
					required: (form) => true,
					validate: (value) => !this.stateContainer.get().showAddFile && value.length == 0 ? "Vyplňte název" : "",
					readOnly: (form) => this.stateContainer.get().disabled,
				},
				documents: {
					defaultValue: [],
					title: ""
				},
				type: {
					title: "Typ",
					defaultValue: undefined,
					readOnly: (form) => this.stateContainer.get().disabled,
				},
				note_description: {
					title: "Popis",
					defaultValue: "",
					required: (form) => true,
					validate: (value) => this.stateContainer.get().showAddFile && value.length == 0 ? "Vyplňte popis" : "",
				},
				note_name: {
					title: "Název",
					defaultValue: "",
					required: (form) => true,
					validate: (value) => this.stateContainer.get().showAddFile && value.length == 0 ? "Vyplňte název" : ""
				},
				file: {
					title: "",
					defaultValue: undefined,
					readOnly: true,
					onChange: (value) => {
						if (value) {
							const documents = this.form.getField("documents").value;
							documents.push({ id: api.emptyGuid, name: value.name, id_dms_file: value.id });
							this.form.setField("documents", documents);
							this.stateContainer.merge(() => ({
								showAddFile: false
							}));
						}
					}
				}
			}
		}, context);
		this.dialog = new dialogs.Dialog<boolean, State>({
			defaultCustomFields: defaultState()
		}, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			this.stateContainer,
			...this.form.getStateContainers(),
			...this.dialog.getStateContainers()
		];
	}

	getStateContainer = () => {
		return this.stateContainer.get();
	}

	handleDescription = async () => {
		await this.form.validate();
		if (this.form.isValid()) {
			const value = this.form.getField("note_description").value;
			const name = this.form.getField("note_name").value;
			var blob = new Blob([value], { type: "text/plain" });
			const file = new File([blob], name + ".txt");
			const result = await this.context.apiFile.upload(file, { suppressErrorNotification: true }) as any;
			const documents = this.form.getField("documents").value;
			documents.push({ id: api.emptyGuid, name: name, id_dms_file: result.id });
			this.form.setField("documents", documents);
			this.form.setField("custom_text", false);
			this.stateContainer.merge(() => ({
				showAddFile: false,
			}));
		}
		else {
			await notification.errorMessage("Formulář obsahuje neúplné informace nebo chyby. Opravte prosím červeně vyznačené položky a poté akci opakujte.");
		}
	}

	openDialog = (type?: api.CodeBookItemWithNumberId, data?: FormFields, isMobile?: boolean, disabled?: boolean) => {
		this.form.clearFields();
		this.stateContainer.merge(() => (defaultState(isMobile)));
		if (type) {
			this.form.setField("type", type);
			this.form.setField("documents", []);
		}
		if (disabled) {
			this.stateContainer.merge(() => ({ disabled: disabled }));
		}
		if (data) {
			data.date = typeof data.date == "string" ? formatting.formatDateForInputDateTime(new Date(data.date)) : formatting.formatDateForInputDateTime(data.date) as any;
			this.form.setFields(data);
		}
		return this.dialog.open();
	}

	save = async () => {
		await this.form.validate();
		if (this.form.isValid()) {
			const name = this.form.getField("name").value;
			const id = this.form.getField("id").value;
			const description = this.form.getField("description").value;
			const type = this.form.getField("type").value;
			const date = this.form.getField("date").value;
			const idState = this.form.getField("id_state").value;
			const documents = this.form.getField("documents").value;
			const files = [] as any;
			documents.map(i => files.push({ id_dms_file: i.id_dms_file }));

			const result = await this.context.api.put("/treatments/" + id, {
				id: id,
				name: name,
				description: description,
				type: type?.id,
				documents: files,
				date: date,
				id_state: idState,
			})

			if (result) {
				this.dialog.close();
				this.context.lastRecords.loadData();
				this.context.calendarPage.loadData();
			}
		}
		else {
			await notification.errorMessage("Formulář obsahuje neúplné informace nebo chyby. Opravte prosím červeně vyznačené položky a poté akci opakujte.");
		}
	}

	/**
	 * Provede reset formuláře
		 */
	reset = async () => {
		await Promise.all([
			this.form.clearFields(),
		]);
	}
}