/**
 * Zobrazení bloků pro medical records
 */
import CheckBoxRadioFrame from "../../../../components/controls/CheckBoxRadioFrame";
import * as React from "react";
import * as state from "../../../../lib/state";

import Dialog from "../../../shared/dialogs/Dialog";
import Checkbox from "../../../../components/controls/Checkbox";
import Button from "../../../../components/controls/Button";

function MedicalRecordShowDataDialog() {
	const { medicalRecordShowDataDialog, medicalRecords } = state.useStateContext();
	const dialog = medicalRecordShowDataDialog.dialog;
	const types = medicalRecordShowDataDialog.dialog.getCustomField("types");
	const titles = [
		{ id: 1, title: "Základní údaje" },
		{ id: 2, title: "Počet měření za poslední měsíc" },
		{ id: 3, title: "Poslední měření" },
		{ id: 4, title: "Zobrazení dat" },
		{ id: 5, title: "Bolesti" },
		{ id: 6, title: "Subjektivní hodnocení pacienta" },
		{ id: 7, title: "Subjektivní hodnocení pacienta 2" },
		{ id: 8, title: "Objektivní hodnocení pacienta" },
		{ id: 9, title: "Závěr" },
		{ id: 10, title: "Dopis od lékaře" },
	];

	async function handleChecked(id: number) {
		let types = medicalRecordShowDataDialog.dialog.getCustomField("types");
		if (types.includes(id)) {
			types = types.filter(x => x != id);
		}
		else {
			types.push(id);
		}
		await medicalRecordShowDataDialog.dialog.setCustomField("types", types);
	}

	return (
		<Dialog className="simple-add-dialog" dialog={dialog}>
			<div>
				<h2 className="pb-3 dialog-title"><img className="d-inline mr-2" src="/images/folders-1.svg" alt="folders-1" />Nastavit zobrazení</h2>
				<hr className="dialog-title-hr" />
				<CheckBoxRadioFrame className="mb-5">
					{titles.map((i, index) => {
						const title = titles.find(x => x.id == i.id)?.title;
						return <div key={index} className="row">
							<div className="col-lg-12">
								<Checkbox className="mt-0" title={title} onCheckedChanged={() => handleChecked(i.id)} checked={types.includes(i.id)} />
							</div>
						</div>
					}
					)}
				</CheckBoxRadioFrame>
				<div className="mt-4 text-center">
					<Button onClick={e => medicalRecordShowDataDialog.save()} className="pt-2 pb-2 float-right">
						<strong>Uložit</strong>
					</Button>
				</div>
			</div>
		</Dialog >
	);
}

export default state.bindContainers(
	MedicalRecordShowDataDialog,
	context => context.medicalRecordShowDataDialog
);