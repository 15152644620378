import * as dialogs from "../../shared/dialogs/dialogs";
import * as context from "../../../context";
import * as forms from "../../../lib/forms";
import * as notification from "../../../lib/notification";
import * as validations from "../../../lib/validations";

export interface FormFields {
	email: string;
}

export class Model {
	public dialog: dialogs.Dialog<undefined, FormFields>;
	public form: forms.Form<FormFields>;

	constructor(private context: context.StateContext) {
		this.dialog = new dialogs.Dialog<undefined, FormFields>({
			defaultCustomFields: {
				email: ""
			}
		}, context);
		this.form = new forms.Form<FormFields>({
			fields: {
				email: {
					title: "Email",
					defaultValue: "",
					required: true,
					validate: (value) => value.length == 0 || !validations.isEmailAddress(value) ? "Email je ve špatném formátu" : ""
				},
			}
		}, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			...this.form.getStateContainers(),
			...this.dialog.getStateContainers()
		];
	}

	openDialog = async () => {
		this.form.clearFields();
		return this.dialog.open();
	}

	save = async () => {
		await this.form.validate();
		if (this.form.isValid()) {
			const result = await this.context.apiAuth.passwordRestoreRequest(this.form.getField("email").value);
			if (result) {
				notification.successMessage("Odkaz na reset hesla byl zaslán na Vaši emailovu adresu.")
			}
			return this.dialog.close();
		}
		else {
			await notification.errorMessage("Formulář obsahuje neúplné informace nebo chyby. Opravte prosím červeně vyznačené položky a poté akci opakujte.");
		}
	}
}