import moment from "moment";
import "moment/locale/cs";

/**
 * Validační funkce
 */

/**
 * Kontrola, zda řetězec je platnou e-mailovou adresou.
 */
export function isEmailAddress(str: string) {
	return /^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+$/.test(str);

}

/**
 * Kontrola, zda e-mail obsahuje více e-mailových adres (alespoň dva zavináče)
 */
export function isMultipleEmailAddress(str: string) {
	return str.length - str.replace(/@/g, "").length >= 2;
}

/**
 * Validace e-mailové adresy
 */
export function validateEmailAddress(value: string, required: boolean = false) {
	if (required && value.trim().length === 0) {
		return "E-mail je povinný";
	}

	if (value.trim().length === 0) {
		return "";
	}

	if (isMultipleEmailAddress(value)) {
		return "E-mail obsahuje více, než jednu adresu. Vyberte prosím jednu platnou.";
	}
	if (!isEmailAddress(value)) {
		return "Chybně zadaný e-mail";
	}
	return "";
}

/**
 * Kontrola, zda řetězec odpovídá telefonnímu číslu. Telefonní číslo se musí skládat ze sekvence minimálně 
 * devíti znaků, kterými smějí být číslice 0–9, kulaté závorky, lomítko, tečka, pomlčka a mezera; této sekvenci může (ale nemusí) předcházet znak +
 */
export function isPhoneNumber(str: string) {
	return /^[+]?[()/0-9. -]{9,}$/.test(str);

}

/**
 * Validace telefonního čísla
 */
export function validatePhoneNumber(value: string, required: boolean = false) {
	if (required && value.trim().length === 0) {
		return "Telefonní číslo je povinné";
	}

	if (value.trim().length > 0 && !isPhoneNumber(value)) {
		return "Neplatný formát telefonního čísla";
	}

	return "";
}

export function isValidPassword(value: string) {
	if (value.trim().length == 0)
		return "Vyplňte prosím heslo";

	if (value.length < 8)
		return "Heslo musí obsahovat minimálně 8 znaků, jedno velké písmeno a jednu číslici";

	if (!/\d/.test(value))
		return "Heslo musí obsahovat alespoň jednu číslici 0-9";

	if (!/[A-Z]/.test(value))
		return "Heslo musí obsahovat alespoň jedno VELKÉ písmeno";

	return "";
}

export function isValidDate(value: string) {
	const normalizedValue = value.replace(/ /g, "");
	return moment(normalizedValue, "D.M.YYYY", true).isValid() || moment(normalizedValue, moment.ISO_8601).isValid();
}

/**
 * Validace data
 */
export function validateDate(value: string, required: boolean = false) {
	if (required && value.trim().length === 0) {
		return "Zadejte prosím datum";
	}

	if (value.trim().length > 0 && !isValidDate(value)) {
		return "Neplatný formát dat. Datum prosím zadávejte ve formátu \"20.6.2021\"";
	}

	return "";
}

export function isValidICO(ico: string) {

	// strip all white spaces
	ico = ico.replace(/s+/, "");

	// test for valid shape
	if (!/^\d{8}$/.test(ico)) {
		return false;
	}

	// test for checksum
	let a = 0;
	for (let i = 0; i < 7; i++) {
		a += parseInt(ico[i]) * (8 - i);
	}
	a = a % 11;
	let c = (a === 0 ? 1 : a === 1 ? 0 : 11 - a);
	return parseInt(ico[7]) === c;
}

/**
 * Validace IČA
 */
export function validateICO(value: string, required: boolean = false) {
	if (required && value.trim().length === 0) {
		return "IČO je povinné";
	}

	if (value.trim().length > 0 && !isValidICO(value)) {
		return "Neplatný formát IČa";
	}

	return "";
}

/**
 * Kontrola předčíslí a čísla účtu. Převzato od Pevíka.
 */
function isBankAccountNumberInternal(predcisli: string, cisloUctu: string) {
	if (predcisli == undefined || cisloUctu == undefined)
		return false;
	if (predcisli != null && predcisli.trim().length > 6)
		return false;
	if (cisloUctu == null || cisloUctu.trim().length < 2 || cisloUctu.trim().length > 10)
		return false;
	var pred = ("000000" + predcisli.trim()).slice(-6);
	var ucet = ("0000000000" + cisloUctu.trim()).slice(-10);
	if (pred.match(/^\d{6}$/g) == null || ucet.match(/^\d{10}$/g) == null)
		return false;
	var p = (10 * Number(pred.charAt(0)) +
		5 * Number(pred.charAt(1)) +
		8 * Number(pred.charAt(2)) +
		4 * Number(pred.charAt(3)) +
		2 * Number(pred.charAt(4)) +
		1 * Number(pred.charAt(5))) % 11;
	var u = (6 * Number(ucet.charAt(0)) +
		3 * Number(ucet.charAt(1)) +
		7 * Number(ucet.charAt(2)) +
		9 * Number(ucet.charAt(3)) +
		10 * Number(ucet.charAt(4)) +
		5 * Number(ucet.charAt(5)) +
		8 * Number(ucet.charAt(6)) +
		4 * Number(ucet.charAt(7)) +
		2 * Number(ucet.charAt(8)) +
		1 * Number(ucet.charAt(9))) % 11;
	return (p == 0 && u == 0);
}

/**
 * Kontrola čísla účtu. Převzato od Pevíka.
 */
export function isBankAccountNumber(bankAccountNumber: string) {
	if (bankAccountNumber == undefined || bankAccountNumber == null)
		return false;
	var pred = "";
	var ucet = "";

	var x: any = bankAccountNumber.trim().split("-");
	if (x.length == 1) {
		x = bankAccountNumber.trim();
		if (x.length > 10) {
			pred = x.substring(0, 6);
			ucet = x.substring(6);
		} else {
			pred = "000000";
			ucet = x;
		}
	} else {
		if (x.length == 2) {
			pred = x[0].trim();
			ucet = x[1].trim();
		} else {
			return false;
		}
	}

	return isBankAccountNumberInternal(pred, ucet);
}