/**
 * Modul domovské stránky
 */

import * as state from "../../../../../lib/state";
import * as apiLib from "../../../../../lib/api";
import * as apiHome from "../../api-home";
import * as context from "../../../../../context";
import * as forms from "../../../../../lib/forms";

/**
 * Stav modulu
 */
interface State {
}

export enum Type {
	Temperature = 1,
	Pressure = 2,
	HeartRate = 3,
	Oximetry = 4,
	BreathRate = 6,
	EKG = 5,
	Weight = 7,
	Body = 8
}

export interface FormFields {
}

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	private stateContainer: state.StateContainer<State>;
	public form: forms.Form<FormFields>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({}, context);
		this.form = new forms.Form<FormFields>({
			fields: {
			}
		}, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			this.stateContainer,
			...this.form.getStateContainers()
		];
	}

	loadData = async () => {
	}

	getCodes = () => {
		return [{ type: 1, code: "TMP" },
		{ type: 3, code: "HRT" },
		{ type: 2, code: "POS" },
		{ type: 4, code: "OXY" },
		{ type: 5, code: "ECG" },
		{ type: 6, code: "BRT" },
		{ type: 7, code: "HRV" },
		{ type: 8, code: "SYS" },
		{ type: 9, code: "DIA" },
		{ type: 10, code: "GLY" },
		{ type: 11, code: "WEI" },
		{ type: 12, code: "BMI" },
		{ type: 13, code: "BMR" },
		{ type: 14, code: "BOF" },
		{ type: 15, code: "EEX" },
		{ type: 16, code: "SPD" },
		{ type: 17, code: "DIS" },
		{ type: 18, code: "PAC" },
		{ type: 19, code: "BAT" },
		{ type: 20, code: "STP" },
		{ type: 21, code: "INR" },
		{ type: 22, code: "FEV1" },
		{ type: 23, code: "FEV6" },
		{ type: 24, code: "PEF" },
		{ type: 25, code: "FEF2575" },
		{ type: 26, code: "F1F6" },
		{ type: 27, code: "CREA" },
		{ type: 28, code: "AUS" },
		{ type: 29, code: "KET" },
		{ type: 30, code: "LCT" },
		{ type: 31, code: "UAC" },
		{ type: 32, code: "CHOL" },
		{ type: 33, code: "TGL" },
		{ type: 34, code: "ECGL1" },
		{ type: 35, code: "ECGL2" },
		{ type: 36, code: "ECGL3" },
		{ type: 37, code: "ECGL5" },
		{ type: 38, code: "FILE" }]
	}

	getData = (measures: apiHome.Widget[]) => {
		const defaultData = [
			{
				name: "Teplota",
				value: "-",
				src: "/images/thermometer-half.svg",
				type: 1,
			},
			{
				name: "Posture",
				value: "-",
				src: "/images/droplet-fill.svg",
				type: 2,
			},
			{
				name: "Tep. frekv.",
				value: "-",
				src: "/images/heart.svg",
				type: 3,
			},
			{
				name: "Oxymetrie",
				value: "-",
				src: "/images/windy-strong.svg",
				type: 4,
			},
			{
				name: "Váha",
				icon: "Weight",
				value: "-",
				type: 11,
			},
		];
		let data = measures.sort(function (a, b) {
			return a.type - b.type;
		}).filter(x => x.type != 5 && x.type != 8 && x.type != 9 && x.type != 26 && x.type != 25 && x.type != 24 && x.type != 23 && x.type != 22 && x.value != "0").map(x => ({
			...x,
			name: defaultData.map(z => z.type).includes(x.type) ? defaultData.find(y => y.type == x.type)?.name : this.getCodes().find(i => i.type == x.type)?.code,
			src: defaultData.map(z => z.type).includes(x.type) ? defaultData.find(y => y.type == x.type)?.src : x.src,
			icon: defaultData.map(z => z.type).includes(x.type) ? defaultData.find(y => y.type == x.type)?.icon : x.icon,
		})) as apiHome.Widget[];

		data = data.map((i) => ({
			...i,
			value: i.type == 1 && i.value != "-" ? i.value + "°C" :
				i.type == 3 && i.value != "-" ? i.value + " bpm" :
					i.type == 4 && i.value != "-" ? i.value + "%" :
						i.type == 6 && i.value != "-" ? i.value + " bpm" :
							i.value,
		})) as any;

		if (measures.map(x => x.type).includes(8) && measures.map(x => x.type).includes(9)) {
			data.push({
				id: apiLib.emptyGuid,
				name: "Tlak",
				value: measures.find(x => x.type == 8)?.value + "/" + measures.find(x => x.type == 9)?.value,
				value2: measures.find(x => x.type == 8)?.value2 ? measures.find(x => x.type == 8)?.value2! : "",
				type: 8,
			})
		}
		if (measures.map(x => x.type).includes(5)) {
			data.push({
				id: apiLib.emptyGuid,
				name: "EKG",
				value: "Zobrazit graf",
				value2: "",
				src: "/images/Path 1164.svg",
				type: 5,
			});
		}
		if (measures.map(x => x.type).includes(22) ||
			measures.map(x => x.type).includes(23) ||
			measures.map(x => x.type).includes(24) ||
			measures.map(x => x.type).includes(25) ||
			measures.map(x => x.type).includes(26)) {
			data.push({
				id: apiLib.emptyGuid,
				name: "Spirometrie",
				value: "Zobrazit graf",
				src: "/images/Path 1164.svg",
				type: 22,
				value2: ""
			});
		}
		return data;
	}

	groupBy = (items: any, key: string) => items.reduce(
		(result: any, item: any) => ({
			...result,
			[item[key]]: [
				...(result[item[key]] || []),
				item,
			],
		}),
		{},
	);

	sortBy = (items: apiHome.Widget[]) => {
		const result = [] as any[];
		for (var key in items) {
			var x = items[key] as any;
			const item = x.sort(function (a: any, b: any) {
				// Turn your strings into dates, and then subtract them
				// to get a value that is either negative, positive, or zero.
				return b.data - a.data;
			});
			result.push(item[0]);
		};
		return result;
	}

	/**
	 * Provede reset formuláře
	 */
	reset = async () => {
		await Promise.all([
			this.form.clearFields(),
		]);
	}
}