/**
 * Seznam emailů
 */
import React from "react";
import * as state from "../../../../lib/state";
import * as api from "../../../../lib/api";
import * as navigation from "../../../../lib/navigation";
import * as formatting from "../../../../lib/formatting";
import Page from "../../../../components/templates/Page";
import List from "../../../../modules/system/lists/List";
import Link from "../../../../components/controls/Link";
import Checkbox from "../../../../components/controls/Checkbox";
import MyRequestAction from "../../../../components/controls/MyRequestAction";
import Button from "../../../../components/controls/Button";

function EmailUsersListPage() {
	const { emailUsersList } = state.useStateContext();
	const { authorization } = state.useStateContext();
	const { api } = state.useStateContext();
	const { emailDialog } = state.useStateContext();
	const { standardDialogs } = state.useStateContext();

	function handleRemove(id: string) {
		api.del("/emails" + id);
	}

	function actions(item: any) {
		const items = [];
		items.push({
			title: "Otevřít email",
			action: async (item: any) => {
				navigation.to("/administrace/emaily/" + item.id);
			},
		});
		items.push({
			title: "Odpovědět",
			action: async (item: any) => {
				handleEmail({ id: item.id_user, name: item.mail_from })
			},
		});
		if (item.readed) {
			items.push({
				title: "Zrušit přečtení",
				action: async (item: any) => {
					const result = await api.post("/emails/readed/" + item.id, {});
					if (result) {
						emailUsersList.list.load();
					}
				},
			})
		};
		items.push({
			title: "Smazat email",
			action: async (item: any) => {
				await standardDialogs.openYesNoDialog("Opravdu chcete smazat email?") === "yes"
					? handleRemove(item.id)
					: undefined
			},
		});
		return items as any;
	}

	function handleFilter() {
		emailUsersList.setIsReaded(false);
		emailUsersList.list.clearFilter();
	}

	async function handleEmail(item?: api.CodeBookItem) {
		emailDialog.openDialog(item);
	}

	return (
		<Page title="Seznam emailů" description="">
			<div className="page-content-container users-list-page">
				<div className="text-light ml-3 mb-4">
					<div className="homepage-responsive-title d-inline">
						Seznam emailů
					</div>
				</div>
				<div className="ml-3 mr-3">
					<Button className="d-inline-block mr-2 font-weight-bold" onClick={() => handleEmail()}>
						Odeslat email
					</Button>
					<div className="mt-3 mb-3">
						<div className="form-filter u-mb-md">
							<div id="records" className="form-filter__inner row">
								<div className="col-md-5 col-xs-12 mb-3">
									<label htmlFor="filter-search" className="inp-label u-vhide">
										Zadejte název
									</label>
									<span className="inp-fix">
										<input
											type="text"
											id="inp-filter-search"
											placeholder={emailUsersList.list.options.quickFilterPlaceholder}
											className="inp-text inp-text--filter"
											value={emailUsersList.list.getFilterFulltextPhrase()}
											onChange={(e) => emailUsersList.list.setFilterFulltextPhrase(e.target.value)}
										/>
									</span>
								</div>
								<div className="col-md-3 col-xs-12">
									<Checkbox
										className="mt-0  inp-text--filter"
										checked={emailUsersList.getIsReaded()} title={"Nepřečtené"}
										onCheckedChanged={(value) => emailUsersList.setIsReaded(value)}
									/>
								</div>
								<div className="col-xs-4 col-12 col-md-auto u-mb-md ml-md-auto">
									<div className="d-inline mr-2">
										<button type="submit" className="btn btn--filter" onClick={emailUsersList.list.loadWithResetPage}>
											<span className="btn__text">
												Vyhledat
											</span>
										</button>
									</div>
									<div className="d-inline">
										<button type="submit" className="btn btn--filter" onClick={handleFilter}>
											<span className="btn__text">
												Reset
											</span>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<List
						itemNode={(item, field) => {
							switch (field) {
								case "mail_from": return <Link to={"/administrace/uzivatel/emaily/" + item.id}>{item[field]}</Link>;
								case "date_of_create": return formatting.formatDateTime(item.date_of_create);
								case "action" as any: return <MyRequestAction className="ml-2 user-list-page-action-sync" id="emails" item={item} actions={actions(item)} />;
								default: return undefined;
							}
						}}
						simpleTable
						list={emailUsersList.list} />
				</div>
			</div>
		</Page >
	);
}

export default state.bindContainers(
	EmailUsersListPage,
	context => context.emailUsersList
);