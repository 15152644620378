/**
 * Seznam uživatelů
 */
import React from "react";
import * as state from "../../../../lib/state";
import * as api from "../../../../lib/api";
import * as navigation from "../../../../lib/navigation";
import Page from "../../../../components/templates/Page";
import List from "../../../../modules/system/lists/List";
import MyRequestAction from "../../../../components/controls/MyRequestAction";
import Button from "../../../../components/controls/Button";
import Link from "../../../../components/controls/Link";

function WaitingRoomListPage() {
	const { waitingRoomList } = state.useStateContext();
	const { authorization } = state.useStateContext();
	const { standardDialogs } = state.useStateContext();

	function actions(item: any) {
		const items = [
			{
				title: "Smazat záznam",
				action: async (item: any) => {
					await standardDialogs.openYesNoDialog("Opravdu chcete smazat uživatele?") === "yes"
						? waitingRoomList.remove(item.id)
						: undefined
				},
			},
		];
		return items as any;
	};

	return (
		<Page title="Seznam recepce" description="">
			{authorization.isAdminOrOperator() &&
				<div className="page-content-container users-list-page">
					<div className="text-light ml-3 mb-4">
						<div className="homepage-responsive-title d-inline">
							Seznam recepce
						</div>
					</div>
					<div className="ml-3 mr-3">
						<Button className="d-inline-block font-weight-bold mt-3 mb-3" onClick={() => navigation.to("/administrace/cekarna/" + api.emptyGuid)}>
							Přidat kategorii
						</Button>
						<List
							itemNode={(item, field) => {
								switch (field) {
									case "name": return <Link to={"/administrace/cekarna/" + item.id}>{item[field]}</Link>;
									case "action" as any: return <MyRequestAction className="ml-2 user-list-page-action-sync" id="waiting-room-list" item={item} actions={actions(item)} />;
									default: return undefined;
								}
							}}
							simpleTable list={waitingRoomList.list} />
					</div>
				</div>
			}
		</Page >
	);
}

export default state.bindContainers(
	WaitingRoomListPage,
	context => context.waitingRoomList
);