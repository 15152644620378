import * as dialogs from "../../../../shared/dialogs/dialogs";
import * as api from "../../../../../lib/api";
import * as context from "../../../../../context";
import * as forms from "../../../../../lib/forms";
import * as formatting from "../../../../../lib/formatting";

interface State {
}

interface FormFields {
	type?: api.CodeBookItemWithNumberId;
	from: string;
	to: string;
}

export class Model {
	public dialog: dialogs.Dialog<undefined, State>;
	public form: forms.Form<FormFields>;

	constructor(private context: context.StateContext) {
		this.form = new forms.Form<FormFields>({
			fields: {
				type: {
					title: "",
					defaultValue: undefined
				},
				from: {
					title: "",
					defaultValue: ""
				},
				to: {
					title: "",
					defaultValue: ""
				}
			}
		}, context);
		this.dialog = new dialogs.Dialog<undefined, State>({
			defaultCustomFields: {
			}
		}, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			...this.form.getStateContainers(),
			...this.dialog.getStateContainers()
		];
	}

	openDialog = async (value: any) => {
		this.form.setField("type", { id: value.type, name: value.name });
		this.form.setField("from", formatting.formatDate(new Date(new Date().getFullYear(), 11, 1)));
		this.form.setField("to", formatting.formatDate(new Date(new Date().getFullYear(), 11, 31, 23, 59, 59)));
		this.dialog.open();
	}

	save = async () => {
		return this.dialog.close();
	}

	/**
	 * Provede reset formuláře
		 */
	reset = async () => {
		await Promise.all([
			this.form.clearFields(),
		]);
	}
}