/**
 * Přidání pain monitoru
 */
import * as React from "react";
import * as forms from "../../../../../lib/forms";
import * as api from "../../../../../lib/api";
import * as formatting from "../../../../../lib/formatting";
import * as painMonDialog from "./pain-monitor-dialog";
import FormsSlider from "../../../../../components/controls/Forms/FormsSlider";
import { GeneralGraph } from "../../../../../modules/shared/graphs/GeneralGraph";
import FormsEditableDiv from "../../../../../components/controls/Forms/FormsEditableDiv";

interface Props {
	form: forms.Form<painMonDialog.FormFields>;
	type: painMonDialog.PainType;
	labels: string[];
	disabled?: boolean;
	isSimple: boolean;
}

export default function PainMonitorType(props: Props) {
	const form = props.form;
	const pain = form.getField("pain_intensity").value as any;

	return (

		<div className="pain-monitor-panel mr-4">
			<div className="mr-0 medical-records-panel-blue medical-records-panel-main text-center medical-records-panel-blue-text">
				<div>{painMonDialog.PainType.title(props.type)}</div>
			</div>
			<div className="medical-records-examination2-panel-inner">
				<div>
					<div className="margin-records-slider-margin mr-2 ml-2">
						<FormsSlider disabled={props.disabled} form={form} field="pain_intensity" maxValue={props.type == painMonDialog.PainType.Pain ? 10 : 5} />
					</div>
				</div>
				{props.isSimple &&
					<div style={{ fontSize: 14 }} className="mb-2 mt-3 ml-2 d-inline-block font-weight-bold">
						{pain &&
							<>
								{props.labels[props.type == painMonDialog.PainType.Pain ? pain < 4 ? 0 : (pain == 4 || pain == 5 || pain == 6) ? 1 : pain > 6 ? 2 : 0 : pain - 1]}
							</>
						}
					</div>
				}
				<div className="d-flex mt-3">
					<div>Trend</div>
				</div>
				<div>
					<div className="d-flex">
						<div style={{ width: "95%" }}>
							<GeneralGraph
								date_from={formatting.substractDaysFromDate(31, formatting.setTimeFromString(new Date(), 0, 0, 0))}
								date_to={formatting.setTimeFromString(new Date(), 23, 59, 59)}
								type={{ id: props.type, name: "" }}
								hideHeader={true}
								height="155px"
							/>
						</div>
					</div>

					<FormsEditableDiv
						field="description"
						disabled={props.disabled}
						form={form}
						className="medical-records-panel-blue medical-records-panel-main mb-4 medical-records-examination-panel-height"
					/>
					{props.type != painMonDialog.PainType.MentalHealth && !props.isSimple &&
						<div className="medical-records-list-item d-block p-2">
							{props.labels.map((i, index) =>
								<div key={index} className="d-flex mb-2 mt-2">
									<div style={{ width: 45 }} className="medical-records-examination-order">
										{props.type == painMonDialog.PainType.Pain ? index == 0 ? "1-3" : index == 1 ? "4-6" : index == 2 ? "7-10" : "" : index + 1}
									</div>
									<div style={{ width: "100%" }}>
										{i}
									</div>
								</div>
							)}
						</div>
					}

				</div>
			</div>
		</div>
	)
}