/**
 * Widget calendar
 */
import React from "react";
import * as state from "../../../../../lib/state";
import * as apiCalendar from "./api-calendar";
import * as formatting from "../../../../../lib/formatting";
import CalendarWeeklyViewItem from "./CalendarWeeklyViewItem";

export default function CalendarWeeklyView() {
	const { calendarPage: model } = state.useStateContext();
	const items = model.form.getField("data").value;
	const currentWeek = model.form.getField("currentWeek").value;

	function getDays() {
		const weeklyData = model.form.getField("data").value;
		const days = formatting.getWeekDays(currentWeek);
		const data = [] as apiCalendar.Calendar[];
		days.map((i, index) => {
			const items = [] as apiCalendar.CalendarItem[];
			weeklyData.map((x, xIndex) => {
				const daysWeek = i.toDate();
				const morningStart = new Date(daysWeek.getFullYear(), daysWeek.getMonth(), daysWeek.getDate(), 0, 0, 0);
				const morningEnd = new Date(daysWeek.getFullYear(), daysWeek.getMonth(), daysWeek.getDate(), 11, 59, 59);

				const noonStart = new Date(daysWeek.getFullYear(), daysWeek.getMonth(), daysWeek.getDate(), 12, 0, 0);
				const noonEnd = new Date(daysWeek.getFullYear(), daysWeek.getMonth(), daysWeek.getDate(), 17, 59, 59);

				const nightStart = new Date(daysWeek.getFullYear(), daysWeek.getMonth(), daysWeek.getDate(), 18, 0, 0);
				const nightEnd = new Date(daysWeek.getFullYear(), daysWeek.getMonth(), daysWeek.getDate(), 23, 59, 59);

				const week = x.date!;

				if (week >= morningStart && week <= morningEnd) {
					items.push({ ...x, date: week, type: apiCalendar.CalendarType.Morning });
				}
				else if (week >= noonStart && week <= noonEnd) {
					items.push({ ...x, date: week, type: apiCalendar.CalendarType.Noon });
				}
				else if (week >= nightStart && week <= nightEnd) {
					items.push({ ...x, date: week, type: apiCalendar.CalendarType.Night });
				}
			});
			data.push({ date: i, items: items });
		});
		return data;
	}

	function getWeekDate() {
		const days = formatting.getWeekDays(currentWeek);
		const first = formatting.formatDate(days[0].toDate());
		const last = formatting.formatDate(days[days.length - 1].toDate());
		return first + " - " + last;
	}

	function handlePreviousWeek() {
		model.form.setField("currentWeek", formatting.addDay(currentWeek, -7));
	}

	function handleNextWeek() {
		model.form.setField("currentWeek", formatting.addDay(currentWeek, 7));
	}

	return (
		<div>
			{model.form.getField("data").value &&
				<div className="calendar-weekly-view-calendar">
					<div className="react-calendar">
						<div className="calendar-weekly-view">
							<div className="react-calendar__navigation">
								<button onClick={handlePreviousWeek} aria-label="" className="react-calendar__navigation__arrow react-calendar__navigation__prev-button" type="button">‹</button>
								<button aria-label="" className="react-calendar__navigation__label" type="button" style={{ flexGrow: 1 }}>
									<span className="react-calendar__navigation__label__labelText react-calendar__navigation__label__labelText--from">
										{getWeekDate()}
									</span>
								</button>
								<button onClick={handleNextWeek} aria-label="" className="react-calendar__navigation__arrow react-calendar__navigation__next-button" type="button">›</button>
							</div>
							<div className="calendar-daily-view-view-container">
								<div className="calendar-weekly-view-table">
									<div className="row calendar-weekly-view-header ml-0 mr-0">
										<div className="calendar-weekly-column col-2"></div>
										<div className="calendar-weekly-column col">00:00-12:00</div>
										<div className="calendar-weekly-column col">12:00-18:00</div>
										<div className="calendar-weekly-column col">18:00-23:59</div>
									</div>
									{getDays().map((i, index) =>
										<div key={index} className="row ml-0 mr-0">
											<div className={"calendar-weekly-column col-2 calendar-weekly-day-name text-center " + (index == getDays().length - 1 ? "calendar-weekly-column-last-left" : "")} key={index}>
												{i.date.format("ddd")}
											</div>
											<div className="calendar-weekly-column col">
												{i.items.map(x => x.type).includes(apiCalendar.CalendarType.Morning) &&
													<CalendarWeeklyViewItem items={i.items.filter(x => x.type == apiCalendar.CalendarType.Morning)} />
												}
											</div>
											<div className="calendar-weekly-column col">
												{i.items.map(x => x.type).includes(apiCalendar.CalendarType.Noon) &&
													<CalendarWeeklyViewItem items={i.items.filter(x => x.type == apiCalendar.CalendarType.Noon)} />
												}
											</div>
											<div className={"calendar-weekly-column col " + (index == getDays().length - 1 ? "calendar-weekly-column-last-right" : "")}>
												{i.items.map(x => x.type).includes(apiCalendar.CalendarType.Night) &&
													<CalendarWeeklyViewItem items={i.items.filter(x => x.type == apiCalendar.CalendarType.Night)} />
												}
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			}
		</div>
	);
}
