/**
 * Detail kategorie
 */
import React from "react";
import * as state from "../../../../lib/state";
import * as navigation from "../../../../lib/navigation";
import Page from "../../../../components/templates/Page";
import FormsInput from "../../../../components/controls/Forms/FormsInput";
import FormsColorPicker from "../../../../components/controls/Forms/FormsColorPicker";
import Button from "../../../../components/controls/Button";
import Checkbox from "../../../../components/controls/Checkbox";

interface Props {
	id: string;
}

function CategoriesDetailPage(props: Props) {
	const { authorization } = state.useStateContext();
	const { categoriesDetail } = state.useStateContext();
	const form = categoriesDetail.form;

	function handleSave() {
		categoriesDetail.save();
	}

	return (
		<Page title="Kategorie - detail" description="">
			{authorization.isAdmin() &&
				<div className="page-content-container">
					<div className="ml-3 mb-3 text-dark">
						<div className="homepage-responsive-title d-inline">
							Kategorie - detail
						</div>
					</div>
					<div className="content-container m-3">
						<div className="row">
							<div className="col-6">
								<FormsInput
									form={form}
									field="name"
								/>
							</div>
							<div className="col-6">
								<FormsColorPicker
									form={form}
									field="color"
								/>
							</div>
						</div>
						<div className="row">
							<Checkbox
								className="mt-0  inp-text--filter"
								checked={form.getField("hidden").value}
								title="Nezobrazovat"
								onCheckedChanged={(e) => form.setField("hidden", e)}
							/>
						</div>
						<div className="row p-2 mt-3">
							<div className="col">
								{
									<div className="d-inline float-left">
										<Button className="font-weight-bold" onClick={handleSave}>
											Uložit
										</Button>
									</div>
								}
								<div className="d-inline float-right">
									<Button className="font-weight-bold" onClick={() => navigation.to("/administrace/uzivatelske-kategorie")}>
										Zpět
									</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
			}
		</Page >
	);
}

export default state.bindContainers(
	CategoriesDetailPage,
	context => context.categoriesDetail
);