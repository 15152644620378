import * as React from "react";
import * as api from "../../../lib/api";
import * as common from "../../../lib/common";
import * as forms from "../../../lib/forms";
import * as state from "../../../lib/state";
import * as painMonDialog from "../../../modules/pages/home/widgets/pain-monitor/pain-monitor-dialog";
import * as  lastRecords from "../../../modules/pages/home/widgets/last-records/last-records";

import FormGroupSwitch from "../Forms/FormGroupSwitch";
import Icon from "../../../modules/system/icons/Icon";
import * as formatting from "../../../lib/formatting";

export interface Props<TFormFields> {
	title: string;
	indexSwitch?: keyof common.SubType<TFormFields, boolean | undefined>;
	index?: keyof common.SubType<TFormFields, api.CodeBookItemWithDate[] | api.CodeBookItem[] | undefined>;
	form: forms.Form<TFormFields>;
	isEdit: boolean;
	isDoctor?: boolean;
	checkbox?: React.ReactNode;
}


export default function WidgetListPanel<TFormFields>(props: Props<TFormFields>) {
	const { addLastMeasurementDialog } = state.useStateContext();
	const { simpleAddDialog } = state.useStateContext();
	const { api } = state.useStateContext();
	const { expandedViewWidget } = state.useStateContext();
	const { optionalViewWidget } = state.useStateContext();
	const { pCardWidget } = state.useStateContext();
	const { doctorDialog } = state.useStateContext();
	const { pilulkoidDialog } = state.useStateContext();
	const { standardDialogs } = state.useStateContext();
	const items = props.index == undefined ? [] : props.form.getField(props.index as keyof TFormFields).value as any as api.CodeBookItemWithDate[];
	const showVaccination = props.form.getField("showVaccination" as keyof TFormFields).value;
	const showDoctor = props.form.getField("showDoctors" as keyof TFormFields).value;

	async function removeItem(id: string) {
		const result = await api.del("/" + (props.index == "vaccination" || props.index == "allergies" || props.index == "operations" ? "treatments" : props.index == "doctors" ? "user_doctors" : props.index?.toString()) + "/" + id);
		if (result) {
			optionalViewWidget.loadData();
			expandedViewWidget.loadData();
			pCardWidget.loadData();
		}
	}

	async function remove(id: string) {
		await standardDialogs.openYesNoDialog("Opravdu chcete smazat záznam?") === "yes"
			? removeItem(id)
			: undefined
	}

	async function handleAdd() {
		if (props.index == "vaccination" || props.index == "allergies" || props.index == "operations") {
			await addLastMeasurementDialog.openDialog(props.index == "allergies" ? { id: 4, name: "Alergie" } : props.index == "vaccination" ? { id: 5, name: "Očkování" } : { id: 3, name: "Záznam" }) as any;
		}
		if (props.index == "pilulkoids") {
			await pilulkoidDialog.openDialog(undefined, undefined) as any;
		}
		if (props.index == "ongoing_treatments") {
			await simpleAddDialog.openDialog("ongoing_treatments", undefined, true, true, true, undefined, undefined, "Přidat léčbu");
		}
		if (props.index == "doctors") {
			await doctorDialog.openDialog({ type: 1 } as any);
		}
		optionalViewWidget.loadData();
		expandedViewWidget.loadData();
		pCardWidget.loadData();
	}

	async function handleOpen(i: any) {
		if (props.index == "vaccination" || props.index == "allergies" || props.index == "operations") {
			const item = i;
			item.type = i.type.id ? i.type : { id: i.type, name: lastRecords.MyRecordType.title(i.type) };
			await addLastMeasurementDialog.openDialog(undefined, item);
		}
		if (props.index == "pilulkoids") {
			await pilulkoidDialog.openDialog(i, true);
		}
		if (props.index == "ongoing_treatments") {
			await simpleAddDialog.openDialog("ongoing_treatments", undefined, true, true, true, undefined, i, "Probíhající onemocnění");
		}
		if (props.index == "doctors") {
			await doctorDialog.openDialog(i);
		}
		optionalViewWidget.loadData();
		expandedViewWidget.loadData();
		pCardWidget.loadData();
	}

	return (
		<div className="mt-2 widget-list-panel">
			<div>
				<div className="mb-3">
					<div className="cursor-pointer d-inline" onClick={() => props.isDoctor ? props.form.setField("showDoctors" as keyof TFormFields, !showDoctor as any) : props.form.setField("showVaccination" as keyof TFormFields, !showVaccination as any)}>
						<h3 className="widget-list-panel-title d-inline">
							{props.title}
						</h3>
						{props.isDoctor != undefined && !props.isEdit && ((!showVaccination && !props.isDoctor) || (!showDoctor && props.isDoctor)) &&
							<div className="d-inline color-primary float-right mr-2">
								<Icon picture="ArrowUp" />
							</div>
						}
						{props.isDoctor != undefined && !props.isEdit && ((showVaccination && !props.isDoctor) || (showDoctor && props.isDoctor)) &&
							<div className="d-inline color-primary float-right mr-2">
								<Icon picture="ArrowDown" />
							</div>
						}
					</div>
					{!props.isEdit && props.indexSwitch &&
						<div className="d-inline-block float-right">
							<FormGroupSwitch
								form={props.form}
								field={props.indexSwitch!}
							/>
						</div>
					}
					{props.isEdit &&
						<div onClick={handleAdd} className="d-inline-block widget-list-panel-add">
							Přidat <Icon picture="Add" />
						</div>
					}
				</div>
				{props.checkbox &&
					<>
						{props.checkbox}
					</>
				}
				{((showVaccination && !props.isDoctor) || (showDoctor && props.isDoctor) || props.isDoctor == undefined || props.isEdit) &&
					<div className="widget-list-panel-item">
						<div className="widget-list-panel-item-container">
							{items.map((i, index) =>
								<div key={index}>
									<div>
										<div onClick={() => handleOpen(i)} className="d-inline">
											{i.name}
										</div>

										{props.isEdit &&
											<div onClick={() => remove(i.id)} className="d-inline widget-list-panel-remove mr-2"><Icon picture="Remove" /></div>
										}

										{i.date != undefined &&
											<div className="mr-3 widget-list-panel-date">
												{formatting.formatDate(i.date as any)}
											</div>
										}

									</div>
								</div>
							)}
						</div>
					</div>
				}
			</div>
		</div>
	);
}