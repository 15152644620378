/*
 * Titulek filtru.
 */

import * as React from "react";
import { Button, ButtonProps } from "reactstrap";
import * as common from "../../lib/common";
import Spinner from "./Spinner";

interface State {
	runnningAction: boolean;
	indicateAction: boolean;
}

type ButtonAdvancedProps = ButtonProps & {
	onClick?: (event: React.MouseEvent<any, MouseEvent>) => Promise<any>;
	theme?: "primary" | "secondary";
	spinner?: boolean;
};

export default class ButtonAdvanced extends React.Component<ButtonAdvancedProps, State> {

	constructor(props: ButtonAdvancedProps) {
		super(props);
		this.state = { indicateAction: false, runnningAction: false };
	}

	handleClick = async (event: React.MouseEvent<any, MouseEvent>) => {
		if (this.props.onClick) {
			await common.withIndication({
				exec: async () => { return this.props.onClick!(event); },
				indicateStart: async () => {
					return new Promise((resolve) => {
						this.setState(() => ({ indicateAction: true }), resolve);
					});
				},
				start: async () => {
					return new Promise((resolve) => {
						this.setState(() => ({ runnningAction: true }), resolve);
					});
				},
				finish: async () => {
					return new Promise((resolve) => {
						this.setState(() => ({ indicateAction: false, runnningAction: false }), resolve);
					});
				},
			});
		}
	}

	render = () => {
		const { theme, spinner, className, children, ...rest } = this.props;
		return (
			<button onClick={this.handleClick} disabled={this.props.disabled || this.state.runnningAction}
				className={"button button-" + (theme ?? "secondary") + " " + (className ?? "")}
				{...rest}
			>
				{this.state.indicateAction &&
					<Spinner className="spinner-border-sm mr-2" />
				}
				{children}
			</button >
		);
	}
}