import React, { useEffect, useState } from "react";
import { HashLink as Link } from "react-router-hash-link";

import * as common from "../../lib/common";
import * as state from "../../lib/state";

import FormsInput from "../controls/Forms/FormsInput";
import FormCheckBox from "../controls/Forms/FormCheckBox";
import Button from "../controls/Button";
import EnterPressArea from "../controls/EnterPressArea";
import Checkbox from "../controls/Checkbox";

function LoginForm() {
	const { login, authorization } = state.useStateContext();
	const { resetPasswordDialog } = state.useStateContext();
	const [isLoaded, setIsLoaded] = useState(false);

	useEffect(() => {
		common.ignorePromises(login.form.clearFields());
		setIsLoaded(true);
	}, []);

	function handleResetPassword() {
		resetPasswordDialog.openDialog();
	}

	return (
		<form autoComplete="off" className="login-form" >
			{isLoaded &&
				<div className="login-form-container">
					{login.getIsLogin() &&
						<>
							<EnterPressArea onEnter={login.doLogin}>
								<h3 className="text-center font-weight-bold mb-5 color-primary">Přihlášení</h3>
								<div className="form-group">
									<FormsInput form={login.form} field="username" inputProps={{ autoFocus: true }} />
								</div>
								<div className="form-group">
									<FormsInput form={login.form} field="password" inputProps={{ type: "password" }} />
								</div>
								<div className="form-group">
									<div className="row">
										<div className="col">
											<label className="login-part-checkbox-label">
												<Checkbox title={""} checked={true} onCheckedChanged={_ => { }} />
												<span className="ml-2 mt-1">
													Zapamatovat
												</span>
											</label>
										</div>
										<div style={{ alignSelf: "center" }} className="col text-right font-weight-bold d-inline">
											<a onClick={handleResetPassword} className="hyperlink-without-href cursor-pointer">Zapomenuté heslo?</a>
										</div>
									</div>
								</div>
								<div className="form-group">
									<Button type="button" theme="primary" onClick={login.doLogin} spinner={authorization.justLoggingIn()}>
										Přihlásit se
									</Button>
								</div>
							</EnterPressArea>
							<div onClick={() => login.setIsLogin(false)} className="login-part-checkbox-label mt-2">
								<span>Nemáte ještě účet?</span> <span className="font-weight-bold ml-1">Vytvořte si ho.</span>
							</div>
						</>
					}
					{!login.getIsLogin() &&
						<div>
							<EnterPressArea onEnter={login.doRegister}>
								<h3 className="text-center font-weight-bold mb-5 color-primary">Registrace</h3>
								<div className="row">
									<div className="col">
										<FormsInput form={login.form} field="register_username" inputProps={{ autoFocus: true, autoComplete: "new-password" }} />
									</div>
								</div>
								<div className="row">
									<div className="col">
										<FormsInput form={login.form} field="register_firstname" inputProps={{ type: "text", autoComplete: "new-password" }} />
									</div>
								</div>
								<div className="row">
									<div className="col">
										<FormsInput form={login.form} field="register_lastname" inputProps={{ type: "text", autoComplete: "new-password" }} />
									</div>
								</div>
								<div className="row">
									<div className="col">
										<FormsInput form={login.form} field="register_phone" inputProps={{ type: "text", autoComplete: "new-password" }} />
									</div>
								</div>
								<div className="row">
									<div className="col-7">
										<FormsInput form={login.form} field="register_personal_identification_number" inputProps={{ type: "number", autoComplete: "new-password" }} />
									</div>
									<div className="col-1 login-rc-slash">
										<div className="d-inline"> /</div>
									</div>
									<div className="col-4">
										<FormsInput form={login.form} field="register_personal_identification_number_sufix" inputProps={{ type: "number", autoComplete: "new-password" }} />
									</div>
								</div>
								<div className="row">
									<div className="col-sm-6">
										<FormsInput form={login.form} field="register_password" inputProps={{ type: "password", autoComplete: "new-password" }} />
									</div>
									<div className="col-sm-6">
										<FormsInput form={login.form} field="register_confirm_password" inputProps={{ type: "password", autoComplete: "new-password" }} />
									</div>
									<div className="col-sm-12">
										<div className="font-weight-bold login-part-checkbox-label">
											<span>
												Heslo musí mít nejméně 8 znaků.
											</span>
										</div>
										<div onClick={() => login.setIsLogin(true)} className="color-primary mr-2 font-weight-bold login-part-checkbox-label float-right">
											<span>
												Zpět
											</span>
										</div>
									</div>
								</div>
								<div className="form-group mt-3">
									<Button type="button" theme="primary" onClick={login.doRegister}>
										Registrovat
									</Button>
								</div>
							</EnterPressArea>
						</div>
					}
				</div>
			}
			<div className="login-drdigital">
				<img src="/images/Mask Group 37.png" />
			</div>
		</form >
	);
}

export default state.bindContainers(
	LoginForm,
	context => context.login.form
);