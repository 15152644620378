import React, { useState, useEffect } from "react";
import * as state from "../../lib/state";
import * as api from "../../lib/api";
import * as navigation from "../../lib/navigation";
import * as formatting from "../../lib/formatting";
import IconSvg from "../controls/IconSvg";
import Link from "../controls/Link";
import usePopup from "../../hooks/popup";
import useExclusiveSwitch from "../../hooks/exclusive-switch";
import { Popover, PopoverBody, PopoverHeader } from "reactstrap";
import storage from "store";
import HtmlContent from "../controls/HtmlContent";
import Icon from "../../modules/system/icons/Icon";

interface Notification {
	id: number;
	text: string;
	readed: boolean;
	date_of_readed: Date;
	sys_date_create: Date;
}

export default function PageHeader() {
	const { apiNotifications } = state.useStateContext();
	const [notificationOpen, setNotificationOpen] = useState(false);
	const [notifications, setNotifications] = useState([] as Notification[]);

	const { authorization } = state.useStateContext();
	const { emailDialog } = state.useStateContext();
	const { apiImages } = state.useStateContext();
	const exclusiveSwitch = useExclusiveSwitch();
	const popup = usePopup();

	async function loadNotifications() {
		const myProfile = authorization.getUserProfile();
		if (myProfile) {
			const result = await apiNotifications.loadUserNotifications(myProfile.id, {}) as any;
			if (result) {
				setNotifications(result.data);
			}
		}
	}

	useEffect(() => {
		window.addEventListener("click", function (e: any) {
			const target = e.target;
			if (target.className.includes && !target.className.includes("header-navigation-popover-text")) {
				setNotificationOpen(false);
			}
		});
		loadNotifications();
	}, []);

	async function markAsRead(item: Notification) {
		item.readed = true;
		item.date_of_readed = new Date();
		await apiNotifications.markAsRead(item);
		loadNotifications();
	}

	async function handleNotification() {
		if (notifications.length > 0) {
			setTimeout(function () {
				setNotificationOpen(!notificationOpen);
			}, 100);
		}
	}

	async function handleProfile() {
		navigation.to("/profil");
	}

	async function handleAdmin() {
		navigation.to("/administrace");
	}

	async function handleEntry() {
		navigation.to("/administrace/zdravotni-zaznam/" + api.emptyGuid);
	}

	async function disableRedirect(e: any) {
		e.preventDefault();
		//window.open("https://xd.adobe.com/view/52f4bf2a-2588-431e-b001-d079a3fd5070-07a7/screen/c046695a-ae7d-4355-8236-e92082e04800");
	}

	async function handleLogout() {
		authorization.logout();
	}

	async function handleEmail() {
		emailDialog.openDialog();
	}

	const image = authorization.getUserProfile()?.profile?.id_dms_file_image;

	return (
		<div className="page-header">
			<div className="page-header-title"><img className="mr-2" src="/images/__TEMP__SVG__.svg" alt="__TEMP__SVG__" />
				Virtuální klinika
				{/* <span>
					{current == "/" &&
						<> - Recepce</>
					}
					{current == "/cekarna" &&
						<> - Čekárna</>
					}
					{current == "/ordinace" &&
						<> - Ordinace</>
					}
					{current == "/administrace-p-card" &&
						<> - Adminsitrace</>
					}
				</span> */}
			</div>
			<div className="page-header-menu">

				<span onClick={handleProfile} id="account" className="cursor-pointer">
					<span style={{ verticalAlign: "middle" }} className="mr-3 max-width-1075">
						{authorization.getUserProfile()?.profile.full_name}
					</span>
					<span className="max-width-1075">
						<img style={{ height: 40 }} className="page-header-menu-img" width={40} height={40} alt="user" src={image == null || image == api.emptyGuid ? "/images/user.jpg" : apiImages.getImageUrl(authorization.getUserProfile()?.profile.id_dms_file_image!)} />
					</span>
				</span>

				<span className="mr-2"></span>

				<span id="notification" className="mr-3 cursor-pointer max-width-1075">
					<img src="/images/ic-bell.svg" alt="ic-bell" />
					{notifications.filter(x => !x.readed).length > 0 &&
						<span className="page-header-notification-count">{notifications.filter(x => !x.readed).length == 0 ? "" : notifications.filter(x => !x.readed).length}</span>
					}
				</span>
				<span onClick={handleEmail} className="color-primary mr-3 cursor-pointer max-width-1075">
					<Icon className="page-header-mail-icon" picture="Email" />
				</span>
				<Popover
					target="notification"
					placement="bottom"
					className="header-navigation-popover"
					fade={true}

					isOpen={notificationOpen}
					toggle={handleNotification}
				>
					<PopoverHeader className="header-navigation-popover-header">
						NOTIFIKACE
					</PopoverHeader>
					<PopoverBody className="header-navigation-popover-body-message">
						{notifications.length > 0 &&
							<>
								{notifications.slice(0, 10).map((i, index) =>
									<div key={i.id}
										className={"header-navigation-popover-text header-navigation-popover-notifikace" + (i.readed ? "  header-navigation-popover-notifikace-nova" : "") + (notifications.length - 1 == index ? " header-navigation-popover-notifikace-last" : "")}>

										{/* {!i.readed &&
											<span className="badge badge-success mr-1">Nová</span>
										} */}
										<div className="font-weight-bold header-navigation-popover-text">{formatting.formatDateTime(i.sys_date_create)}</div>
										<HtmlContent content={i.text} className="header-navigation-popover-text" />
										<div onClick={(e) => markAsRead(i)} className="text-danger cursor-pointer float-right mr-2 popover-delete-icon header-navigation-popover-text"><Icon picture="Remove" /></div>
									</div>
								)}
							</>
						}
						{notifications.length == 0 &&
							<div>
								<div className="header-navigation-popover-notifikace header-navigation-popover-notifikace-last pb-3">Žádné notifikace nejsou k dispozici.</div>
							</div>
						}
					</PopoverBody>
				</Popover>
				{authorization.isAdminOrOperator() &&
					<span onClick={handleAdmin} className="cursor-pointer max-width-1075 mr-3">
						<img src="/images/settings.svg" alt="settings" />
					</span>
				}
				{/* {storage.get("delegateIdUser") &&
					<span onClick={handleEntry} className="cursor-pointer max-width-1075 mr-3 color-primary">
						<img src="/images/qr-code.svg	" alt="settings" />
					</span>
				} */}
				<span onClick={handleLogout} className="cursor-pointer max-width-1075 page-header-logout">
					<Icon className="text-danger" picture="Logout" />
				</span>

				<div className="menu-main">
					<span className="menu-main__burger" onClick={popup.toggle}>
						<IconSvg name={"burger"} />
					</span>
					{popup.isOpen &&
						<div
							className="menu-main__list"
							onClick={(e) => { popup.close(e), exclusiveSwitch.closeAll(); }}
						>
							<div className="menu-main__item">
								<Link to={"/"} className={"menu-main__link"}>
									Ordinace
								</Link>
							</div>
							<div className="menu-main__item">
								<Link to={"/cekarna"} className={"menu-main__link"}>
									Čekárna
								</Link>
							</div>
							<div className="menu-main__item">
								<Link to={"/recepce"} className={"menu-main__link"}>
									Recepce
								</Link>
							</div>
							<div className="menu-main__item">
								<Link to={"/emaily"} className={"menu-main__link"}>
									Emaily
								</Link>
							</div>
							{authorization.isAdminOrOperator() &&
								<div className="menu-main__item">
									<Link to={"/administrace"} className={"menu-main__link"}>
										Administrace
									</Link>
								</div>
							}
							<div onClick={handleLogout} className="menu-main__item">
								<Link to={"/"} className={"menu-main__link"}>
									Odhlásit
								</Link>
							</div>
						</div>
					}
				</div>
			</div>
		</div>
	);
}