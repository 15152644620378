import * as dialogs from "./dialogs";
import * as context from "../../../context";
import * as forms from "../../../lib/forms";
import * as api from "../../../lib/api";
import * as state from "../../../lib/state";
import * as doctors from "../../pages/home/widgets/doctors/doctors";
import * as notification from "../../../lib/notification";

export interface FormFields {
	id: string;
	name: string;
	address: string;
	phone: string;
	email: string;
	last_visit: string;
	appointment_date: string;
	id_image: string;
	type?: api.CodeBookItemWithNumberId;
	manual_entry: boolean;
	id_doctor?: api.CodeBookItemWithNumberId;
	sys_date_create: Date;
}

export interface State {
	disabled: boolean;
}

export class Model {
	public dialog: dialogs.Dialog<undefined, State>;
	public stateContainer: state.StateContainer<State>;
	public form: forms.Form<FormFields>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({ disabled: false }, context);
		this.dialog = new dialogs.Dialog<undefined, State>({
			defaultCustomFields: {
				disabled: false
			}
		}, context);
		this.form = new forms.Form<FormFields>({
			fields: {
				id: {
					title: "",
					defaultValue: api.emptyGuid,
				},
				manual_entry: {
					defaultValue: false,
					title: ""
				},
				address: {
					title: "",
					defaultValue: "",
					readOnly: (form) => this.stateContainer.get().disabled
				},
				email: {
					title: "",
					defaultValue: "",
					readOnly: (form) => this.stateContainer.get().disabled
				},
				phone: {
					title: "",
					defaultValue: "",
					readOnly: (form) => this.stateContainer.get().disabled
				},
				last_visit: {
					title: "Poslední návštěva:",
					defaultValue: null as any,
					readOnly: (form) => this.stateContainer.get().disabled
				},
				appointment_date: {
					title: "Jste objednán/a na:",
					defaultValue: null as any,
					readOnly: (form) => this.stateContainer.get().disabled
				},
				type: {
					title: "Obor",
					defaultValue: { id: 1, name: doctors.DoctorType.title(1) },
					readOnly: (form) => this.stateContainer.get().disabled
				},
				id_image: {
					title: "",
					defaultValue: api.emptyGuid,
					readOnly: (form) => this.stateContainer.get().disabled
				},
				name: {
					title: (value) => this.form.getField("manual_entry").value ? "Manuální zadání lékaře" : "Lékař",
					defaultValue: "",
					readOnly: (form) => this.stateContainer.get().disabled,
					required: true,
					validate: (value, field, form) => !value && form.getField("manual_entry").value ? "Vyplňte hodnotu" : ""
				},
				id_doctor: {
					title: "Lékař",
					defaultValue: undefined,
					readOnly: (form) => this.stateContainer.get().disabled,
					required: true,
					validate: (value, field, form) => !value && !form.getField("manual_entry").value ? "Vyberte hodnotu z našeptávače" : "",
					onChange: (value) => {
						const item = value as any as FormFields;
						if (item) {
							this.form.setField("phone", item.phone);
							this.form.setField("email", item.email);
							this.form.setField("address", item.address);
						}
					}
				},
				sys_date_create: {
					defaultValue: new Date(),
					title: ""
				},
			}
		}, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			this.stateContainer,
			...this.form.getStateContainers(),
			...this.dialog.getStateContainers()
		];
	}

	openDialog = (doctor?: FormFields, disabled?: boolean) => {
		this.form.clearFields();
		this.stateContainer.merge(() => ({ disabled: false }));
		if (disabled) {
			this.stateContainer.merge(() => ({ disabled: disabled }));
		}
		if (doctor) {
			doctor.type = { id: doctor.type as any, name: doctors.DoctorType.title(doctor.type as any) };
			doctor.id_doctor = (doctor as any).doctor;
			if ((!doctor.id_doctor || doctor.id_doctor.id == api.emptyGuid as any) && doctor.id != undefined) {
				doctor.manual_entry = true;
			}
			this.form.setFields(doctor);
		}
		return this.dialog.open();
	}

	loadDoctors = async (prefix: string): Promise<api.CodeBookItemWithNumberId[]> => {
		const doctors = await this.context.apiDoctors.doctors.loadList({
			fulltext: prefix,
			filter: [
				api.qpOr([
					api.qp("id_state", "=", 1),
				])
			]
		});
		return doctors.data as any;
	}

	save = async () => {
		await this.form.validate();
		if (this.form.isValid()) {
			let fields = this.form.getFields();
			fields.type = fields.type?.id as any;
			fields.id_doctor = !fields.id_doctor ? undefined : fields.id_doctor.id as any;
			await this.context.api.put("/user_doctors/" + fields.id, fields);
			this.context.doctors.loadData();
			this.context.calendarPage.loadData();
			return this.dialog.close();
		}
		else {
			await notification.errorMessage("Formulář obsahuje neúplné informace nebo chyby. Opravte prosím červeně vyznačené položky a poté akci opakujte.");
		}
	}
}