/**
 * Calendar weekly item
 */
import React from "react";
import * as state from "../../../../../lib/state";
import * as formatting from "../../../../../lib/formatting";
import * as apiCalendar from "./api-calendar";

interface Props {
	items: apiCalendar.CalendarItem[];
}

export default function CalendarWeeklyViewItem(props: Props) {
	const { calendarDialog } = state.useStateContext();
	const { calendarListDialog } = state.useStateContext();

	function handleOpen() {
		const items = props.items;
		if (items.length > 1) {
			calendarListDialog.openDialog(items as any, calendarListDialog.dialog.getCustomField("currentDate")!);
		}
		else {
			const item = Object.assign({}, items[0]);
			item.date = formatting.formatDateForInputDateTime(item.date) as any;
			calendarDialog.openDialog(true, item as any);
		}
	}

	return (
		<div onClick={handleOpen} className="calendar-weekly-view-event">
			{props.items.length == 1 &&
				<>
					{props.items[0].description}
				</>
			}
			{props.items.length > 1 &&
				<>
					Více událostí
				</>
			}
		</div>
	);
}
