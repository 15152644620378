/**
 * Ovládací prvek pro editaci obrázku
 */

import React, { useRef, useState } from "react";

import * as api from "../../../lib/api";
import * as common from "../../../lib/common";
import * as state from "../../../lib/state";

import Icon from "../icons/Icon";


export interface ImageEditProps {
	id: string;
	onChange?: (id: string) => void;
}

interface State {
	hash: string;
	imageId: string;
}

export default function ImageEdit(props: ImageEditProps) {
	const inputRef = useRef(React.createRef());
	const imageRef = useRef(React.createRef());
	const [imageId, setImageId] = useState(props.id);
	const [hash, setHash] = useState(common.unique());
	const { apiImages } = state.useStateContext();

	async function handleDeleteIconClick() {
		setImageId(api.emptyGuid);
		if (props.onChange) {
			props.onChange(api.emptyGuid);
		}
	}

	function handleUploadClick() {
		(inputRef.current! as any).click();
	}

	async function handleImageUploaded(e: React.ChangeEvent<HTMLInputElement>) {
		const result = await apiImages.upload(e.target.files![0]);
		setImageId(result.id as any);

		if (props.onChange) {
			props.onChange(result.id as any);
		}
	}

	function getImageUrl(id: string) {
		return apiImages.getImageUrl(id);
	}

	return (
		<div className={"image-edit "}>
			{/* Skrytý input file */}
			<input
				className="d-none"
				type="file"
				width={320}
				accept="image/*"
				onChange={handleImageUploaded}
				ref={inputRef as any}>
			</input>

			{/* Prázdný frame */}
			{(imageId === null || imageId == api.emptyGuid) &&
				<>
					<div className="image-edit-empty-frame d-flex flex-column align-items-center justify-content-center">
						<Icon picture="Image" size="6x" className="ml-3" />
						<span className="image-edit-empty-frame-load-link text-center" onClick={handleUploadClick}>Nahrát ze souboru</span>
					</div>
				</>
			}

			{/* Editor obrázku */}
			{(imageId && imageId != api.emptyGuid) &&
				<div className="image-edit-frame">
					<div className="position-relative">

						{/* Obrázek statický */}
						<img className={"image-edit-img"}
							width={320}
							src={apiImages.getImageUrl(imageId)}
							ref={imageRef as any}
							crossOrigin="anonymous"
						/>
					</div>

					{/* Toolbar */}
					<div className="image-edit-toolbar">
						<>
							<span onClick={handleUploadClick} className="image-edit-icon-container">
								<Icon picture="ImageUpload" size="lg" className={"image-edit-icon"} />
							</span>
							<span onClick={handleDeleteIconClick} className="image-edit-icon-container">
								<Icon picture="ImageDelete" size="lg" className="image-edit-icon" />
							</span>
						</>
					</div>
				</div>
			}
		</div >
	);
}