/**
 * Seznam záznamů
 */

import * as state from "../../../../lib/state";
import * as context from "../../../../context";
import * as lists from "../../../system/lists/lists";
import * as apiMedicalRecordsList from "./api-medical-records-list";
import * as api from "../../../../lib/api";

/**
 * Stav modulu
 */
export interface State {
	deleted?: boolean;
}

export class Model implements state.StateModel {
	list: lists.StandardList<apiMedicalRecordsList.Treatment>;
	stateContainer: state.StateContainer<State>;

	constructor(private context: context.StateContext) {
		this.list = new lists.StandardList<apiMedicalRecordsList.Treatment>({
			context: context,
			title: "Seznam záznamů Dr. Digital",
			quickFilterPlaceholder: "Zadejte jméno...",
			filterSystem: _ => [
				api.qp("type" as any, "=", 6),
				api.qp("deleted" as any, "=", this.getDeleted() ? true : false),
			],
			itemActionsList: { actions: [] },
			standardEntityApi: context.apiMedicalRecordsList.treatments as any,
			defaultSorting: { field: "fullname" as any, order: 0 },
			grid: {
				minWidth: "1000px",
				columns: [
					{
						field: "fullname" as any,
						type: "string",
						title: "Jméno a příjmení",
					},
					{
						field: "name" as any,
						type: "string",
						title: "Název",
					},
					{
						field: "date" as any,
						type: "date",
						width: "150px",
						title: "Datum",
					},
					{
						field: "deleted" as any,
						type: "boolean",
						width: "120px",
						center: true,
						title: "Smazáno",
					},
					{
						field: "action" as any,
						width: "80px",
						type: "string",
						title: "Akce",
						preventSorting: true
					},
				]
			},
			defaultPageSize: 40,
			hideTabFilter: true,
			hideQueryMode: true
		});
		this.stateContainer = new state.StateContainer<State>({ deleted: true }, context);
	}

	/**
	* Vrací kolekci stavových kontejnerů
	*/
	getStateContainers = () => [
		this.stateContainer,
		...this.list.getStateContainers(),
	]

	getDeleted = () => {
		return this.stateContainer.get().deleted;
	}

	loadData = async () => {
		await this.list.loadWithResetPage();
	}

	remove = async (id: string) => {
		await this.context.apiMedicalRecordsList.remove(id);
		await this.list.load();
	}

	restore = async (id: string) => {
		await this.context.apiMedicalRecordsList.restore(id);
		await this.list.load();
	}
}