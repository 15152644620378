import * as React from "react";
import * as forms from "../../../lib/forms";
import HtmlEditor from "../HtmlEditor";
import FormItem, { FormItemOptions } from "./FormItem";


export interface FormsHtmlEditor<TFormFields> {
	form: forms.Form<TFormFields>;
	field: keyof TFormFields;
	className?: string;
	formItemOptions?: FormItemOptions;
	disabled?: boolean;
	simpleMode?: boolean;
}

export default function FormsHtmlEditor<TFormFields>(props: FormsHtmlEditor<TFormFields>) {
	return (
		<FormItem
			form={props.form}
			field={props.field}
			options={props.formItemOptions}
			className={props.className}
		>
			<HtmlEditor
				value={props.form.getField(props.field).value as any}
				simpleMode={props.simpleMode}
				onChange={(value: any) => props.form.setField(props.field, value ? value : "")}
				disabled={props.disabled}
			/>
		</FormItem>
	);
}