/**
 * Vybrání události
 */
import * as React from "react";
import * as state from "../../../../../lib/state";
import * as formatting from "../../../../../lib/formatting";
import * as api from "../../../../../lib/api";

import Dialog from "../../../../shared/dialogs/Dialog";
import Button from "../../../../../components/controls/Button";

function CalendarListDialog() {
	const { calendarListDialog } = state.useStateContext();
	const { calendarDialog } = state.useStateContext();
	const dialog = calendarListDialog.dialog;
	const items = calendarListDialog.dialog.getCustomField("items");

	function handleOpen(i: any) {
		const item = Object.assign({}, i);
		item.date = formatting.formatDateForInputDateTime(item.date) as any;
		calendarDialog.openDialog(true, item);
	}

	function handleAdd() {
		calendarListDialog.dialog.close();
		calendarDialog.openDialog(undefined, { date: formatting.getNoTime(new Date(items[0].date!)) } as any)
	}

	return (
		<Dialog className="simple-add-dialog" dialog={dialog}>
			<div>
				<h2 className="pb-1 dialog-title"><img className="d-inline mr-2" src="/images/folders-1.svg" alt="folders-1" />Vyberte událost</h2>
				<hr className="dialog-title-hr" />
				<div className="mt-3">
					{items.map((i, index) =>
						<div onClick={() => handleOpen(i)} key={index} className="row calendar-list-item-row">
							<div className="col-md-12">
								<div className="calendar-list-item">
									<div>
										<div className={"calendar-list-item-title " + ((i as any).id_pilulkoid ? "text-danger" : "")}>{i.description}</div>
										<div className="calendar-list-item-text">{formatting.formatDateTime(i.date as any)}</div>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
				{items.length > 0 &&
					<div className="mt-4 text-center">
						<Button onClick={handleAdd} className="pt-2 pb-2 float-right">
							<strong>Přidat</strong>
						</Button>
					</div>
				}
			</div>
		</Dialog >
	);
}

export default state.bindContainers(
	CalendarListDialog,
	context => context.calendarListDialog
);