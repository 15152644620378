/**
 * Modul domovské stránky
 */

import * as state from "../../../lib/state";
import * as api from "../../../lib/api";
import * as context from "../../../context";
import * as lists from "../../system/lists/lists";

/**
 * Stav modulu
 */
interface State {
}

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	stateContainer: state.StateContainer<State>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({}, context);
	}

	/**
	* Vrací kolekci stavových kontejnerů
	*/
	getStateContainers = () => [
		this.stateContainer,
	]

	loadData = () => {
		this.context.lastMeasurement.loadData();
		this.context.pilulkoid.loadData();
		this.context.painMonitor.loadData();
		this.context.calendarPage.loadData();
		this.context.lastRecords.loadData();
		this.context.doctors.loadData();
		this.context.authorization.loadStatistics();
	}
}