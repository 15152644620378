/**
 * Modul content showcase
 */

import * as state from "../../../../../lib/state";
import * as api from "../../../../../lib/api";
import * as context from "../../../../../context";
import * as forms from "../../../../../lib/forms";
import * as apiHome from "../../api-home";

/**
 * Stav modulu
 */
interface State {
	loading: boolean;
}

export interface FormFields {
	contents: apiHome.Content[];
	index: number;
}

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	public stateContainer: state.StateContainer<State>;
	public form: forms.Form<FormFields>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({
			loading: false
		}, context);
		this.form = new forms.Form<FormFields>({
			fields: {
				contents: {
					title: "",
					defaultValue: []
				},
				index: {
					title: "",
					defaultValue: 0
				},
			}
		}, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			this.stateContainer,
			...this.form.getStateContainers()
		];
	}

	loadData = async () => {
		await this.stateContainer.merge(() => ({ loading: true }));
		const contents = await this.context.api.post("/contents", {}) as any;
		this.form.setField("contents", contents.data);
		await this.stateContainer.merge(() => ({ loading: false }));
	}

	/**
	 * Provede reset formuláře
	 */
	reset = async () => {
		await Promise.all([
			this.form.clearFields(),
		]);
	}
}