import * as dialogs from "../../../../shared/dialogs/dialogs";
import * as context from "../../../../../context";
import * as formatting from "../../../../../lib/formatting";
import * as medicalRecords from "../../../../pages/administration/medical_records/medical-records";

interface State {
	type: number;
	data: medicalRecords.Graph[];
	value?: medicalRecords.Graph;
	loading: boolean;
}

export class Model {
	public dialog: dialogs.Dialog<undefined, State>;

	constructor(private context: context.StateContext) {
		this.dialog = new dialogs.Dialog<undefined, State>({
			defaultCustomFields: {
				type: 0,
				data: [],
				loading: false,
				value: undefined
			}
		}, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			...this.dialog.getStateContainers()
		];
	}

	getLoading = () => {
		return this.dialog.getCustomField("loading");
	}

	getType = () => {
		return this.dialog.getCustomField("type");
	}

	getData = () => {
		return this.dialog.getCustomField("data");
	}

	getValue = () => {
		return this.dialog.getCustomField("value");
	}

	setValue = async (value: medicalRecords.Graph) => {
		await this.dialog.setCustomField("value", value);
	}

	openDialog = async (type: number) => {
		await this.dialog.setCustomField("type", type);
		await this.dialog.setCustomField("data", []);
		await this.dialog.setCustomField("value", undefined);

		this.dialog.open();

		await this.dialog.setCustomField("loading", true);
		let graphData = await this.context.api.post("/users/measurements", {
			date_from: formatting.substractDaysFromDate(type == 5 ? 0 : 31, formatting.setTimeFromString(new Date(), 0, 0, 0)),
			date_to: formatting.setTimeFromString(new Date(), 23, 59, 59),
			type: type
		}) as medicalRecords.Graph[];

		if (type == 8) {
			let data2 = await this.context.api.post("/users/measurements", {
				date_from: formatting.substractDaysFromDate(31, formatting.setTimeFromString(new Date(), 0, 0, 0)),
				date_to: formatting.setTimeFromString(new Date(), 23, 59, 59),
				type: 9
			}) as medicalRecords.Graph[];
			graphData = graphData.map((x, index) => ({ ...x, value2: data2[index]?.value }))
		}

		const result = formatting.createLastWeeklyGraphData(graphData.map(x => ({ ...x, type: type })), 1) as any;
		await this.dialog.setCustomField("data", result);
		await this.dialog.setCustomField("loading", false);
	}

	save = async () => {
		let measurementSelected = this.context.medicalRecords.stateContainer.get().measurement_selected;
		const find = measurementSelected.find(x => x.type == this.dialog.getCustomField("type")) as any;
		if (find) {
			let value = this.dialog.getCustomField("value") as any;
			value.value = value.value + (value.type == 1 ? "°C" : value.type == 3 ? " bpm" : value.type == 4 ? "%" : value.type == 6 ? " bpm" : "");
			find.value = value.value;
			find.value2 = value.value2;
			if (value.type == 5) {
				find.data = value.data;
			}
		}
		else {
			let value = this.dialog.getCustomField("value") as any;
			value.value = value.value + (value.type == 1 ? "°C" : value.type == 3 ? " bpm" : value.type == 4 ? "%" : value.type == 6 ? " bpm" : "");
			measurementSelected.push(value)
		}
		await this.context.medicalRecords.stateContainer.merge(() => ({
			measurement_selected: measurementSelected
		}));
		return this.dialog.close();
	}
}