import React from "react";
import * as navgation from "../../lib/navigation";
import * as api from "../../lib/api";
import Link from "../controls/Link";

export default function PageMenu() {
	function handleMenu(route: string) {
		navgation.to(route);
	}

	function disableRedirect() {
		//window.open("https://xd.adobe.com/view/52f4bf2a-2588-431e-b001-d079a3fd5070-07a7/screen/c046695a-ae7d-4355-8236-e92082e04800");
	}

	return (
		<div className="page-leftnav max-width-1075">
			<div className={"page-leftnav-title "}><img src="/images/Group 655.svg" alt="group7" /></div>
			<div onClick={() => handleMenu("/")} className={"page-leftnav-item " + (api.getCurrentUrl() == "/" ? "page-header-menu-active" : "")}>
				<div className="page-leftnav-item-title mt-2">Ordinace</div>
				<div className="page-leftnav-item-img mt-3" >
					<img src="/images/Group 374.svg" alt="Group374" />
				</div>
			</div>
			<div onClick={() => handleMenu("/cekarna")} className={" page-leftnav-item " + (api.getCurrentUrl() == "/cekarna" ? "page-header-menu-active" : "")}>
				<div className="page-leftnav-item-title mt-2">Čekárna</div>
				<div className="page-leftnav-item-img mt-3" >
					<img src="/images/Group 373.svg" alt="Group373" />
				</div>
			</div>
			<div onClick={() => handleMenu("/recepce")} className={"page-leftnav-item " + (api.getCurrentUrl() == "/recepce" ? "page-header-menu-active" : "")}>
				<div className={"page-leftnav-item-title mt-2 "}>Recepce</div>
				<div className="page-leftnav-item-img mt-3" >
					<img src="/images/Group 211.svg" alt="Group211" />
				</div>
			</div>
			{/* <div onClick={() => handleMenu("/administrace-p-card")} className={"text-center"}>
				<div className={"page-leftnav-sos-all " + (api.getCurrentUrl() == "/administrace-p-card" ? "page-header-menu-active" : "")}>
					<div className={"page-leftnav-tag "}>
						<Link to="/administrace-p-card" className="mr-1">Administrace DZK </Link>
					</div>
				</div>
			</div> */}
		</div>
	);
}