/**
 * Seznam uživatelů
 */

import * as state from "../../../../lib/state";
import * as context from "../../../../context";
import * as api from "../../../../lib/api";
import * as lists from "../../../system/lists/lists";
import * as apiEmails from "./api-emails";

/**
 * Stav modulu
 */
export interface State {
}

export class Model implements state.StateModel {
	list: lists.StandardList<apiEmails.Email>;
	stateContainer: state.StateContainer<State>;

	constructor(private context: context.StateContext) {
		this.list = new lists.StandardList<apiEmails.Email>({
			context: context,
			title: "Seznam emailů",
			quickFilterPlaceholder: "Zadejte název...",
			itemActionsList: { actions: [] },
			standardEntityApi: context.apiEmails.emails as any,
			defaultSorting: { field: "date_of_create" as any, order: 1 },
			// filterSystem: _ => [
			// 	api.qp("from_user" as any, "!=", true),
			// ],
			grid: {
				minWidth: "1000px",
				columns: [
					{
						field: "mail_from" as any,
						type: "string",
						title: "Odesílatel",
					},
					{
						field: "mail_to" as any,
						type: "string",
						title: "Příjemce",
					},
					{
						field: "subject" as any,
						type: "string",
						title: "Subjekt",
					},
					{
						field: "date_of_create" as any,
						type: "string",
						title: "Datum vytvoření",
						width: "125px"
					},
					{
						field: "date_of_sent" as any,
						type: "string",
						title: "Datum odeslání",
						width: "130px"
					},
					{
						field: "error" as any,
						type: "string",
						title: "Chyba",
					},
				]
			},
			defaultPageSize: 40,
			hideTabFilter: true,
			hideQueryMode: true
		});
		this.stateContainer = new state.StateContainer<State>({}, context);
	}

	/**
	* Vrací kolekci stavových kontejnerů
	*/
	getStateContainers = () => [
		this.stateContainer,
		...this.list.getStateContainers(),
	]

	loadData = async () => {
		await this.list.loadWithResetPage();
	}
}