import React, { useRef } from "react";
import * as forms from "../../../lib/forms";
import * as common from "../../../lib/common";
import * as icons from "../../../modules/system/icons/icons";
import FormItem, { FormItemOptions } from "./FormItem";
import Input, { InputProps } from "../Input";
import Icon from "../../../modules/system/icons/Icon";


export interface FormsInputProps<TFormFields> {
	form: forms.Form<TFormFields>;
	field: keyof common.SubType<TFormFields, string>;
	className?: string;
	inputProps?: InputProps;
	formItemOptions?: FormItemOptions;
	onSave?: (field?: any, value?: string) => void;
	icon?: icons.Icon;
}

export default function FormsInput<TFormFields>(props: FormsInputProps<TFormFields>) {
	const field = props.form.getField(props.field);
	const fieldOptions = props.form.getFieldOptions(props.field);
	const hiddenContentRef = useRef<any>(null);

	function handleOnChange(e: any) {
		const value = e;
		props.form.setField(props.field, value as any);
		if (props.onSave) {
			clearTimeout(hiddenContentRef.current._timer);
			hiddenContentRef.current._timer = setTimeout(() => {
				props.onSave!(props.field, value);
			}, 1000);
		}
	}

	return (
		<FormItem
			form={props.form}
			field={props.field}
			options={props.formItemOptions}
			className={props.className + (props.icon ? " form-item-with-icon" : "")}
		>
			{props.icon &&
				<span className="form-control-icon"><Icon picture={props.icon} /></span>
			}
			<Input
				{...props.inputProps}
				ref={hiddenContentRef}
				value={field.value as any}
				placeholder={fieldOptions.placeHolderText}
				disabled={field.readOnly}
				onChange={handleOnChange}
			/>
		</FormItem>
	);
}