/**
 * Modul domovské stránky
 */

import * as state from "../../../../../lib/state";
import * as api from "../../../../../lib/api";
import * as context from "../../../../../context";
import * as common from "../../../../../lib/common";
import * as forms from "../../../../../lib/forms";
import * as apiHome from "../../../home/api-home";

/**
 * Stav modulu
 */
interface State {
}

export interface Doctor {
	id: string,
	name: string;
	type: string;
	address: string;
	phone: string;
	email: string;
	image: string;
	next_visit: string;
}
export interface Widget {
	id: string;
	type: number;
	src: string;
	name: string;
	value: string;
	field: keyof common.SubType<FormFields, boolean | undefined>;
}

export interface FormFields {
	temperature: boolean;
	pressure: boolean;
	hearth_rate: boolean;
	oximetry: boolean;
	breath_rate: boolean;
	ekg: boolean;
	body_fat: boolean;
	weight: boolean;
	showDocuments: boolean;
	documents: api.CodeBookItemWithDate[];
	widgets: Widget[];
	showWidgets: boolean;
	doctors: Doctor[];
	vaccination: api.CodeBookItemWithDate[];
	showOperations: boolean;
	operations: api.CodeBookItemWithDate[];
}

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	public form: forms.Form<FormFields>;

	constructor(private context: context.StateContext) {
		this.form = new forms.Form<FormFields>({
			fields: {
				temperature: {
					title: "",
					defaultValue: false,
				},
				vaccination: {
					title: "",
					defaultValue: [],
				},
				doctors: {
					title: "",
					defaultValue: [],
				},
				showDocuments: {
					title: "Zobrazit",
					defaultValue: false
				},
				documents: {
					title: "",
					defaultValue: [],
				},
				pressure: {
					title: "",
					defaultValue: false,
				},
				hearth_rate: {
					title: "",
					defaultValue: false,
				},
				oximetry: {
					title: "",
					defaultValue: false
				},
				breath_rate: {
					title: "",
					defaultValue: false
				},
				ekg: {
					title: "",
					defaultValue: false
				},
				body_fat: {
					title: "",
					defaultValue: false
				},
				weight: {
					title: "",
					defaultValue: false
				},
				widgets: {
					title: "",
					defaultValue: []
				},
				showWidgets: {
					title: "",
					defaultValue: false
				},
				showOperations: {
					title: "Zobrazit",
					defaultValue: false
				},
				operations: {
					title: "",
					defaultValue: []
				},
			}
		}, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			...this.form.getStateContainers()
		];
	}

	loadData = async () => {
		const doctors = await this.context.api.loadList("/user_doctors", { sort_fields_desc: ["sys_date_create"], sort_fields: ["sys_date_create"] }) as any;
		this.form.setField("doctors", doctors.data);
	}

	/**
	 * Provede reset formuláře
	 */
	reset = async () => {
		await Promise.all([
			this.form.clearFields(),
		]);
	}
}