/**
 * Modul domovské stránky
 */

import * as state from "../../../lib/state";
import * as context from "../../../context";

/**
 * Stav modulu
 */
interface State {
	isOpen: boolean;
}

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	stateContainer: state.StateContainer<State>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({
			isOpen: false
		}, context);
	}

	/**
	* Vrací kolekci stavových kontejnerů
	*/
	getStateContainers = () => [
		this.stateContainer,
	]

	loadData = async () => {
		this.context.optionalViewWidget.loadData();
		this.context.expandedViewWidget.loadData();
		this.context.pCardWidget.loadData();
	}
}