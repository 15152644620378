import * as React from "react";

import * as state from "./lib/state";

// api's
import * as api from "./lib/api";
import * as images from "./modules/system/images/images";
import * as apiAuth from "./modules/system/authorization/api-authorization";
import * as apiImages from "./modules/system/images/api-images";
import * as apiUsers from "./modules/pages/administration/users/api-users";
import * as apiEmails from "./modules/pages/administration/emails/api-emails";
import * as apiDoctors from "./modules/pages/administration/doctors/api-doctors";
import * as apiCategories from "./modules/pages/administration/categories/api-categories";
import * as apiMedicalRecordsList from "./modules/pages/administration/medical-records-list/api-medical-records-list";
import * as apiMedicalRecordsListFile from "./modules/pages/administration/medical-records-list/api-medical-records-list-file";
import * as apiContents from "./modules/pages/administration/waiting-room/api-waiting-room";
import * as apiNotifications from "./modules/system/notifications/api-notifications";
import * as apiCodeList from "./modules/system/code-list/api-code-list";
import * as apiFile from "./modules/system/file/api-file";

// base modules
import * as authorization from "./modules/system/authorization/authorization";
import * as login from "./modules/system/authorization/login";
import * as resetPassword from "./components/sections/reset-password";
import * as dialogs from "./modules/shared/dialogs/dialogs";
import * as standardDialogs from "./modules/shared/dialogs/standard-dialogs";
import * as addLastMeasurementDialog from "./modules/pages/home/widgets/last-records/add-dialog";
import * as captureDialog from "./modules/shared/dialogs/capture-dialog";
import * as lastRecordContentDialog from "./modules/pages/home/widgets/last-records/last-record-content-dialog";
import * as lastMeasurementDialog from "./modules/pages/home/widgets/last-measurement/last-measurement-dialog";
import * as lastMeasurementSelectDialog from "./modules/pages/home/widgets/last-measurement/last-meassurement-widget-select";
import * as simpleAddDialog from "./modules/shared/dialogs/simple-add-dialog";
import * as usersMessageDialog from "./modules/pages/administration/users/users-message-dialog";
import * as medicalRecordDescriptionDialog from "./modules/pages/administration/medical_records/medical-record-description-dialog";
import * as medicalRecordShowDataDialog from "./modules/pages/administration/medical_records/medical-record-show-data-dialog";
import * as painMonitorDialog from "./modules/pages/home/widgets/pain-monitor/pain-monitor-dialog";
import * as doctorDialog from "./modules/shared/dialogs/doctor-dialog";
import * as showImageDialog from "./modules/shared/dialogs/show-image-dialog";
import * as pilulkoidDialog from "./modules/pages/home/widgets/pilulkoid-page/pilulkoid-dialog";
import * as resetPasswordDialog from "./modules/system/authorization/reset-password-dialog";
import * as changePasswordDialog from "./modules/system/authorization/change-password-dialog";
import * as calendarDialog from "./modules/pages/home/widgets/calendar/calendar-dialog";
import * as contentItemDialog from "./modules/pages/administration/waiting-room/waiting-room-item-dialog";
import * as emailDialog from "./modules/pages/administration/emails/email-dialog";
import * as contentDialog from "./modules/shared/contents/content-dialog";
import * as calendarListDialog from "./modules/pages/home/widgets/calendar/calendar-list-dialog";

// page modules
import * as home from "./modules/pages/home/home";
import * as waitingRoom from "./modules/pages/waiting-room/waiting-room";
import * as users from "./modules/pages/administration/users/users";
import * as doctorsList from "./modules/pages/administration/doctors/doctors-list";
import * as categoriesList from "./modules/pages/administration/categories/categories-list";
import * as medicalRecordsList from "./modules/pages/administration/medical-records-list/medical-records-list";
import * as medicalRecordsListFile from "./modules/pages/administration/medical-records-list/medical-records-list-file";
import * as emailsDetail from "./modules/pages/administration/emails/emails-detail";
import * as emailsList from "./modules/pages/administration/emails/emails-list";
import * as emailUsersList from "./modules/pages/administration/emails/email-users-list";
import * as doctorsDetail from "./modules/pages/administration/doctors/doctors-detail";
import * as categoriesDetail from "./modules/pages/administration/categories/categories-detail";
import * as usersDetail from "./modules/pages/administration/users/user-detail";
import * as userProfile from "./components/sections/user-profile";
import * as waitingRoomList from "./modules/pages/administration/waiting-room/waiting-room-list";
import * as waitingRoomItemList from "./modules/pages/administration/waiting-room/waiting-room-item-list";
import * as waitingRoomDetail from "./modules/pages/administration/waiting-room/waiting-room-detail";
import * as individualReport from "./modules/pages/administration/reports/individual-report";
import * as medicalRecords from "./modules/pages/administration/medical_records/medical-records";
import * as pCardAdmin from "./modules/pages/p-card-admin/p-card-admin";
import * as widgetView from "./components/sections/widget-view";
import * as lastMeasurement from "./modules/pages/home/widgets/last-measurement/last-measurement";
import * as optionalViewWidget from "./modules/pages/p-card-admin/widgets/optional-view-widget/optional-view-widget";
import * as expandedViewWidget from "./modules/pages/p-card-admin/widgets/expanded-view-widget/expanded-view-widget";
import * as pCardWidget from "./modules/pages/p-card-admin/widgets/p-card-widget/p-card-widget";
import * as calendarPage from "./modules/pages/home/widgets/calendar/calendar-page";
import * as lastRecords from "./modules/pages/home/widgets/last-records/last-records";
import * as pilulkoid from "./modules/pages/home/widgets/pilulkoid-page/pilulkoid";
import * as painMonitor from "./modules/pages/home/widgets/pain-monitor/pain-monitor";
import * as doctors from "./modules/pages/home/widgets/doctors/doctors";
import * as contentShowcase from "./modules/pages/home/widgets/content-showcase/content-showcase";
import * as office from "./modules/pages/office/office";

export class StateContext {
	hostname: string;
	simplyfiedHeader: boolean;

	// some forwarded base modules
	state: state.StateContainerContext;
	dialogs: dialogs.Model;
	standardDialogs: standardDialogs.Model;
	addLastMeasurementDialog: addLastMeasurementDialog.Model;
	captureDialog: captureDialog.Model;
	doctorDialog: doctorDialog.Model;
	showImageDialog: showImageDialog.Model;
	simpleAddDialog: simpleAddDialog.Model;
	usersMessageDialog: usersMessageDialog.Model;
	medicalRecordDescriptionDialog: medicalRecordDescriptionDialog.Model;
	medicalRecordShowDataDialog: medicalRecordShowDataDialog.Model;
	painMonitorDialog: painMonitorDialog.Model;
	pilulkoidDialog: pilulkoidDialog.Model;
	resetPasswordDialog: resetPasswordDialog.Model;
	changePasswordDialog: changePasswordDialog.Model;
	calendarDialog: calendarDialog.Model;
	contentItemDialog: contentItemDialog.Model;
	emailDialog: emailDialog.Model;
	calendarListDialog: calendarListDialog.Model;
	lastRecordContentDialog: lastRecordContentDialog.Model;
	lastMeasurementDialog: lastMeasurementDialog.Model;
	lastMeasurementSelectDialog: lastMeasurementSelectDialog.Model;
	contentDialog: contentDialog.Model;

	// api's
	api: api.SpringRestApi;
	images: images.Model;
	apiAuth: apiAuth.Api;
	apiImages: apiImages.Api;
	apiUsers: apiUsers.Api;
	apiEmails: apiEmails.Api;
	apiDoctors: apiDoctors.Api;
	apiCategories: apiCategories.Api;
	apiMedicalRecordsList: apiMedicalRecordsList.Api;
	apiMedicalRecordsListFile: apiMedicalRecordsListFile.Api;
	apiContents: apiContents.Api;
	apiNotifications: apiNotifications.Api;
	apiFile: apiFile.Api;

	// base functionalities models
	authorization: authorization.Model;
	login: login.Model;
	resetPassword: resetPassword.Model;

	// page models
	home: home.Model;
	waitingRoom: waitingRoom.Model;
	users: users.Model;
	doctorsList: doctorsList.Model;
	categoriesList: categoriesList.Model;
	medicalRecordsList: medicalRecordsList.Model;
	medicalRecordsListFile: medicalRecordsListFile.Model;
	doctorsDetail: doctorsDetail.Model;
	categoriesDetail: categoriesDetail.Model;
	usersDetail: usersDetail.Model;
	emailsList: emailsList.Model;
	emailUsersList: emailUsersList.Model;
	emailsDetail: emailsDetail.Model;
	userProfile: userProfile.Model;
	waitingRoomList: waitingRoomList.Model;
	waitingRoomItemList: waitingRoomItemList.Model;
	waitingRoomDetail: waitingRoomDetail.Model;
	individualReport: individualReport.Model;
	medicalRecords: medicalRecords.Model;
	pCardAdmin: pCardAdmin.Model;
	widgetView: widgetView.Model;
	optionalViewWidget: optionalViewWidget.Model;
	expandedViewWidget: expandedViewWidget.Model;
	pCardWidget: pCardWidget.Model;
	calendarPage: calendarPage.Model;
	lastRecords: lastRecords.Model;
	contentShowcase: contentShowcase.Model;
	lastMeasurement: lastMeasurement.Model;
	pilulkoid: pilulkoid.Model;
	painMonitor: painMonitor.Model;
	doctors: doctors.Model;
	office: office.Model;

	constructor(hostname: string) {
		this.hostname = hostname;
		this.simplyfiedHeader = hostname === "pdd.dent.cz";

		// some forwarded base modules
		this.state = new state.StateContainerContext();
		this.dialogs = new dialogs.Model(this);
		this.standardDialogs = new standardDialogs.Model(this);
		this.addLastMeasurementDialog = new addLastMeasurementDialog.Model(this);
		this.captureDialog = new captureDialog.Model(this);
		this.simpleAddDialog = new simpleAddDialog.Model(this);
		this.usersMessageDialog = new usersMessageDialog.Model(this);
		this.medicalRecordDescriptionDialog = new medicalRecordDescriptionDialog.Model(this);
		this.medicalRecordShowDataDialog = new medicalRecordShowDataDialog.Model(this);
		this.painMonitorDialog = new painMonitorDialog.Model(this);
		this.doctorDialog = new doctorDialog.Model(this);
		this.showImageDialog = new showImageDialog.Model(this);
		this.pilulkoidDialog = new pilulkoidDialog.Model(this);
		this.resetPasswordDialog = new resetPasswordDialog.Model(this);
		this.changePasswordDialog = new changePasswordDialog.Model(this);
		this.calendarDialog = new calendarDialog.Model(this);
		this.contentItemDialog = new contentItemDialog.Model(this);
		this.emailDialog = new emailDialog.Model(this);
		this.lastRecordContentDialog = new lastRecordContentDialog.Model(this);
		this.lastMeasurementDialog = new lastMeasurementDialog.Model(this);
		this.lastMeasurementSelectDialog = new lastMeasurementSelectDialog.Model(this);
		this.contentDialog = new contentDialog.Model(this);
		this.calendarListDialog = new calendarListDialog.Model(this);

		// api's
		this.api = new api.SpringRestApi({
			apiKey: "60a009a4-ac62-4afc-ad13-0fa266c99f35",
			endpoint: (this.hostname === "vk.drdigital.cz")
				? "https://vk-api.drdigital.cz/api/v1"
				: "https://virtualni-klinika-api.ssapp.cz/api/v1",
			context: this
		});
		this.apiAuth = new apiAuth.Api(this);
		this.apiImages = new apiImages.Api(this);
		this.apiUsers = new apiUsers.Api(this);
		this.apiEmails = new apiEmails.Api(this);
		this.apiDoctors = new apiDoctors.Api(this);
		this.apiCategories = new apiCategories.Api(this);
		this.apiMedicalRecordsList = new apiMedicalRecordsList.Api(this);
		this.apiMedicalRecordsListFile = new apiMedicalRecordsListFile.Api(this);
		this.apiContents = new apiContents.Api(this);
		this.apiNotifications = new apiNotifications.Api(this);
		this.apiFile = new apiFile.Api(this);

		// base functionalities models
		this.authorization = new authorization.Model(this);
		this.login = new login.Model(this);
		this.resetPassword = new resetPassword.Model(this);
		this.images = new images.Model(this);

		// page models
		this.home = new home.Model(this);
		this.pCardAdmin = new pCardAdmin.Model(this);
		this.widgetView = new widgetView.Model(this);
		this.optionalViewWidget = new optionalViewWidget.Model(this);
		this.expandedViewWidget = new expandedViewWidget.Model(this);
		this.pCardWidget = new pCardWidget.Model(this);
		this.calendarPage = new calendarPage.Model(this);
		this.lastRecords = new lastRecords.Model(this);
		this.contentShowcase = new contentShowcase.Model(this);
		this.lastMeasurement = new lastMeasurement.Model(this);
		this.pilulkoid = new pilulkoid.Model(this);
		this.painMonitor = new painMonitor.Model(this);
		this.doctors = new doctors.Model(this);
		this.waitingRoom = new waitingRoom.Model(this);
		this.users = new users.Model(this);
		this.doctorsList = new doctorsList.Model(this);
		this.categoriesList = new categoriesList.Model(this);
		this.medicalRecordsList = new medicalRecordsList.Model(this);
		this.medicalRecordsListFile = new medicalRecordsListFile.Model(this);
		this.doctorsDetail = new doctorsDetail.Model(this);
		this.categoriesDetail = new categoriesDetail.Model(this);
		this.usersDetail = new usersDetail.Model(this);
		this.emailsList = new emailsList.Model(this);
		this.emailUsersList = new emailUsersList.Model(this);
		this.emailsDetail = new emailsDetail.Model(this);
		this.userProfile = new userProfile.Model(this);
		this.waitingRoomList = new waitingRoomList.Model(this);
		this.waitingRoomItemList = new waitingRoomItemList.Model(this);
		this.waitingRoomDetail = new waitingRoomDetail.Model(this);
		this.individualReport = new individualReport.Model(this);
		this.medicalRecords = new medicalRecords.Model(this);
		this.office = new office.Model(this);
	}
}

const AppContext = React.createContext<StateContext>(undefined as any);
export default AppContext;
