import * as React from "react";

export type SelectProps<TValue> = Omit<React.HTMLProps<HTMLSelectElement>, "value" | "onChange"> & {
	value: any;
	options: { title: string; value: any }[];
	onChange?: (value: TValue) => void;
};

export default function Select<TValue>(props: SelectProps<TValue>) {
	function handleChange(value: TValue) {
		if (props.onChange) {
			props.onChange(value);
		}
	}
	const { value, options, onChange, ...restProps } = props;
	return (
		<select
			{...restProps}
			onChange={(e) => handleChange(props.options[e.currentTarget.options.selectedIndex].value)}
			className={"custom-select " + (props.className ?? "")}>

			{props.options.map(i =>
				<option key={i.title} defaultValue={i.value == undefined || props.value == undefined ? false : (props.value.id == undefined ? props.value == i.value : props.value.id == i.value.id) as any} selected={i.value == undefined || props.value == undefined ? false : (props.value.id == undefined ? props.value == i.value : props.value.id == i.value.id)}>{i.title}</option>
			)}
		</select>
	);
}