/**
 * Seznam uživatelů
 */
import React from "react";
import * as state from "../../../../lib/state";
import * as api from "../../../../lib/api";
import List from "../../../../modules/system/lists/List";
import MyRequestAction from "../../../../components/controls/MyRequestAction";
import Button from "../../../../components/controls/Button";

function WaitingRoomItemListPage() {
	const { waitingRoomItemList } = state.useStateContext();
	const { waitingRoomDetail } = state.useStateContext();
	const { contentItemDialog } = state.useStateContext();
	const { standardDialogs } = state.useStateContext();

	function actions(item: any) {
		const items = [
			{
				title: "Smazat záznam",
				action: async (item: any) => {
					await standardDialogs.openYesNoDialog("Opravdu chcete smazat uživatele?") === "yes"
						? waitingRoomItemList.remove(item.id)
						: undefined
				},
			},
		];
		return items as any;
	};

	return (
		<div className="ml-3 mr-3">
			<Button className="d-inline-block font-weight-bold mt-3 mb-3" onClick={() =>
				contentItemDialog.openDialog({
					id_image: api.emptyGuid,
					id_main_image: api.emptyGuid,
					description: "",
					title: "",
					id: api.emptyGuid,
					id_content: waitingRoomDetail.form.getField("id").value,
					sys_date_create: new Date(),
					show_ambulance: false,
					url: ""
				})}>
				Přidat subkategorii
			</Button>
			<List
				itemNode={(item, field) => {
					switch (field) {
						case "title": return <a className="cursor-pointer" onClick={() => contentItemDialog.openDialog(item as any)}>{item[field]}</a>;
						case "action" as any: return <MyRequestAction className="ml-2 user-list-page-action-sync" id="waiting-room-list" item={item} actions={actions(item)} />;
						default: return undefined;
					}
				}}
				simpleTable list={waitingRoomItemList.list} />
		</div>
	);
}

export default state.bindContainers(
	WaitingRoomItemListPage,
	context => context.waitingRoomItemList
);