/**
 * Přidání souboru
 */
import Button from "../../../../../components/controls/Button";
import * as React from "react";
import * as state from "../../../../../lib/state";
import * as notification from "../../../../../lib/notification";
import * as apiLib from "../../../../../lib/api";
import * as formatting from "../../../../../lib/formatting";
import * as lastRecords from "../last-records/last-records";

import Dialog from "../../../../shared/dialogs/Dialog";
import FormsInput from "../../../../../components/controls/Forms/FormsInput";
import FormTextArea from "../../../../../components/controls/Forms/FormsTextArea";
import Icon from "../../../../../modules/system/icons/Icon";

function CalendarDialog() {
	const { calendarDialog } = state.useStateContext();
	const { pilulkoidDialog } = state.useStateContext();
	const { painMonitorDialog } = state.useStateContext();
	const { doctorDialog } = state.useStateContext();
	const { addLastMeasurementDialog } = state.useStateContext();
	const { calendarPage } = state.useStateContext();
	const { calendarListDialog } = state.useStateContext();
	const { standardDialogs } = state.useStateContext();
	const { api } = state.useStateContext();
	const dialog = calendarDialog.dialog;
	const disabled = calendarDialog.stateContainer.get().disabled;
	const form = calendarDialog.form;
	const idDoctor = form.getField("id_doctor").value;
	const idPainMonitor = form.getField("id_pain_monitor").value;
	const idPilulkoid = form.getField("id_pilulkoid").value;
	const idTreatment = form.getField("id_treatment").value

	async function removeItem() {
		const result = await api.del("/calendars/" + calendarDialog.form.getField("id").value);
		if (result) {
			notification.successMessage("Událost byla smazána.");
			await calendarPage.loadData();
			calendarDialog.dialog.close();
			const items = calendarListDialog.dialog.getCustomField("items");
			if (items) {
				const date = calendarListDialog.dialog.getCustomField("currentDate");
				if (date) {
					const data = calendarPage.getData().filter(x => x.day == date!.toString());
					calendarListDialog.dialog.setCustomField("items", data as any);
				}
			}
		}
	}

	async function handleRemove() {
		await standardDialogs.openYesNoDialog("Opravdu chcete smazat událost?") === "yes"
			? removeItem()
			: undefined
	}

	async function handleOpen() {
		if (idDoctor) {
			const item = await api.get("/user_doctors/" + idDoctor) as any;
			doctorDialog.openDialog(item, true);
		}
		if (idPainMonitor) {
			const item = await api.get("/pain_monitors/" + idPainMonitor) as any;
			painMonitorDialog.openDialog(item, true);
		}
		if (idPilulkoid) {
			const item = await api.get("/pilulkoids/" + idPilulkoid) as any;
			pilulkoidDialog.openDialog(item, true);
		}
		if (idTreatment) {
			const item = await api.get("/treatments/" + idTreatment);
			const type = { id: idTreatment, name: lastRecords.MyRecordType.title(idTreatment as any) } as any;
			const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
			addLastMeasurementDialog.openDialog(type, item as any, isMobile, true);
		}
	}

	function handleAdd() {
		calendarDialog.dialog.close();
		calendarDialog.openDialog(undefined, { date: formatting.getNoTime(new Date(calendarDialog.form.getField("date").value)) } as any)
	}

	return (
		<Dialog className="simple-add-dialog" dialog={dialog}>
			<div>
				<h2 className="pb-3 dialog-title"><img className="d-inline mr-2" src="/images/folders-1.svg" alt="folders-1" /> Událost</h2>
				<hr className="dialog-title-hr" />
				<FormTextArea textAreaProps={{ disabled: disabled == undefined ? false : disabled as any } as any} form={form} field="description" />
				<FormsInput
					inputProps={{ type: "datetime-local" }}
					form={form}
					field="date"
				/>
				{calendarDialog.form.getField("id").value != apiLib.emptyGuid &&
					<div onClick={handleAdd} className="color-primary d-inline cursor-pointer pilulkoid-remove mr-2">
						<Icon className="mr-1" picture="Add" /> Přidat
					</div>
				}
				{(idDoctor || idPainMonitor || idPilulkoid || idTreatment) &&
					<div onClick={handleOpen} className="color-primary d-inline cursor-pointer pilulkoid-remove">
						<Icon className="mr-1" picture="Search" /> Náhled
					</div>
				}
				{calendarDialog.form.getField("id").value != apiLib.emptyGuid &&
					<div onClick={handleRemove} className="color-primary d-inline float-right cursor-pointer pilulkoid-remove">
						Odebrat <Icon className="ml-1" picture="Remove" />
					</div>
				}
				{!disabled &&
					<div className="mt-4 text-center">
						<Button onClick={e => calendarDialog.save()} className="pt-2 pb-2 float-right">
							<strong>Vložit</strong>
						</Button>
					</div>
				}
			</div>
		</Dialog >
	);
}

export default state.bindContainers(
	CalendarDialog,
	context => context.calendarDialog
);