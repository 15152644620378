
/**
 * Individuální report - grafy
 */
import React, { useRef } from "react";
import * as state from "../../../../lib/state";
import * as api from "../../../../lib/api";
import * as formatting from "../../../../lib/formatting";
import * as painMonitorDialog from "../../home/widgets/pain-monitor/pain-monitor-dialog";
import { GeneralGraph } from "../../../../modules/shared/graphs/GeneralGraph";
import Switch from "../../../../components/controls/Switch";
interface Props {
	isSecondPage?: boolean;
}

export default function IndividualReportsChartsPage(props: Props) {
	const { individualReport } = state.useStateContext();
	const { lastMeasurement } = state.useStateContext();
	const { authorization } = state.useStateContext();
	const { apiImages } = state.useStateContext();
	const { api: apiState } = state.useStateContext();
	const container = individualReport.getStateContainer();
	const generalRef = useRef<any>(null);
	const generalRef2 = useRef<any>(null);
	const generalRef3 = useRef<any>(null);
	const generalRef4 = useRef<any>(null);

	const general2Ref = useRef<any>(null);
	const general2Ref2 = useRef<any>(null);
	const general2Ref3 = useRef<any>(null);
	const general2Ref4 = useRef<any>(null);
	const general2Ref5 = useRef<any>(null);
	const general2Ref6 = useRef<any>(null);
	const general2Ref7 = useRef<any>(null);
	const general2Ref8 = useRef<any>(null);
	const general2Ref9 = useRef<any>(null);

	const PainType = painMonitorDialog.PainType;
	const profile = authorization.getUserProfile()?.profile;

	const painType = [
		{ id: PainType.SubjectiveFeelingOfHealth, name: PainType.title(PainType.SubjectiveFeelingOfHealth) },
		{ id: PainType.SubjectiveFeelingOfEnergy, name: PainType.title(PainType.SubjectiveFeelingOfEnergy) },
		{ id: PainType.SubjectiveMoodFeeling, name: PainType.title(PainType.SubjectiveMoodFeeling) },
		{ id: PainType.Sleep, name: PainType.title(PainType.Sleep) },
		{ id: PainType.FoodAndExcretion, name: PainType.title(PainType.FoodAndExcretion) },
		{ id: PainType.Breathing, name: PainType.title(PainType.Breathing) },
		{ id: PainType.SightSmellHearing, name: PainType.title(PainType.SightSmellHearing) },
		{ id: PainType.Pain, name: PainType.title(PainType.Pain) },
		{ id: PainType.MentalHealth, name: PainType.title(PainType.MentalHealth) },
	] as api.CodeBookItemWithNumberId[];

	const meassures = [
		{ id: 3, name: "Heart rate", ref: generalRef2 },
		{ id: 4, name: "Oximetry", ref: generalRef3 },
		{ id: 8, name: "Tlak", ref: generalRef4 },
		{ id: 6, name: "Breath rate" },
	] as api.CodeBookItemWithNumberId[];

	async function handleFilter(e: any) {
		individualReport.handleFilter(e, generalRef, generalRef2, generalRef3, generalRef4, general2Ref, general2Ref2, general2Ref3, general2Ref4, general2Ref5, general2Ref6, general2Ref7, general2Ref8, general2Ref9);
		const all_meassurements = await apiState.post("/users/all_meassurements", { date_from: formatting.substractDaysFromDate(e ? 7 : 31, formatting.setTimeFromString(new Date(), 0, 0, 0)), date_to: formatting.setTimeFromString(new Date(), 23, 59, 59) }) as any;
		await individualReport.stateContainer.merge(() => ({
			measurementData: all_meassurements.data.sort((a: any, b: any) => (new Date(a.data) as any) - (new Date(b.data) as any)),
			loadingMeasurements: false
		}));
		individualReport.handleFilter(e, generalRef, generalRef2, generalRef3, generalRef4, general2Ref, general2Ref2, general2Ref3, general2Ref4, general2Ref5, general2Ref6, general2Ref7, general2Ref8, general2Ref9);
	}

	return (
		<>
			<div className="medical-records-panel">
				<div className="medical-records-panel-header">
					<div className="medical-records-panel-page">
						<img width={120} src="/images/Group 655.svg" alt="group7" />
					</div>
					<div className="medical-records-panel-header-page">
						{individualReport.getPageSize(2)}
					</div>
				</div>
				<div className="text-center">
					<h2 style={{ width: "60 %" }} className="medical-records-panel-title font-weight-bold">Zobrazení dat</h2>
				</div>
				<div className="medical-records-panel-blue medical-records-panel-main text-center medical-records-panel-blue-text mb-4">
					<span>Filrace</span>
				</div>
				<div>Filtrace pro zobrazení dat za poslední měsíc. Pomocí přepínače můžete přepínat mezi týdnem/měsícem.</div>
				<div className="mb-4 mt-3">
					<Switch
						title={container.showWeek ? "Zobrazení týdne" : "Zobrazení měsíce"}
						value={container.showWeek}
						onCheckedChanged={handleFilter}
					/>
				</div>
				<div className="medical-records-panel-blue medical-records-panel-main text-center medical-records-panel-blue-text mb-4">
					<span>Vitální údaje</span>
				</div>
				{meassures.map((i, index) =>
					<GeneralGraph
						key={index}
						data={container.measurementData}
						ref={index == 0 ? generalRef : index == 1 ? generalRef2 : index == 2 ? generalRef3 : generalRef4}
						width="100%"
						showWeek={container.showWeek}
						date_from={container.measures_date_from}
						date_to={container.measures_date_to}
						secondType={i.id == 8 ? { id: 9, name: "DIA" } : undefined}
						saving={container.saving}
						type={{ id: i.id, name: i.name }}
						types={[{ id: i.id, name: i.name }]}
						disabled={true}
						measurement={true}
					/>
				)}
				<div className="medical-records-panel-footer">
					{individualReport.getPageSize(2)}
				</div>
			</div>
			<div className="medical-records-panel">
				<div className="medical-records-panel-header">
					<div className="medical-records-panel-page">
						<img width={120} src="/images/Group 655.svg" alt="group7" />
					</div>
					<div className="medical-records-panel-header-page">
						{individualReport.getPageSize(3)}
					</div>
				</div>
				<div className="medical-records-panel-blue medical-records-panel-main text-center medical-records-panel-blue-text mb-4">
					Zdravotní deník
				</div>
				{painType.slice(0, 5).map((i, index) =>
					<GeneralGraph
						key={index}
						ref={index == 0 ? general2Ref : index == 1 ? general2Ref2 : index == 2 ? general2Ref3 : index == 4 ? general2Ref4 : general2Ref5}
						width="100%"
						showWeek={container.showWeek}
						date_from={container.measures_date_from}
						date_to={container.measures_date_to}
						type={{ id: i.id, name: i.name }}
						saving={container.saving}
						types={[{ id: i.id, name: i.name }]}
						disabled={true}
					/>
				)}
				<div className="medical-records-panel-footer">
					{individualReport.getPageSize(3)}
				</div>
			</div>
			<div className="medical-records-panel">
				<div className="medical-records-panel-header">
					<div className="medical-records-panel-page">
						<img width={120} src="/images/Group 655.svg" alt="group7" />
					</div>
					<div className="medical-records-panel-header-page">
						{individualReport.getPageSize(4)}
					</div>
				</div>
				<div className="medical-records-panel-blue medical-records-panel-main text-center medical-records-panel-blue-text mb-4">
					Zdravotní deník
				</div>
				{painType.slice(5, 10).map((i, index) =>
					<GeneralGraph
						key={index}
						ref={index == 0 ? general2Ref6 : index == 1 ? general2Ref7 : index == 2 ? general2Ref8 : general2Ref9}
						showWeek={container.showWeek}
						date_from={container.measures_date_from}
						width="100%"
						date_to={container.measures_date_to}
						type={{ id: i.id, name: i.name }}
						saving={container.saving}
						types={[{ id: i.id, name: i.name }]}
						disabled={true}
					/>
				)}
				<div className="medical-records-panel-footer">
					{individualReport.getPageSize(4)}
				</div>
			</div>
			<div className="medical-records-panel">
				<div className="medical-records-panel-header">
					<div className="medical-records-panel-page">
						<img width={120} src="/images/Group 655.svg" alt="group7" />
					</div>
					<div className="medical-records-panel-header-page">
						{individualReport.getPageSize(5)}
					</div>
				</div>
				<div className="medical-records-panel-blue medical-records-panel-main text-center medical-records-panel-blue-text mb-4 mt-3">
					Dokumenty
				</div>
				<div className="d-flex mt-2">
					{profile?.id_insurance_card && profile?.id_insurance_card != api.emptyGuid &&
						<div style={{ width: "33%" }} className="mb-3 mr-3">
							<div className="medical-records-list-item-title mb-1">Průkaz pojištěnce:</div>
							<div className="d-block">
								<img style={{ height: 190, width: 335 }} alt="pojistenec" src={apiImages.getImageUrl(profile?.id_insurance_card!)} />
							</div>
						</div>
					}
					{profile?.id_covid_certificate && profile?.id_covid_certificate != api.emptyGuid &&
						<div style={{ width: "33%" }} className="mb-3 mr-3">
							<div className="medical-records-list-item-title mb-1">Covid očkování:</div>
							<div className="d-block">
								<img style={{ height: 190, width: 335 }} alt="covid" src={apiImages.getImageUrl(profile?.id_covid_certificate!)} />
							</div>
						</div>
					}
					<div className="medical-records-panel-footer">
						<div style={{ float: "left", marginLeft: 25 }} className="d-inline">
							<a target="_blank" href="https://www.drdigital.cz">www.drdigital.cz</a>
						</div>
						{individualReport.getPageSize(5)}
						<div className="medical-records-conclusion-drdigital">
							<img src="/images/drdigital.jpg" />
						</div>
					</div>
				</div>
			</div>
		</>
	);
}