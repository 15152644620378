/**
 * MedicalRecordsEvaluation
 */
import * as React from "react";
import FormsRadio from "../../../../components/controls/Forms/FormRadio";
import FormTextArea from "../../../../components/controls/Forms/FormsTextArea";
import * as state from "../../../../lib/state";
import * as formatting from "../../../../lib/formatting";
import FormFileUpload from "../../../../modules/system/file/FormFileUpload";
import Button from "../../../../components/controls/Button";
import MedicalRecordHideSwitch from "./MedicalRecordHideSwitch";

export default function MedicalRecordsEvaluation() {
	const { medicalRecords } = state.useStateContext();
	const form = medicalRecords.form;
	const container = medicalRecords.getStateContainer();

	function removeFileUpload(field: keyof any, fieldUpload: keyof any) {
		form.setField(field as any, undefined);
		form.setField(fieldUpload as any, undefined);
	}

	return (
		<>
			{!container.types.includes(8) &&
				<div className={"medical-records-panel " + (container.types.includes(8) ? "print-hide" : "")}>
					<div className="medical-records-panel-header">
						<div className="medical-records-panel-page">
							<img width={120} src="/images/Group 655.svg" alt="group7" />
						</div>
						<div className="medical-records-panel-header-page ">
							{medicalRecords.getPageSize(6)}
						</div>
						<div>
							<div>Datum záznamu: {formatting.formatDate(form.getField("record_date").value)}</div>
							<div className="float-right">Datum hovoru: {formatting.formatDate(form.getField("call_date").value)}</div>
						</div>
					</div>
					<div className="text-center">
						<MedicalRecordHideSwitch type={8} />
						<h2 className="medical-records-panel-title font-weight-bold">Tele vyšetření - objektivní hodnocení pacienta</h2>
					</div>
					<div className="medical-records-panel-blue medical-records-panel-main d-flex p-3 mb-3">
						<div className="medical-records-panel-text-main medical-records-panel-blue-text d-inline">
							Airway
						</div>
						<div className="medical-records-panel-radio-container">
							<div className="medical-records-panel-radio">
								<FormsRadio option={true} form={form} field="airway" />
							</div>
						</div>
					</div>
					<div className="medical-records-panel-green medical-records-panel-main p-3 mb-3">
						<div className="d-flex">
							<div>
								<div className="medical-records-panel-text-main medical-records-panel-green-text">
									Breathing
								</div>
								<div className="mt-2">
									<FormTextArea form={form} field="breathing_evaluation_description" />
								</div>
							</div>
							<div className="medical-records-panel-radio-container">
								<div className="medical-records-panel-radio d-block">
									<FormsRadio option={true} form={form} field="eupnoe" />
								</div>
								<div className="medical-records-panel-radio d-block">
									<FormsRadio option={true} form={form} field="tratchea" />
								</div>
								<div className="medical-records-panel-radio d-block">
									<FormsRadio option={true} form={form} field="without_stridor" />
								</div>
								<div className="medical-records-panel-radio d-block">
									<FormsRadio option={true} form={form} field="without_involvement_of_auxiliary_respiratory_muscles" />
								</div>
								<div className="medical-records-panel-radio d-block">
									<FormsRadio option={true} form={form} field="speaks_in_sentence_without_shortnes_of_breath" />
								</div>
								<div className="medical-records-panel-radio d-block">
									<FormsRadio option={true} form={form} field="not_cyanosis" />
								</div>
								<div className="medical-records-panel-radio d-flex">
									{(form.getField("breathing_attachment_included").value || form.getField("breathing_attachment_included").value == undefined) &&
										<FormsRadio option={true} form={form} field="breathing_attachment_included" />
									}
									{form.getField("breathing_attachment_included").value == false &&
										<>
											<div style={{ width: 510 }} className="medical-records-evaluation-attachment-label">přiložený poslechový nález k hodnocení</div>
											<div className="medical-records-evaluation-attachment-item">
												<FormFileUpload simple={true} className="medical-records-evaluation-attachment d-inline-block" form={form} field="breathing_attachment" />
												<Button
													onClick={() => removeFileUpload("breathing_attachment_included", "breathing_attachment")}
													className="pt-2 pb-2 medical-records-remove mt-0 d-inline-block">
													Zrušit
												</Button>
											</div>
										</>
									}
								</div>
							</div>
						</div>
					</div>
					<div className="medical-records-panel-yellow medical-records-panel-main p-3 d-flex mb-3">
						<div className="medical-records-panel-text-main medical-records-panel-yellow-text d-inline">
							Circulation
						</div>
						<div className="medical-records-panel-radio-container">
							<div className="medical-records-panel-radio d-flex">
								{(form.getField("circulation_regularly").value || form.getField("circulation_regularly").value == undefined) &&
									<FormsRadio option={true} form={form} field="circulation_regularly" />
								}
								{form.getField("circulation_regularly").value == false &&
									<>
										<div style={{ width: 510 }} className="medical-records-evaluation-attachment-label">akce srdce pravidelná (korelace s EKG křivkou) - nelze hodnotit online</div>
										<div className="medical-records-evaluation-attachment-item">
											<FormFileUpload simple={true} className="medical-records-evaluation-attachment d-inline-block" form={form} field="circulation_attachment" />
											<Button
												onClick={() => removeFileUpload("circulation_regularly", "circulation_attachment")}
												className="pt-2 pb-2 medical-records-remove mt-0 d-inline-block">
												Zrušit
											</Button>
										</div>
									</>
								}
							</div>
						</div>
					</div>
					<div className="medical-records-panel-orange medical-records-panel-main p-3 mb-3">
						<div className="d-flex">
							<div>
								<div className="medical-records-panel-text-main medical-records-panel-orange-text">
									Disability
								</div>
								<div className="mt-2">
									<FormTextArea form={form} field="disability_description" />
								</div>
							</div>
							<div className="medical-records-panel-radio-container">
								<div className="medical-records-panel-radio d-block">
									<FormsRadio option={true} form={form} field="lucid" />
								</div>
								<div className="medical-records-panel-radio d-block">
									<FormsRadio option={true} form={form} field="time_and_place_oriented" />
								</div>
								<div className="medical-records-panel-radio d-block">
									<FormsRadio option={true} form={form} field="without_obvious_nurological_deficit" />
								</div>
								<div className="medical-records-panel-radio d-block">
									<FormsRadio option={true} form={form} field="limb_momentum_symmetrical" />
								</div>
								<div className="medical-records-panel-radio d-block">
									<FormsRadio option={true} form={form} field="facialis_normal" />
								</div>
								<div className="medical-records-panel-radio d-block">
									<FormsRadio option={true} form={form} field="isokorie" />
								</div>
							</div>
						</div>
					</div>
					<div className="medical-records-panel-purple medical-records-panel-main p-3">
						<div className="d-flex">
							<div>
								<div className="medical-records-panel-text-main medical-records-panel-purple-text">
									Exposure
								</div>
								<div className="mt-2">
									<FormTextArea form={form} field="exposure_description" />
								</div>
							</div>
							<div className="medical-records-panel-radio-container">
								<div className="medical-records-panel-radio d-block">
									<FormsRadio option={true} form={form} field="normal_skin_color" />
								</div>
								<div className="medical-records-panel-radio d-block">
									<FormsRadio option={true} form={form} field="not_pale" />
								</div>
								<div className="medical-records-panel-radio d-block">
									<FormsRadio option={true} form={form} field="not_icteric" />
								</div>
								<div className="medical-records-panel-radio d-block">
									<FormsRadio option={true} form={form} field="afebrile" />
								</div>
								<div className="medical-records-panel-radio d-flex">
									{(form.getField("normal_skin").value || form.getField("normal_skin").value == undefined) &&
										<FormsRadio option={true} form={form} field="normal_skin" />
									}
									{form.getField("normal_skin").value == false &&
										<>
											<div style={{ width: 510 }} className="medical-records-evaluation-attachment-label">kůže bez podezřelých naevů</div>
											<div className="medical-records-evaluation-attachment-item">
												<FormFileUpload simple={true} className="medical-records-evaluation-attachment d-inline-block" form={form} field="skin_attachment" />
												<Button
													onClick={() => removeFileUpload("normal_skin", "skin_attachment")}
													className="pt-2 pb-2 medical-records-remove mt-0 d-inline-block">
													Zrušit
												</Button>
											</div>
										</>
									}
								</div>
								<div className="medical-records-panel-radio d-block">
									<FormsRadio option={true} form={form} field="normal_hydration" />
								</div>
								<div className="medical-records-panel-radio d-block">
									<FormsRadio option={true} form={form} field="eyes_without_redness" />
								</div>
								<div className="medical-records-panel-radio d-block">
									<FormsRadio option={true} form={form} field="ears_nose_without_secretion" />
								</div>
								<div className="medical-records-panel-radio d-block">
									<FormsRadio option={true} form={form} field="normal_neck" />
								</div>
								{/* <div className="medical-records-panel-radio d-flex">
							{!form.getField("throat_calm").value &&
								<FormsRadio option={true} form={form} field="throat_calm" />
							}
							{form.getField("throat_calm").value &&
								<>
									<div style={{ width: 510 }} className="medical-records-evaluation-attachment-label">hrdlo v přehledném rozsahu klidné</div>
									<div className="medical-records-evaluation-attachment-item">
										<FormFileUpload simple={true} className="medical-records-evaluation-attachment d-inline-block" form={form} field="throat_attachment" />
										<Button
											onClick={() => removeFileUpload("throat_calm", "throat_attachment")}
											className="pt-2 pb-2 medical-records-remove mt-0 d-inline-block">
											Zrušit
										</Button>
									</div>
								</>
							}
						</div> */}
								<div className="medical-records-panel-radio d-block">
									<FormsRadio option={true} form={form} field="chest_symmetrical" />
								</div>
								<div className="medical-records-panel-radio d-block">
									<FormsRadio option={true} form={form} field="belly_soft" />
								</div>
								<div className="medical-records-panel-radio d-block">
									<FormsRadio option={true} form={form} field="limbs_without_defigurations" />
								</div>
								<div className="medical-records-panel-radio d-block">
									<FormsRadio option={true} form={form} field="lower_limbs_without_swelling" />
								</div>
								{/* <div className="medical-records-panel-radio d-block">
							<FormsRadio option={true} form={form} field="possible_PMKs_lead_to_clear_urine" />
						</div> */}
							</div>
						</div>
					</div>
					<div className="medical-records-panel-footer">
						{medicalRecords.getPageSize(6)}
					</div>
				</div>
			}
		</>
	);
}
