import * as React from "react";

export default function Accesibility() {
	return (
		<p className="accessibility">
			{/* <a title="Přejít k obsahu (Klávesová zkratka: Alt + 2)" accessKey="2" href="#main">Přejít k obsahu</a>
			<span className="hide">|</span>
			<a href="#menu-main">Přejít k hlavnímu menu</a>
			<span className="hide">|</span>
			<a href="#form-search">Přejít k vyhledávání</a> */}
		</p>
	);
}