/**
 * Modul domovské stránky
 */

import * as state from "../../../../../lib/state";
import * as api from "../../../../../lib/api";
import * as context from "../../../../../context";
import * as forms from "../../../../../lib/forms";

export interface Doctor {
	id: string;
	name: string;
	address: string;
	phone: string;
	email: string;
	last_visit: Date;
	id_image: string;
	appointment_date: Date;
	type: any;
	doctor: api.CodeBookItem;
}

export enum DoctorType {
	Practitioner = 1,
	Specialist = 2,
	Dentist = 3,
	Other = 4,
	All = 5,
}

/**
 * Metody stavu typu.
 */
export namespace DoctorType {
	export function title(v: DoctorType) {
		switch (v) {
			case DoctorType.Practitioner: return "Praktický lékař";
			case DoctorType.Specialist: return "Specialista";
			case DoctorType.Dentist: return "Zubař";
			case DoctorType.Other: return "Ostatní";
			case DoctorType.All: return "Vše";
		}
	}
}

/**
 * Stav modulu
 */
interface State {
}

export interface FormFields {
	items: Doctor[];
	index: number;
}

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	private stateContainer: state.StateContainer<State>;
	public form: forms.Form<FormFields>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({}, context);
		this.form = new forms.Form<FormFields>({
			fields: {
				items: {
					title: "",
					defaultValue: []
				},
				index: {
					title: "",
					defaultValue: 0
				}
			}
		}, context);
	}

	handleUpload = (value: api.CodeBookItem | undefined) => {
		if (value) {
			const records = this.form.getField("items").value as any;
			records.push({
				id: api.emptyGuid,
				name: value.name
			})
			this.form.setField("items", records);
		}
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			this.stateContainer,
			...this.form.getStateContainers()
		];
	}

	loadData = async () => {
		const doctors = await this.context.api.loadList<Doctor>("/user_doctors", { sort_fields_desc: ["sys_date_create" as any], sort_fields: ["sys_date_create" as any] }) as any;
		let data = doctors.data;
		this.form.setField("items", data);
	}

	/**
	 * Provede reset formuláře
	 */
	reset = async () => {
		await Promise.all([
			this.form.clearFields(),
		]);
	}
}