/**
 * Modul domovské stránky
 */

import * as state from "../../../../../lib/state";
import * as api from "../../../../../lib/api";
import * as formatting from "../../../../../lib/formatting";
import * as context from "../../../../../context";
import * as forms from "../../../../../lib/forms";
import * as apiHome from "../../api-home";
import * as apiFiles from "../../../../system/file/api-file";

/**
 * Stav modulu
 */
interface State {
}

export enum MyRecordType {
	Illness = 1,
	Operation = 2,
	Entry = 3,
	Allergy = 4,
	Vaccination = 5,
	DrDigital = 6
}

/**
 * Metody stavu typu.
 */
export namespace MyRecordType {
	export function title(v: MyRecordType) {
		switch (v) {
			case MyRecordType.Illness: return "Nemoc";
			case MyRecordType.Operation: return "Operace";
			case MyRecordType.Entry: return "Záznam";
			case MyRecordType.Allergy: return "Alergie";
			case MyRecordType.Vaccination: return "Očkování";
			case MyRecordType.DrDigital: return "Dr. Digital";
		}
	}
}

export interface FormFields {
	file?: api.CodeBookItem;
	foto?: api.CodeBookItem;
	video?: api.CodeBookItem;
	audio?: api.CodeBookItem;
	last_records: apiHome.Record[];
	index: number;
}

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	private stateContainer: state.StateContainer<State>;
	public form: forms.Form<FormFields>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({}, context);
		this.form = new forms.Form<FormFields>({
			fields: {
				foto: {
					title: "",
					defaultValue: undefined,
					onChange: (value) => this.handleUpload(value, apiFiles.FileType.Foto)
				},
				video: {
					title: "",
					defaultValue: undefined,
					onChange: (value) => this.handleUpload(value, apiFiles.FileType.Video)
				},
				audio: {
					title: "",
					defaultValue: undefined,
					onChange: (value) => this.handleUpload(value, apiFiles.FileType.Audio)
				},
				file: {
					title: "",
					defaultValue: undefined,
					onChange: (value) => this.handleUpload(value, apiFiles.FileType.File)
				},
				last_records: {
					title: "",
					defaultValue: []
				},
				index: {
					title: "",
					defaultValue: 0
				}
			}
		}, context);
	}

	handleUpload = (value: api.CodeBookItem | undefined, type: apiFiles.FileType) => {
		if (value) {
			const records = this.form.getField("last_records").value;
			records.push({
				id: api.emptyGuid,
				date: new Date(),
				name: value.name,
				type: type,
				id_state: 1
			})
			this.form.setField("last_records", records);
		}
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			this.stateContainer,
			...this.form.getStateContainers()
		];
	}

	loadData = async () => {
		const my_files = await this.context.api.loadList("/treatments", { sort_fields_desc: ["sys_date_create"], sort_fields: ["sys_date_create"] }) as any;
		let data = my_files.data as apiHome.Record[];
		// data = data.map((f, index) => {
		// 	const extension = f.name.split('.').pop()?.toLowerCase()!;
		// 	const extensionExists = formatting.fileExtensions.find(x => x.extensions.includes(extension))
		// 	f.type = extensionExists ? extensionExists.type : apiFiles.FileType.Documents;
		// 	return f;
		// });

		this.form.setField("last_records", data);
	}

	/**
	 * Provede reset formuláře
	 */
	reset = async () => {
		await Promise.all([
			this.form.clearFields(),
		]);
	}
}