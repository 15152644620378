import React, { useState, useEffect } from "react";
import { Popover, PopoverBody, PopoverHeader } from "reactstrap";
import Icon from "../../modules/system/icons/Icon";

interface Props {
	text: React.ReactNode;
	id: string;
	className?: string;
};

export default function Help(props: Props) {
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		window.addEventListener("click", function (e: any) {
			setIsOpen(false);
		});
	}, []);

	return (
		<>
			<span className="cursor-pointer" id={props.id}>
				<Icon className="color-primary ml-1" picture="Comment" />
			</span>
			<Popover
				target={props.id}
				placement="bottom"
				isOpen={isOpen}
				popperClassName={props.className ? props.className : ""}
				toggle={() => setIsOpen(!isOpen)}
				className={"header-navigation-popover"}
			>
				<PopoverHeader className="header-navigation-popover-header help-popover-header ">
					Nápověda
				</PopoverHeader>
				<PopoverBody style={{ padding: 11 }} className="header-navigation-popover-body-message help-popover-body">
					{props.text}
				</PopoverBody>
			</Popover>
		</>
	);
}