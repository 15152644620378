import * as api from "../../../../lib/api";
import * as context from "../../../../context";
import * as apiCodeList from "../../../system/code-list/api-code-list";

export interface Treatment extends api.EntityReadBase {
	id: string;
	name: string;
}

export class Api {
	treatments: api.EntityApi<Treatment, Treatment>;

	constructor(private context: context.StateContext) {
		this.treatments = new api.EntityApi<Treatment, Treatment>({
			api: context.api,
			path: "/all_treatments"
		});
	}

	loadData = () => {
		return this.context.api.loadList<apiCodeList.CodeList>("/all_treatments", { per_page: Number.MAX_SAFE_INTEGER });
	}

	remove = (id: string) => {
		return this.context.api.del<apiCodeList.CodeList>("/treatments/" + id);
	}

	restore = (id: string) => {
		return this.context.api.del<apiCodeList.CodeList>("/treatments/restore/" + id);
	}
}