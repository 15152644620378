/**
 * Obrázek, který umožňuje editaci v samostatném dialogu
 */
import React, { useState } from "react";
import { Button } from "reactstrap";
import * as imagesModel from "./images";
import * as common from "../../../lib/common";
import * as state from "../../../lib/state";
import * as api from "../../../lib/api";

import Image, { ImageProps } from "./Image";
import Icon from "../icons/Icon";

export type ImagePreviewProps = Omit<ImageProps, "id">;

export type ImageEditableProps = {
	id: string;
	onChange?: (imageId: string) => void;
	previewImageProps?: ImagePreviewProps;
	aspectRatio?: imagesModel.AspectRatio;
	title?: string;
	showBtn: boolean;
	defaultSrc?: string;
	className?: string;
	classNameIcon?: string;
	onSave?: (field?: any, value?: string) => void;
};


export default function ImageEditableImageEditable(props: ImageEditableProps) {
	const [previewHash, setPreviewHash] = useState(common.unique());
	const { images } = state.useStateContext();

	async function handleEditClick() {
		if (!props.showBtn) return;
		const imageId = await images.letUserEditImage(props.id == null ? api.emptyGuid : props.id, props.aspectRatio);
		if (imageId === undefined) return;

		setPreviewHash(common.unique());
		if (props.onChange) {
			await props.onChange(imageId);
			if (props.onSave) {
				props.onSave(imageId);
			}
		}
	}

	return (
		<div className="image-editable" onClick={handleEditClick}>
			<Image
				className={"image-editable-image " + (props.className == undefined ? "" : props.className)}
				{...props.previewImageProps}
				id={props.id}
				showBtn={props.showBtn}
				classNameIcon={props.classNameIcon}
				defaultSrc={props.defaultSrc}
				title={props.title}
				hash={previewHash}
			/>
		</div>
	);
}