/*
 * Komponenta radio tlačítka.
 */
import * as React from "react";
import CheckboxOriginal, { CheckBoxRadioProps } from "./CheckboxOriginal";

export type RadioProps = Omit<CheckBoxRadioProps, "type">;

export default function Radio(props: RadioProps) {
	return (
		<CheckboxOriginal type="radio" {...props} />
	);
}