/*
 * Komponenta checkboxu.
 */
import * as React from "react";

export default function Switch(props: any) {
	function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
		if (props.onCheckedChanged) {
			props.onCheckedChanged(e.target.checked);
		}
	}

	return (
		<>
			{props.title &&
				<div className="switch-text d-inline">{props.title}</div>
			}
			<label className={"switch ml-2 " + (props.className || "")}>
				<input
					type="checkbox"
					checked={props.value ? true : false}
					disabled={props.readOnly}
					onChange={handleChange}>
				</input>
				<div className="slider round"></div>
			</label>
		</>
	);
}