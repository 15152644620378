/**
 * Seznam záznamů
 */
import React from "react";
import * as state from "../../../../lib/state";
import Page from "../../../../components/templates/Page";
import List from "../../../../modules/system/lists/List";
import MyRequestAction from "../../../../components/controls/MyRequestAction";
import Checkbox from "../../../../components/controls/Checkbox";

interface Props {
	id: string;
}

function MedicalRecordsListFilePage(id: Props) {
	const { medicalRecordsListFile } = state.useStateContext();
	const { standardDialogs } = state.useStateContext();
	const { authorization } = state.useStateContext();

	function actions(item: any) {
		const items = [];
		if (item.deleted) {
			items.push({
				title: "Obnovit záznam",
				action: async (item: any) => {
					await standardDialogs.openYesNoDialog("Opravdu chcete obnovit záznam?") === "yes"
						? medicalRecordsListFile.restore(item.id)
						: undefined
				},
			})
		};
		if (!item.deleted) {
			items.push({
				title: "Smazat záznam",
				action: async (item: any) => {
					await standardDialogs.openYesNoDialog("Opravdu chcete smazat záznam?") === "yes"
						? medicalRecordsListFile.remove(item.id)
						: undefined
				},
			})
		};
		return items as any;
	};

	async function handleDeleted() {
		await medicalRecordsListFile.stateContainer.merge(() => ({ deleted: !medicalRecordsListFile.getDeleted() }));
		medicalRecordsListFile.list.load();
	}

	async function handleClear() {
		await medicalRecordsListFile.stateContainer.merge(() => ({ deleted: true }));
		await medicalRecordsListFile.list.clearFilter();
	}

	return (
		<Page title="Seznam souborů" description="">
			{authorization.isAdminOrOperator() &&
				<div className="page-content-container users-list-page">
					<div className="text-light ml-3 mb-4">
						<div className="homepage-responsive-title d-inline">
							Seznam souborů
						</div>
					</div>
					<div className="ml-3 mr-3">
						<div className="form-filter u-mb-md">
							<div id="records" className="form-filter__inner row">
								<div className="col-md-5 col-xs-12 mb-3">
									<label htmlFor="filter-search" className="inp-label u-vhide">
										Zadejte jméno
									</label>
									<span className="inp-fix">
										<input
											type="text"
											id="inp-filter-search"
											placeholder={medicalRecordsListFile.list.options.quickFilterPlaceholder}
											className="inp-text inp-text--filter"
											value={medicalRecordsListFile.list.getFilterFulltextPhrase()}
											onChange={(e) => medicalRecordsListFile.list.setFilterFulltextPhrase(e.target.value)}
										/>
									</span>
								</div>
								<div className="col-md-3 col-xs-12">
									<Checkbox className="mt-0  inp-text--filter" checked={medicalRecordsListFile.getDeleted()} title={"Smazáno"} onCheckedChanged={handleDeleted} />
								</div>
								<div className="col-xs-4 col-12 col-md-auto u-mb-md ml-md-auto">
									<div className="d-inline mr-2">
										<button type="submit" className="btn btn--filter" onClick={medicalRecordsListFile.list.loadWithResetPage}>
											<span className="btn__text">
												Vyhledat
											</span>
										</button>
									</div>
									<div className="d-inline">
										<button type="submit" className="btn btn--filter" onClick={handleClear}>
											<span className="btn__text">
												Reset
											</span>
										</button>
									</div>
								</div>
							</div>
						</div>
						<List
							itemNode={(item, field) => {
								switch (field) {
									case "deleted": return item.deleted ? "Ano" : "Ne";
									case "action" as any: return <MyRequestAction className="ml-2 user-list-page-action-sync" id="treatments" item={item} actions={actions(item)} />;
									default: return undefined;
								}
							}}
							simpleTable list={medicalRecordsListFile.list} />
					</div>
				</div>
			}
		</Page >
	);
}

export default state.bindContainers(
	MedicalRecordsListFilePage,
	context => context.medicalRecordsListFile
);