/**
 * Modul pro přihlášení a odhlášení uživatele
 */

import * as state from "../../../lib/state";
import * as common from "../../../lib/common";
import storage from "store";
import * as notification from "../../../lib/notification";
import * as navigation from "../../../lib/navigation";
import * as context from "../../../context";

import * as apiAuth from "./api-authorization";
import * as apiUsers from "../../pages/administration/users/api-users";

/**
 * Stav modulu
 */
export interface State {
	userProfile?: apiAuth.Profile;
	userStatistics?: apiAuth.Statistics;
	loadingUserprofile: boolean;
	justLoggingIn: boolean;
	userLoggedIn: boolean;
	resetToken: string;
}

export enum Role {
	User = 1,
	PaidUser = 2,
	Admin = 3,
	Operator = 4
}

/**
 * Pomocná metoda zajišťující typovou obálku argumentu.
 */
function createPartialState(state: Partial<State>) {

	return state;
}

export class Model {
	public stateContainer: state.StateContainer<State>;

	constructor(private context: context.StateContext) {
		const userLoggedIn = this.getAuthToken() !== "";
		this.stateContainer = new state.StateContainer<State>({
			userProfile: undefined,
			userLoggedIn: userLoggedIn,
			loadingUserprofile: false,
			justLoggingIn: false,
			resetToken: "",
			userStatistics: undefined
		}, context);

		context.api.setBindings(
			this.getAuthToken,
			this.rejectAuthToken
		);

		if (this.userLoggedIn()) {
			common.ignorePromises(this.loadUserProfile());
		}
	}

	getResetToken = () => {
		return this.stateContainer.get().resetToken;
	}

	setResetToken = (resetToken: string) => {
		this.stateContainer.merge(() => ({
			resetToken: resetToken
		}));
	}

	/**
	 * Přihlásí uživatele pomocí jména a hesla
	 */
	login = async (userName: string, password: string) => {
		const credentials: apiAuth.Credentials = {
			username: userName,
			password: password
		};

		const authresponse = await common.withIndication({
			exec: () => this.context.apiAuth.loginWithCredentials(credentials),
			start: () => this.stateContainer.merge(() => createPartialState({ justLoggingIn: true })),
			finish: () => this.stateContainer.merge(() => createPartialState({ justLoggingIn: false }))
		});

		if (authresponse.access_token.length > 0) {
			storage.set("authToken", authresponse.access_token);


			await this.stateContainer.merge(() => ({
				userLoggedIn: true,
			}));
		}

		common.ignorePromises(this.loadUserProfile());
		navigation.to("/");
		return authresponse;
	}

	/**
	 * Odhlásí uživatele
	 */
	logout = async (error?: boolean) => {
		const delegateIdUser = storage.get("delegateIdUser");

		if (delegateIdUser) {
			const profile = this.getUserProfile();
			await this.setDelegatedUser(delegateIdUser, true, "/administrace/uzivatele");
			notification.successMessage("Náhled uživatele " + profile?.profile.full_name + " byl ukončen.");
		}
		else {
			await this.context.apiAuth.logout();
			storage.remove("authToken");
			storage.remove("delegateIdUser");

			await this.stateContainer.merge(() => createPartialState({
				userProfile: undefined,
				userLoggedIn: false,
			}));
			document.location.href = "/";
			if (!error) {
				notification.successMessage("Byli jste úspěšně odhlášeni.");
			}
		}
	}

	/**
	 * Vrací true, pokud je uživatel nalogován, avšak nemusí být ještě načten profil
	 */
	userLoggedIn = () => {
		return this.getAuthToken().length > 0;
	}

	/**
	 * Načte profil přihlášeného uživatele
	 */
	loadUserProfile = async (): Promise<apiAuth.Profile> => {
		const user = await common.withIndication({
			exec: () => this.context.apiAuth.loadUserProfile(),
			start: () => this.stateContainer.merge(() => createPartialState({ loadingUserprofile: true })),
			finish: () => this.stateContainer.merge(() => createPartialState({ loadingUserprofile: false }))
		});
		user.widgets = [
			{ id: 1, name: "last-measurement" },
			{ id: 2, name: "last-records" },
			{ id: 4, name: "calendar" },
			{ id: 5, name: "pilulkoid" },
			{ id: 6, name: "p-card-preview" },
			{ id: 7, name: "content-showcase" },
		];

		await this.stateContainer.merge(() => createPartialState({
			userProfile: user,
		}));
		return user;
	}

	loadStatistics = async () => {
		const statistics = await this.context.api.loadList("/users/statistics", {});
		await this.stateContainer.merge(() => createPartialState({
			userStatistics: statistics.data[0] as any,
		}));
	}

	getStatistics = () => {
		return this.stateContainer.get().userStatistics;
	}

	getStatisticsFilled = () => {
		const statistics = this.getStatistics();
		let filled = 0;
		const items = [];
		if (statistics?.ab_filled) {
			filled += 10;
		}
		else {
			items.push("Není vyplněna krevní skupina.");
		}
		if (statistics?.address_filled) {
			filled += 10;
		}
		else {
			items.push("Není vyplněna adresa.");
		}
		if (statistics?.birthdate_filled) {
			filled += 10;
		}
		else {
			items.push("Není vyplněno datum narození.");
		}
		if (statistics?.crisis_contact_phone_filled) {
			filled += 10;
		}
		else {
			items.push("Není vyplněn krizový kontakt a krizový telefon.");
		}
		if (statistics?.fullname_filled) {
			filled += 10;
		}
		else {
			items.push("Není vyplněno jméno a příjmení.");
		}
		if (statistics?.height_weight_filled) {
			filled += 10;
		}
		else {
			items.push("Není vyplněna výška a váha.");
		}
		if (statistics?.insurance_card_filled) {
			filled += 10;
		}
		else {
			items.push("Není vyplněna kartička pojištěnce.");
		}
		if (statistics?.rc_filled) {
			filled += 10;
		}
		else {
			items.push("Není vyplněno rodné číslo.");
		}
		if (statistics?.type_filled) {
			filled += 10;
		}
		else {
			items.push("Není vyplněno rodinné zázemí.");
		}
		if (statistics?.doctor_filled) {
			filled += 10;
		}
		else {
			items.push("Není vyplněn žádný lékař.");
		}

		return { filled: filled, items: items };
	}

	setUser = async (user: apiAuth.Profile) => {
		await this.stateContainer.merge(() => createPartialState({
			userProfile: user,
		}));
	}

	/**
	 * Vrací info o přihlášeném uživateli nebo undefined, není-li uživatelský
	 * profil dosud načten.
	 */
	getUserProfile = (): apiAuth.Profile | undefined => {
		return this.stateContainer.get().userProfile;
	}

	isAdminOrOperator = () => {
		const profile = this.stateContainer.get().userProfile?.profile;
		return profile?.role.id == apiUsers.Role.Admin || profile?.role.id == apiUsers.Role.Operator;
	}

	isAdmin = () => {
		const profile = this.stateContainer.get().userProfile?.profile;
		return profile?.role.id == apiUsers.Role.Admin;
	}

	getAuthToken = () => {
		const authToken = storage.get("authToken");
		return authToken || "";
	}

	loadingUserProfile = () => {
		return this.stateContainer.get().loadingUserprofile;
	}

	justLoggingIn = () => {
		return this.stateContainer.get().justLoggingIn;
	}

	rejectAuthToken = async () => {
		storage.remove("authToken");
		storage.remove("delegateIdUser");

		await this.stateContainer.merge(() => createPartialState({
			userProfile: undefined,
			userLoggedIn: false,
		}));
	}

	loadData = async () => {
		if (this.userLoggedIn()) {
			await this.loadUserProfile();
		}
	}

	/**
 * Nastaví jméno uživatele
 */
	setDelegatedUser = async (id: string, cancel?: boolean, url?: string) => {
		const result = await this.context.api.post("/users/" + id + "/impersonation", {}) as any;
		if (result) {
			storage.set("authToken", result.access_token);
			if (!cancel) {
				const myProfile = this.getUserProfile();
				storage.set("delegateIdUser", myProfile?.id);
			}
			else {
				storage.remove("delegateIdUser");
			}
			document.location.href = url ? url : "/recepce";
		}
	}
}