/**
 * Modul domovské stránky
 */

import * as state from "../../../../../lib/state";
import * as api from "../../../../../lib/api";
import * as context from "../../../../../context";
import * as forms from "../../../../../lib/forms";
import * as apiHome from "../../api-home";

/**
 * Stav modulu
 */
interface State {
}

export interface FormFields {
	items: apiHome.Pilulkoid[];
}

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	private stateContainer: state.StateContainer<State>;
	public form: forms.Form<FormFields>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({}, context);
		this.form = new forms.Form<FormFields>({
			fields: {
				items: {
					title: "",
					defaultValue: [],
				},
			}
		}, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			this.stateContainer,
			...this.form.getStateContainers()
		];
	}

	loadData = async () => {
		const items = await this.context.api.loadList<apiHome.Pilulkoid>("/pilulkoids", { sort_fields_desc: ["sys_date_create" as any], sort_fields: ["sys_date_create" as any] }) as any;
		this.form.setField("items", items.data);
	}

	/**
	 * Provede reset formuláře
	 */
	reset = async () => {
		await Promise.all([
			this.form.clearFields(),
		]);
	}
}