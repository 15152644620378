/**
 * Stránka GeneralGraph
 */
import React, { useState, useEffect } from "react";
import * as apiLib from "../../../lib/api";
import * as formatting from "../../../lib/formatting";
import * as state from "../../../lib/state";
import * as medicalRecords from "../../pages/administration/medical_records/medical-records";
import { Bar, Line, LineChart as LineChartGraph, LabelList, ResponsiveContainer, XAxis, YAxis, Tooltip } from "recharts";

interface Props {
	date_from: Date;
	date_to: Date;
	type: apiLib.CodeBookItemWithNumberId;
	setLoading: (value: boolean) => void;
	setData: (value: medicalRecords.Graph[]) => void;
	secondType?: apiLib.CodeBookItemWithNumberId;
	dateType?: number;
	loading: boolean;
	measurementType: number;
	width?: string;
	data?: medicalRecords.MeasurementData[];
}

export default function LineChart(props: Props) {
	const [data, setData] = useState([] as medicalRecords.Graph[]);
	const [graphData, setGraphData] = useState([] as medicalRecords.Graph[]);
	const { api } = state.useStateContext();

	useEffect(() => {
		if (props.type.id) {
			getData(graphData);
		}
	}, [props.type.id]);

	async function getGraphData() {
		props.setLoading(props.data ? false : true);
		let graphData = props.data ? props.data.filter(x => x.type == props.measurementType) : await api.post("/users/measurements", {
			date_from: props.date_from,
			date_to: props.date_to,
			type: props.measurementType
		}) as medicalRecords.Graph[];

		if (props.secondType) {
			const graphData2 = props.data ? props.data.filter(x => x.type == props.secondType!.id) : await api.post("/users/measurements", {
				date_from: props.date_from,
				date_to: props.date_to,
				type: props.secondType.id
			}) as medicalRecords.Graph[];

			graphData = graphData.map((x, index) => ({
				...x, value2: graphData2[index]?.value
			}));
		}

		graphData = graphData.sort((a: any, b: any) => (formatting.parseDateStringFormat(a.data, "D.M.YYYY H:mm") as any) - (formatting.parseDateStringFormat(b.data, "D.M.YYYY H:mm") as any))

		await setGraphData(graphData);
		await getData(graphData);
		props.setLoading(false);
	}

	useEffect(() => {
		getGraphData();
	}, [props.measurementType, props.date_from]);

	async function getData(graphData: medicalRecords.Graph[]) {
		let result = [];
		if (!props.dateType || props.dateType == 2) {
			result = formatting.createAverageMonthlyGraphData(graphData, props.dateType == 2) as any
		}
		if (props.dateType == 1) {
			result = formatting.createAverageYearGraphData(graphData) as any
		}
		if (props.dateType == 3) {
			result = formatting.createLastWeeklyGraphData(graphData, 7) as any
		}
		if (props.dateType == 4) {
			result = formatting.createLastWeeklyGraphData(graphData, 1) as any
		}

		result = result.sort((a: any, b: any) => (formatting.parseDateStringFormat(a.data, "D.M.YYYY H:mm") as any) - (formatting.parseDateStringFormat(b.data, "D.M.YYYY H:mm") as any));

		await setData(result);
		await props.setData(result);
	}

	const CustomTooltip = ({ active, payload, label }: any) => {
		if (active && payload && payload.length) {
			return (
				<>
					{payload[0].payload.value > 0 &&
						<div className="custom-tooltip">
							<div>{props.dateType != 1 ? "Datum měření" : "Datum posledního měření"}: <span className="general-graph-amount ">{payload[0].payload.data}</span></div>
							<div>{props.dateType != 1 ? "Hodnota" : "Průměr měření:"} <span className={"general-graph-amount "}>{payload[0].payload.value}</span></div>
							{payload[0].payload.value2 != undefined && !isNaN(payload[0].payload.value2) &&
								<div>{props.dateType != 1 ? "Hodnota2" : "Průměr měření:"} <span className={"general-graph-amount "}>{payload[0].payload.value2}</span></div>
							}
						</div>
					}
				</>
			);
		}

		return null;
	};

	let lineIndex = 0;

	const renderCustomizedLabel = (props: any) => {
		const {
			x, y, width, height, value,
		} = props;
		const index = lineIndex;
		lineIndex = + 1;

		return (
			<text x={x} y={y + 5} offset="5" className="recharts-text recharts-label" textAnchor="middle">
				<tspan x={index == 0 && data.length > 1 ? x + 14 : x} dy="0.71em">{value}</tspan>
			</text>
		);
	};

	return (
		<>
			{data.length > 0 && !props.loading &&
				<ResponsiveContainer width={props.width ? props.width : "105%"}>
					<LineChartGraph
						data={data}
						margin={{
							top: 5,
							right: 30,
							left: 20,
							bottom: 5,
						}}
					>
						<XAxis dataKey="dateNumber" />
						<YAxis domain={['dataMin', 'dataMax']} />
						<Tooltip content={<CustomTooltip />} />
						<Line isAnimationActive={false} type="monotone" dataKey="value" stroke="#8884d8">
							<LabelList content={renderCustomizedLabel} dataKey="value" position="bottom" />
						</Line>
						{props.secondType &&
							<Line isAnimationActive={false} type="monotone" dataKey="value2" stroke="#82ca9d">
								<LabelList content={renderCustomizedLabel} dataKey="value2" position="bottom" />
							</Line>
						}
					</LineChartGraph>
				</ResponsiveContainer>
			}
		</>
	);
} 