/**
 * Přidání souboru
 */
import Button from "../../../../components/controls/Button";
import * as React from "react";
import * as state from "../../../../lib/state";

import Dialog from "../../../shared/dialogs/Dialog";
import FormsInput from "../../../../components/controls/Forms/FormsInput";
import UsersMessage from "./UsersMessage";

function UsersMessageDialog() {
	const { usersMessageDialog } = state.useStateContext();
	const dialog = usersMessageDialog.dialog;

	return (
		<Dialog classNameModal="user-message-dialog" dialog={dialog}>
			<UsersMessage />

			<div className="mt-4 text-center">
				<Button onClick={e => usersMessageDialog.save()} className="pt-2 pb-2 float-right">
					<strong>Odeslat</strong>
				</Button>
			</div>
		</Dialog >
	);
}

export default state.bindContainers(
	UsersMessageDialog,
	context => context.usersMessageDialog
);