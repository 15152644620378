import * as dialogs from "../../../../shared/dialogs/dialogs";
import * as context from "../../../../../context";
import * as forms from "../../../../../lib/forms";
import * as api from "../../../../../lib/api";
import * as state from "../../../../../lib/state";
import * as formatting from "../../../../../lib/formatting";
import * as notification from "../../../../../lib/notification";

export interface FormFields {
	id: string;
	description: string;
	date: string;
	id_pilulkoid: string;
	id_pain_monitor: string;
	id_doctor: string;
	id_treatment: string;
	sys_date_create: Date;
}

export interface State {
	disabled: boolean;
}

export class Model {
	public stateContainer: state.StateContainer<State>;
	public dialog: dialogs.Dialog<undefined, State>;
	public form: forms.Form<FormFields>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({ disabled: false }, context);
		this.dialog = new dialogs.Dialog<undefined, State>({
			defaultCustomFields: {
				disabled: false
			}
		}, context);
		this.form = new forms.Form<FormFields>({
			fields: {
				id: {
					title: "",
					defaultValue: api.emptyGuid
				},
				id_doctor: {
					defaultValue: "",
					title: ""
				},
				id_pain_monitor: {
					defaultValue: "",
					title: ""
				},
				id_treatment: {
					defaultValue: "",
					title: ""
				},
				id_pilulkoid: {
					defaultValue: "",
					title: ""
				},
				description: {
					title: "Popis",
					defaultValue: "",
					readOnly: (form) => this.stateContainer.get().disabled,
					required: (form) => true,
					validate: (value) => value.length == 0 ? "Vyplňte popis" : ""
				},
				date: {
					title: "Datum",
					defaultValue: "",
					readOnly: (form) => this.stateContainer.get().disabled,
					required: (form) => true,
					validate: (value) => value.length == 0 ? "Vyplňte datum" : ""
				},
				sys_date_create: {
					defaultValue: new Date(),
					title: ""
				},
			}
		}, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			this.stateContainer,
			...this.form.getStateContainers(),
			...this.dialog.getStateContainers()
		];
	}

	openDialog = async (disabled?: boolean, values?: FormFields) => {
		this.form.clearFields();
		await this.stateContainer.merge(() => ({ disabled: disabled ? disabled : undefined }));
		if (values) {
			values.date = typeof values.date == "string" ? values.date : formatting.formatDateForInputDateTime(values.date)
			this.form.setFields(values);
		}
		return this.dialog.open();
	}

	save = async () => {
		await this.form.validate();
		if (this.form.isValid()) {
			const result = await this.context.api.put("/calendars/" + this.form.getField("id").value, this.form.getFields());
			if (result) {
				notification.successMessage("Událost byla uložena.");
				this.context.calendarPage.loadData();
				return this.dialog.close();
			}
		}
		else {
			await notification.errorMessage("Formulář obsahuje neúplné informace nebo chyby. Opravte prosím červeně vyznačené položky a poté akci opakujte.");
		}
	}
}