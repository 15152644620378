/*
 * Komponenta checkboxu a radio tlačítka.
 */
import * as React from "react";

export interface CheckBoxRadioProps {
	className?: string;
	title?: React.ReactNode | string;
	checked?: boolean;
	onCheckedChanged?: (value: boolean) => void;
	small?: boolean;
	readOnly?: boolean;
}

export default function CheckboxRadio(props: CheckBoxRadioProps) {
	function handleChange(value: boolean) {
		if (props.onCheckedChanged) {
			props.onCheckedChanged(value);
		}
	}
	return (
		<>
			<div style={{ width: 150 }} className="d-inline radio-container">
				<div className="d-inline">
					<input
						type="radio"
						className="checkbox-radio-new"
						disabled={props.readOnly}
						onChange={() => handleChange(true)}
						checked={props.checked == true}>
					</input>
					<span className="ml-2 mr-3 checkbox-radio-new-radio-text">Ano</span>
				</div>
				<div className="d-inline">
					<input
						type="radio"
						className="checkbox-radio-new"
						disabled={props.readOnly}
						onChange={() => handleChange(false)}
						checked={props.checked == false}>
					</input>
					<span className="ml-2 checkbox-radio-new-radio-text">Ne</span>
				</div>
			</div>
		</>
	);
}