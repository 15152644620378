/**
 * Modul domovské stránky
 */

import * as state from "../../../../../lib/state";
import * as api from "../../../../../lib/api";
import * as context from "../../../../../context";
import * as forms from "../../../../../lib/forms";
import * as formatting from "../../../../../lib/formatting";
import * as apiAuth from "../../../../system/authorization/api-authorization";
import * as apiUsers from "../../../administration/users/api-users";

/**
 * Stav modulu
 */
interface State {
	user: apiUsers.User;
}

export enum UserType {
	Apartment = 1,
	House = 2,
	NursingHome = 3,
	RetirementHome = 4
}

/**
 * Metody stavu typu.
 */
export namespace UserType {
	export function title(v: UserType) {
		switch (v) {
			case UserType.Apartment: return "Byt";
			case UserType.House: return "Dům";
			case UserType.NursingHome: return "Dům s pečovatelskou službou";
			case UserType.RetirementHome: return "Domov pro seniory";
			default: return "";
		}
	}
}

export interface FormFields {
	id_insurance_card: string;
	id_covid_certificate: string;
	showDoctors?: boolean;
	showVaccination?: boolean;
	showCard: boolean;
	full_name: string;
	first_name: string;
	last_name: string;
	birthdate: string;
	height: string;
	weight: string;
	ab: string;
	personal_identification_number: string;
	personal_identification_number_sufix: string;
	crisis_name: string;
	crisis_phone: string;
	id_dms_file_image: string;
	no_long_term_medication: boolean;
	no_long_term_illness: boolean;
	type: api.CodeBookItemWithNumberId | null;
	address: string;
	email_receiver: boolean;
}

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	private stateContainer: state.StateContainer<State>;
	public form: forms.Form<FormFields>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({ user: {} as any }, context);
		this.form = new forms.Form<FormFields>({
			fields: {
				showCard: {
					defaultValue: false,
					title: ""
				},
				address: {
					defaultValue: "",
					title: ""
				},
				showDoctors: {
					defaultValue: undefined,
					title: ""
				},
				no_long_term_illness: {
					defaultValue: false,
					title: ""
				},
				no_long_term_medication: {
					defaultValue: false,
					title: ""
				},
				type: {
					defaultValue: null,
					title: ""
				},
				showVaccination: {
					defaultValue: undefined,
					title: ""
				},
				id_insurance_card: {
					title: "",
					defaultValue: api.emptyGuid
				},
				id_covid_certificate: {
					title: "",
					defaultValue: api.emptyGuid
				},
				full_name: {
					defaultValue: "",
					title: ""
				},
				first_name: {
					defaultValue: "",
					title: "Jméno: "
				},
				last_name: {
					defaultValue: "",
					title: "Příjmení: "
				},
				birthdate: {
					defaultValue: null as any,
					title: ""
				},
				height: {
					defaultValue: "",
					title: ""
				},
				weight: {
					defaultValue: "",
					title: ""
				},
				ab: {
					defaultValue: "",
					title: ""
				},
				personal_identification_number: {
					defaultValue: "",
					title: ""
				},
				personal_identification_number_sufix: {
					defaultValue: "",
					title: ""
				},
				crisis_phone: {
					defaultValue: "",
					title: ""
				},
				crisis_name: {
					defaultValue: "",
					title: ""
				},
				id_dms_file_image: {
					defaultValue: "",
					title: "",
				},
				email_receiver: {
					defaultValue: false,
					title: ""
				}
			}
		}, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			this.stateContainer,
			...this.form.getStateContainers()
		];
	}

	loadData = async () => {
		if (!this.context.authorization.getUserProfile()) {
			await this.context.authorization.loadUserProfile();
		}
		const auth = this.context.authorization.getUserProfile() as any;
		const profile = await this.context.apiUsers.loadDetail(auth.id) as any;
		await this.stateContainer.merge(() => ({
			user: profile
		}));
		if (profile.type) {
			profile.type = { id: profile.type, name: UserType.title(profile.type) };
		}
		this.setUser(profile);
	}

	setUser = async (user: apiUsers.User) => {
		this.form.setField("full_name", user.full_name);
		this.form.setField("address", user.address);
		this.form.setField("type", user.type);
		this.form.setField("first_name", user.first_name);
		this.form.setField("last_name", user.last_name);
		this.form.setField("birthdate", formatting.formatDate(user.birthdate, { format: "iso" }));
		this.form.setField("height", user.height as any);
		this.form.setField("weight", user.weight as any);
		this.form.setField("ab", user.ab);
		this.form.setField("id_dms_file_image", user.id_dms_file_image);
		this.form.setField("crisis_phone", user.crisis_phone);
		this.form.setField("crisis_name", user.crisis_name);
		this.form.setField("id_covid_certificate", user.id_covid_certificate);
		this.form.setField("id_insurance_card", user.id_insurance_card);
		this.form.setField("personal_identification_number", user.personal_identification_number);
		this.form.setField("personal_identification_number_sufix", user.personal_identification_number_sufix);
		this.form.setField("no_long_term_illness", user.no_long_term_illness);
		this.form.setField("no_long_term_medication", user.no_long_term_medication);
		this.form.setField("email_receiver", user.email_receiver);
	}

	saveUser = async (field: keyof apiUsers.User, value: any) => {
		const user = this.stateContainer.get().user as any;
		user[field] = value;
		this.setUser(user);
		let item = Object.assign({}, user);
		item["role_id"] = user.role.id;
		item["role"] = undefined;
		item.type = item.type == null ? null : item.type.id as any
		if (field == "id_dms_file_image" && value == api.emptyGuid) {
			item["id_dms_file_image"] = null;
		}
		await this.context.apiUsers.saveUser(item.id, item);
	}

	/**
	 * Provede reset formuláře
	 */
	reset = async () => {
		await Promise.all([
			this.form.clearFields(),
		]);
	}
}