import * as api from "../../../../lib/api";
import * as context from "../../../../context";
import * as apiCodeList from "../../../system/code-list/api-code-list";

interface MedicalState {
	id_state: number;
	id: string;
}

export enum Role {
	User = 1,
	PaidUser = 2,
	Admin = 3,
	Operator = 4
}

export interface UserCategory {
	id: string;
	id_user: string;
	id_category: string;
	name: string;
}

export interface Category {
	id: string;
	name: string;
	color: string;
	category_id: string;
	checked?: boolean;
}

export interface User extends api.EntityReadBase {
	id: string;
	emails: api.CodeBookItem[];
	full_name: string;
	first_name: string;
	last_name: string;
	id_dms_file_image: string;
	ab: string;
	adi_life_uid: number | null;
	email: string;
	phone: string;
	crisis_phone: string;
	crisis_name: string;
	height: string;
	weight: string;
	sync_to_adi: boolean;
	personal_identification_number: string;
	personal_identification_number_sufix: string;
	birthdate?: Date;
	image: api.CodeBookItem;
	id_insurance_card: string;
	id_covid_certificate: string;
	role: api.CodeBookItemWithNumberId;
	number_of_actions: number;
	no_long_term_medication: boolean;
	no_long_term_illness: boolean;
	type: api.CodeBookItemWithNumberId | null;
	address: string;
	medical_record_state: MedicalState;
	email_receiver: boolean;
	sys_date_create?: Date;
	notifications?: [{ id: string, text: string, sys_date_create: Date }];
	user_categories: UserCategory[];
	user_allowed_categories: UserCategory[];
}

export class Api {
	users: api.EntityApi<User, User>;
	usersReceivers: api.EntityApi<User, User>;

	constructor(private context: context.StateContext) {
		this.users = new api.EntityApi<User, User>({
			api: context.api,
			path: "/users/list"
		});
		this.usersReceivers = new api.EntityApi<User, User>({
			api: context.api,
			path: "/users/receivers"
		});
	}

	loadData = () => {
		return this.context.api.loadList<apiCodeList.CodeList>("/users/list", {
			per_page: Number.MAX_SAFE_INTEGER, filter: [
				api.qpOr([
					api.qp("deleted" as any, "=", false),
				])
			]
		});
	}

	loadDataReceivers = () => {
		return this.context.api.loadList<apiCodeList.CodeList>("/users/receivers", {
			per_page: Number.MAX_SAFE_INTEGER, filter: [
				api.qpOr([
					api.qp("deleted" as any, "=", false),
				])
			]
		});
	}

	remove = (id: string) => {
		return this.context.api.del<apiCodeList.CodeList>("/users/" + id);
	}

	sync = (id: string) => {
		return this.context.api.post("/users/" + id + "/sync_to_adi", {});
	}

	loadDetail(id: string) {
		return this.context.api.get<User>("/users/" + id);
	}

	saveUser(id: string, user: User) {
		return this.context.api.put<User, User>("/users/" + id, user);
	}
}