/**
 * Seznam uživatelů
 */

import * as state from "../../../../lib/state";
import * as context from "../../../../context";
import * as api from "../../../../lib/api";
import * as formatting from "../../../../lib/formatting";
import * as lists from "../../../system/lists/lists";
import * as apiUsers from "./api-users";
import * as notification from "../../../../lib/notification";

/**
 * Stav modulu
 */
export interface State {
	is_action: boolean;
	is_concept: boolean;
	dateFrom: Date | null;
	dateTo: Date | null;
	unreaded: boolean;
	categories: apiUsers.Category[];
}

export class Model implements state.StateModel {
	list: lists.StandardList<apiUsers.User>;
	stateContainer: state.StateContainer<State>;

	constructor(private context: context.StateContext) {
		this.list = new lists.StandardList<apiUsers.User>({
			context: context,
			title: "Seznam uživatelů",
			quickFilterPlaceholder: "Zadejte jméno...",
			filterSystem: _ => [
				api.qp("id" as any, "!=", this.context.authorization.getUserProfile() ? this.context.authorization.getUserProfile()!.id : undefined),
				api.qp("deleted" as any, "=", false),
				api.qp("sys_date_create" as any, ">=", this.getDateFrom() == null ? undefined : new Date(formatting.setTimeFromString(this.getDateFrom()!, 0, 0, 0))),
				api.qp("sys_date_create" as any, "<=", this.getDateTo() == null ? undefined : new Date(formatting.setTimeFromString(this.getDateTo()!, 23, 59, 59))),
				api.qp("medical_record_state.id_state" as any, "=", this.getIsConcept() ? 1 + (this.getIsAction() ? "|| medical_record_state.id_state=" + 2 : "") : undefined),
				api.qp("medical_record_state.id_state" as any, "=", this.getIsAction() && !this.getIsConcept() ? 2 : undefined),
				api.qp("number_of_unreaded_emails" as any, ">", this.getNumberOfUnreadedEmails() ? 0 : undefined),
				api.qp("(user_categories" as any, "=", this.stateContainer.get().categories.filter(x => x.checked).map(x => x.id).length > 0 ? "[" + this.stateContainer.get().categories.filter(x => x.checked).map(x => x.id).toString() + "])" : undefined),
			],
			itemActionsList: { actions: [] },
			standardEntityApi: context.apiUsers.users as any,
			sortingFields: [],
			defaultSorting: { field: "sys_date_create", order: 1 } as any,
			grid: {
				minWidth: "1000px",
				columns: [
					{
						field: "sys_date_create" as any,
						type: "string",
						width: "115px",
						title: "Kategorie",
					},
					{
						field: "adi_life_uid" as any,
						type: "string",
						width: "105px",
						title: "AdiLifeId",
					},
					{
						field: "full_name" as any,
						type: "string",
						title: "Jméno a příjmení",
					},
					{
						field: "login" as any,
						type: "string",
						title: "Email",
					},
					{
						field: "role.name" as any,
						type: "string",
						width: "120px",
						title: "Role",
					},
					{
						field: "delegate" as any,
						type: "string",
						width: "115px",
						title: "Náhled",
						preventSorting: true
					},
					{
						field: "medical_record_state.id_state" as any,
						type: "string",
						width: "110px",
						title: "Stav ZZ",
						center: true,
					},
					{
						field: "report" as any,
						type: "string",
						width: "120px",
						title: "Reporty",
						center: true,
					},
					{
						field: "emails" as any,
						type: "string",
						width: "110px",
						title: "Emaily",
						center: true,
					},
					{
						field: "action" as any,
						width: "80px",
						type: "string",
						title: "Akce",
						preventSorting: true
					},
				]
			},
			defaultPageSize: 40,
			hideTabFilter: true,
			hideQueryMode: true
		});
		this.stateContainer = new state.StateContainer<State>({ is_action: false, is_concept: false, unreaded: false, categories: [], dateFrom: null, dateTo: null }, context);
	}

	/**
	* Vrací kolekci stavových kontejnerů
	*/
	getStateContainers = () => [
		this.stateContainer,
		...this.list.getStateContainers(),
	]

	getIsAction = () => {
		return this.stateContainer.get().is_action;
	}

	setIsAction = async (value: boolean) => {
		await this.stateContainer.merge(() => ({
			is_action: value
		}));
		this.list.loadWithResetPage();
	}

	getIsConcept = () => {
		return this.stateContainer.get().is_concept;
	}

	setIsConcept = async (value: boolean) => {
		await this.stateContainer.merge(() => ({
			is_concept: value
		}));
		this.list.loadWithResetPage();
	}

	getDateFrom = () => {
		return this.stateContainer.get().dateFrom;
	}

	getDateTo = () => {
		return this.stateContainer.get().dateTo;
	}

	setDateFrom = async (value: Date | null) => {
		if (!value || (value && isNaN(value.getTime()))) {
			await this.stateContainer.merge(() => ({
				dateFrom: null
			}));
		}
		else {
			await this.stateContainer.merge(() => ({
				dateFrom: value
			}));
		}
		this.list.loadWithResetPage();
	}

	setDateTo = async (value: Date | null) => {
		if (!value || (value && isNaN(value.getTime()))) {
			await this.stateContainer.merge(() => ({
				dateTo: null
			}));
		}
		else {
			await this.stateContainer.merge(() => ({
				dateTo: value
			}));
		}
		this.list.loadWithResetPage();
	}

	getNumberOfUnreadedEmails = () => {
		return this.stateContainer.get().unreaded;
	}

	setNumberOfUnreadedEmails = async (value: boolean) => {
		await this.stateContainer.merge(() => ({
			unreaded: value
		}));
		this.list.loadWithResetPage();
	}

	setCategory = async (value: boolean, id: string) => {
		const categories = this.stateContainer.get().categories;
		const data = categories.map(x => ({ ...x, checked: x.id == id ? value : x.checked })) as any as apiUsers.Category[];
		await this.stateContainer.merge(() => ({
			categories: data
		}));
		this.list.loadWithResetPage();
	}

	loadData = async () => {
		await this.list.loadWithResetPage();
		const categories = await this.context.api.loadList("/categories", {
			sort_fields: ["sys_date_create"],
			filter: [
				api.qpOr([
					api.qp("hidden", "=", false),
				])
			] as api.QueryFilterItem<any, keyof any>[]
		}) as any;
		await this.stateContainer.merge(() => ({
			categories: categories.data
		}));

	}

	removeUser = async (id: string) => {
		await this.context.apiUsers.remove(id);
		await this.list.load();
	}

	setRole = async (item: any, role: apiUsers.Role) => {
		const user = Object.assign({}, item);
		if (user.role.id == apiUsers.Role.Admin && role == apiUsers.Role.Admin) {
			user.role_id = apiUsers.Role.User;
		}

		else if (role == apiUsers.Role.Admin) {
			user.role_id = apiUsers.Role.Admin;
		}

		else if (user.role.id == apiUsers.Role.Operator && role == apiUsers.Role.Operator) {
			user.role_id = apiUsers.Role.User;
		}

		else if (role == apiUsers.Role.Operator) {
			user.role_id = apiUsers.Role.Operator;
		}

		user.role = undefined;
		user.user_allowed_categories = undefined;
		user.user_categories = undefined;
		await this.context.apiUsers.saveUser(user.id, user);
		await this.loadData();
	}

	deSyncUser = async (item: any) => {
		const user = Object.assign({}, item);
		user.role_id = user.role.id;
		user.role = undefined;
		user.user_allowed_categories = undefined;
		user.user_categories = undefined;
		user.adi_life_uid = null;
		user.adi_life_sending_enabled = false;
		await this.context.apiUsers.saveUser(user.id, user);
		await this.loadData();
	}

	syncUser = async (id: string) => {
		const result = await this.context.apiUsers.sync(id);
		if (result) {
			notification.successMessage("Synchronizace s Adilife proběhla úspěšně.")
			await this.list.load();
		}
	}
}