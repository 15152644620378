/*
 * Layout pro akce.
 */

import Icon from "../../modules/system/icons/Icon";
import React, { useEffect, useState } from "react";

/**
 * Třída pro akce
 */
export interface Actions<TItem> {
	title: string;
	action: (object: TItem) => void;
	groupStart?: boolean;
	groupEnd?: boolean;
	index?: number;
	icon?: string;
	disabled: boolean;
}



interface Props<TItem> {
	item?: TItem;
	actions: Actions<TItem>[];
	className?: string;
	id?: string;
}

export default function MyRequestAction<TItem>(props: Props<TItem>) {
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		window.addEventListener("click", function (e: any) {
			if (e.target.className != "homepage-widget-active") {
				//setIsOpen(false);
			}
		});
	}, []);

	function handleAction(action: (object?: TItem) => void, e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
		e.preventDefault();
		e.stopPropagation();
		action(props.item);
	}
	return (
		<>
			<div onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)} onClick={() => setIsOpen(!isOpen)} id={props.id} className={(props.className == undefined ? "" : props.className) + " d-inline my-request-action-three-dots"}>
				<Icon className={"three-dots " + (isOpen ? "three-dots-active" : "")} picture="ThreeDots" />
				<div className={isOpen ? "homepage-widget-active" : "d-none"}>
					<div className="text-left">
						{props.actions.map((a, index) =>
							<div onClick={(e) => handleAction(a.action, e)} className="my-request-action-item" key={index}>
								<span>{a.title}</span>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
}