/**
 * Přidání souboru
 */
import Button from "../../../components/controls/Button";
import * as React from "react";
import * as state from "../../../lib/state";
import * as api from "../../../lib/api";
import * as doctors from "../../pages/home/widgets/doctors/doctors";
import * as formatting from "../../../lib/formatting";
import Dialog from "../../shared/dialogs/Dialog";
import Icon from "../../../modules/system/icons/Icon";
import FormsInput from "../../../components/controls/Forms/FormsInput";
import FormsSelect from "../../../components/controls/Forms/FormSelect";
import FormsAutocomplete from "../../../components/controls/Forms/FormsAutocomplete";

function DoctorDialog() {
	const { doctorDialog } = state.useStateContext();
	const dialog = doctorDialog.dialog;
	const form = doctorDialog.form;
	const { apiImages } = state.useStateContext();
	const image = form.getField("id_image").value;
	const disabled = doctorDialog.stateContainer.get().disabled;
	const manualEntry = form.getField("manual_entry").value;

	function handleUpload() {
		const input = formatting.createHiddenInput();
		input.onchange = async e => {
			const file = (e as any).target.files![0];
			const uploadResult = await apiImages.upload(file) as any;
			const body = document.getElementsByTagName("body")[0];
			body.removeChild(input);
			if (uploadResult) {
				form.setField("id_image", uploadResult.id);
			}
		};
		input.click();
	}

	async function handleManualEntry() {
		const value = doctorDialog.form.getField("manual_entry").value;
		await doctorDialog.form.setField("manual_entry", !value);
		await doctorDialog.form.setField("id_doctor", undefined);
		await doctorDialog.form.setField("name", "");
	}

	return (
		<Dialog className="doctor-dialog" dialog={dialog}>
			<div>
				<h2 className="pb-3 dialog-title"><img className="d-inline mr-2" src="/images/__TEMP__SVG__.svg" alt="folders-1" />Návštěvy lékaře</h2>
				<hr className="dialog-title-hr mb-2" />
				<div className="row">
					<div className="col-7">
						<FormsSelect
							form={form}
							field="type"
							options={[
								{
									title: doctors.DoctorType.title(1),
									value: { id: 1, name: doctors.DoctorType.title(1) }
								},
								{
									title: doctors.DoctorType.title(2),
									value: { id: 2, name: doctors.DoctorType.title(2) }
								},
								{
									title: doctors.DoctorType.title(3),
									value: { id: 3, name: doctors.DoctorType.title(3) }
								},
								{
									title: doctors.DoctorType.title(4),
									value: { id: 4, name: doctors.DoctorType.title(4) }
								},
							]}
						/>
						{manualEntry &&
							<FormsInput
								form={form}
								field="name"
							/>
						}
						{!manualEntry &&
							<FormsAutocomplete
								form={form}
								field="id_doctor"
								autocompleteProps={{
									trigger: "onfocus",
									loadItems: doctorDialog.loadDoctors,
									getItemText: item => item?.name ?? "",
									getMenuItem: item => item,
									node: item => <>{(item as any).address}</>
								}}
							/>
						}
						<div onClick={handleManualEntry} className="mb-2 doctor-dialog-manual-entry color-primary">
							{!manualEntry &&
								<div>Nenalezli jste svého lékaře? Klikněte sem pro manuální zadání.</div>
							}
							{manualEntry &&
								<div>Zpět na výběr lékaře.</div>
							}
						</div>
						<hr className="dialog-title-hr mb-2" />
						<div className="row mb-2">
							<div className="col-12">
								<FormsInput inputProps={{ type: "datetime-local" }} form={form} field="last_visit" />
							</div>
							<div className="col-12">
								<FormsInput inputProps={{ type: "datetime-local" }} form={form} field="appointment_date" />
							</div>
						</div>
						<hr className="dialog-title-hr mb-2" />
						<div className="mb-2">Kontakt:</div>
						<div className="color-primary mb-1">
							<FormsInput
								form={form}
								icon="SearchLocation"
								field="address"
							/>
							<FormsInput
								form={form}
								icon="Phone"
								field="phone"
							/>
							<FormsInput
								form={form}
								icon="Email"
								field="email"
							/>
						</div>
					</div>
					<div className="col-5">
						<div className="text-center">
							<div className="col-12">
								<div className="pilulkoid-media-panel">
									{image == api.emptyGuid &&
										<div className="pt-5">
											<div className="pilulkoid-media-icon">
												<Icon picture="Image" />
											</div>
											<div className="color-primary mt-2">
												Foto lékaře
											</div>
										</div>
									}
									{image != api.emptyGuid &&
										<div className="mt-2">
											<img style={{ maxHeight: 180 }} alt="pilulkoid" src={apiImages.getImageUrl(image)} />
										</div>
									}
								</div>
							</div>
							{!disabled &&
								<>
									<div className="col-12">
										<div onClick={handleUpload} className="pilulkoid-media-panel-download">
											<span className="color-primary">Vložit foto</span> <Icon className="mr-2 float-right color-primary" picture="Download" />
										</div>
									</div>
									<div className="col-12">
										<div className="pilulkoid-media-panel-download disable-button-demo">
											<span className="color-primary">Nahrát dokument</span> <Icon className="mr-2 float-right color-primary" picture="Download" />
										</div>
									</div>
									<div className="col-12">
										<div onClick={() => form.setField("last_visit", formatting.formatDate(new Date(), { format: "iso" }))} className="pilulkoid-media-panel-download">
											<span className="color-primary">Zaznamenat návštěvu</span>
										</div>
									</div>
								</>
							}
						</div>
					</div>
				</div>
				<hr />
				{!disabled &&
					<div className="mt-4 text-center">
						<Button onClick={(e: any) => doctorDialog.save()} className="pt-2 pb-2 float-right">
							<strong>Vložit</strong>
						</Button>
					</div>
				}
			</div>
		</Dialog >
	);
}

export default state.bindContainers(
	DoctorDialog,
	context => context.doctorDialog
);