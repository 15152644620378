/**
 * Přidání souboru
 */
import Button from "../../../../../components/controls/Button";
import * as React from "react";
import * as state from "../../../../../lib/state";
import * as api from "../../../../../lib/api";
import * as formatting from "../../../../../lib/formatting";

import Dialog from "../../../../shared/dialogs/Dialog";
import FormsInput from "../../../../../components/controls/Forms/FormsInput";
import FormTextArea from "../../../../../components/controls/Forms/FormsTextArea";
import Icon from "../../../../../modules/system/icons/Icon";
import HtmlContent from "../../../../../components/controls/HtmlContent";
import FormsCheckBox from "../../../../../components/controls/Forms/FormCheckBox";

function PilulkoidDialog() {
	const { pilulkoidDialog } = state.useStateContext();
	const { apiImages } = state.useStateContext();
	const { standardDialogs } = state.useStateContext();
	const dialog = pilulkoidDialog.dialog;
	const disabled = pilulkoidDialog.form.getField("disabled").value;
	const form = pilulkoidDialog.form;
	const image = form.getField("id_image").value;

	async function handleRemove() {
		await standardDialogs.openYesNoDialog("Opravdu chcete smazat záznam?") === "yes"
			? pilulkoidDialog.remove()
			: undefined
	}

	function handleUpload() {
		const input = formatting.createHiddenInput();
		input.onchange = async e => {
			const file = (e as any).target.files![0];
			const uploadResult = await apiImages.upload(file) as any;
			const body = document.getElementsByTagName("body")[0];
			body.removeChild(input);
			if (uploadResult) {
				form.setField("id_image", uploadResult.id);
			}
		};
		input.click();
	}

	return (
		<Dialog className="pilulkoid-dialog" dialog={dialog}>
			<div>
				<h2 className="pb-3 dialog-title"><img className="d-inline mr-2" src="/images/pill.svg" alt="folders-1" />Lékarna Dr.Digital</h2>
				<hr className="dialog-title-hr" />
				<div className="row">
					<div className="col-4">
						<div className="row">
							<div className="col-12">
								<div className="pilulkoid-media-panel">
									{image == api.emptyGuid &&
										<div className="pt-5">
											<div className="pilulkoid-media-icon">
												<Icon picture="Image" />
											</div>
											<div className="color-primary mt-2">
												Foto léku
											</div>
										</div>
									}
									{image != api.emptyGuid &&
										<div className="mt-2">
											<img style={{ maxHeight: 180 }} alt="pilulkoid" src={apiImages.getImageUrl(image)} />
										</div>
									}
								</div>
							</div>
							{!disabled &&
								<div className="col-12">
									<div onClick={handleUpload} className="pilulkoid-media-panel-download">
										<span className="color-primary">Vložit foto</span> <Icon className="mr-2 float-right color-primary" picture="Download" />
									</div>
								</div>
							}
						</div>
					</div>
					{!disabled &&
						<div className="col-8">
							<div className="row">
								<div className="col-12">
									<FormsInput form={pilulkoidDialog.form} field="name" />
								</div>
								<div className="col-12">
									<FormTextArea form={pilulkoidDialog.form} field="description" />
								</div>
								<div className="col-12">
									<FormsInput form={pilulkoidDialog.form} field="dosage" />
								</div>
								<div className="col-6">
									<FormsInput inputProps={{ type: "datetime-local" }} form={pilulkoidDialog.form} field="expiration_date" />
								</div>
								<div className="col-6">
									<FormsCheckBox form={pilulkoidDialog.form} field="on_prescription" />
								</div>
							</div>
						</div>
					}
					{!disabled &&
						<div className="col mt-4 text-center float-right">
							<Button onClick={e => pilulkoidDialog.save()} className="pt-2 pb-2 float-right">
								Uložit do pilulkoidu
							</Button>
						</div>
					}
					{disabled &&
						<div className="col-8">
							<div>
								<h3 className="pilulkoid-name mt-0 mb-1">
									Název Léku {pilulkoidDialog.form.getField("name").value}
								</h3>
								<div className="pilulkoid-disabled-description">
									<label>
										<HtmlContent content={pilulkoidDialog.form.getField("description").value} />
									</label>
								</div>
								<div className="mt-4">
									<label>Dávkování</label>
								</div>
							</div>
							<div>
								<div className="d-inline pilulkoid-name">
									{pilulkoidDialog.form.getField("dosage").value}
								</div>
							</div>
						</div>
					}
				</div>
				{disabled &&
					<div onClick={handleRemove} className="color-primary d-inline float-right cursor-pointer pilulkoid-remove">
						Odebrat z pilukoidu <Icon className="ml-2" picture="Remove" />
					</div>
				}
			</div>
		</Dialog >
	);
}

export default state.bindContainers(
	PilulkoidDialog,
	context => context.pilulkoidDialog
);