import * as forms from "../../lib/forms";
import * as api from "../../lib/api";
import * as context from "../../context";
import storage from "store";

interface State {
	redirectRoute: string;
}

interface LoginFields {
	id: string;
	init: boolean;
}

export class Model {
	public form: forms.Form<LoginFields>;

	constructor(private context: context.StateContext) {
		this.form = new forms.Form<LoginFields>({
			fields: {
				id: {
					title: "",
					defaultValue: ""
				},
				init: {
					title: "",
					defaultValue: false
				}
			},
		}, context);

	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			...this.form.getStateContainers()
		];
	}
	loadData = async (id: any) => {
		const data = await this.context.api.post("/auth/token", { token: id }) as any;
		await storage.set("authToken", data.access_token);
		const user = await this.context.api.get("/auth/info");
		this.setUser(user);
	}

	setUser = async (user: any) => {
		const documents = [
			{
				id: api.emptyGuid,
				name: "Report Dr. Digital - Srpen 2022"
			},

		] as api.CodeBookItemWithDate[];

		const widgets = [
			{
				id: 1,
				name: "Teplota",
				value: "43,8",
				src: "/images/thermometer-half.svg",
				type: 1,
				field: "temperature"
			},
			{
				id: 2,
				name: "Tlak",
				value: "135/95",
				src: "/images/droplet-fill.svg",
				type: 2,
				field: "pressure"
			},
			{
				id: 3,
				name: "Heart rate",
				value: "56 bpm",
				src: "/images/heart.svg",
				type: 3,
				field: "hearth_rate"
			},
			{
				id: 4,
				name: "Oximetry",
				value: "87%",
				src: "/images/windy-strong.svg",
				type: 4,
				field: "oximetry"
			},
			{
				id: 6,
				name: "Breath rate",
				value: "123 bpm",
				src: "/images/lungs.svg",
				type: 6,
				field: "breath_rate"
			},
			{
				id: 5,
				name: "EKG",
				value: "See graph",
				src: "/images/Path 1164.svg",
				type: 5,
				field: "ekg"
			},

		] as any[];

		const pilulkoids = [
			{
				id: api.emptyGuid,
				name: "Condrosulf 400"
			},
			{
				id: api.emptyGuid,
				name: "Ibalgin"
			},
			{
				id: api.emptyGuid,
				name: "Diozen"
			},
			{
				id: api.emptyGuid,
				name: "Rowatinex"
			},

		] as api.CodeBookItem[];
		const treatements = [
			{
				id: api.emptyGuid,
				name: "Artroza pravého kolene II. Stupeň"
			},
			{
				id: api.emptyGuid,
				name: "Akutní zánět ledvin"
			},

		] as api.CodeBookItem[];
		const alergies = [
			{
				id: api.emptyGuid,
				name: "Mírná alergie na prach "
			},

		] as api.CodeBookItem[];
		const operations = [
			{
				id: api.emptyGuid,
				name: "Zánět slepého střeva",
				date: "13.2.1986"
			},
			{
				id: api.emptyGuid,
				name: "Operace levého kolene – Artroskopie",
				date: "14.10.2018"
			},

		] as api.CodeBookItemWithDate[];


		const vaccination = [
			{
				id: api.emptyGuid,
				name: "Test",
				date: "19.3.1999"
			},
		] as api.CodeBookItemWithDate[];

		this.context.optionalViewWidget.form.setField("pilulkoids", pilulkoids);
		this.context.optionalViewWidget.form.setField("ongoing_treatments", treatements);
		this.context.optionalViewWidget.form.setField("allergies", alergies);
		this.context.expandedViewWidget.form.setField("operations", operations);
		this.context.expandedViewWidget.form.setField("documents", documents);
		//this.context.pCardWidget.form.setField("vaccination", vaccination);
		this.context.expandedViewWidget.form.setField("widgets", widgets);
		this.form.setField("init", true);
	}
}