/**
 * Formulář pro reset hesla
 */

import * as context from "../../context";
import * as state from "../../lib/state";
import * as api from "../../lib/api";
import * as navigation from "../../lib/navigation";
import * as validations from "../../lib/validations";
import * as forms from "../../lib/forms";

/**
 * Definice dat formuláře
 */
export interface FormFields {
	password: string;
	repeat_password: string;
};

export interface State {
}

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	private stateContainer: state.StateContainer<State>;
	public form: forms.Form<FormFields>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({ resetToken: "" }, context);
		this.form = new forms.Form<FormFields>({
			fields: {
				password: {
					title: "Nové heslo",
					defaultValue: "",
					required: true,
					validate: value => value.trim().length === 0 ? "Heslo je povinné pole" : "",
				},
				repeat_password: {
					title: "Zopakujte heslo",
					defaultValue: "",
					required: true,
					validate: (value, model, form) => {
						if (value != form.getField("password").value) {
							return "Heslo neodpovídá novému heslu"
						}
						return this.validatePassword(value);
					}
				}
			}
		}, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			this.stateContainer,
			...this.form.getStateContainers()
		];
	}

	/**
	 * Validuje password
	 */
	validatePassword = (value: string) => {
		return validations.isValidPassword(value);
	}

	send = async () => {
		await this.form.validate();
		if (this.form.isValid()) {
			const result = await this.context.api.post("/auth/change_password?token=" + api.getUrlParam("resetToken"), { new_password: this.form.getField("password").value });
			if (result) {
				await this.context.standardDialogs.openInformationDialog("Heslo bylo změněno.");
				navigation.to("/prihlaseni");
			}
		}
		else {
			await this.context.standardDialogs.openInformationDialog("Formulář obsahuje neúplné informace nebo chyby. Opravte prosím červeně vyznačené položky a poté akci opakujte.");
		}
	}
}