/**
 * Widget calendar
 */
import React from "react";
import * as state from "../../../../../lib/state";
import * as api from "../../../../../lib/api";
import * as formatting from "../../../../../lib/formatting";
import Icon from "../../../../../modules/system/icons/Icon";

export default function CalendarDailyView() {
	const { calendarPage: model } = state.useStateContext();
	const { calendarDialog } = state.useStateContext();
	const currentDay = model.form.getField("currentDay").value;
	const items = model.form.getField("data").value.filter(x => new Date(new Date(x.date!).getFullYear(), new Date(x.date!).getMonth(), new Date(x.date!).getDate(), 0, 0, 0).toString() == new Date(currentDay.getFullYear(), currentDay.getMonth(), currentDay.getDate(), 0, 0, 0).toString());

	function handleAdd() {
		calendarDialog.openDialog();
	}

	async function handleOpen(i: any) {
		const item = Object.assign({}, i);
		item.date = formatting.formatDateForInputDateTime(item.date) as any;
		await calendarDialog.openDialog(true, item);
	}


	return (
		<div>
			<div className="calendar-daily-view-calendar">
				<div className="react-calendar">
					<div className="calendar-weekly-view">
						<div className="react-calendar__navigation">
							<button onClick={model.previoustDay} aria-label="" className="react-calendar__navigation__arrow react-calendar__navigation__prev-button" type="button">‹</button>
							<button aria-label="" className="react-calendar__navigation__label" type="button" style={{ flexGrow: 1 }}>
								<span className="react-calendar__navigation__label__labelText react-calendar__navigation__label__labelText--from">
									{formatting.formatDate(model.form.getField("currentDay").value)}
								</span>
							</button>
							<button onClick={model.nextDay} aria-label="" className="react-calendar__navigation__arrow react-calendar__navigation__next-button" type="button">›</button>
						</div>
					</div>
					<div className="react-calendar__viewContainer calendar-daily-view-view-container">
						<div className="calendar-daily-view-header mt-1 mb-1">
							Naplánované události
						</div>
						<div className="calendar-daily-view-container mt-1">
							{items.map((i, index) =>
								<div onClick={() => handleOpen(i)} key={index} className="calendar-daily-view-item">
									<div className="mr-2 calendar-daily-view-date d-inline">{formatting.formatTime(i.date)}</div>
									<div className="calendar-daily-view-split d-inline">|</div>
									<div className="ml-2 d-inline">{i.description}</div>
								</div>
							)}
							{items.length == 0 &&
								<div className="ml-3 mt-3">Žádné naplánované události.</div>
							}
						</div>
					</div>
				</div>
			</div>
			<div className="calendar-daily-view-add">
				<div onClick={handleAdd} className="calendar-daily-view-add-container">Přidat událost <Icon picture="Add" /></div>
			</div>
		</div>
	);
}
