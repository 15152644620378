/*
 * Layout homepage stránky.
 */

import React, { useState, useEffect } from "react";

import * as state from "../../lib/state";
import * as api from "../../lib/api";

import PageHeader from "../sections/PageHeader";
import LoginForm from "../sections/LoginForm";
import Accessibility from "../controls/Accessibility";
import PageMenu from "../sections/PageMenu";
import ResetPassword from "../sections/ResetPassword";

export interface PageProps {
	title: React.ReactNode;
	description?: string;
	authorized?: boolean;
	children: React.ReactNode;
}

export default function Page(props: PageProps) {
	const stateContext = state.useStateContext();
	const { authorization, dialogs } = stateContext;
	const [id, setId] = useState("");

	function authorizedToPage() {
		return !props.authorized && authorization.userLoggedIn();
	}

	const title = (authorizedToPage() ? (props.title ? props.title : "") : "Přihlášení") + " | Virtuální klinika";

	useEffect(() => {
		setId(api.getUrlParam("resetToken")!);
		if (document.title != title) {
			document.title = title;
		}
	}, []);

	return (
		<>
			<div>
				<title>{title}</title>
				<meta name="description" content={props.description} />
				<meta property="og:title" content={title} />
				<meta property="og:description" content={props.description} />
				<meta name="twitter:title" content={title} />
				<meta name="twitter:description" content={props.description} />
				<meta property="og:image" content="" />
			</div>
			{authorizedToPage() &&
				<div className={"page" + (dialogs.isSomeDialogOpen() ? " page--modal-open" : "")}>
					<Accessibility />
					<PageMenu />
					<div role="main" id="main" className="navbar-main-wrapper">
						<PageHeader />
						<div className="page-main">
							{authorizedToPage() && authorization.getUserProfile() &&
								<>{props.children}</>
							}
						</div>
					</div>
				</div>
			}
			{!authorizedToPage() &&
				<>
					{!id &&
						<LoginForm />
					}
					{id &&
						<ResetPassword />
					}
				</>
			}
		</>
	);
}