/**
 * Přidání pain monitoru
 */
import Button from "../../../../../components/controls/Button";
import * as React from "react";
import * as state from "../../../../../lib/state";
import * as api from "../../../../../lib/api";

import Dialog from "../../../../shared/dialogs/Dialog";
import FormsInput from "../../../../../components/controls/Forms/FormsInput";
import Icon from "../../../../../modules/system/icons/Icon";
import * as painMonDialog from "./pain-monitor-dialog";
import PainMonitorType from "./PainMonitorType";

function PainMonitorDialog() {
	const { painMonitorDialog } = state.useStateContext();
	const { standardDialogs } = state.useStateContext();
	const dialog = painMonitorDialog.dialog;
	const disabled = painMonitorDialog.form.getField("disabled").value;
	const form = painMonitorDialog.form;
	const type = form.getField("type").value;

	async function handleType(type: number) {
		form.setField("type", type);
		let name = painMonDialog.PainType.title(type);
		switch (type) {
			case painMonDialog.PainType.SubjectiveFeelingOfHealth:
				name = "Vitalita"
				break;
			case painMonDialog.PainType.SubjectiveFeelingOfEnergy:
				name = "Energie"
				break;
			case painMonDialog.PainType.SubjectiveMoodFeeling:
				name = "Nálada"
				break;
			case painMonDialog.PainType.SightSmellHearing:
				name = "Smysly"
				break;
			case painMonDialog.PainType.CompleteHealthEntry:
				name = "Kompletní záznam"
				break;
		}
		form.setField("name", name);

		if (type == painMonDialog.PainType.CompleteHealthEntry) {
			const pains = [
				{ description: "", pain_intensity: null, type: painMonDialog.PainType.SubjectiveFeelingOfHealth, name: "Vitalita" },
				{ description: "", pain_intensity: null, type: painMonDialog.PainType.SubjectiveFeelingOfEnergy, name: "Energie" },
				{ description: "", pain_intensity: null, type: painMonDialog.PainType.SubjectiveMoodFeeling, name: "Nálada" },
				{ description: "", pain_intensity: null, type: painMonDialog.PainType.Sleep, name: painMonDialog.PainType.title(painMonDialog.PainType.Sleep) },
				{ description: "", pain_intensity: null, type: painMonDialog.PainType.FoodAndExcretion, name: painMonDialog.PainType.title(painMonDialog.PainType.FoodAndExcretion) },
				{ description: "", pain_intensity: null, type: painMonDialog.PainType.Breathing, name: painMonDialog.PainType.title(painMonDialog.PainType.Breathing) },
				{ description: "", pain_intensity: null, type: painMonDialog.PainType.SightSmellHearing, name: "Smysly" }
			];

			await painMonitorDialog.formsPains.setFields(pains);
		}
	}

	async function handleRemove() {
		await standardDialogs.openYesNoDialog("Opravdu chcete smazat záznam?") === "yes"
			? painMonitorDialog.remove()
			: undefined
	}

	return (
		<Dialog className="pain-monitor-dialog" dialog={dialog}>
			<div>
				<h2 className="pb-3 dialog-title"><img className="d-inline mr-2" src="/images/folders-1.svg" alt="folders-1" />{form.getField("id").value == api.emptyGuid ? "Přidat záznam" : "Zdravotní deník"}</h2>
				<hr className="dialog-title-hr" />
				{!type &&
					<div>
						<div className="row">
							<div onClick={() => handleType(painMonDialog.PainType.Pain)} className="col-6">
								<div className="pain-monitor-media-panel pain-monitor-media-panel-simple ">
									<div className="last-records-media-icon">
										<img className="d-inline mr-2" src="/images/pill.svg" alt="pill" />
									</div>
									<div className="color-primary mt-2">
										{painMonDialog.PainType.title(painMonDialog.PainType.Pain)}
									</div>
								</div>
							</div>
							<div onClick={() => handleType(painMonDialog.PainType.MentalHealth)} className="col-6">
								<div className="pain-monitor-media-panel pain-monitor-media-panel-simple2">
									<div className="last-records-media-icon">
										<img className="d-inline mr-2" src="/images/bxs-brain.svg" alt="bxs-brain" />
									</div>
									<div className="color-primary mt-2">
										{painMonDialog.PainType.title(painMonDialog.PainType.MentalHealth)}
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div onClick={() => handleType(painMonDialog.PainType.SubjectiveFeelingOfHealth)} className="col-6">
								<div className="pain-monitor-media-panel ">
									<div className="last-records-media-icon">
										<img className="d-inline mr-2" src="/images/md-fitness.svg" alt="pill" />
									</div>
									<div className="color-primary mt-2">
										Vitalita
									</div>
								</div>
							</div>
							<div onClick={() => handleType(painMonDialog.PainType.SubjectiveFeelingOfEnergy)} className="col-6">
								<div className="pain-monitor-media-panel ">
									<div className="last-records-media-icon">
										<img className="d-inline mr-2" src="/images/GROUP 651.svg" alt="carrot" />
									</div>
									<div className="color-primary mt-2">
										Energie
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div onClick={() => handleType(painMonDialog.PainType.SubjectiveMoodFeeling)} className="col-6">
								<div className="pain-monitor-media-panel ">
									<div className="last-records-media-icon">
										<Icon picture="Cloud" />
									</div>
									<div className="color-primary mt-2">
										Nálada
									</div>
								</div>
							</div>
							<div onClick={() => handleType(painMonDialog.PainType.Sleep)} className="col-6">
								<div className="pain-monitor-media-panel ">
									<div className="last-records-media-icon">
										<Icon picture="Moon" />
									</div>
									<div className="color-primary mt-2">
										{painMonDialog.PainType.title(painMonDialog.PainType.Sleep)}
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div onClick={() => handleType(painMonDialog.PainType.FoodAndExcretion)} className="col-6">
								<div className="pain-monitor-media-panel ">
									<div className="last-records-media-icon">
										<img className="d-inline mr-2" src="/images/carrot.svg" alt="carrot" />
									</div>
									<div className="color-primary mt-2">
										{painMonDialog.PainType.title(painMonDialog.PainType.FoodAndExcretion)}
									</div>
								</div>
							</div>
							<div onClick={() => handleType(painMonDialog.PainType.Breathing)} className="col-6">
								<div className="pain-monitor-media-panel ">
									<div className="last-records-media-icon">
										<img className="d-inline mr-2" src="/images/TEMP  SVG.svg" alt="carrot" />
									</div>
									<div className="color-primary mt-2">
										{painMonDialog.PainType.title(painMonDialog.PainType.Breathing)}
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div onClick={() => handleType(painMonDialog.PainType.SightSmellHearing)} className="col-6">
								<div className="pain-monitor-media-panel ">
									<div className="last-records-media-icon">
										<Icon picture="Eye" />
									</div>
									<div className="color-primary mt-2">
										Smysly
									</div>
								</div>
							</div>
							<div onClick={() => handleType(painMonDialog.PainType.CompleteHealthEntry)} className="col-6">
								<div className="pain-monitor-media-panel ">
									<div className="last-records-media-icon">
										<Icon picture="BookMedical" />
									</div>
									<div className="color-primary mt-2">
										Kompletní záznam
									</div>
								</div>
							</div>
						</div>
					</div>
				}
				{type &&
					<>
						<FormsInput form={form} field="name" />

						{type != painMonDialog.PainType.CompleteHealthEntry &&
							<PainMonitorType isSimple={true} disabled={disabled} type={type} form={form} labels={!painMonDialog.labels.find(x => x.id == type) ? [] : painMonDialog.labels.find(x => x.id == type)!.items} />
						}

						{type == painMonDialog.PainType.CompleteHealthEntry &&
							<>
								{painMonitorDialog.formsPains.get().map((i, index) =>
									<div key={index} className="mt-3">
										<PainMonitorType isSimple={true} disabled={disabled} key={index} type={i.getField("type").value!} form={i as any} labels={!painMonDialog.labels.find(x => x.id == i.getField("type").value) ? [] : painMonDialog.labels.find(x => x.id == i.getField("type").value)!.items} />
									</div>
								)}
							</>
						}

						<FormsInput
							inputProps={{ type: "datetime-local" }}
							form={form}
							className="mt-2"
							field="date"
						/>
						{!disabled &&
							<div className="mt-4 text-center">
								<Button onClick={(e: any) => painMonitorDialog.save()} className="pt-2 pb-2 float-right">
									<strong>Vložit</strong>
								</Button>
							</div>
						}
						{disabled &&
							<div onClick={handleRemove} className="color-primary d-inline float-right cursor-pointer pilulkoid-remove">
								Odebrat z widgetu <Icon className="ml-2" picture="Remove" />
							</div>
						}
					</>
				}
			</div>
		</Dialog >
	);
}

export default state.bindContainers(
	PainMonitorDialog,
	context => context.painMonitorDialog
);