/**
 * Přidání souboru
 */
import React, { useEffect, useRef, useState } from "react";
import * as state from "../../../lib/state";
import * as common from "../../../lib/common";
import * as api from "../../../lib/api";

import Dialog from "./Dialog";
import Button from "../../../components/controls/Button";

function CaptureDialog() {
	const { captureDialog } = state.useStateContext();
	const [justUploading, setJustUploading] = useState(false);
	const [uploadingFileName, setUploadingFileName] = useState("");
	const context = state.useStateContext();
	const form = captureDialog.form;
	const videoRecordRef = useRef<HTMLVideoElement>(null);
	const capturePhotoRef = useRef<HTMLCanvasElement>(null);
	let [mediaRecorder, setMediaRecorder] = useState(undefined as MediaRecorder | undefined);
	const fileState = form.getField("state").value;
	const init = form.getField("init").value;

	useEffect(() => {
		if (init) {
			startCamera(false);
		}
	}, []);

	async function apiUpload(file: globalThis.File) {
		return context.apiFile.upload(file, { suppressErrorNotification: true });
	}

	async function uploadFile(file: any) {
		let uploadResult: api.OperationResponse | undefined = undefined;
		try {
			uploadResult = await common.withIndication({
				start: async () => setUploadingFileName(file.name),
				indicateStart: async () => setJustUploading(true),
				finish: async () => { setJustUploading(false); setUploadingFileName(""); },
				exec: () => apiUpload(file)
			});
		} catch (err) {
			await context.standardDialogs.openInformationDialog(("Soubor se nepodařilo nahrát.") + " " + api.getErrorMessage(err, "Neznámá chyba."));
		}
		if (uploadResult) {
			await form.setField("file", {
				id: uploadResult.id as string,
				name: file.name
			});
			setUploadingFileName(file.name);
		}
	}

	function startWebcam(stream: any) {
		const mainVideo = videoRecordRef!.current!;
		if (mainVideo) {
			capturePhotoRef.current!.style.height = "0px";
			(window as any).stream = stream;
			mainVideo.srcObject = stream;
		}
	}

	async function startRecording() {
		const mainVideo = videoRecordRef!.current!;
		if (mainVideo.srcObject === null) {
			mainVideo.srcObject = (window as any).stream;
		}
		const mediaRecorder = new MediaRecorder((window as any).stream, { mimeType: "video/webm" });
		await setMediaRecorder(mediaRecorder);
		mediaRecorder!.start();
		mediaRecorder!.ondataavailable = recordVideo;
	}

	function recordVideo(event: any) {
		if (event.data && event.data.size > 0) {
			const mainVideo = videoRecordRef!.current!;
			mainVideo.srcObject = null;
			let videoUrl = URL.createObjectURL(event.data);
			mainVideo.src = videoUrl;
		}
	}

	async function startCamera(audio: boolean) {
		const stream = await navigator.mediaDevices.getUserMedia({
			audio: false,
			video: true
		});
		await startWebcam(stream);
	}

	async function handleRecord() {
		const mainVideo = videoRecordRef!.current!;
		capturePhotoRef.current!.style.height = "0px";
		mainVideo.style.height = "320px";
		await form.setField("state", "");
		await startCamera(false);
		startRecording();
	}

	async function handlePause() {
		const mainVideo = videoRecordRef!.current!;
		capturePhotoRef.current!.style.height = "320px";
		mainVideo.style.height = "0px";
		const canvas = capturePhotoRef!.current!;
		const context = canvas.getContext("2d")!;
		context.drawImage(mainVideo, 0, 0, 500, 320);
		form.setField("state", "saved");
	}

	async function handleSave() {
		const mainVideo = videoRecordRef!.current!;
		const canvas = capturePhotoRef!.current!;
		setTimeout(() => {
			const file = new File([canvas.toDataURL("image/png")], "photo.png");
			uploadFile(file);
			saveDialog({ id: api.emptyGuid, name: file.name });
		}, 100);
	}

	async function saveDialog(file: api.CodeBookItem) {
		return captureDialog.dialog.close(file);
	}

	return (
		<Dialog className="last-records-add-dialog" dialog={captureDialog.dialog}>
			<div>
				<h2 className="pb-3 dialog-title"><img className="d-inline mr-2" src="/images/folders-1.svg" alt="folders-1" /> Vyfotit</h2>
				<hr className="dialog-title-hr" />
				<div className="mt-3">
					<div>
						<video className="d-block mb-3" ref={videoRecordRef} width={500} height={320} playsInline autoPlay controls></video>
						<canvas width={500} height={320} ref={capturePhotoRef} className="capturePhoto d-block mb-3"></canvas>
						<div className="text-center">
							{fileState == "" &&
								<div className="d-inline" onClick={handlePause}>
									<Button className="btn-primary">
										Vyfotit
									</Button>
								</div>
							}
							{fileState == "saved" &&
								<div onClick={handleSave} className="d-inline">
									<Button className="btn-primary">
										Uložit
									</Button>
								</div>
							}
							{form.getField("state").value == "saved" &&
								<div onClick={handleRecord} className="ml-3 d-inline">
									<Button className="btn-primary">
										Vyfotit znovu
									</Button>
								</div>
							}
						</div>
					</div>
				</div>
			</div>
		</Dialog >
	);
}

export default state.bindContainers(
	CaptureDialog,
	context => context.captureDialog
);