import * as React from "react";
import { ColorPicker } from "@progress/kendo-react-inputs";
import { ColorPickerChangeEvent } from "@progress/kendo-react-inputs/dist/npm/colors/interfaces/ColorPickerChangeEvent";

export interface Props {
	onChange?: (value: string) => void;
	value: string;
};

export default function ColorPickerInput(props: Props) {
	function handleChange(e: ColorPickerChangeEvent) {
		if (props.onChange) {
			props.onChange(e.value);
		}
	}

	const propsToDelegate = {
		...props
	};

	delete propsToDelegate.onChange;

	return (
		<ColorPicker value={!props.value ? "white" : props.value} onChange={handleChange} view="gradient" defaultValue={"green"} />
	);
}