import * as React from "react";
import * as forms from "../../../lib/forms";
import * as common from "../../../lib/common";

import ImageEditable, { ImageEditableProps } from "../../../modules/system/images/ImageEditable";
import FormItem, { FormItemOptions } from "./FormItem";


export interface FormImageEdit<TFormFields> {
	form: forms.Form<TFormFields>;
	field: keyof common.SubType<TFormFields, string>;
	className?: string;
	classNameIcon?: string;
	formItemOptions?: FormItemOptions;
	imageEditableProps?: Omit<ImageEditableProps, "id" | "onChange">;
	title?: string;
	showBtn: boolean;
	defaultSrc?: string;
	onSave?: (field?: any, value?: string) => void;
}

export default function FormImageEdit<TFormFields>(props: FormImageEdit<TFormFields>) {
	const field = props.form.getField(props.field);
	const fieldOptions = props.form.getFieldOptions(props.field);

	async function handleChange(value: string) {
		await props.form.setField(props.field, value as any);
	}

	return (
		<FormItem
			form={props.form}
			field={props.field}
			options={props.formItemOptions}
		>
			<ImageEditable
				{...props.imageEditableProps}
				id={field.value as any}
				classNameIcon={props.classNameIcon}
				showBtn={props.showBtn}
				onSave={props.onSave}
				className={props.className}
				defaultSrc={props.defaultSrc}
				title={props.title}
				onChange={value => handleChange(value)}
			/>
		</FormItem>
	);
}