/**
 * Modul domovské stránky
 */

import * as state from "../../../../../lib/state";
import * as api from "../../../../../lib/api";
import * as context from "../../../../../context";
import * as forms from "../../../../../lib/forms";
import * as lastRecords from "../../../home/widgets/last-records/last-records";
import * as apiHome from "../../../home/api-home";

/**
 * Stav modulu
 */
interface State {
}

export interface FormFields {
	showDrugs: boolean;
	showTreatements: boolean;
	showAllergies: boolean;
	pilulkoids: api.CodeBookItem[];
	ongoing_treatments: api.CodeBookItem[];
	allergies: api.CodeBookItem[];
	no_long_term_medication: boolean;
	no_long_term_illness: boolean;
	type: api.CodeBookItemWithNumberId | null;
}

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	private stateContainer: state.StateContainer<State>;
	public form: forms.Form<FormFields>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({}, context);
		this.form = new forms.Form<FormFields>({
			fields: {
				showDrugs: {
					title: "Zobrazit",
					defaultValue: false,
				},
				showTreatements: {
					title: "Zobrazit",
					defaultValue: false,
				},
				showAllergies: {
					title: "Zobrazit",
					defaultValue: false,
				},
				pilulkoids: {
					title: "",
					defaultValue: [],
				},
				ongoing_treatments: {
					title: "",
					defaultValue: [],
				},
				allergies: {
					title: "",
					defaultValue: [],
				},
				no_long_term_illness: {
					defaultValue: false,
					title: ""
				},
				no_long_term_medication: {
					defaultValue: false,
					title: "",
				},
				type: {
					defaultValue: null,
					title: ""
				},
			}
		}, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			this.stateContainer,
			...this.form.getStateContainers()
		];
	}

	loadData = async () => {
		const my_files = await this.context.api.loadList("/treatments", { sort_fields_desc: ["sys_date_create"] as any, sort_fields: ["sys_date_create"] as any }) as any;
		let data = my_files.data as apiHome.Record[];
		this.context.expandedViewWidget.form.setField("vaccination", data.filter(x => x.type == lastRecords.MyRecordType.Vaccination) as any);
		const dataDrugs = await this.context.api.loadList<api.CodeBookItem>("/pilulkoids", { sort_fields_desc: ["sys_date_create"] as any, sort_fields: ["sys_date_create"] as any }) as any;
		this.form.setField("pilulkoids", dataDrugs.data);
		const treatements = await this.context.api.loadList<api.CodeBookItem>("/ongoing_treatments", { sort_fields_desc: ["sys_date_create"] as any, sort_fields: ["sys_date_create"] as any }) as any;
		this.form.setField("ongoing_treatments", treatements.data);
		this.form.setField("allergies", data.filter(x => x.type == lastRecords.MyRecordType.Allergy) as any);
		this.context.expandedViewWidget.form.setField("operations", data.filter(x =>
			x.type == lastRecords.MyRecordType.Operation ||
			x.type == lastRecords.MyRecordType.Entry ||
			x.type == lastRecords.MyRecordType.Illness
		) as any);
		this.form.setField("no_long_term_illness", this.context.authorization.getUserProfile()?.profile.no_long_term_illness as any);
		this.form.setField("no_long_term_medication", this.context.authorization.getUserProfile()?.profile.no_long_term_medication as any);
	}

	/**
	 * Provede reset formuláře
	 */
	reset = async () => {
		await Promise.all([
			this.form.clearFields(),
		]);
	}
}