/**
 * Reset hesla
 */
import * as React from "react";
import * as state from "../../../lib/state";

import Dialog from "../../shared/dialogs/Dialog";
import FormsInput from "../../../components/controls/Forms/FormsInput";
import ButtonAdvanced from "../../../components/controls/ButtonAdvanced";

function ChangePasswordDialog() {
	const { changePasswordDialog } = state.useStateContext();
	const dialog = changePasswordDialog.dialog;
	const form = changePasswordDialog.form;

	return (
		<Dialog className="simple-add-dialog" dialog={dialog}>
			<div>
				<h2 className="pb-3 dialog-title">Změna hesla</h2>
				<hr className="dialog-title-hr" />
				<div className="row">
					<div className="col-md-12">
						<FormsInput
							form={form}
							inputProps={{ type: "password", autoComplete: "new-password" }}
							field="old_password"
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<FormsInput
							form={form}
							inputProps={{ type: "password", autoComplete: "new-password" }}
							field="password"
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<FormsInput
							form={form}
							inputProps={{ type: "password", autoComplete: "new-password" }}
							field="confirm_password"
						/>
					</div>
				</div>
				<div className="mt-4 text-center">
					<ButtonAdvanced onClick={changePasswordDialog.save} className="pt-2 pb-2 float-right">
						<strong>Změnit heslo</strong>
					</ButtonAdvanced>
				</div>
			</div>
		</Dialog >
	);
}

export default state.bindContainers(
	ChangePasswordDialog,
	context => context.changePasswordDialog
);