/**
 * Přidání subkategorie
 */
import Button from "../../../../components/controls/Button";
import * as React from "react";
import * as state from "../../../../lib/state";
import * as formatting from "../../../../lib/formatting";

import Dialog from "../../../shared/dialogs/Dialog";
import FormsInput from "../../../../components/controls/Forms/FormsInput";
import FormsHtmlEditor from "../../../../components/controls/Forms/FormsHtmlEditor";
import Icon from "../../../../modules/system/icons/Icon";
import FormsImage from "../../../../components/controls/Forms/FormsImage";
import FormsCheckBox from "../../../../components/controls/Forms/FormCheckBox";

function WaitingRoomItemDialog() {
	const { contentItemDialog } = state.useStateContext();
	const { apiImages } = state.useStateContext();
	const dialog = contentItemDialog.dialog;
	const form = contentItemDialog.form;
	const image = form.getField("id_image").value;

	function handleUpload() {
		const input = formatting.createHiddenInput();
		input.onchange = async e => {
			const file = (e as any).target.files![0];
			const uploadResult = await apiImages.upload(file) as any;
			const body = document.getElementsByTagName("body")[0];
			body.removeChild(input);
			if (uploadResult) {
				form.setField("id_image", uploadResult.id);
			}
		};
		input.click();
	}

	return (
		<Dialog className="content-item-dialog" dialog={dialog}>
			<div>
				<h2 className="pb-3 dialog-title"><img className="d-inline mr-2" src="/images/folders-1.svg" alt="folders-1" /> Subkategorie</h2>
				<hr className="dialog-title-hr" />
				<div className="row">
					<div className="col-4">
						<div className="row">
							<div className="col-12">
								<FormsImage
									form={form}
									field="id_image"
								/>
							</div>
						</div>
					</div>
					<div className="col">
						<FormsInput
							form={form}
							field="title"
						/>
						<FormsCheckBox form={form} field="show_ambulance" />
						<div style={{ width: 150 }} onClick={handleUpload} className="pilulkoid-media-panel-download">
							<span className="color-primary">Vložit foto</span> <Icon className="mr-2 float-right color-primary" picture="Download" />
						</div>
					</div>
				</div>
				<div className="row mt-2">
					<div className="col">
						<FormsInput
							form={form}
							field="url"
						/>
					</div>
				</div>
				<div className="row mt-2 text-left">
					<div className="col">
						<FormsHtmlEditor
							form={form}
							field="description"
						/>
					</div>
				</div>
				<div></div>
				<div className="mt-4 text-center">
					<Button onClick={e => contentItemDialog.save()} className="pt-2 pb-2 float-right">
						<strong>Uložit</strong>
					</Button>
				</div>
			</div>
		</Dialog >
	);
}

export default state.bindContainers(
	WaitingRoomItemDialog,
	context => context.contentItemDialog
);