/**
 * Stránka GeneralGraph
 */
import React, { useState, useEffect } from "react";
import * as apiLib from "../../../lib/api";
import * as formatting from "../../../lib/formatting";
import * as state from "../../../lib/state";
import * as medicalRecords from "../../pages/administration/medical_records/medical-records";
import { Line, LineChart as LineChartGraph, LabelList, ResponsiveContainer, XAxis, YAxis, Tooltip } from "recharts";

interface Props {
	date_from: Date;
	date_to: Date;
	type: apiLib.CodeBookItemWithNumberId;
	setLoading: (value: boolean) => void;
	setData: (value: medicalRecords.Graph[]) => void;
	data?: medicalRecords.MeasurementData[];
}

export default function GeneralGraph(props: Props) {
	const [data, setData] = useState([] as medicalRecords.Graph[]);
	const [maximumValue, setMaximumValue] = useState(undefined as number | undefined);
	const { api } = state.useStateContext();

	useEffect(() => {
		if (props.type.id) {
			getGraphData();
		}
	}, [props.type?.id, props.date_from]);

	async function getGraphData() {
		props.setLoading(props.data ? false : true);
		const graphData = props.data ? props.data.filter(x => x.type == props.type.id) : await api.post("/users/measurements", {
			date_from: props.date_from,
			date_to: props.date_to,
			type: props.type.id
		}) as medicalRecords.Graph[];
		if (graphData.length > 0) {
			let result = formatting.createAverageMonthlyGraphData(graphData, true) as any;
			result = result.sort((a: any, b: any) => (formatting.parseDateStringFormat(a.data, "D.M.YYYY H:mm") as any) - (formatting.parseDateStringFormat(b.data, "D.M.YYYY H:mm") as any));
			await setData(result);
			await props.setData(result);
		}
		const maximumValue = apiLib.maximumValuesForMeasurement(props.type?.id);
		await setMaximumValue(maximumValue == undefined ? undefined : maximumValue.value);
		await props.setLoading(false);

	}

	const CustomTooltip = ({ active, payload, label }: any) => {
		if (active && payload && payload.length) {
			return (
				<>
					{payload[0].payload.value > 0 &&
						<div className="custom-tooltip">
							<div>Datum záznamu: <span className="general-graph-amount ">{payload[0].payload.data}</span></div>
							<div>{"Hodnota"}: <span className={"general-graph-amount " + (Number(maximumValue) < payload[0].payload.value ? " text-danger" : "")}>{payload[0].payload.value}</span></div>
						</div>
					}
				</>
			);
		}

		return null;
	};

	let lineIndex = 0;

	const renderCustomizedLabel = (props: any) => {
		const {
			x, y, width, height, value,
		} = props;
		const index = lineIndex;
		lineIndex = + 1;

		return (
			<text x={x} y={y + 5} offset="5" className="recharts-text recharts-label" textAnchor="middle">
				<tspan x={index == 0 && data.length > 1 ? x + 14 : x} dy="0.71em">{value}</tspan>
			</text>
		);
	};

	return (
		<>
			{data.length > 0 &&
				<ResponsiveContainer width="105%">
					<LineChartGraph
						data={data}
						margin={{
							top: 5,
							right: 30,
							left: 20,
							bottom: 5,
						}}
					>
						<XAxis dataKey="dateNumber" />
						<YAxis domain={[1, 5]} />
						<Tooltip content={<CustomTooltip />} />
						<Line isAnimationActive={false} type="monotone" dataKey="value" stroke="#8884d8">
							<LabelList content={renderCustomizedLabel} dataKey="value" position="bottom" />
						</Line>
					</LineChartGraph>
				</ResponsiveContainer>
			}
		</>
	);
} 