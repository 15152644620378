import Icon from "../../../modules/system/icons/Icon";
import * as React from "react";
import * as forms from "../../../lib/forms";
import * as api from "../../../lib/api";
import * as state from "../../../lib/state";

import FormItem, { FormItemOptions } from "./FormItem";


export interface FormsImageProps<TFormFields> {
	form: forms.Form<TFormFields>;
	field: keyof TFormFields;
	className?: string;
	formItemOptions?: FormItemOptions;
}

export default function FormsImage<TFormFields>(props: FormsImageProps<TFormFields>) {
	const { apiImages } = state.useStateContext();
	const image = props.form.getField(props.field).value as any;
	return (
		<FormItem
			form={props.form}
			field={props.field}
			options={props.formItemOptions}
			className={props.className}
		>
			<div className="forms-image-panel">
				{image == api.emptyGuid &&
					<div className="pt-5">
						<div className="forms-image-icon ">
							<Icon picture="Image" />
						</div>
						<div className="color-primary mt-2">
							Úvodní foto
						</div>
					</div>
				}
				{image != api.emptyGuid &&
					<div className="mt-2">
						<img style={{ maxHeight: 180 }} alt="pilulkoid" src={apiImages.getImageUrl(image)} />
					</div>
				}
			</div>
		</FormItem>
	);
}