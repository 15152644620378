/**
 * Seznam uživatelů
 */

import * as state from "../../../../lib/state";
import * as context from "../../../../context";
import * as lists from "../../../system/lists/lists";
import * as apiDoctors from "./api-doctors";
import * as api from "../../../../lib/api";

/**
 * Stav modulu
 */
export interface State {
	checked?: boolean;
}

export class Model implements state.StateModel {
	list: lists.StandardList<apiDoctors.Doctor>;
	stateContainer: state.StateContainer<State>;

	constructor(private context: context.StateContext) {
		this.list = new lists.StandardList<apiDoctors.Doctor>({
			context: context,
			title: "Seznam uživatelů",
			quickFilterPlaceholder: "Zadejte jméno...",
			filterSystem: _ => [
				api.qp("id_state" as any, "=", this.getChecked() ? 2 : undefined),
			],
			itemActionsList: { actions: [] },
			standardEntityApi: context.apiDoctors.doctors as any,
			sortingFields: [],
			grid: {
				minWidth: "1000px",
				columns: [
					{
						field: "name" as any,
						type: "string",
						title: "Jméno a příjmení",
					},
					{
						field: "address" as any,
						type: "string",
						title: "Adresa",
					},
					{
						field: "imported" as any,
						type: "string",
						width: "90px",
						title: "Import",
					},
					{
						field: "action" as any,
						width: "80px",
						type: "string",
						title: "Akce",
						preventSorting: true
					},
				]
			},
			defaultPageSize: 40,
			hideTabFilter: true,
			hideQueryMode: true
		});
		this.stateContainer = new state.StateContainer<State>({ checked: undefined }, context);
	}

	/**
	* Vrací kolekci stavových kontejnerů
	*/
	getStateContainers = () => [
		this.stateContainer,
		...this.list.getStateContainers(),
	]

	getChecked = () => {
		return this.stateContainer.get().checked;
	}

	loadData = async () => {
		await this.list.loadWithResetPage();
	}

	remove = async (id: string) => {
		await this.context.apiDoctors.remove(id);
		await this.list.load();
	}

	accept = async (item: any) => {
		item.id_state = 1;
		await this.context.apiDoctors.save(item.id, item);
		await this.list.load();
	}
}