/**
 * Seznam kategorií
 */
import React from "react";
import * as state from "../../../../lib/state";
import * as navigation from "../../../../lib/navigation";
import * as apiLib from "../../../../lib/api";
import Page from "../../../../components/templates/Page";
import List from "../../../system/lists/List";
import MyRequestAction from "../../../../components/controls/MyRequestAction";
import Button from "../../../../components/controls/Button";
import Link from "../../../../components/controls/Link";

function CategoriesListPage() {
	const { categoriesList } = state.useStateContext();
	const { api } = state.useStateContext();
	const { authorization } = state.useStateContext();
	const { standardDialogs } = state.useStateContext();

	function actions(item: any) {
		const items = [
			{
				title: "Smazat kategorii",
				action: async (item: any) => {
					await standardDialogs.openYesNoDialog("Opravdu chcete smazat kategorii?") === "yes"
						? categoriesList.remove(item.id)
						: undefined
				},
			},
		];
		return items as any;
	};

	async function handleClear() {
		await categoriesList.stateContainer.merge(() => ({ checked: undefined }));
		await categoriesList.list.clearFilter();
	}

	return (
		<Page title="Seznam kategorií" description="">
			{authorization.isAdmin() &&
				<div className="page-content-container users-list-page">
					<div className="text-light ml-3 mb-4">
						<div className="homepage-responsive-title d-inline">
							Seznam kategorií
						</div>
					</div>
					<div className="ml-3 mr-3">
						<div className="mt-3 mb-3">
							<Button className="d-inline-block mr-2 font-weight-bold" onClick={() => navigation.to("/administrace/uzivatelske-kategorie/" + apiLib.emptyGuid)}>
								Přidat kategorii
							</Button>
						</div>
						<div className="form-filter u-mb-md">
							<div id="records" className="form-filter__inner row">
								<div className="col-md-5 col-xs-12 mb-3">
									<label htmlFor="filter-search" className="inp-label u-vhide">
										Zadejte název
									</label>
									<span className="inp-fix">
										<input
											type="text"
											id="inp-filter-search"
											placeholder={categoriesList.list.options.quickFilterPlaceholder}
											className="inp-text inp-text--filter"
											value={categoriesList.list.getFilterFulltextPhrase()}
											onChange={(e) => categoriesList.list.setFilterFulltextPhrase(e.target.value)}
										/>
									</span>
								</div>
								<div className="col-xs-4 col-12 col-md-auto u-mb-md ml-md-auto">
									<div className="d-inline mr-2">
										<button type="submit" className="btn btn--filter" onClick={categoriesList.list.loadWithResetPage}>
											<span className="btn__text">
												Vyhledat
											</span>
										</button>
									</div>
									<div className="d-inline">
										<button type="submit" className="btn btn--filter" onClick={handleClear}>
											<span className="btn__text">
												Reset
											</span>
										</button>
									</div>
								</div>
							</div>
						</div>
						<List
							itemNode={(item, field) => {
								switch (field) {
									case "name": return <Link to={"/administrace/uzivatelske-kategorie/" + item.id}>{item[field]}</Link>;
									case "color": return <span style={{ width: 24, height: 24, background: item.color, display: "block" }}></span>;
									case "action" as any: return <MyRequestAction className="ml-2 user-list-page-action-sync" id="categories" item={item} actions={actions(item)} />;
									default: return undefined;
								}
							}}
							simpleTable list={categoriesList.list} />
					</div>
				</div>
			}
		</Page >
	);
}

export default state.bindContainers(
	CategoriesListPage,
	context => context.categoriesList
);