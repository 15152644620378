/**
 * Parsování a formátování.
 */

import numeral from "numeral";
import "numeral/locales";
import * as apiFiles from "../modules/system/file/api-file";
import moment from "moment";
import * as medicalRecords from "../modules/pages/administration/medical_records/medical-records";

export function init() {
	numeral.locale("cs");
}

/**
 * Parsuje řetězec jako celé číslo. Vrací: 
 * 		- celé číslo, pokud je řetězec platné celé číslo
 * 		- null, pokud je vstupní řetězec prázdný nebo null
 * 		- NaN, pokud je vstupní řetězec v neplatném formátu
 */
export function parseWholeNumber(value: string | undefined) {
	if (value === undefined || value.trim() == "") {
		return undefined;
	}
	const num = numeral(value).value();
	if (num === null) {
		return NaN;
	} else {
		return num;
	}
}

export const monthNames = ["Leden", "Únor", "Březen", "Duben", "Květen", "Červen",
	"Červenec", "Srpen", "Září", "Říjen", "Listopad", "Prosinec"
];

export const fileExtensions = [
	{ type: apiFiles.FileType.Reports, extensions: ["pdf"] },
	{ type: apiFiles.FileType.Foto, extensions: ["jpg", "jpeg", "png", "gif", "svg", "webp", "ico", "bmp", "tiff"] },
	{ type: apiFiles.FileType.Video, extensions: ["mp4", "mov", "wmv", "avi", "flv", "mkv", "webm", "mpeg"] },
	{ type: apiFiles.FileType.Audio, extensions: ["mp3", "flac", "m4a", "wav", "wma", "aac", "ogg"] },
	{ type: apiFiles.FileType.Documents, extensions: ["doc", "docx", "xls", "xlsx", "txt", "ods", "odt", "ppt", "pptx"] },
];

/**
 *  Parsuje řetězec jako datum.
 */
export function parseDate(value: string) {
	const normalizedValue = value.replace(/ /g, "");
	let mdate = moment(normalizedValue, "D.M.YYYY", true);
	if (!mdate.isValid()) {
		mdate = moment(normalizedValue, moment.ISO_8601);
	}
	return mdate.isValid() ? mdate.toDate() : undefined;
}

/**
 * Zformátuje číslo jako celé číslo (zaokrouhlí případnou desetinou část).
 */
export function formatWholeNumber(value: number | undefined) {
	if (value === undefined) {
		return "";
	} else {
		return numeral(value).format();
	}
}

/**
 * Zformátuje číslo jako měnu.
 */
export function formatCurrency(value: number | undefined) {
	if (value === undefined) {
		return "";
	} else {
		return numeral(value).format() + " Kč";
	}
}

/**
 * Naformátuje hodnotu jako procento.
 */
export function formatPercent(value: number | undefined) {
	if (value === undefined) {
		return "";
	} else {
		return numeral(value * 100).format() + " %";
	}
}

/**
 * Naformátuje hodnotu jako datum.
 */
export function formatDate(value: Date | undefined, options?: { format?: "long" | "iso" | "strip" | "strip-long" }) {
	if (value === undefined) {
		return "";
	} else {
		const now = new Date();
		let format = "D. M. YYYY";

		switch (options?.format) {
			case "iso":
				format = "YYYY-MM-DD";
				break;
			case "long":
				format = "D. MMMM. YYYY";
				break;
			case "strip":
				if (now.getFullYear() === value.getFullYear())
					format = "D. M."; break;
			case "strip-long":
				format = now.getFullYear() === value.getFullYear() ? "D. MMMM" : "D. MMMM YYYY"; break;
		}

		return moment(value).format(format);
	}
}

/**
 * Naformátuje rozsah dat
 */
export function formatDateRange(dateFrom?: Date, dateTo?: Date, withYear?: boolean) {
	if (!dateFrom) {
		return "";
	}
	else if (dateTo !== undefined) {
		// obě data vyplněná
		if (dateFrom.getDate() === dateTo.getDate()
			&& dateFrom.getMonth() === dateTo.getMonth()
			&& dateFrom.getFullYear() === dateTo.getFullYear()) {

			// obě data stejný den
			return formatDate(dateFrom);
		} else if (dateFrom.getMonth() === dateTo.getMonth()
			&& dateFrom.getFullYear() === dateTo.getFullYear()) {

			// obě data stejný měsíc a rok
			return dateFrom.getDate() + ". - " + formatDate(dateTo);
		} else {
			return formatDate(dateFrom) + " - " + formatDate(dateTo);
		}
	} else {
		return formatDate(dateTo == undefined ? dateFrom : dateTo);
	}
}

/**
 * Naformátuje hodnotu jako datum s časem nebo pouze čas, jedná-li se o dnešek
 */
export function formatDateTime(value: Date | undefined, options?: { format?: "strip" }) {
	if (value === undefined) {
		return "";
	} else {
		const now = new Date();
		let format = "D. M. YYYY H:mm";

		if (options?.format === "strip") {
			if (moment(value).isSame(now, "day")) {
				format = "H:mm";
			} else if (moment(value).isSame(now, "year")) {
				format = "D. M.";
			}
		}

		return moment(value).format(format);
	}
}

export function formatTime(value: Date | undefined) {
	if (value === undefined) {
		return "";
	}
	return moment(value).format("H:mm");
}

export function formatCustomDate(value: Date | undefined, format: string) {
	if (value === undefined) {
		return "";
	} else {
		return moment(value).format(format);
	}
}

export function pluralize(num: number, one: string, twofour: string, zeroOrMore: string) {
	if (num == 1) {
		return one;
	} else if (num >= 2 && num <= 4) {
		return twofour;
	} else if (num == 0 || num >= 5) {
		return zeroOrMore;
	}
}

/**
 * Naformátuje hodnotu jako datum mínus počet dní
 */
export function addDay(value: Date, count: number) {
	const m = moment;
	return moment(value).add(count, "days").toDate();
}

/**
 * Vygeneruje rodné číslo
 */
export function generateRegistrationNumber(value: Date, genderId: number) {
	const date = formatDate(value, { format: "iso" }).trim();
	return date.substr(2, 2) + (genderId == 1 ? (Number(date.substr(5, 2)) + 50).toString() : date.substr(5, 2)) + date.substr(8, 6);
}

export function calculateAge(value: Date) {
	return Math.floor((new Date() as any - new Date(value).getTime()) / 3.15576e+10);
}

export function romanize(num: number) {
	if (isNaN(num))
		return NaN;
	let digits = String(+num).split(""),
		key = ["", "C", "CC", "CCC", "CD", "D", "DC", "DCC", "DCCC", "CM",
			"", "X", "XX", "XXX", "XL", "L", "LX", "LXX", "LXXX", "XC",
			"", "I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX"],
		roman = "",
		i = 3;
	while (i--)
		roman = (key[+digits.pop()! + (i * 10)] || "") + roman;
	return Array(+digits.join("") + 1).join("M") + roman;
}

/**
 * Validace pro rodné číslo
 */
export function identificationNumberValidation(value: string) {
	if (value == "") {
		return "";
	}

	value = value.replace("/", "");

	let rodneCisloResults = {
		ok: 1,
		prilisKratke: 2,
		prilisDlouhe: 3,
		nepripustnaKoncovka000: 4,
		neniDelitelne11: 5,
		nevalidnircecp: 6,
		mesicNevalidni: 7,
		denNevalidni: 8,
		rodneCisloObsahujeJineZnakyNezCislice: 9,
	}

	let ePohlavi = {
		Muz: 1,
		Zena: 2
	}
	let RodneCisloData = function () {
		return { ecp: false, rcplus: false, ok: true, pohlavi: 0, datumNarozeni: new Date() };
	}
	function isNumber(n: any) {
		return /^-?\d+$/.test(n);
	}

	function parseRodneCislo(rodneCislo: any) {
		/// <param name="rodneCislo" type="String">Description</param>
		rodneCislo = rodneCislo.trim();
		if (!isNumber(rodneCislo)) {
			return rodneCisloResults.rodneCisloObsahujeJineZnakyNezCislice;
		}
		if (rodneCislo.length < 9) {
			return rodneCisloResults.prilisKratke;
		}
		let rok = Number(rodneCislo.substr(0, 2));
		let mesic = Number(rodneCislo.substr(2, 2));
		let den = Number(rodneCislo.substr(4, 2));
		let koncovka = rodneCislo.substr(6, rodneCislo.length - 6);

		if (rodneCislo.length > 10) {
			return rodneCisloResults.prilisDlouhe;
		} else if (9 == rodneCislo.length) {
			if (koncovka == "000") {
				return rodneCisloResults.nepripustnaKoncovka000;
			}
		} else {
			// zde je delka rc 10
			if (Number(rodneCislo) % 11 != 0) {
				return rodneCisloResults.neniDelitelne11;
			}
		}

		let data = RodneCisloData();
		data.pohlavi = mesic > 50 ? ePohlavi.Zena : ePohlavi.Muz;
		if (data.pohlavi == ePohlavi.Zena) {
			mesic = mesic - 50;
		}
		if (mesic > 20) {
			data.rcplus = true;
			mesic = mesic - 20;
		}
		if (den > 40) {
			data.ecp = true;
		}
		if (data.ecp && data.rcplus) {
			return rodneCisloResults.nevalidnircecp;
		}

		if (koncovka.length == 3) {
			if (rok > 53) {
				rok += 1800;
			} else {
				rok += 1900;
			}
		} else if (koncovka.length == 4) {
			if (rok > 53) {
				rok += 1900;
			} else {
				rok += 2000;
			}
		}
		if (mesic < 1 || mesic > 12) {
			return rodneCisloResults.mesicNevalidni;
		}
		if (den < 1 || den > 31) {
			return rodneCisloResults.denNevalidni;
		}
		data.datumNarozeni = new Date(rok, mesic - 1, den);
		return data;
	};

	let rc = value;

	let test = parseRodneCislo(rc);

	function check() {
		if (rc != null && rc != "" && !(typeof test === "object" && test !== null)) {
			let text = "Chyba, nelze pokračovat s tímto rodným číslem";
			switch (test) {
				case 2:
					text = "Rodné číslo je příliš krátké."
					break;
				case 3:
					text = "Rodné číslo je příliš dlouhé."
					break;
				case 4:
					text = "Rodné číslo má špatnou koncovku."
					break;
				case 5:
				case 6:
				case 7:
				case 8:
					text = "Rodné číslo má špatný formát."
					break;
				case 9:
					text = "Rodné číslo jiné znaky než čísla."
					break;
			}
			return text;
		}
		else {
			return "";
		}
	}
	return check();
}

/**
 * Naformátuje hodnotu jako název dne.
 */
export function formatDayName(value: Date | null) {
	if (value === null) {
		return 0;
	} else {
		return moment(value).locale("cs").format("dddd");
	}
}

/**
 * Naformátuje hodnotu jako číslo měsíce.
 */
export function formatMonthNumber(value: Date | null) {
	if (value === null) {
		return 0;
	} else {
		return moment(value).month();
	}
}

export function getNameDay() {
	let data = [];
	data.push(["Nový rok, Den obnovy samostatného českého státu", "Karina", "Radmila", "Diana", "Dalimil", "Tři králové", "Vilma", "Čestmír", "Vladan", "Břetislav", "Bohdana", "Pravoslav", "Edita", "Radovan", "Alice", "Ctirad", "Drahoslav", "Vladislav", "Doubravka", "Ilona", "Běla", "Slavomír", "Zdeněk", "Milena", "Miloš", "Zora", "Ingrid", "Otýlie", "Zdislava", "Robin", "Marika"]);
	data.push(["Hynek", "Nela", "Blažej", "Jarmila", "Dobromila", "Vanda", "Veronika", "Milada", "Apolena", "Mojmír", "Božena", "Slavěna", "Věnceslav", "Valentýn", "Jiřina", "Ljuba", "Miloslava", "Gizela", "Patrik", "Oldřich", "Lenka", "Petr", "Svatopluk", "Matěj", "Liliana", "Dorota", "Alexandr", "Lumír", "Horymír"]);
	data.push(["Bedřich", "Anežka", "Kamil", "Stela", "Kazimír", "Miroslav", "Tomáš", "Gabriela", "Františka", "Viktorie", "Anděla", "Řehoř", "Růžena", "Rút, Matylda", "Ida", "Elena, Herbert", "Vlastimil", "Eduard", "Josef", "Světlana", "Radek", "Leona", "Ivona", "Gabriel", "Marián", "Emanuel", "Dita", "Soňa", "Taťána", "Arnošt", "Kvido"]);
	data.push(["Hugo", "Erika", "Richard", "Ivana", "Miroslava", "Vendula", "Heřman, Hermína", "Ema", "Dušan", "Darja", "Izabela", "Julius", "Aleš", "Vincenc", "Anastázie", "Irena", "Rudolf", "Valérie", "Rostislav", "Marcela", "Alexandra", "Evženie", "Vojtěch", "Jiří", "Marek", "Oto", "Jaroslav", "Vlastislav", "Robert", "Blahoslav"]);
	data.push(["Svátek práce", "Zikmund", "Alexej", "Květoslav", "Klaudie", "Radoslav", "Stanislav", "Den vítězství", "Ctibor", "Blažena", "Svatava", "Pankrác", "Servác", "Bonifác", "Žofie", "Přemysl", "Aneta", "Nataša", "Ivo", "Zbyšek", "Monika", "Emil", "Vladimír", "Jana", "Viola", "Filip", "Valdemar", "Vilém", "Maxmilián", "Ferdinand", "Kamila"]);
	data.push(["Laura", "Jarmil", "Tamara", "Dalibor", "Dobroslav", "Norbert", "Iveta, Slavoj", "Medard", "Stanislava", "Gita", "Bruno", "Antonie", "Antonín", "Roland", "Vít", "Zbyněk", "Adolf", "Milan", "Leoš", "Květa", "Alois", "Pavla", "Zdeňka", "Jan", "Ivan", "Adriana", "Ladislav", "Lubomír", "Petr a Pavel", "Šárka"]);
	data.push(["Jaroslava", "Patricie", "Radomír", "Prokop", "Cyril, Metoděj", "Den upálení mistra Jana Husa", "Bohuslava", "Nora", "Drahoslava", "Libuše, Amálie", "Olga", "Bořek", "Markéta", "Karolína", "Jindřich", "Luboš", "Martina", "Drahomíra", "Čeněk", "Ilja", "Vítězslav", "Magdaléna", "Libor", "Kristýna", "Jakub", "Anna", "Věroslav", "Viktor", "Marta", "Bořivoj", "Ignác"]);
	data.push(["Oskar", "Gustav", "Miluše", "Dominik", "Kristián", "Oldřiška", "Lada", "Soběslav", "Roman", "Vavřinec", "Zuzana", "Klára", "Alena", "Alan", "Hana", "Jáchym", "Petra", "Helena", "Ludvík", "Bernard", "Johana", "Bohuslav", "Sandra", "Bartoloměj", "Radim", "Luděk", "Otakar", "Augustýn", "Evelína", "Vladěna", "Pavlína"]);
	data.push(["Linda, Samuel", "Adéla", "Bronislav", "Jindřiška", "Boris", "Boleslav", "Regína", "Mariana", "Daniela", "Irma", "Denisa", "Marie", "Lubor", "Radka", "Jolana", "Ludmila", "Naděžda", "Kryštof", "Zita", "Oleg", "Matouš", "Darina", "Berta", "Jaromír", "Zlata", "Andrea", "Jonáš", "Václav, Den české státnosti", "Michal", "Jeroným"]);
	data.push(["Igor", "Olivie, Oliver", "Bohumil", "František", "Eliška", "Hanuš", "Justýna", "Věra", "Štefan, Sára", "Marina", "Andrej", "Marcel", "Renáta", "Agáta", "Tereza", "Havel", "Hedvika", "Lukáš", "Michaela", "Vendelín", "Brigita", "Sabina", "Teodor", "Nina", "Beáta", "Erik", "Šarlota, Zoe", "Den vzniku samostatného československého státu", "Silvie", "Tadeáš", "Štěpánka"]);
	data.push(["Felix", "Památka zesnulých (dušičky)", "Hubert", "Karel", "Miriam", "Liběna", "Saskie", "Bohumír", "Bohdan", "Evžen", "Martin", "Benedikt", "Tibor", "Sáva", "Leopold", "Otmar", "Mahulena, Den boje za svobodu a demokracii", "Romana", "Alžběta", "Nikola", "Albert", "Cecílie", "Klement", "Emílie", "Kateřina", "Artur", "Xenie", "René", "Zina", "Ondřej"]);
	data.push(["Iva", "Blanka", "Svatoslav", "Barbora", "Jitka", "Mikuláš", "Benjamín", "Květoslava", "Vratislav", "Julie", "Dana", "Simona", "Lucie", "Lýdie", "Radana", "Albína", "Daniel", "Miloslav", "Ester", "Dagmar", "Natálie", "Šimon", "Vlasta", "Adam, Eva, Štědrý den", "1. svátek vánoční", "Štěpán, 2. svátek vánoční", "Žaneta", "Bohumila", "Judita", "David", "Silvestr"]);

	let date = new Date();
	let day = date.getDate() - 1;
	let month = date.getMonth();

	return data[month][day];
}

export function getWeekDays(date: Date) {
	const weekStart = moment(date).startOf("week");

	const days = [];
	for (let i = 0; i <= 6; i++) {
		days.push(moment(weekStart).add(i, "days"));
	}

	return days;
}

export function getNoTime(date: Date) {
	const item = moment(date).startOf("day").toDate();
	return item;
}

export function formatDateForInputDateTime(date: Date) {
	return new Date(date.getTime() + new Date().getTimezoneOffset() * -60 * 1000).toISOString().slice(0, 19);
}

export function substractMonthsFromDate(numberOfMonth: number, date: Date) {
	const result = moment(date).subtract(numberOfMonth, "months").toDate();
	return result;
}

export function substractDaysFromDate(numberOfDays: number, date: Date) {
	const result = moment(date).subtract(numberOfDays, "days").toDate();
	return result;
}

/**
 * Nastaví čas
 */
export function setTimeFromString(date: Date, hours: number, minutes: number, seconds: number) {
	var setDate = moment(date).toDate();
	setDate.setHours(hours);
	setDate.setMinutes(minutes);
	setDate.setSeconds(seconds);
	return setDate;
}

interface Graph {
	data: string,
	value: number,
	value2: number,
	dateNumber?: number;
	type: number;
	id: number;
	sid: number;
}

/**
 * Generování průměru za poslední rok
 */
export function createAverageYearGraphData(graphData: medicalRecords.Graph[]) {
	const months = [] as Graph[];
	for (let i = 0; i < 13; i++) {
		const date = substractMonthsFromDate(i, new Date());
		let data = graphData.sort((a, b) => (new Date(b.data) as any) - (new Date(a.data) as any)).filter(x => x.value != 0 && new Date(x.data).getMonth() == date.getMonth() && new Date(x.data).getFullYear() == date.getFullYear()) as medicalRecords.Graph[];
		if (data[0]) {
			months.push(
				{
					data: formatDate(new Date(data[0].data)),
					value: Number((data.reduce((b, a) => Number(b.toFixed(1)) + Number(a.value), 0) / (data.length == 0 ? 1 : data.length)).toFixed(1)),
					value2: Number((data.reduce((b, a) => Number(b.toFixed(1)) + Number(a.value2), 0) / (data.length == 0 ? 1 : data.length)).toFixed(1)),
					type: data[0].type,
					id: data[0]?.id,
					sid: data[0]?.sid!,
				}
			);
		}
	}
	return months;
}

/**
 * Generování průměru za poslední měsíc
 */
export function createAverageMonthlyGraphData(graphData: medicalRecords.Graph[], showAll?: boolean) {
	const days = [] as Graph[];
	for (let i = 0; i < 33; i++) {
		const date = substractDaysFromDate(i, new Date());
		let data = graphData
			.sort((a, b) => (new Date(b.data) as any) - (new Date(a.data) as any))
			.filter(x => x.value != 0 && new Date(x.data).getMonth() == date.getMonth() && new Date(x.data).getFullYear() == date.getFullYear() && new Date(x.data).getDate() == date.getDate()
			) as medicalRecords.Graph[];
		if (showAll) {
			data.map(i =>
				days.push(
					{
						data: formatDateTime(new Date(i.data)),
						value: Number(i.value),
						value2: Number(i.value2),
						type: i.type,
						id: i?.id,
						sid: i?.sid!,
					}
				)
			);
		}
		else if (data[0]) {
			days.push(
				{
					data: formatDate(new Date(data[0].data)),
					value: Number((data.reduce((b, a) => Number(b.toFixed(1)) + Number(a.value), 0) / (data.length == 0 ? 1 : data.length)).toFixed(1)),
					value2: Number(data[0].value2),
					type: data[0].type,
					id: data[0]?.id,
					sid: data[0]?.sid!,
				}
			);
		}
	}
	return days;
}

/**
 * Generování průměru za posledních x dní
 */
export function createLastWeeklyGraphData(graphData: medicalRecords.Graph[], numberOfDays: number) {
	const days = [] as Graph[];
	const dateFrom = substractDaysFromDate(numberOfDays, new Date());
	const dateTo = new Date();

	let data = graphData
		.sort((a, b) => (new Date(b.data) as any) - (new Date(a.data) as any))
		.filter(x => x.value != 0 && moment(new Date(x.data)).isBetween(dateFrom, dateTo)
		) as medicalRecords.Graph[];
	data.map(x =>
		days.push(
			{
				data: formatDateTime(new Date(x.data)),
				value: Number(x.value),
				value2: Number(x.value2),
				type: x.type,
				id: x.id,
				sid: x.sid!
			}
		)
	)

	return days;
}

export function createLastYearGraphData(graphData: medicalRecords.Graph[]) {
	const months = [] as Graph[];
	for (let i = 0; i < 13; i++) {
		const date = substractMonthsFromDate(i, new Date());
		let data = graphData.sort((a, b) => (new Date(b.data) as any) - (new Date(a.data) as any)).filter(x => x.value != 0 && new Date(x.data).getMonth() == date.getMonth() && new Date(x.data).getFullYear() == date.getFullYear()) as medicalRecords.Graph[];
		months.push(
			{
				data: formatDateTime(data[0] == undefined ? undefined : new Date(data[0].data)),
				dateNumber: romanize(date.getMonth() == 0 ? 12 : date.getMonth()) as number,
				value: Number(data[0]?.value),
				value2: Number(data[0]?.value2),
				type: data[0]?.type,
				id: data[0]?.id,
				sid: data[0]?.sid!,
			}
		);
	}
	return months;
}

/**
 * Generování průměru
 */
export function createAverageNumber(data: number[]) {
	const filter = data.filter(x => x != 0);
	const sum = filter.reduce((b, a) => a + b);
	const result = Number((sum / filter.length).toFixed(1));
	return result;
}

/**
 * Převede base64 url na File
 */
export function dataURLtoFile(dataurl: string, filename: string) {
	var arr = dataurl.split(',') as any,
		mime = arr[0].match(/:(.*?);/)[1],
		bstr = atob(arr[1]),
		n = bstr.length,
		u8arr = new Uint8Array(n);

	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}

	return new File([u8arr], filename, { type: mime });
}

/**
 * Převede datum na formát s podtržítky
 */
export function formatFileDate() {
	return formatDateTime(new Date()).replace(/\  /g, ' ').replace(/ /g, "_").replace(":", "_").replace(/\./g, '');
}

export function createHiddenInput() {
	const body = document.getElementsByTagName("body")[0];
	const input = document.createElement("input");
	input.type = "file";
	(input as any).capture = "user"
	input.style.display = "none";
	body.appendChild(input);
	return input;
}

export function parseDateStringFormat(value: string, format: string) {
	var date = moment(value, format);
	return date.toDate();
}