import React, { useState } from "react";
import Input, { InputProps } from "../../../components/controls/Input";
import Button from "../../../components/controls/Button";
import * as forms from "../../../lib/forms";
import * as common from "../../../lib/common";
import * as api from "../../../lib/api";
import * as state from "../../../lib/state";
import Icon from "../icons/Icon";

export interface File {
	id: string;
	name: string;
}

export interface FileUploadProps {
	className?: string;
	accept?: string;
	value?: File;
	fallbackErrorMessage?: string;
	onChange?: (file?: File) => void;
	form: forms.Form<any>;
	field: keyof common.SubType<any, File>;
	isCustom?: boolean;
	content?: React.ReactNode;
	simple?: boolean;
}

function createHiddenInput(simple?: boolean) {
	const body = document.getElementsByTagName("body")[0];
	const input = document.createElement("input");
	input.type = "file";
	if (!simple) {
		(input as any).capture = "user"
	}

	input.style.display = "none";
	body.appendChild(input);
	return input;
}

function removeHiddenInput(input: HTMLInputElement) {
	const body = document.getElementsByTagName("body")[0];
	body.removeChild(input);
}

export default function FileUpload(props: FileUploadProps) {

	const [justUploading, setJustUploading] = useState(false);
	const [uploadingFileName, setUploadingFileName] = useState("");
	const context = state.useStateContext();
	const field = props.form.getField(props.field);


	async function apiUpload(file: globalThis.File) {
		return context.apiFile.upload(file, { suppressErrorNotification: true });
	}

	async function uploadFile() {
		const input = createHiddenInput(props.simple);
		input.accept = props.accept ?? "";
		input.onchange = async e => {
			const file = (e as any).target.files![0];
			let uploadResult: api.OperationResponse | undefined = undefined;
			try {
				uploadResult = await common.withIndication({
					start: async () => setUploadingFileName(file.name),
					indicateStart: async () => setJustUploading(true),
					finish: async () => { setJustUploading(false); setUploadingFileName(""); },
					exec: () => apiUpload(file)
				});
			} catch (err) {
				await context.standardDialogs.openInformationDialog((props.fallbackErrorMessage ?? "Soubor se nepodařilo nahrát.") + " " + api.getErrorMessage(err, "Neznámá chyba."));
			}
			removeHiddenInput(input);
			if (uploadResult && props.onChange) {
				props.onChange({
					id: uploadResult.id as string,
					name: file.name
				});
				setUploadingFileName(file.name);
			}
		};
		input.click();
	}

	function clearFile() {
		if (props.onChange) {
			props.onChange(undefined);
			setUploadingFileName("");
		}
	}

	async function handlClick() {
		if (!field.value) {
			await uploadFile();
		}
		else {
			clearFile();
		}
	}

	function getFileName() {
		return field.value?.name == undefined ? "" : field.value?.name;
	}

	return (
		<>
			{props.isCustom &&
				<div onClick={handlClick}>
					{props.content}
				</div>
			}
			{!props.isCustom &&
				<div className={"file-upload " + (props.className ?? "")}>
					<Input disabled={true} className="file-upload__input" value={getFileName()} />
					<Button disabled={justUploading} onClick={handlClick}>
						{!justUploading && field.value === undefined &&
							<Icon className="ml-2 color-primary" picture="Download" />
						}
						{!justUploading && field.value &&
							<Icon className="ml-2 color-primary" picture="Remove" />
						}
						{justUploading &&
							<span className="spinner-border file-upload__spinner"></span>
						}
					</Button>
				</div>
			}
		</>
	);
}