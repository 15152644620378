/**
 * Modul Ordinace
 */

import * as state from "../../../lib/state";
import * as api from "../../../lib/api";
import * as context from "../../../context";
import * as lists from "../../system/lists/lists";

/**
 * Stav modulu
 */
interface State {
}

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	stateContainer: state.StateContainer<State>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({}, context);
	}

	/**
	* Vrací kolekci stavových kontejnerů
	*/
	getStateContainers = () => [
		this.stateContainer,
	]
}