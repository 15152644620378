/**
 * Stránka EKGChart
 */
import React, { useState, useEffect } from "react";
import * as apiLib from "../../../lib/api";
import * as formatting from "../../../lib/formatting";
import * as state from "../../../lib/state";
import * as medicalRecords from "../../pages/administration/medical_records/medical-records";

interface Props {
	date_from: Date;
	date_to: Date;
	type: apiLib.CodeBookItemWithNumberId;
	setLoading: (value: boolean) => void;
	setData: (value: medicalRecords.Graph[]) => void;
	loading: boolean;
}

export default function EKGChart(props: Props) {
	const [data, setData] = useState([] as medicalRecords.Graph[]);
	const { api } = state.useStateContext();

	async function handleDownload(item: medicalRecords.Graph) {
		const sid = item.sid;
		props.setLoading(true);
		setTimeout(async () => {
			const response = await api.post("/users/measurement/pdf/" + sid, {}) as any;
			props.setLoading(false);
			if (response) {
				const linkSource = `data:application/pdf;base64,${response.data}`;
				const downloadLink = document.createElement("a");
				const fileName = "EKG_" + formatting.formatDateTime(item.data) + ".pdf";
				downloadLink.href = linkSource;
				downloadLink.download = fileName;
				downloadLink.click();
			}
		}, 2000)
	}

	useEffect(() => {
		async function getGraphData() {
			props.setLoading(true);
			let graphData = await api.post("/users/measurements", {
				date_from: formatting.setTimeFromString(props.date_from, 0, 0, 0),
				date_to: formatting.setTimeFromString(props.date_from, 23, 59, 59),
				type: props.type.id
			}) as medicalRecords.Graph[];
			props.setLoading(false);

			await setData(graphData);
			await props.setData(graphData);
			await props.setLoading(false);
		}
		getGraphData();
	}, [props.type.id, props.date_from]);

	return (
		<>
			{data.length > 0 && !props.loading &&
				<div style={{ width: "95%" }} className="widget-list-panel-item">
					<div className="widget-list-panel-item-container">
						{data.map((i, index) =>
							<div key={index}>
								<div>
									<div onClick={() => handleDownload(i)} className="d-inline">
										{formatting.formatDateTime(i.data)}
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			}
		</>
	);
} 