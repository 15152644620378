/**
 * Modul detailu lékaře
 */

import * as state from "../../../../lib/state";
import * as api from "../../../../lib/api";
import * as notification from "../../../../lib/notification";
import * as navigation from "../../../../lib/navigation";
import * as context from "../../../../context";
import * as forms from "../../../../lib/forms";
import * as doctors from "../../home/widgets/doctors/doctors";

/**
 * Stav modulu
 */
interface State {
}

export interface FormFields {
	id: string;
	name: string;
	address: string;
	phone: string;
	phone2: string;
	email: string;
	type: any;
	id_image: string;
	id_state: number;
	street: string;
	zip_code: string;
	city: string;
	web: string;
	imported: boolean;
	sys_date_create: Date;
}

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	private stateContainer: state.StateContainer<State>;
	public form: forms.Form<FormFields>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({}, context);
		this.form = new forms.Form<FormFields>({
			fields: {
				id: {
					title: "",
					defaultValue: api.emptyGuid
				},
				id_state: {
					defaultValue: 1,
					title: ""
				},
				name: {
					title: "Jméno a příjmení",
					defaultValue: "",
					required: true,
					validate: (value) => value.trim().length === 0 ? "Vyplňte pole" : "",
					readOnly: (value) => this.form.getField("imported").value
				},
				address: {
					title: "Adresa",
					defaultValue: "",
					readOnly: (value) => this.form.getField("imported").value
				},
				email: {
					defaultValue: "",
					title: "Email",
					readOnly: (value) => this.form.getField("imported").value
				},
				id_image: {
					defaultValue: api.emptyGuid,
					title: "",
					readOnly: (value) => this.form.getField("imported").value
				},
				web: {
					defaultValue: "",
					title: "Web",
					readOnly: (value) => this.form.getField("imported").value
				},
				phone: {
					defaultValue: "",
					title: "Telefon",
					readOnly: (value) => this.form.getField("imported").value
				},
				phone2: {
					defaultValue: "",
					title: "Telefon2",
					readOnly: (value) => this.form.getField("imported").value
				},
				type: {
					defaultValue: { id: 1, name: doctors.DoctorType.title(1) },
					title: "Obor",
					readOnly: (value) => this.form.getField("imported").value
				},
				street: {
					defaultValue: "",
					title: "",
					readOnly: (value) => this.form.getField("imported").value
				},
				zip_code: {
					defaultValue: "",
					title: "",
					readOnly: (value) => this.form.getField("imported").value
				},
				city: {
					defaultValue: "",
					title: "",
					readOnly: (value) => this.form.getField("imported").value
				},
				imported: {
					defaultValue: false,
					title: ""
				},
				sys_date_create: {
					defaultValue: new Date(),
					title: ""
				},
			}
		}, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			this.stateContainer,
			...this.form.getStateContainers()
		];
	}

	loadData = async (id: string) => {
		if (id != api.emptyGuid) {
			const doctor = await this.context.api.get("/doctors/" + id) as doctors.Doctor;
			if (doctor) {
				doctor.type = { id: doctor.type as any, name: doctors.DoctorType.title(doctor.type as any) };
				this.form.setFields(doctor as any);
			}
		}
		else {
			this.form.clearFields();
		}
	}

	save = async () => {
		await this.form.validate();
		if (this.form.isValid()) {
			const data = this.form.getFields();
			data.type = data.type.id;
			const doctor = await this.context.api.put("/doctors/" + this.form.getField("id").value, data);
			if (doctor) {
				await notification.successMessage("Lékař byl úspěšně uložen.");
				navigation.to("/administrace/lekari/" + doctor.id);
			}
		}
		else {
			await notification.errorMessage("Formulář obsahuje neúplné informace nebo chyby. Opravte prosím červeně vyznačené položky a poté akci opakujte.");
		}
	}

	/**
	 * Provede reset formuláře
	 */
	reset = async () => {
		await Promise.all([
			this.form.clearFields(),
		]);
	}
}