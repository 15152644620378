/**
 * Stránka GeneralGraph
 */
import React from "react";
import * as state from "../../../lib/state";
import { PieChart, Pie, Cell, Legend, ResponsiveContainer } from "recharts";
import Spinner from "../../../components/controls/Spinner";

interface Props {
	data: any[];
	loading: boolean;
}

export default function PieChartGraph(props: Props) {
	const { api } = state.useStateContext();
	const data = props.data;

	const RADIAN = Math.PI / 180;
	const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, value, name, data }: any) => {
		const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
		const x = cx + radius * Math.cos(-midAngle * RADIAN);
		const y = cy + radius * Math.sin(-midAngle * RADIAN);

		return (
			<text key={index} x={x * 1.38} y={y * 1.4} fill="white" textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
				{props.data[index].value}
			</text>
		);
	};

	const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#F3E0BE", "pink", "#98B4D4", "#dc3545", "#B565A7"];

	return (
		<>
			<div className="pie-chart-container" >
				{props.loading &&
					<Spinner className="mr-2" small />
				}
				<ResponsiveContainer width="99%" height="99%">
					<PieChart>
						<Legend layout="vertical" verticalAlign="top" align="left" />
						<Pie isAnimationActive={false} transform="scale(1.4)" labelLine={false} label={renderCustomizedLabel} outerRadius={80} data={data.map(x => ({ ...x, value: x.value == 0 ? 1 : x.value }))} dataKey="value" nameKey="name" cx="20%" cy="30%" fill="#8884d8">
							{data.map((entry, index) => (
								<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
							))}
						</Pie>
					</PieChart>
				</ResponsiveContainer>
			</div>
		</>
	);
} 