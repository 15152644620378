/**
 * Seznam uživatelů
 */
import React from "react";
import * as state from "../../../../lib/state";
import * as navigation from "../../../../lib/navigation";
import * as notification from "../../../../lib/notification";
import * as apiUsers from "../../administration/users/api-users";
import * as api from "../../../../lib/api";
import * as formatting from "../../../../lib/formatting";
import Page from "../../../../components/templates/Page";
import List from "../../../../modules/system/lists/List";
import MyRequestAction from "../../../../components/controls/MyRequestAction";
import Icon from "../../../../modules/system/icons/Icon";
import Checkbox from "../../../../components/controls/Checkbox";
import NotificationPopOver from "./NotificationPopOver";

function UsersListPage() {
	const { users } = state.useStateContext();
	const { authorization } = state.useStateContext();
	const { usersMessageDialog } = state.useStateContext();
	const { standardDialogs } = state.useStateContext();
	const container = users.stateContainer.get();

	function actions(item: any) {
		const items = [
			{
				title: "Smazat uživatele",
				action: async (item: any) => {
					await standardDialogs.openYesNoDialog("Opravdu chcete smazat uživatele?") === "yes"
						? users.removeUser(item.id)
						: undefined
				},
			},
			{
				title: item.role.id == apiUsers.Role.Admin ? "Zrušit admina" : "Nastavit admina",
				action: async (item: any) => {
					await standardDialogs.openYesNoDialog("Opravdu chcete provést tuto operaci?") === "yes"
						? users.setRole(item, apiUsers.Role.Admin)
						: undefined
				},
			},
			{
				title: item.role.id == apiUsers.Role.Operator ? "Zrušit operátora" : "Nastavit operátora",
				action: async (item: any) => {
					await standardDialogs.openYesNoDialog("Opravdu chcete provést tuto operaci?") === "yes"
						? users.setRole(item, apiUsers.Role.Operator)
						: undefined
				},
			},
		];
		if (!item.adi_life_uid) {
			items.push({
				title: "Synchronizace",
				action: async (item: any) => {
					users.syncUser(item.id);
				},
			});
		}
		else {
			items.push({
				title: "Desynchronizace",
				action: async (item: any) => {
					users.deSyncUser(item);
				},
			});
		};
		return items as any;
	};

	function handleEntry(user: apiUsers.User) {
		const role = authorization.getUserProfile()?.profile.role.id;
		const id = user.medical_record_state?.id;
		const idState = user.medical_record_state?.id_state;
		if ((idState == 2 || idState == 3) && role != apiUsers.Role.Admin) {
			return;
		}
		const url = "/administrace/zdravotni-zaznam/" + (id ? id : api.emptyGuid);
		authorization.setDelegatedUser(user.id, undefined, url);
	}

	function handleFilter() {
		users.setIsAction(false);
		users.setIsConcept(false);
		users.setDateFrom(null);
		users.setDateTo(null);
		users.stateContainer.merge((prev) => ({
			categories: prev.categories.map(x => ({ ...x, checked: false }))
		}));
		users.setNumberOfUnreadedEmails(false);
		users.list.clearFilter();
	}

	function handleEmail(emails: api.CodeBookItem[]) {
		if (emails.length == 1) {
			navigation.to("/administrace/emaily/" + emails[0].id);
		}
		else {
			navigation.to("/emaily");
		}
	}

	async function handleDoctorMessage(item: apiUsers.User) {
		const result = await usersMessageDialog.openDialog({ id_user: item.id, id_dms_file_doctor_attachment: api.emptyGuid } as any);
		if (result == true) {
			notification.successMessage("Vzkaz od lékaře byl vytvořen.");
		}
	}

	function handleReport() {
		navigation.to("/administrace/individualni-report");
	}

	return (
		<Page title="Seznam uživatelů" description="">
			{authorization.isAdminOrOperator() &&
				<div className="page-content-container users-list-page">
					<div className="text-light ml-3 mb-4">
						<div className="homepage-responsive-title d-inline">
							Seznam uživatelů
						</div>
					</div>
					<div className="ml-3 mr-3">
						<div className="mt-3 mb-3">
							<div className="form-filter u-mb-md pb-0">
								<div id="records" className="row">
									<div className="col-md-8 col-xs-12 mb-3">
										<label htmlFor="filter-search" className="inp-label u-vhide">
											Zadejte jméno
										</label>
										<span className="inp-fix">
											<input
												type="text"
												id="inp-filter-search"
												placeholder={users.list.options.quickFilterPlaceholder}
												className="inp-text inp-text--filter"
												value={users.list.getFilterFulltextPhrase()}
												onChange={(e) => users.list.setFilterFulltextPhrase(e.target.value)}
											/>
										</span>
									</div>
									<div className="col-xs-4 col-12 col-md-auto u-mb-md ml-md-auto">
										<div className="d-inline mr-2">
											<button type="submit" className="btn btn--filter" onClick={users.list.loadWithResetPage}>
												<span className="btn__text">
													Vyhledat
												</span>
											</button>
										</div>
										<div className="d-inline">
											<button type="submit" className="btn btn--filter" onClick={handleFilter}>
												<span className="btn__text">
													Reset
												</span>
											</button>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-4 col-xs-12">
										<Checkbox
											className="mt-0  inp-text--filter"
											checked={users.getIsConcept()} title={"Záznamy v konceptu"}
											onCheckedChanged={(value) => users.setIsConcept(value)}
										/>
									</div>
									<div className="col-md-4 col-xs-12">
										<Checkbox
											className="mt-0  inp-text--filter"
											checked={users.getIsAction()} title={"Záznamy pro schválení"}
											onCheckedChanged={(value) => users.setIsAction(value)}
										/>
									</div>
									<div className="col-md-4 col-xs-12">
										<Checkbox
											className="mt-0  inp-text--filter"
											checked={users.getNumberOfUnreadedEmails()} title={"Nepřečtené emaily"}
											onCheckedChanged={(value) => users.setNumberOfUnreadedEmails(value)}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-4 mb-3">
										<div style={{ height: 45, padding: 7 }} className="inp-text--filter">
											{container.categories.map((i, index) =>
												<div
													title={i.name}
													onClick={() => users.setCategory(!i.checked, i.id)}
													style={{ boxShadow: i.color + (i.checked ? "0px 0px 0px 6px inset" : "0px 0px 0px 14px inset") }}
													className="users-circle">
												</div>
											)}
										</div>
									</div>
									<div className="col-4 mb-3">
										<label htmlFor="filter-search" className="inp-label u-vhide">
											Datum registrace
										</label>
										<span className="inp-fix">
											<input
												type="date"
												id="inp-filter-search"
												className="inp-text inp-text--filter"
												onChange={(e) => users.setDateFrom(new Date(e.target.value))}
												value={formatting.formatDate(users.getDateFrom() == null ? undefined : users.getDateFrom()!, { format: "iso" })}
											/>
										</span>
									</div>
									<div className="col-4 mb-3">
										<label htmlFor="filter-search" className="inp-label u-vhide">
											Datum registrace
										</label>
										<span className="inp-fix">
											<input
												type="date"
												id="inp-filter-search"
												className="inp-text inp-text--filter"
												onChange={(e) => users.setDateTo(new Date(e.target.value))}
												value={formatting.formatDate(users.getDateTo() == null ? undefined : users.getDateTo()!, { format: "iso" })}
											/>
										</span>
									</div>
								</div>
							</div>
						</div>
						<List
							itemNode={(item, field) => {
								switch (field) {
									case "sys_date_create" as any: return <>
										<div>
											{container.categories && container.categories.length > 0 && item.user_categories.map((i, index) =>
												<div
													title={i.name}
													style={{ boxShadow: container.categories.find(x => x.id == i.id_category)?.color + "0px 0px 0px 14px inset" }}
													className="users-circle">
												</div>
											)}
										</div>
									</>;
									case "medical_record_state.id_state" as any: return <>
										{(item.medical_record_state?.id_state == 3 || !item.medical_record_state) &&
											<div className="color-primary cursor-pointer" onClick={() => handleEntry(item)}>
												<Icon picture="Add" />
											</div>
										}
										{(item.medical_record_state?.id_state == 1) &&
											<span onClick={() => handleEntry(item)} className="cursor-pointer user-list-task-number badge badge-danger">
												Koncept
											</span>
										}
										{(item.medical_record_state?.id_state == 2) &&
											<span onClick={() => handleEntry(item)} className="cursor-pointer user-list-task-number badge badge-success">
												Ke schválení
											</span>
										}
									</>;
									case "full_name" as any: return <div onClick={() => navigation.to("/administrace/uzivatele/" + item.id)} className="cursor-pointer color-primary">{item.full_name}</div>;
									case "emails" as any: return <div onClick={() => handleEmail(item.emails)} className="users-list-emails cursor-pointer font-weight-bold">{item.emails.length > 0 ? item.emails.length : ""}</div>;
									case "action" as any: return <MyRequestAction className="ml-2 user-list-page-action-sync" id="users" item={item} actions={actions(item)} />;
									case "delegate" as any: return <div onClick={() => authorization.setDelegatedUser(item.id)} className="text-center cursor-pointer color-primary"><Icon picture="User" /></div>;
									case "report" as any: return <div className="cursor-pointer font-weight-bold">
										<div className="d-inline">
											<span onClick={() => handleDoctorMessage(item)}><Icon className="mr-2 color-primary" picture="Email" /></span>
											<span onClick={() => authorization.setDelegatedUser(item.id, undefined, "/administrace/individualni-report")}><Icon className="color-primary" picture="Chart" /></span>
										</div>
										<div id={"guid-" + item.id} className="d-inline">
											<img style={{ marginTop: "-4px" }} width={20} src="/images/ic-bell.svg" alt="ic-bell" />
											{item.notifications && item.notifications.length > 0 &&
												<span className="users-list-notification-count ">{item.notifications.length}</span>
											}
											<NotificationPopOver notifications={item.notifications!} id={"guid-" + item.id} />
										</div>
									</div>;
									default: return undefined;
								}
							}}
							simpleTable list={users.list} />
					</div>
				</div>
			}
		</Page >
	);
}

export default state.bindContainers(
	UsersListPage,
	context => context.users
);
