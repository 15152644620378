/**
 * Standardní seznam (filtry, třídění, akce, stránkování, apod...)
 */

import * as React from "react";

import * as lists from "./lists";
import * as api from "../../../lib/api";
import * as common from "../../../lib/common";
import * as formatting from "../../../lib/formatting";

import Spinner from "../../../components/controls/Spinner";
import Input from "../../../components/controls/Input";
import Button from "../../../components/controls/Button";
import DropDownMenu from "../../../components/controls/DropDownMenu";
import Icon from "../icons/Icon";

import ListTabFilter from "./ListTabFilter";
import ListPaginator from "./ListPaginator";

export interface ListProps<TItem extends api.EntityReadBase, AdvancedFilterFields> {
	list: lists.StandardList<TItem, AdvancedFilterFields>;
	className?: string;
	simpleTable?: boolean;

	advancedFilter?: React.ReactNode;
	itemNode?: (item: TItem, field: keyof TItem) => React.ReactNode;
	extraButton?: React.ReactNode;
}

function getOperatorTitle(operatorSymbol: lists.GridColumnFilterOperator) {
	switch (operatorSymbol) {
		case "=": return "je";
		case "!=": return "není";
		case "starts": return "začíná";
		case "contains": return "obsahuje";
		case "<": return "menší než";
		case "<=": return "menší nebo rovno";
		case ">": return "větší než";
		case ">=": return "větší nebo rovno";
		case "between": return "mezi";
		case "all": return "vše";
		case "true": return "ano";
		case "false": return "ne";
		case "not true": return "jiné než ano";
		case "not false": return "jiné než ne";
		default: return common.unwanted(operatorSymbol);
	}
}

export default function List<TItem extends api.EntityReadBase, AdvancedFilterFields>(props: ListProps<TItem, AdvancedFilterFields>) {

	async function toggleSortingField(field: keyof TItem, preventSorting?: boolean) {
		if (preventSorting) {
			return;
		}
		if (props.list.getSortingField() === field) {
			await props.list.toggleSortingOrder();
		} else {
			await props.list.setSortingField(field);
			if (props.list.getSortingOrder() === lists.SortingOrder.Desc) {
				await props.list.toggleSortingOrder();
			}
		}
	}

	async function reset() {
		await Promise.all([
			props.list.clearFilter(),
			props.list.setSortingField(undefined)
		]);
	}

	function addNullOpartors(field: keyof TItem) {
		return (field as string).replace(".", "?.");
	}

	return (
		<div className={props.className ?? ""}>
			{props.list.options.hideTabFilter !== true && props.list.getFilterMode() !== "query" &&
				<ListTabFilter
					className="list-column-filter mb-4"
					list={props.list}
				/>
			}

			{!props.simpleTable &&
				<div className="form-filter u-mb-lg">
					<div id="records" className="form-filter__inner row">
						<div className="col-12 col-sm-6 col-md-3 u-mb-md">
							<label htmlFor="number-item" className="inp-label u-vhide">
								Záznamů na stránku
							</label>
							<span className="inp-fix">
								<select
									name="select"
									id="inp-number-item"
									className="inp-select inp-select--filter"
									value={props.list.getPageSize().toString()}
									onChange={(e) => props.list.setPageSize(Number(e.target.value))}
								>
									<option value="20">
										20
									</option>
									<option value="40">
										40
									</option>
									<option value="80">
										80
									</option>
									<option value="160">
										160
									</option>
									<option value="320">
										320
									</option>
								</select>
							</span>
						</div>
						<div className={"col-12 col-sm-6 col-md-" + (props.extraButton == undefined ? "5" : "4") + " u-mb-md"}>
							<label htmlFor="filter-search" className="inp-label u-vhide">
								Hledaný výraz
							</label>
							<Input
								id="filter-search"
								className="inp-text inp-text--filter"
								value={props.list.getFilterFulltextPhrase()}
								onChange={props.list.setFilterFulltextPhrase}
								placeholder="Hledaný výraz"
							/>
						</div>
						<div className={"col-12 col-md-" + (props.extraButton == undefined ? "3" : "4") + " u-mb-md text-center text-md-right"}>
							<Button theme="primary" onClick={reset}>
								Zrušit filtr
							</Button>
							{props.extraButton &&
								<>{props.extraButton}</>
							}
						</div>
					</div>
				</div>
			}

			{/* <ListFilterInfo className="mb-3" list={props.list} /> */}

			{/* Header */}
			<div className="list__header">
				{props.list.justLoadingData() &&
					<Spinner className="mr-2" small />
				}
				{!props.simpleTable &&
					<>
						{props.list.getTitle()}
					</>
				}
				{props.simpleTable && props.list.justLoadingData() &&
					<span className="font-weight-normal">
						{/* Načítám data... */}
					</span>
				}
				&nbsp;
			</div>
			<div className="list__grid-wrapper">
				<div className="list__grid-container">
					<table className="list__grid table-designed" style={{ minWidth: props.list.options.grid?.minWidth ?? "initial" }}>
						<thead>
							<tr>
								{props.list.getGridColumns().map(i =>
									<th key={i.field as string} className="pr-3" style={{ width: i.options.width ?? "auto" }}>
										<div
											className="d-flex align-items-center list__grid-column-header"
											style={{ justifyContent: i.options.type === "number" || i.options.type === "date" || i.options.alignRight ? "flex-end" : "unset" }}
											onClick={(e) => toggleSortingField(i.field, i.options.preventSorting)}
										>
											<div className="mr-2">
												{i.options.title}
											</div>
											{!i.options.preventSorting &&
												<>
													{props.list.getSortingField() === i.field && props.list.getSortingOrder() === lists.SortingOrder.Asc &&
														<Icon picture="SortAsc" />
													}
													{props.list.getSortingField() === i.field && props.list.getSortingOrder() === lists.SortingOrder.Desc &&
														<Icon picture="SortDesc" />
													}
												</>
											}
										</div>
										{/* <div className="d-flex align-items-center"> */}

										{!props.simpleTable &&
											<>
												{i.options.type === "string" &&
													<DropDownMenu className="mr-3 list__grid-column-header-dropdown" currentItemTitle={getOperatorTitle(i.operator)} items={[
														{
															title: getOperatorTitle("="),
															onClick: async () => { await props.list.setGridColumnOperator(i.field, "="); }
														},
														{
															title: getOperatorTitle("!="),
															onClick: async () => { await props.list.setGridColumnOperator(i.field, "!="); }
														},
														{
															title: getOperatorTitle("starts"),
															onClick: async () => { await props.list.setGridColumnOperator(i.field, "starts"); }
														},
														{
															title: getOperatorTitle("contains"),
															onClick: async () => { await props.list.setGridColumnOperator(i.field, "contains"); }
														}
													]} />
												}
												{(i.options.type === "date" || i.options.type === "number") &&
													<DropDownMenu className="mr-3  list__grid-column-header-dropdown" currentItemTitle={getOperatorTitle(i.operator)} items={[
														{
															title: getOperatorTitle("="),
															onClick: async () => { await props.list.setGridColumnOperator(i.field, "="); }

														},
														{
															title: getOperatorTitle("!="),
															onClick: async () => { await props.list.setGridColumnOperator(i.field, "!="); }
														},
														{
															title: getOperatorTitle("<"),
															onClick: async () => { await props.list.setGridColumnOperator(i.field, "<"); }
														},
														{
															title: getOperatorTitle("<="),
															onClick: async () => { await props.list.setGridColumnOperator(i.field, "<="); }
														},
														{
															title: getOperatorTitle(">"),
															onClick: async () => { await props.list.setGridColumnOperator(i.field, ">"); }
														},
														{
															title: getOperatorTitle(">="),
															onClick: async () => { await props.list.setGridColumnOperator(i.field, ">="); }
														},
														{
															title: getOperatorTitle("between"),
															onClick: async () => { await props.list.setGridColumnOperator(i.field, "between"); }
														}
													]} />
												}
												{(i.options.type === "boolean") &&
													<DropDownMenu className="mr-3 list__grid-column-header-dropdown" currentItemTitle={getOperatorTitle(i.operator)} items={[
														{
															title: getOperatorTitle("all"),
															onClick: async () => { await props.list.setGridColumnOperator(i.field, "all"); }
														},
														{
															title: getOperatorTitle("true"),
															onClick: async () => { await props.list.setGridColumnOperator(i.field, "true"); }
														},
														{
															title: getOperatorTitle("false"),
															onClick: async () => { await props.list.setGridColumnOperator(i.field, "false"); }
														},
														{
															title: getOperatorTitle("not true"),
															onClick: async () => { await props.list.setGridColumnOperator(i.field, "not true"); }
														},
														{
															title: getOperatorTitle("not false"),
															onClick: async () => { await props.list.setGridColumnOperator(i.field, "not false"); }
														}
													]} />
												}

												{i.options.type !== "boolean" &&
													<div>
														<Input
															className="list__header-input mb-0"
															value={i.filterValue}
															onChange={(value) => props.list.setGridColumnFilterValue(i.field, value)}
															type={i.options.type === "number" ? "number" : i.options.type === "date" ? "date" : "text"}
														/>
														{i.operator === "between" &&
															<Input
																className="list__header-input mt-1 mb-0"
																value={i.filterValueTwo}
																onChange={(value) => props.list.setGridColumnFilterValueTwo(i.field, value)}
																type={i.options.type === "number" ? "number" : i.options.type === "date" ? "date" : "text"}
															/>
														}
													</div>
												}
											</>
										}
										{/* </div> */}
									</th>
								)}
							</tr>
						</thead>
						<tbody>
							{props.list.getData().length === 0 &&
								<tr>
									<td colSpan={props.list.getGridColumns().length}>
										Nenalezen žádný záznam.
									</td>
								</tr>
							}
							{props.list.getData().map(r =>
								<tr key={r.id}>
									{props.list.getGridColumns().map(c =>
										<td key={c.field as string} style={{ textAlign: c.options.center ? "center" : c.options.type === "number" || c.options.type === "date" || c.options.alignRight ? "right" : "unset" }}>
											{props.itemNode && props.itemNode(r, c.field)}
											{(props.itemNode === undefined || props.itemNode(r, c.field) == undefined) &&
												<>
													{c.options.type === "string" &&
														<>{eval("r." + addNullOpartors(c.field))}</>
													}
													{c.options.type === "number" &&
														<>{eval("r." + addNullOpartors(c.field))}</>
													}
													{c.options.type === "date" &&
														<>{formatting.formatDate(eval("r." + addNullOpartors(c.field)) as unknown as Date | undefined)}</>
													}
													{c.options.type === "boolean" &&
														<>{eval("r." + addNullOpartors(c.field)) ? "Ano" : "Ne"}</>
													}
												</>
											}
										</td>
									)}
								</tr>
							)}
						</tbody>
					</table>
				</div>
			</div>

			<ListPaginator list={props.list} />
		</div>
	);
}