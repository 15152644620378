import * as dialogs from "../../../shared/dialogs/dialogs";
import * as context from "../../../../context";
import * as forms from "../../../../lib/forms";
import * as state from "../../../../lib/state";
import * as api from "../../../../lib/api";
import * as notification from "../../../../lib/notification";
import e from "express";

export interface FormFields {
	mail_from: string;
	mail_to: any;
	subject: string;
	body: string;
	mail_to_type?: api.CodeBookItem;
}

export interface FileFormFields {
	attachment?: api.CodeBookItem;
}

interface FormData extends FormFields {
	attachments: FileFormFields[];
}

export interface State {
	emails: api.CodeBookItem[];
}

export class Model {
	public dialog: dialogs.Dialog<undefined, State>;
	public stateContainer: state.StateContainer<State>;
	public form: forms.Form<FormFields>;
	public formsFiles: forms.FormCollection<FileFormFields>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({ emails: [] }, context);
		this.dialog = new dialogs.Dialog<undefined, State>({
			defaultCustomFields: {
				emails: []
			}
		}, context);
		this.form = new forms.Form<FormFields>({
			fields: {
				mail_from: {
					defaultValue: "",
					title: "Odesílatel"
				},
				mail_to: {
					defaultValue: "",
					title: "Příjemce",
					required: true,
					validate: value => !value ? "Příjemce je povinné pole" : "",
				},
				mail_to_type: {
					defaultValue: undefined,
					title: ""
				},
				subject: {
					title: "Subjekt",
					defaultValue: "",
				},
				body: {
					title: "Tělo",
					defaultValue: "",
				},
			}
		}, context);
		this.formsFiles = new forms.FormCollection({
			fields: {
				attachment: {
					title: "",
					defaultValue: undefined,
				}
			}
		}, this.context, this.form);
		this.formsFiles.add();
	}

	addFile = () => this.formsFiles.add();

	removeFile = async (formFile: forms.Form<FileFormFields>) =>
		await this.context.standardDialogs.openYesNoDialog("Odstranit soubor?") === "yes"
			? this.formsFiles.remove(formFile)
			: undefined

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			this.stateContainer,
			...this.form.getStateContainers(),
			...this.dialog.getStateContainers()
		];
	}

	openDialog = async (item?: api.CodeBookItem) => {
		this.form.clearFields();
		const profile = this.context.authorization.getUserProfile();
		const users = await this.context.apiUsers.usersReceivers.loadList({
			filter: [
				api.qpOr([
					api.qp("email_receiver", "=", true),
				])
			]
		});
		const emails = users.data.map(x => ({ ...x, name: x.email, title: x.email, value: { id: x.id, name: x.email } }));
		if (item && emails && emails.filter(x => x.value.id == item.id).length == 0) {
			emails.push({ name: item.name, title: item.name, value: { id: item.id, name: item.name } } as any);
		}
		await this.stateContainer.merge(() => ({
			emails: emails,
		}));
		this.form.setField("mail_from", profile?.email!);
		if (item) {
			this.form.setField("mail_to", item);
		}
		return this.dialog.open();
	}

	save = async () => {
		await this.form.validate();
		if (this.form.isValid()) {
			const data: FormData = {
				...this.form.getFields(),
				mail_to: this.form.getField("mail_to").value.name,
				mail_to_type: this.form.getField("mail_to").value,
				attachments: this.formsFiles.get().filter(i => i.getField("attachment").value !== undefined).map(i => i.getFields())
			};
			const result = await this.context.api.put("/emails/users/" + api.emptyGuid, data);
			if (result) {
				notification.successMessage("Email byl odeslán.");
				return this.dialog.close();
			}
		}
		else {
			await notification.errorMessage("Formulář obsahuje neúplné informace nebo chyby. Opravte prosím červeně vyznačené položky a poté akci opakujte.");
		}
	}
}