import HtmlContent from "../../../../components/controls/HtmlContent";
import React, { useState, useEffect } from "react";
import * as state from "../../../../lib/state";
import { Popover, PopoverBody, PopoverHeader } from "reactstrap";
import * as formatting from "../../../../lib/formatting";
import Icon from "../../../../modules/system/icons/Icon";

interface Props {
	notifications: any[];
	id: string;
	className?: string;
};

export default function NotificationPopOver(props: Props) {
	const { apiNotifications, users } = state.useStateContext();
	const [isOpen, setIsOpen] = useState(false);
	const [data, setData] = useState(props.notifications);

	useEffect(() => {
		window.addEventListener("click", function (e: any) {
			if ((e.target.className != "popover-delete-icon" && e.target.tagName != "path" && e.target.tagName != "svg")) {
				setIsOpen(false);
			}
		});
	}, []);

	async function markAsRead(item: any) {
		item.readed = true;
		item.date_of_readed = new Date();
		await apiNotifications.markAsRead(item);
		setData(props.notifications.filter(x => x.id != item.id));
		users.list.load();
	}

	return (
		<>
			{data && data.length > 0 &&
				<Popover
					target={props.id}
					placement="bottom"
					isOpen={isOpen}
					popperClassName={props.className ? props.className : ""}
					toggle={() => data && data.length > 0 ? setIsOpen(!isOpen) : undefined}
					className={"header-navigation-popover"}
				>
					<PopoverHeader className="header-navigation-popover-header">
						NOTIFIKACE
					</PopoverHeader>
					<PopoverBody className="header-navigation-popover-body-message">
						{data.map((i, index) =>
							<div key={index}
								className={"header-navigation-popover-text header-navigation-popover-notifikace" + (data!.length - 1 == index ? " header-navigation-popover-notifikace-last" : "")}>
								<div className="font-weight-bold header-navigation-popover-text">{formatting.formatDateTime(i.sys_date_create)}</div>
								<HtmlContent content={i.text} className="header-navigation-popover-text" />
								<div onClick={(e) => markAsRead(i)} className="text-danger cursor-pointer float-right mr-2 popover-delete-icon header-navigation-popover-text"><Icon picture="Remove" /></div>
							</div>
						)}
					</PopoverBody>
				</Popover>
			}
		</>
	);
}