import * as api from "../../../../lib/api";
import * as context from "../../../../context";
import * as apiCodeList from "../../../system/code-list/api-code-list";

export interface Doctor extends api.EntityReadBase {
	id: string;
	name: string;
	address: string;
	phone: string;
	email: string;
	type: any;
	id_image: string;
	id_state: number;
	imported: boolean;
}

export class Api {
	doctors: api.EntityApi<Doctor, Doctor>;

	constructor(private context: context.StateContext) {
		this.doctors = new api.EntityApi<Doctor, Doctor>({
			api: context.api,
			path: "/doctors"
		});
	}

	loadData = () => {
		return this.context.api.loadList<apiCodeList.CodeList>("/doctors", { per_page: Number.MAX_SAFE_INTEGER });
	}

	remove = (id: string) => {
		return this.context.api.del<apiCodeList.CodeList>("/doctors/" + id);
	}

	loadDetail(id: string) {
		return this.context.api.get<Doctor>("/doctors/" + id);
	}

	save(id: string, user: Doctor) {
		return this.context.api.put<Doctor, Doctor>("/doctors/" + id, user);
	}
}