/**
 * Přidání souboru
 */
import Button from "../../../components/controls/Button";
import * as React from "react";
import * as state from "../../../lib/state";

import Dialog from "../../shared/dialogs/Dialog";
import FormsInput from "../../../components/controls/Forms/FormsInput";
import FormTextArea from "../../../components/controls/Forms/FormsTextArea";

function SimpleAddDialog() {
	const { simpleAddDialog } = state.useStateContext();
	const dialog = simpleAddDialog.dialog;
	const disabled = simpleAddDialog.form.getField("disabled").value;
	const title = simpleAddDialog.form.getField("title").value;

	return (
		<Dialog className="simple-add-dialog" dialog={dialog}>
			<div>
				<h2 className="pb-3 dialog-title"><img className="d-inline mr-2" src="/images/folders-1.svg" alt="folders-1" />{!title ? (disabled ? "Záznam" : "Přidat záznam") : title}</h2>
				<hr className="dialog-title-hr" />
				{dialog.getCustomField("showName") &&
					<FormsInput form={simpleAddDialog.form} field="name" />
				}
				{dialog.getCustomField("showDescription") &&
					<FormTextArea textAreaProps={{ disabled: disabled == undefined ? false : disabled as any } as any} form={simpleAddDialog.form} field="description" />
				}
				{dialog.getCustomField("showDate") &&
					<FormsInput
						inputProps={{ type: "date" }}
						form={simpleAddDialog.form}
						field="date"
					/>
				}
				{!disabled &&
					<div className="mt-4 text-center">
						<Button onClick={e => simpleAddDialog.save()} className="pt-2 pb-2 float-right">
							<strong>Vložit</strong>
						</Button>
					</div>
				}
			</div>
		</Dialog >
	);
}

export default state.bindContainers(
	SimpleAddDialog,
	context => context.simpleAddDialog
);