/**
 * Widget calendar
 */
import React from "react";
import * as state from "../../../../../lib/state";
import * as api from "../../../../../lib/api";
import * as formatting from "../../../../../lib/formatting";
import Calendar from "react-calendar";
import MyRequestAction from "../../../../../components/controls/MyRequestAction";
import CalendarDailyView from "./CalendarDailyView";
import * as apiCalendar from "./api-calendar";
import CalendarWeeklyView from "./CalendarWeeklyView";
import Help from "../../../../../components/controls/Help";

function CalendarPage() {
	const { calendarPage: model } = state.useStateContext();
	const today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0).toString();
	const { calendarDialog } = state.useStateContext();
	const { calendarListDialog } = state.useStateContext();
	const items = model.form.getField("data").value;
	const data = model.getData();

	const actions = [{
		title: "Přidat událost",
		icon: "Add",
		action: async () => {
			calendarDialog.openDialog();
		},
	},
	{
		title: "Měsíční přehled",
		icon: "Slider",
		action: async () => {
			await model.form.setField("type", apiCalendar.CalendarView.Month);
			model.loadData();
		},
	},
	{
		title: "Týdenní přehled",
		icon: "Slider",
		action: async () => {
			await model.form.setField("type", apiCalendar.CalendarView.Week);
			model.loadData();
		},
	},
	{
		title: "Dnešní agenda",
		icon: "Slider",
		action: async () => {
			await model.form.setField("type", apiCalendar.CalendarView.Day);
			model.loadData();
		},
	}];

	function handleOpen(date: Date, e: any) {
		if (data.map(x => x.day).includes(date.toString())) {
			const items = data.filter(x => x.day == date.toString());
			if (items.length > 1) {
				calendarListDialog.openDialog(items as any, date);
			}
			else {
				const codeBookItem = items[0];
				codeBookItem.date = typeof codeBookItem.date == "string" ? codeBookItem.date : formatting.formatDateForInputDateTime(codeBookItem.date) as any;
				calendarDialog.openDialog(true, codeBookItem as any);
			}
		}
		else {
			calendarDialog.openDialog(undefined, { date: date } as any);
		}
	}

	return (
		<div className="calendar-page-container">
			<div className="homepage-widget-header">
				<img className="d-inline mr-2" src="/images/calendar.svg" alt="calendar" />
				<h2 className="d-inline">Kalendář</h2>
				<Help id="calendar-help" text="Zaznamenávejte si všechny události a plánované prohlídky u lékaře a mějte tak svůj čas pod kontrolou." />
				<MyRequestAction id="last-measurement-action" actions={actions as any} />
			</div>
			<div className="calendar-container mt-3">
				<div className="row">
					<div className="col-sm-12 mb-3">
						{model.form.getField("type").value == apiCalendar.CalendarView.Month &&
							<Calendar
								onChange={handleOpen}
								className="pb-2"
								tileClassName={({ date, view }: any) => {
									const ds = date.toString();
									const event = data.find(x => x.day == ds);
									return (
										(event && event?.id_pilulkoid != undefined && event?.id_pilulkoid != api.emptyGuid) ? " calendar-expiration" :
											data.map(x => x.day).includes(ds) ? " calendar-day-event " : (ds === today ? " calendar-bg " as any : null)
									)
								}
								}
							/>
						}
						{model.form.getField("type").value == apiCalendar.CalendarView.Week &&
							<CalendarWeeklyView />
						}
						{model.form.getField("type").value == apiCalendar.CalendarView.Day &&
							<CalendarDailyView />
						}
					</div>
				</div>
			</div>
		</div>
	);
}

export default state.bindContainers(
	CalendarPage,
	c => c.calendarPage
);