/**
 * Detail mailu
 */
import React from "react";
import * as navigation from "../../../../lib/navigation";
import * as state from "../../../../lib/state";
import * as apiLib from "../../../../lib/api";
import Page from "../../../../components/templates/Page";
import FormsInput from "../../../../components/controls/Forms/FormsInput";
import Button from "../../../../components/controls/Button";
import FormsHtmlEditor from "../../../../components/controls/Forms/FormsHtmlEditor";

interface Props {
	id: string;
}

function EmailsUserDetailPage(props: Props) {
	const { authorization } = state.useStateContext();
	const { api } = state.useStateContext();
	const { emailsDetail } = state.useStateContext();
	const form = emailsDetail.form;
	const { emailDialog } = state.useStateContext();

	async function handleDownload(file: any) {
		return await api.download("/dms_files/" + file.id + "/download", file.name, {});
	}

	async function handleEmail(item?: apiLib.CodeBookItem) {
		emailDialog.openDialog(item);
	}

	return (
		<Page title="Email - detail" description="">
			<div className="page-content-container">
				<div className="ml-3 mb-3 text-dark">
					<div className="homepage-responsive-title d-inline">
						Email - detail
					</div>
				</div>
				<div className="content-container m-3">
					<div className="row">
						<div className="col-12">
							<FormsInput
								form={form}
								field="subject"
							/>
						</div>
					</div>
					<div className="row">
						<div className="col">
							<FormsInput
								form={form}
								field="mail_from"
							/>
						</div>
						<div className="col">
							<FormsInput
								form={form}
								field="mail_to"
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<FormsHtmlEditor
								form={form}
								disabled={true}
								field="body"
							/>
						</div>
					</div>
					{emailsDetail.form.getField("new_attachments").value.length > 0 &&
						<>
							<label>Přílohy</label>
							<div className="row">
								{emailsDetail.form.getField("new_attachments").value.map((x, index) =>
									<div key={index} className="col-4">
										<div onClick={() => handleDownload(x)} className="files-list cursor-pointer">
											<div className="d-flex justify-content-between align-items-center mb-3">
												<div className="files-item d-flex align-items-center files-clickable">{x.name}</div>
											</div>
										</div>
									</div>
								)}
							</div>
						</>
					}
					<div className="row p-2 mt-3">
						<div className="col">
							{
								<div className="d-inline float-left">
									<Button className="font-weight-bold" onClick={() => handleEmail({ id: form.getFields().id_user!, name: form.getFields().mail_from! })}>
										Odpovědět
									</Button>
								</div>
							}
							<div className="d-inline float-right">
								<Button className="font-weight-bold" onClick={() => navigation.to("/emaily")}>
									Zpět
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Page >
	);
}

export default state.bindContainers(
	EmailsUserDetailPage,
	context => context.emailsDetail
);