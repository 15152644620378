import * as dialogs from "../../../shared/dialogs/dialogs";
import * as context from "../../../../context";
import * as forms from "../../../../lib/forms";
import * as api from "../../../../lib/api";
import * as notification from "../../../../lib/notification";

export interface FormFields {
	id: string;
	title: string;
	description: string;
	id_image: string;
	id_main_image: string;
	id_content: string;
	sys_date_create: Date;
	show_ambulance: boolean;
	url: string;
}

export interface State {
}

export class Model {
	public dialog: dialogs.Dialog<undefined, State>;
	public form: forms.Form<FormFields>;

	constructor(private context: context.StateContext) {
		this.dialog = new dialogs.Dialog<undefined, State>({
			defaultCustomFields: {
				setValue: undefined,
			}
		}, context);
		this.form = new forms.Form<FormFields>({
			fields: {
				id: {
					defaultValue: api.emptyGuid,
					title: ""
				},
				id_content: {
					defaultValue: api.emptyGuid,
					title: ""
				},
				url: {
					defaultValue: "",
					title: "Url"
				},
				title: {
					title: "Název",
					defaultValue: "",
					required: (form) => true,
					validate: (value) => value.length == 0 ? "Vyplňte název" : ""
				},
				description: {
					title: "Popis",
					defaultValue: "",
					required: (form) => true,
					validate: (value) => value.length == 0 || value == "<div><br></div>" ? "Vyplňte popis" : ""
				},
				id_image: {
					title: "",
					defaultValue: api.emptyGuid,
					required: (form) => true,
					validate: (value) => value == api.emptyGuid ? "Nahrajte obrázek" : ""
				},
				id_main_image: {
					title: "",
					defaultValue: api.emptyGuid
				},
				sys_date_create: {
					defaultValue: new Date(),
					title: ""
				},
				show_ambulance: {
					title: "Zobrazit odkaz na spuštení aplikace \"Záchranka\".",
					defaultValue: false
				},
			}
		}, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			...this.form.getStateContainers(),
			...this.dialog.getStateContainers()
		];
	}

	openDialog = async (values: FormFields) => {
		this.form.clearFields();
		this.form.setFields(values);
		return this.dialog.open();
	}

	save = async () => {
		await this.form.validate();
		if (this.form.isValid()) {
			const result = await this.context.api.put("/content_items/" + this.form.getField("id").value, this.form.getFields());
			if (result) {
				notification.successMessage("Subkategorie byla uložena.");
				this.context.waitingRoomItemList.list.load();
				return this.dialog.close();
			}
		}
		else {
			await notification.errorMessage("Formulář obsahuje neúplné informace nebo chyby. Opravte prosím červeně vyznačené položky a poté akci opakujte.");
		}
	}
}