import * as React from "react";
import * as forms from "../../../lib/forms";
import * as common from "../../../lib/common";

import FormItem, { FormItemOptions } from "./FormItem";
import Autocomplete, { AutocompleteProps } from "../Autocomplete";


export interface FormsAutocompleteProps<TFormFields, TField extends keyof TFormFields> {
	form: forms.Form<TFormFields>;
	field: TField;
	autocompleteProps: Omit<AutocompleteProps<TFormFields[TField]>, "value">;
	formItemOptions?: FormItemOptions;
}

export default function FormsAutocomplete<TFormFields, TField extends keyof TFormFields>(props: FormsAutocompleteProps<TFormFields, TField>) {

	async function handleSelect(value: TFormFields[TField]) {
		if (typeof props.form.getField(props.field).value !== "string") {
			await props.form.setField(props.field, value);
		}
	}

	async function handleChange(value: string) {
		await props.form.setField(props.field, undefined as any);
	}

	return (
		<FormItem
			form={props.form}
			field={props.field}
			options={props.formItemOptions}
		>
			<Autocomplete
				{...props.autocompleteProps}
				value={props.form.getField(props.field).value}
				inputProps={{
					disabled: props.form.isFieldReadOnly(props.field),
				}}
				onSelect={handleSelect}
				onChange={handleChange}
			/>
		</FormItem>
	);
}