import * as api from "../../../lib/api";
import * as context from "../../../context";

export interface File {
	id: string;
	name: string;
}

export enum FileType {
	Reports = 1,
	Foto = 2,
	Video = 3,
	Audio = 4,
	Documents = 5,
	Last = 6,
	File = 7
}

export interface FileFormFields {
	attachment?: api.CodeBookItem;
}

export class Api {
	constructor(private context: context.StateContext) {
	}

	getDownloadLink = (id: api.Id) => {
		return this.context.api.options.endpoint + "/dms_files/" + id + "/download";
	}

	/**
	* Provede upload souboru.
	*/
	upload = (file: globalThis.File, options?: api.RequestOptions) => {
		let formData = new FormData();
		formData.append("file", file);
		return this.context.api.post<FormData>("/dms_files/upload", formData, options);
	}

	/**
	 * Stáhne přílohu
	 */
	download = async (id: string, name: string) => {
		return await this.context.api.download("/dms_files/" + id + "/download", name, {});
	}
}