/**
 * Widget optional view
 */
import * as React from "react";
import * as state from "../../../../../lib/state";
import FormGroupSwitch from "../../../../../components/controls/Forms/FormGroupSwitch";
import WidgetListPanel from "../../../../../components/controls/widget-list-panel/WidgetListPanel";
import FormsCheckBox from "../../../../../components/controls/Forms/FormCheckBox";

interface Props {
	isEdit: boolean;
	isView: boolean;
}

function OptionalViewWidget(props: Props) {
	const { optionalViewWidget: model } = state.useStateContext();
	const { pCardWidget } = state.useStateContext();
	const form = model.form;


	return (
		<div>
			<div className="p-card-widget-page-container">
				<div className="homepage-widget-header p-card-widget-header">
					<img className="d-inline mr-2" src="/images/list-checked.svg" alt="qr-code" />
					<h2 className="d-inline">Volitelné zobrazení</h2>
				</div>
				<WidgetListPanel checkbox={<>
					<div className="d-inline-block">
						<FormsCheckBox
							form={form}
							disabled={!props.isEdit}
							onSave={pCardWidget.saveUser}
							className="optional-view-item-checkbox"
							enhancedTitle={<span>Bez dlouhodobé medikace</span>}
							field={"no_long_term_medication"}
						/>
					</div>
				</>} isEdit={props.isEdit} title="Užívané medikace" index="pilulkoids" indexSwitch="showDrugs" form={form} />
				<div className="optional-view-item">
					<WidgetListPanel checkbox={<>
						<div className="d-inline-block">
							<FormsCheckBox
								form={form}
								disabled={!props.isEdit}
								onSave={pCardWidget.saveUser}
								className="optional-view-item-checkbox"
								enhancedTitle={<span>Bez dlouhodobého onemocnění</span>}
								field={"no_long_term_illness"}
							/>
						</div>
					</>} isEdit={props.isEdit} title="Probíhající onemocnění" index="ongoing_treatments" indexSwitch="showTreatements" form={form} />
				</div>
				<div className="optional-view-item">
					<WidgetListPanel isEdit={props.isEdit} title="Alergie" index="allergies" indexSwitch="showAllergies" form={form} />
				</div>
			</div>
		</div >
	);
}

export default state.bindContainers(
	OptionalViewWidget,
	c => c.optionalViewWidget
);