import * as React from "react";
import * as forms from "../../../lib/forms";
import ColorPickerInput from "../ColorPicker";

import FormItem, { FormItemOptions } from "./FormItem";


export interface Props<TFormFields> {
	form: forms.Form<TFormFields>;
	field: keyof TFormFields;
	className?: string;
	formItemOptions?: FormItemOptions;
}

export default function FormsColorPicker<TFormFields>(props: Props<TFormFields>) {
	return (
		<FormItem
			form={props.form}
			field={props.field}
			options={props.formItemOptions}
			className={props.className}
		>
			<ColorPickerInput
				value={props.form.getField(props.field).value as any}
				onChange={value => props.form.setField(props.field, value as any)}
			/>
		</FormItem>
	);
}