/**
 * Přidání souboru
 */
import React, { useRef, useState } from "react";
import * as state from "../../../../../lib/state";
import * as common from "../../../../../lib/common";
import * as notification from "../../../../../lib/notification";
import * as formatting from "../../../../../lib/formatting";
import * as api from "../../../../../lib/api";
import storage from "store";
import * as addDialog from "./add-dialog";
import FormFileUpload from "../../../../system/file/FormFileUpload";
import { isMobile, isTablet, isAndroid, isIOS } from "react-device-detect";

import Dialog from "../../../../shared/dialogs/Dialog";
import Icon from "../../../../../modules/system/icons/Icon";
import FormTextArea from "../../../../../components/controls/Forms/FormsTextArea";
import Button from "../../../../../components/controls/Button";
import FormsInput from "../../../../../components/controls/Forms/FormsInput";
import VideoPlayer from "../../../../../components/controls/VideoPlayer";
import FormsSelect from "../../../../../components/controls/Forms/FormSelect";

function LastRecordsAddDialog() {
	const { addLastMeasurementDialog } = state.useStateContext();
	let [mediaRecorder, setMediaRecorder] = useState(undefined as MediaRecorder | undefined);
	const [justUploading, setJustUploading] = useState(false);
	const context = state.useStateContext();
	const form = addLastMeasurementDialog.form;
	const videoRecordRef = useRef<HTMLVideoElement>(null);
	const capturePhotoRef = useRef<HTMLCanvasElement>(null);
	const audioRef = useRef<HTMLAudioElement>(null);
	const container = addLastMeasurementDialog.getStateContainer();
	const type = container.type;
	const fileState = container.state;
	const videoOrAudio = type == "video" || type == "audio";
	const documents = form.getField("documents").value;
	const deviceIsMobile = isMobile || isTablet || isAndroid || isIOS || container.isMobile;
	const disabled = addLastMeasurementDialog.stateContainer.get().disabled;

	async function apiUpload(file: globalThis.File) {
		return context.apiFile.upload(file, { suppressErrorNotification: true });
	}

	async function uploadFile(file: any) {
		let uploadResult: api.OperationResponse | undefined = undefined;
		try {
			uploadResult = await common.withIndication({
				indicateStart: async () => setJustUploading(true),
				finish: async () => { setJustUploading(false); },
				exec: () => apiUpload(file)
			});
		} catch (err) {
			await notification.errorMessage(("Soubor se nepodařilo nahrát.") + " " + api.getErrorMessage(err, "Neznámá chyba."));
		}
		if (uploadResult) {
			await form.setField("file", {
				id: uploadResult.id as string,
				name: file.name
			});
			await addLastMeasurementDialog.stateContainer.merge(() => (addDialog.defaultState()));
			await form.setField("file", undefined);
		}
	}

	async function clearFile() {
		await form.setField("file", undefined);
	}

	function startWebcam(stream: any) {
		const mainVideo = videoRecordRef!.current!;
		(window as any).stream = stream;
		mainVideo.srcObject = stream;
	}

	function starAudioRecord(stream: any) {
		const audio = audioRef!.current!;
		(window as any).stream = stream;
		audio.srcObject = stream;
	}

	async function startRecordingVideo() {
		const mainVideo = videoRecordRef!.current!;
		if (mainVideo.srcObject === null) {
			mainVideo.srcObject = (window as any).stream;
		}
		const mediaRecorder = new MediaRecorder((window as any).stream, { mimeType: "video/webm" });
		await setMediaRecorder(mediaRecorder);
		mediaRecorder!.start();
		mediaRecorder!.ondataavailable = recordVideo;
	}

	async function startRecordingAudio() {
		const audio = audioRef!.current!;
		if (audio.srcObject === null) {
			audio.srcObject = (window as any).stream;
		}
		const mediaRecorder = new MediaRecorder((window as any).stream, { mimeType: "audio/webm" });
		await setMediaRecorder(mediaRecorder);
		mediaRecorder!.start();
		mediaRecorder!.ondataavailable = recordAudio;
	}

	function recordVideo(event: any) {
		if (event.data && event.data.size > 0) {
			const mainVideo = videoRecordRef!.current!;
			mainVideo.srcObject = null;
			let videoUrl = URL.createObjectURL(event.data);
			mainVideo.src = videoUrl;
		}
	}

	function recordAudio(event: any) {
		if (event.data && event.data.size > 0) {
			const audio = audioRef!.current! as any;
			audio.srcObject = null;
			let audioUrl = URL.createObjectURL(event.data);
			audio.src = audioUrl;
		}
	}

	async function handlClick(type: "audio" | "video" | "photo") {
		if (form.getField("file").value === undefined) {
			await addLastMeasurementDialog.stateContainer.merge(() => ({
				showRecord: true,
				type: type
			}));
			if (type == "photo") {
				startCamera(false);
				capturePhotoRef.current!.style.height = "0px";
			}
			if (type != "audio") {
				const mainVideo = videoRecordRef!.current!;
				mainVideo!.style.height = "320px";
			}
		} else {
			clearFile();
		}
	}

	async function startCamera(audio: boolean, noVideo?: boolean) {
		const stream = await navigator.mediaDevices.getUserMedia({
			audio: audio,
			video: noVideo == undefined ? true : noVideo
		});
		if (type != "audio") {
			await startWebcam(stream);
		}
		if (type == "video") {
			startRecordingVideo();
		}
		if (type == "audio") {
			await starAudioRecord(stream);
			startRecordingAudio();
		}
	}

	async function handleRecord() {
		if (type == "photo") {
			capturePhotoRef.current!.style.height = "0px";
		}
		if (type != "audio") {
			const mainVideo = videoRecordRef!.current!;
			mainVideo!.style.height = "320px";
		}
		await addLastMeasurementDialog.stateContainer.merge(() => ({
			state: videoOrAudio ? "recording" : ""
		}));
		await startCamera(videoOrAudio, type == "audio" ? true : undefined);
	}

	async function handlePause() {
		const mainVideo = videoRecordRef!.current!;
		if (type == "photo") {
			capturePhotoRef.current!.style.height = "320px";
			mainVideo!.style.height = "0px";
			const canvas = capturePhotoRef!.current!;
			const context = canvas.getContext("2d")!;
			context.drawImage(mainVideo, 0, 0, 500, 320);
		}
		if (type == "video" || type == "audio") {
			mediaRecorder!.stop();
		}
		await addLastMeasurementDialog.stateContainer.merge(() => ({
			state: "saved"
		}));
	}

	function blobToDataURL(blob: Blob, callback: any) {
		var fileReader = new FileReader();
		fileReader.onload = function (e: any) { callback(e.target.result); }
		return fileReader.readAsDataURL(blob);
	}

	async function handleSave() {
		const mainVideo = videoRecordRef!.current!;
		const canvas = capturePhotoRef!.current!;
		const audio = audioRef!.current!;
		let file = await form.getField("file").value as any;
		if (type == "photo") {
			const fileToSend = formatting.dataURLtoFile(canvas.toDataURL("image/png"), "foto_záznam_" + formatting.formatFileDate() + ".png");
			file = fileToSend;
		}
		if (type == "video") {
			const fileToSend = await fetch(mainVideo.src).then(res => res.blob());
			file = new File([fileToSend], "video_záznam_" + formatting.formatFileDate() + ".webm");
		}
		if (type == "audio") {
			const fileToSend = await fetch(audio.src).then(res => res.blob());
			file = new File([fileToSend], "audio_záznam_" + formatting.formatFileDate() + ".ogg");
		}
		await uploadFile(file);
	}

	async function handleDownload(file: any) {
		return await context.api.download("/dms_files/" + file.id_dms_file + "/download", file.name, {});
	}

	async function handleRemove(index: number) {
		form.setField("documents", documents.filter((_, i) => index != i));
	}

	async function handleAdd() {
		await addLastMeasurementDialog.stateContainer.merge(() => ({
			showAddFile: true
		}));
	}

	async function handleBack() {
		await addLastMeasurementDialog.stateContainer.merge(() => ({
			showAddFile: false
		}));
	}

	return (
		<Dialog className="last-records-add-dialog" dialog={addLastMeasurementDialog.dialog}>
			<div>
				<h2 className="pb-3 dialog-title"><img className="d-inline mr-2" src="/images/folders-1.svg" alt="folders-1" /> Zdravotní záznam</h2>
				<hr className="dialog-title-hr" />
				{container.showAddFile &&
					<div className="mt-3">
						{!form.getField("custom_text").value &&
							<>
								{!container.showRecord &&
									<>
										<div className="row">
											{deviceIsMobile &&
												<>
													<div className="col-sm-6">
														<FormFileUpload
															form={addLastMeasurementDialog.form}
															content={
																<div className="last-records-media-panel">
																	<div className="last-records-media-icon">
																		<Icon picture="Video" />
																	</div>
																	<div className="color-primary mt-2">
																		Natočit video
																	</div>
																</div>
															}
															isCustom={true}
															fileUploadProps={{ accept: "video/*" } as any}
															field="file"
														/>
													</div>
													<div className="col-sm-6">
														<FormFileUpload
															form={addLastMeasurementDialog.form}
															content={
																<div className="last-records-media-panel">
																	<div className="last-records-media-icon">
																		<Icon picture="Microphone" />
																	</div>
																	<div className="color-primary mt-2">
																		Nahrát audio
																	</div>
																</div>
															}
															isCustom={true}
															fileUploadProps={{ accept: "audio/*" } as any}
															field="file"
														/>
													</div>
													<div className="col-sm-6">
														<FormFileUpload
															form={addLastMeasurementDialog.form}
															content={
																<div className="last-records-media-panel">
																	<div className="last-records-media-icon">
																		<Icon picture="Camera" />
																	</div>
																	<div className="color-primary mt-2">
																		Vyfotit
																	</div>
																</div>
															}
															isCustom={true}
															fileUploadProps={{ accept: "image/*" } as any}
															field="file"
														/>
													</div>
												</>
											}
											{!deviceIsMobile &&
												<>
													<div className="col">
														<div onClick={() => handlClick("video")} className="last-records-media-panel">
															<div className="last-records-media-icon">
																<Icon picture="Video" />
															</div>
															<div className="color-primary mt-2">
																Natočit video
															</div>
														</div>
													</div>
													<div className="col-sm-6">
														<div onClick={() => handlClick("audio")} className="last-records-media-panel">
															<div className="last-records-media-icon">
																<Icon picture="Microphone" />
															</div>
															<div className="color-primary mt-2">
																Nahrát audio
															</div>
														</div>
													</div>
													<div className="col-sm-6">
														<div onClick={() => handlClick("photo")} className="last-records-media-panel">
															<div className="last-records-media-icon">
																<Icon picture="Camera" />
															</div>
															<div className="color-primary mt-2">
																Vyfotit
															</div>
														</div>
													</div>
												</>
											}
											<div className="col-sm-6">
												<FormFileUpload
													form={addLastMeasurementDialog.form}
													simple={true}
													content={
														<div className="last-records-media-panel">
															<div className="last-records-media-icon">
																<Icon picture="FilePdf" />
															</div>
															<div className="color-primary mt-2">
																Vložit soubor
															</div>
														</div>
													}
													isCustom={true}
													field="file"
												/>
											</div>
											<div className="col-sm-6">
												<div onClick={() => form.setField("custom_text", true)} className="last-records-media-panel">
													<div className="last-records-media-icon">
														<Icon picture="Detail" />
													</div>
													<div className="color-primary mt-2">
														Vlastní text
													</div>
												</div>
											</div>
										</div>
										<div className="mt-4">
											<Button onClick={handleBack} className="pt-2 pb-2 float-right">
												<strong>Zpět</strong>
											</Button>
										</div>
									</>
								}
								<div>
									{(type == "video" || type == "photo") &&
										<>
											{(type == "video" || type == "photo") &&
												<VideoPlayer refVideo={videoRecordRef} width={500} height={320} />
											}
											{type == "photo" &&
												<canvas width={500} height={320} ref={capturePhotoRef} className="capturePhoto d-block mb-3"></canvas>
											}
										</>
									}
									{type == "audio" &&
										<audio ref={audioRef} controls></audio>
									}
									{(type == "video" || type == "photo" || type == "audio") &&
										<div className="text-center">
											{fileState == "" && (videoOrAudio) &&
												<div className="d-inline" onClick={handleRecord}>
													<Button className="btn-primary">
														Začít nahrávat
													</Button>
												</div>
											}
											{(fileState == "recording" && (videoOrAudio) || (fileState == "" && type == "photo")) &&
												<div className="d-inline" onClick={handlePause}>
													<Button className="btn-primary">
														{type == "photo" ? <>Vyfotit</> : <>Ukončit nahrávání</>}
													</Button>
												</div>
											}
											{fileState == "saved" && (type == "photo" || videoOrAudio) &&
												<div onClick={handleSave} className="d-inline">
													<Button className="btn-primary">
														Uložit
													</Button>
												</div>
											}
											{container.state == "saved" &&
												<div onClick={handleRecord} className="ml-3 d-inline">
													<Button className="btn-primary">
														{type == "photo" ? <>Vyfotit</> : <>Nahrát</>} znovu
													</Button>
												</div>
											}
										</div>
									}
								</div>
							</>
						}
						{form.getField("custom_text").value &&
							<>
								<FormsInput form={form} field="note_name" />
								<FormTextArea form={form} field="note_description" />
								<div className="mt-4 text-center">
									<Button onClick={e => addLastMeasurementDialog.handleDescription()} className="pt-2 pb-2 float-right">
										<strong>Vložit</strong>
									</Button>
								</div>
							</>
						}
					</div>
				}
				{!container.showAddFile &&
					<div>
						<FormsSelect
							form={form}
							field="type"
							options={[
								{
									title: "Nemoc",
									value: { id: 1, name: "Nemoc" }
								},
								{
									title: "Operace",
									value: { id: 2, name: "Operace" }
								},
								{
									title: "Záznam",
									value: { id: 3, name: "Záznam" }
								},
								{
									title: "Alergie",
									value: { id: 4, name: "Alergie" }
								},
								{
									title: "Očkování",
									value: { id: 5, name: "Očkování" }
								},
								{
									title: "Záznam Dr. Digital",
									value: { id: 6, name: "Záznam Dr. Digital" }
								},
							]}
						/>
						<FormsInput form={form} field="name" />
						<FormTextArea textAreaProps={{ disabled: disabled == undefined ? false : disabled as any } as any} form={form} field="description" />
						<FormsInput inputProps={{ type: "datetime-local" }} form={form} field="date" />
						{!disabled &&
							<Button onClick={handleAdd} className="pt-2 pb-2 mb-3">
								<strong>Přidat soubor</strong>
							</Button>
						}
						{documents.length > 0 &&
							<div className="add-dialog-list-panel-item">
								<div className="add-dialog-list-panel-item-container">
									{documents.map((i, index) =>
										<div key={index}>
											<div>
												<div onClick={() => handleDownload(i)} className="d-inline">
													{i.name}
												</div>
												<div onClick={() => handleRemove(index)} className="d-inline widget-list-panel-remove mr-2"><Icon picture="Remove" /></div>
											</div>
										</div>
									)}
								</div>
							</div>
						}
						{!disabled &&
							<div className="mt-4 text-center">
								<Button onClick={e => addLastMeasurementDialog.save()} className="pt-2 pb-2 float-right">
									<strong>Uložit</strong>
								</Button>
							</div>
						}
					</div>
				}
			</div>
		</Dialog >
	);
}

export default state.bindContainers(
	LastRecordsAddDialog,
	context => context.addLastMeasurementDialog
);