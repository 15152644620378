/**
 * Widget pilulkoid
 */
import * as api from "../../../../../lib/api";
import * as state from "../../../../../lib/state";
import * as React from "react";
import Help from "../../../../../components/controls/Help";
import MyRequestAction from "../../../../../components/controls/MyRequestAction";
import Icon from "../../../../../modules/system/icons/Icon";

export function PilulkoidPage() {
	const { pilulkoid: model } = state.useStateContext();
	const { apiImages } = state.useStateContext();
	const { pilulkoidDialog } = state.useStateContext();

	const actions = [{
		title: "Přidat záznam",
		icon: "Add",
		action: async () => {
			handleAdd();
		},
	}];
	async function handleAdd() {
		pilulkoidDialog.openDialog(undefined, undefined) as any;
	}

	function handleOpen(i: any) {
		pilulkoidDialog.openDialog(i, true);
	}

	return (
		<div>
			<div className="pilulkoid-page-container">
				<div className="homepage-widget-header">
					<img className="d-inline mr-2" src="/images/pill.svg" alt="thermometer-half" />
					<h2 className="d-inline">Lékarna Dr.Digital</h2>
					<Help id="pilulkoid-help" text="Zapište si léky, které pravidelně užíváte." />
					{/* <MyRequestAction actions={actions as any} /> */}
					<span onClick={handleAdd} className="cursor-pointer float-right color-primary"><Icon picture="Add" /></span>
				</div>
				<div className="mt-3 mb-3">
					{model.form.getField("items").value.map((i, index) =>
						<div onClick={() => handleOpen(i)} key={index} className="row pilulkoid-list-item-row">
							<div className="col-md-12">
								<div className="pilulkoid-list-item">
									{i.id_image != api.emptyGuid && i.id_image &&
										<img style={{ maxWidth: 60 }} className="mr-3" src={apiImages.getImageUrl(i.id_image)} alt={i.name} />
									}
									<div>
										<div className="pilulkoid-list-item-title">{i.name}</div>
										<div className="pilulkoid-list-item-text">{i.dosage}</div>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default state.bindContainers(
	PilulkoidPage,
	c => c.pilulkoid
);