import * as dialogs from "../../../shared/dialogs/dialogs";
import * as context from "../../../../context";
import * as forms from "../../../../lib/forms";
import * as notification from "../../../../lib/notification";

export interface FormFields {
	description: string;
}

export class Model {
	public dialog: dialogs.Dialog<string, FormFields>;
	public form: forms.Form<FormFields>;

	constructor(private context: context.StateContext) {
		this.dialog = new dialogs.Dialog<string, FormFields>({
			defaultCustomFields: {
				description: "",
			}
		}, context);
		this.form = new forms.Form<FormFields>({
			fields: {
				description: {
					title: "Popis",
					defaultValue: "",
					required: (form) => true,
					validate: (value) => value.length == 0 ? "Vyplňte popis" : ""
				},
			}
		}, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			...this.form.getStateContainers(),
			...this.dialog.getStateContainers()
		];
	}

	openDialog = async (description: string) => {
		await this.form.clearFields();
		if (description) {
			this.form.setField("description", description);
		}

		return this.dialog.open();
	}

	save = async () => {
		await this.form.validate();
		if (this.form.isValid()) {
			return this.dialog.close(this.form.getField("description").value);
		}
		else {
			await notification.errorMessage("Formulář obsahuje neúplné informace nebo chyby. Opravte prosím červeně vyznačené položky a poté akci opakujte.");
		}
	}
}