/**
 * Přidání souboru
 */
import * as React from "react";
import * as state from "../../../../lib/state";
import FormsHtmlEditor from "../../../../components/controls/Forms/FormsHtmlEditor";

export default function UsersMessage() {
	const { usersMessageDialog } = state.useStateContext();
	const dialog = usersMessageDialog.dialog;

	return (
		<div className="message-doctor-dialog">
			<div id="message-doctor-dialog">
				<div>
					<div className="medical-records-panel p-0 ml-0 mr-0">
						<div className="d-inline-block text-left mb-5">
							<div>
								<img className="user-message-dialog-header-dr" src="/images/drdigitalThumbsUp_faceNeutral.png" alt="group7" />
							</div>
							<div>
								<img style={{ marginTop: "-1px" }} width={650} src="/images/letter.png" alt="group7" />
							</div>
						</div>
						<div style={{ marginTop: 60 }} className="d-inline-block float-right mb-5">
							<div>
								<img className="users-message-dialog-page1-img" src="/images/Group 655.svg" alt="group7" />
							</div>
						</div>
						<h2 style={{ fontSize: 35 }} className="text-center mt-0">
							Vzkaz od lékaře - strana 1
						</h2>
						<div id="text-page1" className="medical-records-panel-blue medical-records-panel-main mb-4 users-message-dialog-page1">
							<FormsHtmlEditor
								simpleMode={true}
								field="page1"
								form={usersMessageDialog.form}
							/>
						</div>
					</div>
				</div>
				<div>
					<div className="medical-records-panel p-0 ml-0 mr-0">
						<h2 className="users-message-dialiog-bottom-text">
							Vzkaz od lékaře - strana 2
						</h2>
						<div id="text-page2" className="medical-records-panel-blue medical-records-panel-main mb-4 users-message-dialog-page2">
							<FormsHtmlEditor
								simpleMode={true}
								field="page2"
								form={usersMessageDialog.form}
							/>
						</div>
						<div className="users-message-dialog-sign">
							<div className="d-inline-block ml-4">
								<img className="mt-2" width={160} src="/images/podpis - kopie.png" />
							</div>
							<div className="d-inline-block">
								<img width={90} src="/images/foto-dvorak - kopie.png" />
							</div>
							<div className="font-weight-bold">
								MUDr. Marek Dvořák, PhD., MBA
							</div>
						</div>
						<div className="medical-records-panel-footer">
							<div>
								<img width={"100%"} src="/images/footer.png" alt="group7" />
							</div>
							<div style={{ right: 0 }} className="medical-records-conclusion-drdigital">
								<img width={290} src="/images/Component 6 – 1.png" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}