import React, { forwardRef, Component } from "react";

interface SlideDownContentProps extends SlideDownProps {
	forwardedRef: React.Ref<HTMLDivElement> | null;
}

class SlideDownContent extends Component<SlideDownContentProps> {
	private outerRef: HTMLDivElement | null = null;

	private handleRef = (ref: HTMLDivElement | null) => {
		/* Handle both the internal and forwardedRef and maintain correct typings */
		this.outerRef = ref;

		if (this.props.forwardedRef) {
			if (typeof this.props.forwardedRef === "function") {
				this.props.forwardedRef(ref);
			} else if (typeof this.props.forwardedRef === "object") {
				const forwardedRef = this.props.forwardedRef as any;
				forwardedRef.current = ref;
			} else {
				throw new Error(`Invalid forwardedRef ${this.props.forwardedRef}`);
			}
		}
	}

	componentDidMount() {
		if (this.outerRef && !this.props.open) {
			this.outerRef.classList.add("closed");
		}
	}

	getSnapshotBeforeUpdate() {
		/* Prepare to resize */
		return this.outerRef ? this.outerRef.getBoundingClientRect().height + "px" : null;
	}

	componentDidUpdate(_prevProps: SlideDownContentProps, _prevState: any, snapshot: string | null) {
		if (this.outerRef && snapshot !== null && this.props.open !== _prevProps.open) {
			this.startTransition(snapshot);
		}
	}

	private startTransition(prevHeight: string) {
		if (!this.outerRef) return;

		let endHeight = "0px";

		if (this.props.open) {
			this.outerRef.classList.remove("closed");
			this.outerRef.style.removeProperty("height");
			endHeight = getComputedStyle(this.outerRef).height;
		}

		if (parseFloat(endHeight).toFixed(2) !== parseFloat(prevHeight).toFixed(2)) {
			this.outerRef.classList.add("transitioning");
			this.outerRef.style.height = prevHeight;
			this.outerRef.offsetHeight; // force repaint
			this.outerRef.style.height = endHeight;
		}
	}

	private endTransition() {
		if (!this.outerRef) return;

		this.outerRef.classList.remove("transitioning");
		this.outerRef.style.removeProperty("height");

		if (!this.props.open) {
			this.outerRef.classList.add("closed");
		}
	}

	private handleTransitionEnd = (evt: React.TransitionEvent) => {
		if ((evt.target === this.outerRef) && (evt.propertyName === "height")) {
			this.endTransition();
		}
	}

	render() {
		const { as = "div", children, className, open, forwardedRef, ...rest } = this.props;
		const containerClassName = className ? "react-slidedown " + className : "react-slidedown";

		return React.createElement(
			as,
			{
				ref: this.handleRef,
				className: containerClassName,
				onTransitionEnd: this.handleTransitionEnd,
				...rest
			},
			<>
				{/* nulový div zamezí nenulovému marginu shora i zdola, který jinak způsobuje "poskočení" animace */}
				<div style={{ height: 0, width: 0 }}>&nbsp;</div>
				{children}
				<div style={{ height: 0, width: 0 }}>&nbsp;</div>
			</>
		);
	}
}

interface SlideDownProps extends React.HTMLAttributes<HTMLDivElement> {
	as?: keyof JSX.IntrinsicElements | React.ComponentType<any>;
	open: boolean;
}

export default forwardRef((props: SlideDownProps, ref: React.Ref<HTMLDivElement>) => (
	<SlideDownContent {...props} forwardedRef={ref} />
));