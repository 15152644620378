/**
 * Zapomenuté heslo
 */
import React from "react";
import * as state from "../../lib/state";
import * as navigation from "../../lib/navigation";
import FormsInput from "../../components/controls/Forms/FormsInput";
import ButtonAdvanced from "../controls/ButtonAdvanced";


function ResetPassword() {
	const { resetPassword } = state.useStateContext();

	return (
		<div className="login-form" >
			<div className="login-form-container">
				<h3 className="text-center font-weight-bold mb-5 color-primary">Reset hesla</h3>
				<FormsInput
					form={resetPassword.form} field="password" inputProps={{ type: "password" }}
				/>
				<FormsInput
					form={resetPassword.form} field="repeat_password" inputProps={{ type: "password" }}
				/>
				<div onClick={() => navigation.to("/prihlaseni")} className="login-part-checkbox-label mt-2 mb-3">
					<span className="font-weight-bold ml-1">Zpět do přihlášení</span>
				</div>
			</div>
			<div className="text-right">
				<ButtonAdvanced theme="primary" onClick={resetPassword.send}>Odeslat</ButtonAdvanced>
			</div>
		</div>
	);
}

export default state.bindContainers(
	ResetPassword,
	c => c.resetPassword
);