/**
 * Modul domovské stránky
 */

import * as state from "../../../../../lib/state";
import * as formatting from "../../../../../lib/formatting";
import * as context from "../../../../../context";
import * as forms from "../../../../../lib/forms";
import * as apiHome from "../../api-home";
import * as apiCalendar from "./api-calendar";

export interface FormFields {
	type: apiCalendar.CalendarView;
	currentDay: Date;
	currentWeek: Date;
	data: apiHome.Calendar[];
}

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	public form: forms.Form<FormFields>;

	constructor(private context: context.StateContext) {
		this.form = new forms.Form<FormFields>({
			fields: {
				type: {
					title: "",
					defaultValue: apiCalendar.CalendarView.Month
				},
				currentDay: {
					title: "",
					defaultValue: new Date()
				},
				currentWeek: {
					title: "",
					defaultValue: new Date()
				},
				data: {
					title: "",
					defaultValue: []
				}
			}
		}, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			...this.form.getStateContainers()
		];
	}

	loadData = async () => {
		const calendar = await this.context.api.loadList("/calendars") as any;
		this.form.setField("data", calendar.data);
	}

	nextDay = async () => {
		const currentDay = this.form.getField("currentDay").value;
		const date = formatting.addDay(currentDay, 1);
		this.form.setField("currentDay", date);
	}

	previoustDay = async () => {
		const currentDay = this.form.getField("currentDay").value;
		const date = formatting.addDay(currentDay, -1);
		this.form.setField("currentDay", date);
	}

	getData = () => {
		const items = [] as apiCalendar.CalendarItem[];
		const data = this.form.getField("data").value;
		data.map((i, index) => {
			const date = new Date(i.date as any);
			const day = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0).toString();
			items.push({ ...i, date: date, day: day });
		});
		return items;
	}

	/**
	 * Provede reset formuláře
	 */
	reset = async () => {
		await Promise.all([
			this.form.clearFields(),
		]);
	}
}