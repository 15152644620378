/*
 * Komponenta radio tlačítka.
 */
import * as React from "react";
import CheckboxRadio, { CheckBoxRadioProps } from "./CheckboxRadio";

export type RadioProps = Omit<CheckBoxRadioProps, "type">;

export default function Radio(props: RadioProps) {
	return (
		<CheckboxRadio {...props} />
	);
}