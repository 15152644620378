/**
 * Modul Medical record
 */

import * as state from "../../../../lib/state";
import * as notification from "../../../../lib/notification";
import * as api from "../../../../lib/api";
import * as context from "../../../../context";
import * as forms from "../../../../lib/forms";
import * as apiHome from "../../home/api-home";
import * as formatting from "../../../../lib/formatting";
import * as lastRecords from "../../home/widgets/last-records/last-records";
import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import storage from "store";

interface MeassurementSelected {
	uid?: string;
	id: string;
	sid: number;
	type: number;
	value: string;
	value2: string;
	data?: any;
	date: Date | null;
}

export interface MeasurementData {
	data: Date;
	value: number;
	value2: number;
	type: number;
	id: number;
	sid?: number;
}

export interface AllData {
	data: MeasurementData[];
	statistics: MeasurementsCount[];
}

export enum MedicalRecordState {
	InConcept = 1,
	ApprovedByOperator = 2,
	ApprovedByDoctor = 3
}

/**
 * Stav modulu
 */

interface MeasurementsCount {
	name: string;
	type: number;
	value: number;
}
interface State {
	saving: boolean;
	drugs: api.CodeBookItem[];
	evaluation_date_from: Date;
	evaluation_date_to: Date;
	measures_date_from: Date;
	measures_date_to: Date;
	sleep_average?: number;
	food_and_excretion_average?: number;
	subjective_mood_feeling_average?: number;
	subjective_feeling_of_health_average?: number;
	subjective_feeling_of_energy_average?: number;
	breathing_average?: number;
	sight_smell_hearing_average?: number;
	measurement_selected: MeassurementSelected[];
	pilulkoids: api.CodeBookItem[];
	ongoing_treatments: api.CodeBookItem[];
	allergies: api.CodeBookItem[];
	operations: api.CodeBookItem[];
	vaccination: api.CodeBookItem[];
	doctors: api.CodeBookItem[];
	loaded: boolean;
	showWeek: boolean;
	all_feeling_meassurements: MeasurementsCount[];
	all_meassurements: MeasurementsCount[];
	loadingMeasurements: boolean;
	showButtons: boolean;
	measurementData: MeasurementData[];
	lastMeasurementData: MeasurementData | null;
	types: number[];
}

export interface Pain {
	name: string;
	date: string;
	duration: string;
	cause: string;
	pain_relief: string;
	pain_intensity: number[] | null;
	description: string;
	pain_intensity_from?: number;
	pain_intensity_to?: number;
}

interface FormData extends FormFields {
	pains: Pain[];
	measurement_selected: MeassurementSelected[];
	message_name: string;
	types: string;
}

export interface Graph {
	data: Date;
	value: number;
	value2: number;
	type: number;
	id: number;
	sid?: number;
}

export interface FormFields {
	sleep: number | null;
	food_and_excretion: number | null;
	breathing: number | null;
	sight_smell_hearing: number | null;
	subjective_feeling_of_health: number | null;
	subjective_feeling_of_energy: number | null;
	subjective_mood_feeling: number | null;
	patient_description: string;
	personal_description: string;
	pains: Pain[];
	types: string;
	no_daily_difficulty?: boolean;
	sleep_description: string;
	food_and_excretion_description: string;
	breathing_description: string;
	sight_smell_hearing_description: string;
	airway?: boolean;
	eupnoe?: boolean;
	tratchea?: boolean;
	without_stridor?: boolean;
	without_involvement_of_auxiliary_respiratory_muscles?: boolean;
	speaks_in_sentence_without_shortnes_of_breath?: boolean;
	not_cyanosis?: boolean;
	breathing_attachment_included?: boolean;
	breathing_attachment?: api.CodeBookItem;
	breathing_evaluation_description: string;
	circulation_regularly?: boolean
	circulation_attachment?: api.CodeBookItem;
	disability_description: string;
	lucid?: boolean;
	time_and_place_oriented?: boolean;
	without_obvious_nurological_deficit?: boolean;
	limb_momentum_symmetrical?: boolean;
	facialis_normal?: boolean;
	isokorie?: boolean;
	normal_skin_color?: boolean;
	not_pale?: boolean;
	not_icteric?: boolean;
	afebrile?: boolean;
	exposure_description: string;
	normal_skin?: boolean;
	skin_attachment?: api.CodeBookItem;
	normal_hydration?: boolean;
	eyes_without_redness?: boolean;
	ears_nose_without_secretion?: boolean;
	normal_neck?: boolean;
	throat_calm?: boolean;
	throat_attachment?: api.CodeBookItem;
	chest_symmetrical?: boolean;
	belly_soft?: boolean;
	limbs_without_defigurations?: boolean;
	lower_limbs_without_swelling?: boolean;
	// possible_PMKs_lead_to_clear_urine?: boolean;
	evaluation: string;
	conclusion: string;
	recommendation: string;
	report_issued_by: string;
	record_date?: Date;
	call_date?: Date;
	medical_record_attachment?: api.CodeBookItem;
	id_dms_file_doctor_attachment?: api.CodeBookItem;
	id: string;
	id_state: MedicalRecordState;
	message_from_doctor: string;
	id_user: string;
	description: string;
	id_state_temporary?: number;
	report_created_by: string;
	subjective_mood_feeling_description: string;
	subjective_feeling_of_energy_description: string;
	subjective_feeling_of_health_description: string;
}

function defaultState() {
	return {
		saving: false,
		drugs: [],
		evaluation_date_from: formatting.substractDaysFromDate(31, formatting.setTimeFromString(new Date(), 0, 0, 0)),
		evaluation_date_to: formatting.setTimeFromString(new Date(), 23, 59, 59),
		measures_date_from: formatting.substractDaysFromDate(31, formatting.setTimeFromString(new Date(), 0, 0, 0)),
		measures_date_to: formatting.setTimeFromString(new Date(), 23, 59, 59),
		measurement_selected: [],
		pilulkoids: [],
		ongoing_treatments: [],
		allergies: [],
		operations: [],
		vaccination: [],
		doctors: [],
		loaded: false,
		showWeek: false,
		all_feeling_meassurements: [],
		all_meassurements: [],
		loadingMeasurements: true,
		showButtons: false,
		measurementData: [],
		lastMeasurementData: null,
		types: []
	} as State;
}

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	public stateContainer: state.StateContainer<State>;
	public form: forms.Form<FormFields>;
	public formsPain: forms.FormCollection<Pain>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>(defaultState(), context);
		this.form = new forms.Form<FormFields>({
			fields: {
				id: {
					defaultValue: "",
					title: ""
				},
				types: {
					defaultValue: "",
					title: ""
				},
				description: {
					defaultValue: "",
					title: ""
				},
				subjective_feeling_of_energy_description: {
					defaultValue: "",
					title: ""
				},
				subjective_feeling_of_health_description: {
					defaultValue: "",
					title: ""
				},
				subjective_mood_feeling_description: {
					defaultValue: "",
					title: ""
				},
				breathing_evaluation_description: {
					defaultValue: "",
					title: ""
				},
				personal_description: {
					defaultValue: "",
					title: ""
				},
				patient_description: {
					title: "",
					defaultValue: "",
				},
				pains: {
					title: "",
					defaultValue: []
				},
				subjective_feeling_of_health: {
					title: "",
					defaultValue: null,
					validate: (value) => this.cancelValidation(!value ? "Vyberte volbu" : "", 6)
				},
				subjective_feeling_of_energy: {
					title: "",
					defaultValue: null,
					validate: (value) => this.cancelValidation(!value ? "Vyberte volbu" : "", 6)
				},
				subjective_mood_feeling: {
					title: "",
					defaultValue: null,
					validate: (value) => this.cancelValidation(!value ? "Vyberte volbu" : "", 6)
				},
				no_daily_difficulty: {
					title: "Každodenní činnost zvládám bez potíží",
					defaultValue: undefined
				},
				sleep: {
					title: "",
					defaultValue: null,
					validate: (value) => this.cancelValidation(!value ? "Vyberte volbu" : "", 7)
				},
				sleep_description: {
					title: "",
					defaultValue: "",
				},
				food_and_excretion: {
					title: "",
					defaultValue: null,
					validate: (value) => this.cancelValidation(!value ? "Vyberte volbu" : "", 7)
				},
				food_and_excretion_description: {
					title: "",
					defaultValue: ""
				},
				breathing: {
					title: "",
					defaultValue: null,
					validate: (value) => this.cancelValidation(!value ? "Vyberte volbu" : "", 7)
				},
				breathing_description: {
					title: "",
					defaultValue: "",
				},
				sight_smell_hearing: {
					title: "",
					defaultValue: null,
					validate: (value) => this.cancelValidation(!value ? "Vyberte volbu" : "", 7)
				},
				sight_smell_hearing_description: {
					defaultValue: "",
					title: ""
				},
				airway: {
					title: "dýchací cesty udržuje volně průchodné",
					defaultValue: undefined,
					validate: (value) => this.cancelValidation(value == undefined ? "Vyberte volbu" : "", 8)
				},
				eupnoe: {
					title: "eupnoe",
					defaultValue: undefined,
					validate: (value) => this.cancelValidation(value == undefined ? "Vyberte volbu" : "", 8)
				},
				tratchea: {
					title: "trachea ve střední čáře",
					defaultValue: undefined,
					validate: (value) => this.cancelValidation(value == undefined ? "Vyberte volbu" : "", 8)
				},
				without_stridor: {
					title: "bez slyšitelného stridoru a přidatných zvuků",
					defaultValue: undefined,
					validate: (value) => this.cancelValidation(value == undefined ? "Vyberte volbu" : "", 8)
				},
				without_involvement_of_auxiliary_respiratory_muscles: {
					title: "bez zapojení pomocných dýchacích svalů",
					defaultValue: undefined,
					validate: (value) => this.cancelValidation(value == undefined ? "Vyberte volbu" : "", 8)
				},
				speaks_in_sentence_without_shortnes_of_breath: {
					title: "hovoří ve větách bez zadýchání",
					defaultValue: undefined,
					validate: (value) => this.cancelValidation(value == undefined ? "Vyberte volbu" : "", 8)
				},
				not_cyanosis: {
					title: "není cyanosa",
					defaultValue: undefined,
					validate: (value) => this.cancelValidation(value == undefined ? "Vyberte volbu" : "", 8)
				},
				breathing_attachment_included: {
					title: "bez přiloženého poslechového nálezu k hodnocení",
					defaultValue: undefined,
					validate: (value) => this.cancelValidation(value == undefined ? "Vyberte volbu" : "", 8)
				},
				breathing_attachment: {
					title: "",
					defaultValue: undefined,
					validate: (value, field, form) => this.cancelValidation(form.getField("breathing_attachment_included").value == false && !value ? "Nahrajte přílohu" : "", 8)
				},
				circulation_regularly: {
					title: "akce srdce pravidelná (korelace s EKG křivkou) - nelze hodnotit online",
					defaultValue: undefined,
					validate: (value) => this.cancelValidation(value == undefined ? "Vyberte volbu" : "", 8)
				},
				circulation_attachment: {
					title: "",
					defaultValue: undefined,
					validate: (value, field, form) => this.cancelValidation(form.getField("circulation_regularly").value == false && !value ? "Nahrajte přílohu" : "", 8)
				},
				disability_description: {
					title: "",
					defaultValue: "",
				},
				lucid: {
					title: "lucidní",
					defaultValue: undefined,
					validate: (value) => this.cancelValidation(value == undefined ? "Vyberte volbu" : "", 8)
				},
				time_and_place_oriented: {
					title: "orientovaný časem, místem, osobou i situačně",
					defaultValue: undefined,
					validate: (value) => this.cancelValidation(value == undefined ? "Vyberte volbu" : "", 8)
				},
				without_obvious_nurological_deficit: {
					title: "bez zjevného neurologického deficitu (porucha čití nebo hybnosti)",
					defaultValue: undefined,
					validate: (value) => this.cancelValidation(value == undefined ? "Vyberte volbu" : "", 8)
				},
				limb_momentum_symmetrical: {
					title: "hybnost končetin symetrická",
					defaultValue: undefined,
					validate: (value) => this.cancelValidation(value == undefined ? "Vyberte volbu" : "", 8)
				},
				facialis_normal: {
					title: "inervace n. facialis v normě",
					defaultValue: undefined,
					validate: (value) => this.cancelValidation(value == undefined ? "Vyberte volbu" : "", 8)
				},
				isokorie: {
					title: "isokorie (pokud lze hodnotit)",
					defaultValue: undefined,
					validate: (value) => this.cancelValidation(value == undefined ? "Vyberte volbu" : "", 8)
				},
				normal_skin_color: {
					title: "normální kožní kolorit",
					defaultValue: undefined,
					validate: (value) => this.cancelValidation(value == undefined ? "Vyberte volbu" : "", 8)
				},
				not_pale: {
					title: "není bledý",
					defaultValue: undefined,
					validate: (value) => this.cancelValidation(value == undefined ? "Vyberte volbu" : "", 8)
				},
				not_icteric: {
					title: "není ikterický",
					defaultValue: undefined,
					validate: (value) => this.cancelValidation(value == undefined ? "Vyberte volbu" : "", 8)
				},
				afebrile: {
					title: "afebrilní",
					defaultValue: undefined,
					validate: (value) => this.cancelValidation(value == undefined ? "Vyberte volbu" : "", 8)
				},
				exposure_description: {
					title: "",
					defaultValue: ""
				},
				normal_skin: {
					title: "bez podezřelých naevů na kůži",
					defaultValue: undefined,
					validate: (value) => this.cancelValidation(value == undefined ? "Vyberte volbu" : "", 8)
				},
				skin_attachment: {
					title: "",
					defaultValue: undefined,
					validate: (value, field, form) => this.cancelValidation(form.getField("normal_skin").value == false && !value ? "Nahrajte přílohu" : "", 8)
				},
				normal_hydration: {
					title: "normální hydratace",
					defaultValue: undefined,
					validate: (value) => this.cancelValidation(value == undefined ? "Vyberte volbu" : "", 8)
				},
				eyes_without_redness: {
					title: "oči bez zarudnutí",
					defaultValue: undefined,
					validate: (value) => this.cancelValidation(value == undefined ? "Vyberte volbu" : "", 8)
				},
				ears_nose_without_secretion: {
					title: "uši/nos bez sekrece",
					defaultValue: undefined,
					validate: (value) => this.cancelValidation(value == undefined ? "Vyberte volbu" : "", 8)
				},
				normal_neck: {
					title: "krk bez viditelných abnormalit (náplň krčních žil nezvýšená, thyroidea bez zjevné patologie)",
					defaultValue: undefined,
					validate: (value) => this.cancelValidation(value == undefined ? "Vyberte volbu" : "", 8)
				},
				// throat_calm: {
				// 	title: "hrdlo v přehledném rozsahu klidné",
				// 	defaultValue: undefined,
				// 	validate: (value) => value == undefined ? "Vyberte volbu" : ""
				// },
				// throat_attachment: {
				// 	title: "",
				// 	defaultValue: undefined,
				// 	validate: (value, field, form) => form.getField("throat_calm").value && !value ? "Nahrajte přílohu" : ""
				// },
				chest_symmetrical: {
					title: "hrudník symetrický",
					defaultValue: undefined,
					validate: (value) => this.cancelValidation(value == undefined ? "Vyberte volbu" : "", 8)
				},
				belly_soft: {
					title: "břicho měkké ve 4 kvadrantech",
					defaultValue: undefined,
					validate: (value) => this.cancelValidation(value == undefined ? "Vyberte volbu" : "", 8)
				},
				limbs_without_defigurations: {
					title: "končetiny bez defigurací",
					defaultValue: undefined,
					validate: (value) => this.cancelValidation(value == undefined ? "Vyberte volbu" : "", 8)
				},
				lower_limbs_without_swelling: {
					title: "dolní končetiny bez otoků",
					defaultValue: undefined,
					validate: (value) => this.cancelValidation(value == undefined ? "Vyberte volbu" : "", 8)
				},
				// possible_PMKs_lead_to_clear_urine: {
				// 	title: "případné PMK vedou čirou moč",
				// 	defaultValue: undefined,
				// 	validate: (value) => value == undefined ? "Vyberte volbu" : ""
				// },
				evaluation: {
					title: "",
					defaultValue: "",
					validate: (value) => this.cancelValidation(!value ? "Vyplňte hodnotu" : "", 9)
				},
				conclusion: {
					title: "",
					defaultValue: "",
					validate: (value) => this.cancelValidation(!value ? "Vyplňte hodnotu" : "", 9)
				},
				recommendation: {
					title: "",
					defaultValue: "",
					validate: (value) => this.cancelValidation(!value ? "Vyplňte hodnotu" : "", 9)
				},
				report_issued_by: {
					title: "",
					defaultValue: "",
					validate: (value) => this.cancelValidation(!value ? "Vyplňte hodnotu" : "", 9)
				},
				report_created_by: {
					title: "",
					defaultValue: "",
					validate: (value) => this.cancelValidation(!value ? "Vyplňte hodnotu" : "", 9)
				},
				record_date: {
					title: "",
					defaultValue: undefined
				},
				call_date: {
					title: "",
					defaultValue: undefined
				},
				medical_record_attachment: {
					defaultValue: undefined,
					title: ""
				},
				id_state: {
					defaultValue: MedicalRecordState.InConcept,
					title: ""
				},
				id_user: {
					defaultValue: api.emptyGuid,
					title: ""
				},
				id_state_temporary: {
					defaultValue: 1,
					title: ""
				},
				message_from_doctor: {
					defaultValue: "",
					title: "",
					validate: (value, field, form) => this.cancelValidation(!value && (form.getField("id_state").value == MedicalRecordState.ApprovedByOperator || form.getField("id_state_temporary").value == MedicalRecordState.ApprovedByOperator) ? "Vyplňte hodnotu" : "", 9)
				}
			}
		}, context);
		this.formsPain = new forms.FormCollection<Pain>({
			fields: {
				cause: {
					defaultValue: "",
					title: "",
					validate: (value) => !value ? "Vyplňte hodnotu" : ""
				},
				description: {
					defaultValue: "",
					title: "",
					validate: (value) => !value ? "Vyplňte hodnotu" : ""
				},
				duration: {
					defaultValue: "",
					title: "",
					validate: (value) => !value ? "Vyplňte hodnotu" : ""
				},
				name: {
					defaultValue: "",
					title: "",
					validate: (value) => !value ? "Vyplňte hodnotu" : ""
				},
				pain_intensity: {
					defaultValue: null,
					title: "",
					validate: (value) => !value ? "Vyplňte hodnotu" : ""
				},
				pain_relief: {
					defaultValue: "",
					title: "",
					validate: (value) => !value ? "Vyplňte hodnotu" : ""
				},
				date: {
					defaultValue: formatting.formatDate(new Date(), { format: "iso" }),
					title: "",
					validate: (value) => !value ? "Vyplňte hodnotu" : ""
				}
			}
		}, this.context, this.form);
	}

	cancelValidation = (validation: string, type: number) => {
		const types = this.stateContainer.get().types;
		if (types.includes(type)) {
			return "";
		}
		return validation;
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			this.stateContainer,
			...this.form.getStateContainers()
		];
	}

	getStateContainer = () => {
		return this.stateContainer.get();
	}

	getLoaded = () => {
		return this.stateContainer.get().loaded;
	}

	loadData = async (id: string) => {
		await this.reset();
		await this.form.setField("id", id);

		const all_meassurements = await this.context.api.post("/users/all_meassurements", { date_from: formatting.substractDaysFromDate(31, formatting.setTimeFromString(new Date(), 0, 0, 0)), date_to: formatting.setTimeFromString(new Date(), 23, 59, 59) }) as any;
		const all_feeling_meassurements = await this.context.api.post("/users/all_feeling_meassurements", { date_from: formatting.substractDaysFromDate(31, formatting.setTimeFromString(new Date(), 0, 0, 0)), date_to: formatting.setTimeFromString(new Date(), 23, 59, 59) }) as any;
		const sort = this.context.lastMeasurement.sortBy(this.context.lastMeasurement.groupBy(all_meassurements.data, "type")) as any;
		const lastmeasurementData = this.context.lastMeasurement.getData(sort as any) as any;

		await this.stateContainer.merge(() => ({
			all_feeling_meassurements: all_feeling_meassurements,
			all_meassurements: all_meassurements.statistics,
			measurementData: all_meassurements.data.sort((a: any, b: any) => (new Date(a.data) as any) - (new Date(b.data) as any)),
			lastMeasurementData: lastmeasurementData,
			loadingMeasurements: false
		}));

		this.loadPanels();

		if (id != api.emptyGuid) {
			const result = await this.context.api.get("/medical_records/" + id) as FormFields;
			if (result) {
				result.pains = result.pains.map(x => ({ ...x, pain_intensity: [x.pain_intensity_from!, x.pain_intensity_to!] }));
				await this.form.setFields(result);
				const measurement_selected = ((result as any).measurement_selected as MeassurementSelected[]);
				await this.stateContainer.merge(() => ({
					loaded: true,
				}));
				await this.stateContainer.merge(() => ({
					measurement_selected: measurement_selected.length == 0 ? [] : measurement_selected.map(x => ({ ...x, data: !x.date ? null : formatting.formatDate(x.date), id: x.sid as any, uid: x.id })),
					types: Array.from(result.types.split(","), Number)
				}));

				this.formsPain.setFields(result.pains);
			}
		}
		setTimeout(() =>
			this.stateContainer.merge(() => ({
				showButtons: true
			})
			), 8500);
	}

	loadPanels = async () => {
		const drugs = await this.context.api.loadList<api.CodeBookItem>("/pilulkoids", { sort_fields_desc: ["sys_date_create" as any], sort_fields: ["sys_date_create" as any] }) as any;
		const my_files = await this.context.api.loadList("/treatments", { sort_fields_desc: ["sys_date_create"] as any, sort_fields: ["sys_date_create"] as any }) as any;
		let data = my_files.data as apiHome.Record[];
		const dataDrugs = await this.context.api.loadList<api.CodeBookItem>("/pilulkoids", { sort_fields_desc: ["sys_date_create"] as any, sort_fields: ["sys_date_create"] as any }) as any;
		const treatements = await this.context.api.loadList<api.CodeBookItem>("/ongoing_treatments", { sort_fields_desc: ["sys_date_create"] as any, sort_fields: ["sys_date_create"] as any }) as any;
		const doctors = await this.context.api.loadList("/user_doctors", { sort_fields_desc: ["sys_date_create"], sort_fields: ["sys_date_create"] }) as any;
		await this.stateContainer.merge(() => ({
			drugs: drugs.data,
			pilulkoids: dataDrugs.data,
			ongoing_treatments: treatements.data,
			allergies: data.filter(x => x.type == lastRecords.MyRecordType.Allergy),
			operations: data.filter(x =>
				x.type == lastRecords.MyRecordType.Operation ||
				x.type == lastRecords.MyRecordType.Entry ||
				x.type == lastRecords.MyRecordType.Illness
			),
			vaccination: data.filter(x => x.type == lastRecords.MyRecordType.Vaccination),
			doctors: doctors.data
		}));
	}

	setAverage = async (field: any, value: number) => {
		await this.stateContainer.merge(() => ({
			[field]: value,
		}));
	}

	removePain = async (formPain: forms.Form<Pain>) =>
		this.formsPain.remove(formPain)

	getPageSize = (originalPage: number, firstThree?: boolean) => {

		if (!this.stateContainer.get().loaded && this.form.getField("id").value != api.emptyGuid) {
			return "";
		}

		const pains = this.formsPain.get();
		let maxPageSize = pains.length > 3 ? 9 : 8;
		const types = this.stateContainer.get().types;
		let count = 0;
		const allThreeTypes = types.includes(1) && types.includes(2) && types.includes(3);

		if (allThreeTypes) {
			count += 1;
		}
		if (types.includes(4)) {
			count += 1;
		}
		if (types.includes(5)) {
			count += 1;
		}
		if (types.includes(6)) {
			count += 1;
		}
		if (types.includes(7)) {
			count += 1;
		}
		if (types.includes(8)) {
			count += 1;
		}
		if (types.includes(9)) {
			count += 1;
		}
		if (types.includes(10)) {
			count += 1;
		}

		const pages = [] as { page: number, index: number }[];
		let index = 0;
		for (let i = 1; i < 11; i++) {
			if (i < 4 && (!types.includes(1) || !types.includes(2) || !types.includes(3))) {
				if (index == 0) {
					index += 1;
				}
				pages.push({ page: 1, index: index });
			}
			else if (!types.includes(i)) {
				index += 1;

				pages.push({ page: i - 2, index: index });
			}
		}

		const page = pages.find(x => x.page == originalPage) ? pages.find(x => x.page == originalPage)?.index! : 0;

		const oneMorePage = pains.length > 3;
		return (types.includes(1) && allThreeTypes && originalPage == 1) ? "" : (types.includes(originalPage + 2) && originalPage != 1) ? "" :
			((oneMorePage ? (page + (firstThree ? 0 : 1)) : page) + "/" + (maxPageSize - count));
	}

	getAverageColor = (value: number) => {
		let color = "#dc3545";

		if (value > 1 && value <= 2) {
			color = "#ff8a07";
		}

		if (value > 2 && value <= 3) {
			color = "#ffce0c";
		}

		if (value > 3 && value <= 4) {
			color = "#fff50f";
		}

		if (value > 4 && value <= 5) {
			color = "#6dff0e";
		}

		return color;
	}

	savePdf = async (print: any) => {
		await this.stateContainer.merge(() => ({
			saving: true
		}));
		await print.save();

		const that = this;
		setTimeout(async function () {
			await that.stateContainer.merge(() => ({
				saving: false
			}));
		}, 1000);
	}

	remove = async (id: string) => {
		const result = await this.context.api.del("/medical_records/" + id);
		if (result) {
			this.context.authorization.logout();
		}
	}

	getShowWeek = () => {
		return this.stateContainer.get().showWeek;
	}

	setShowWeek = async (value: boolean) => {
		await this.stateContainer.merge(() => ({
			showWeek: value
		}));
	}

	// handleFilter = async (value: boolean, ref: any, ref2: any, ref3: any, ref4: any) => {
	// 	await this.stateContainer.merge(() => ({
	// 		measures_date_from: formatting.substractDaysFromDate(value ? 7 : 31, formatting.setTimeFromString(new Date(), 0, 0, 0)),
	// 		measures_date_to: formatting.setTimeFromString(new Date(), 23, 59, 59),
	// 	}));
	// 	await this.setShowWeek(value);
	// 	// ref.current.changeDateFrom(this.getShowWeek());
	// 	// ref2.current.changeDateFrom(this.getShowWeek());
	// 	// ref3.current.changeDateFrom(this.getShowWeek());
	// 	// ref4.current.changeDateFrom(this.getShowWeek());
	// }

	save = async (print: any, idState?: MedicalRecordState) => {
		await this.form.setField("id_state_temporary", idState as number);
		const noValidation = api.getUrlParam("noValidation") || idState == MedicalRecordState.InConcept;
		if (!noValidation) {
			await this.form.validate();
			await this.form.validate();
		}
		if (noValidation ? true : this.form.isValid()) {
			const fn = this.context.authorization.getUserProfile()?.profile.first_name;
			const ln = this.context.authorization.getUserProfile()?.profile.last_name;

			let medical_record_attachment = null;
			let id_dms_file_doctor_attachment = null;
			const messageName = "Vzkaz_od_lékaře_" + formatting.formatDate(new Date()).replace(/\  /g, ' ').replace(/ /g, "_").replace(":", "_").replace(/\./g, '') + ".pdf";

			if (idState == MedicalRecordState.ApprovedByDoctor) {
				const textPage1 = document.getElementById("text-page1");
				const textPage2 = document.getElementById("text-page2");

				textPage1!.textContent = "";
				textPage2!.textContent = "";

				const drawPdf = await drawDOM(print.rootElForPDF, {
					scale: 0.49,
					margin: { left: -30 },
					paperSize: "A4"
				});
				const exportPD = await exportPDF(drawPdf);
				const name = "ZZ_" + fn + ln + "_" + formatting.formatDate(new Date()).replace(/\  /g, ' ').replace(/ /g, "_").replace(":", "_").replace(/\./g, '') + ".pdf";

				var file = formatting.dataURLtoFile(exportPD, name);
				const messageFromDoctor = document.getElementById("message_from_doctor")!.innerHTML;
				const doctorEl = document.getElementById("message-doctor-dialog") as any;

				textPage1!.innerHTML = messageFromDoctor;

				const exportPDDoctor = await exportPDF(await drawDOM(doctorEl, {
					scale: 0.49,
					margin: { bottom: 0 },
					paperSize: "A4"
				}));

				var fileDoctor = formatting.dataURLtoFile(exportPDDoctor, messageName);
				const uploadResultDoctor = await this.context.apiFile.upload(fileDoctor);
				id_dms_file_doctor_attachment = uploadResultDoctor.id;

				const uploadResult = await this.context.apiFile.upload(file);
				medical_record_attachment = { id: uploadResult.id as string, name: name };
			}

			const measurement_selected = [] as MeassurementSelected[];
			await this.stateContainer.get().measurement_selected.map((i) => {
				measurement_selected.push({
					id: i.uid!,
					sid: (!i.sid || i.sid == 0) ? Number(i.id) : i.sid,
					type: i.type,
					value: i.value,
					value2: !i.value2 ? "" : i.value2,
					date: !i.data ? null : !formatting.parseDate(i.data) ? null : formatting.parseDate(i.data)!
				});
			});

			const data: FormData = {
				...this.form.getFields(),
				pains: this.formsPain.get().map(f => f.getFields()),
				medical_record_attachment: medical_record_attachment as any,
				id_dms_file_doctor_attachment: id_dms_file_doctor_attachment as any,
				measurement_selected: measurement_selected,
				message_name: messageName,
				types: this.stateContainer.get().types.join(",")
			};

			await this.stateContainer.merge(() => ({
				saving: true
			}));

			const that = this;
			if (idState) {
				data.id_state = idState;
			}

			const result = await this.context.api.put("/medical_records/" + data.id, data);
			if (result) {
				setTimeout(async function () {
					await that.reset();
					notification.successMessage("Zdravotní záznam byl uložen.")
					if (storage.get("delegateIdUser")) {
						that.context.authorization.logout();
					}
					else {
						document.location.href = "/";
					}
				}, 1000);
			}
		}
		else {
			await notification.errorMessage("Formulář obsahuje neúplné informace nebo chyby. Opravte prosím červeně vyznačené položky a poté akci opakujte.");
		}
	}

	handleTypes = async (type: number) => {
		let types = this.stateContainer.get().types;
		if (types.includes(type)) {
			types = types.filter(x => x != type);
		}
		else {
			types.push(type);
		}
		await this.stateContainer.merge((prev) => ({
			types: types
		}));
	}

	/**
	 * Provede reset formuláře
	 */
	reset = async () => {
		await this.form.clearFields();
		await this.formsPain.get().map(x => this.removePain(x));
		await this.stateContainer.merge(() => (defaultState()));
	}
}