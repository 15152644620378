import * as dialogs from "./dialogs";
import * as context from "../../../context";

interface InformationDialogFields {
	message: string;
	buttonOkTitle: string;
}

interface YesNoDialogFields {
	message: string;
	buttonYesTitle: string;
	buttonNoTitle: string;
}

export class Model {
	informationDialog: dialogs.Dialog<undefined, InformationDialogFields>;
	yesNoDialog: dialogs.Dialog<"yes" | "no", YesNoDialogFields>;

	constructor(private context: context.StateContext) {
		this.informationDialog = new dialogs.Dialog<undefined, InformationDialogFields>({
			defaultCustomFields: {
				message: "",
				buttonOkTitle: "Ok"
			},
			hideCloseButton: true
		}, context);

		this.yesNoDialog = new dialogs.Dialog<"yes" | "no", YesNoDialogFields>({
			defaultCustomFields: {
				message: "",
				buttonYesTitle: "Ano",
				buttonNoTitle: "Ne",
			},
			hideCloseButton: true
		}, context);
	}

	openInformationDialog = async (message: string, buttonOkTitle?: string) => {
		await this.informationDialog.setCustomField("message", message);
		if (buttonOkTitle !== undefined && buttonOkTitle !== "") {
			await this.informationDialog.setCustomField("buttonOkTitle", buttonOkTitle);
		}
		return this.informationDialog.open();
	}

	openYesNoDialog = async (message: string, buttonYesTitle?: string, buttonNoTitle?: string) => {
		await this.yesNoDialog.setCustomField("message", message);
		if (buttonYesTitle !== undefined && buttonYesTitle !== "") {
			await this.yesNoDialog.setCustomField("buttonYesTitle", buttonYesTitle);
		}
		if (buttonNoTitle !== undefined && buttonNoTitle !== "") {
			await this.yesNoDialog.setCustomField("buttonNoTitle", buttonNoTitle);
		}
		return this.yesNoDialog.open();
	}
}