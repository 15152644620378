/**
 * Zobrazení náhledu obrázku
 */
import * as React from "react";
import * as state from "../../../lib/state";

import Dialog from "../../shared/dialogs/Dialog";

function ShowImageDialog() {
	const { showImageDialog } = state.useStateContext();
	const { apiImages } = state.useStateContext();
	const dialog = showImageDialog.dialog;

	return (
		<Dialog dialog={dialog}>
			<div>
				<h2 className="pb-3 dialog-title"><img className="d-inline mr-2" src="/images/__TEMP__SVG__.svg" alt="folders-1" />{showImageDialog.form.getField("title").value}</h2>
				<div className="text-center">
					<img className="show-image-dialog-img" src={apiImages.getImageUrl(showImageDialog.form.getField("id_image").value)} />
				</div>
			</div>
		</Dialog >
	);
}

export default state.bindContainers(
	ShowImageDialog,
	context => context.showImageDialog
);