/**
 * Domovská stránka
 */
import React from "react";
import * as state from "../../../lib/state";
import * as formatting from "../../../lib/formatting";
import * as apiAuth from "../../system/authorization/api-authorization";

import Page from "../../../components/templates/Page";
import LastMeasurementPage from "./widgets/last-measurement/LastMeasurementPage";
import LastRecords from "./widgets/last-records/LastRecords";
import CalendarPage from "./widgets/calendar/CalendarPage";
import PilulkoidPage from "./widgets/pilulkoid-page/PilulkoidPage";
import PainMonitor from "./widgets/pain-monitor/PainMonitor";
import DoctorsPage from "./widgets/doctors/DoctorsPage";
import Help from "../../../components/controls/Help";

function HomePage() {
	const { authorization } = state.useStateContext();
	const widgets = authorization.getUserProfile()?.widgets;
	const widgetIds = widgets == undefined ? [] : widgets.map(x => x.id) as number[];
	let widgetWidth = "col-4 homepage-widget-height widget-max-width max-width-840-width-100 max-width-1514-width-50";
	const demoAccount = authorization.getUserProfile()?.demo_account;
	const statistics = authorization.getStatisticsFilled()!;

	return (
		<Page title="Ordinace" description="">
			<div className="page-content-container">
				{statistics.filled != 0 && statistics.filled < 100 &&
					<div className="statistiscs demo-account">
						<span>Aplikace je vyplněna z <span className="text-danger font-weight-bold">{statistics.filled}%</span>. Pro náhled co vám chybí klikněte na bublinku vedle textu.</span>
						<Help className="statistics-help" id="statistics-help" text={
							statistics.items.map((x, index) =>
								<div key={index} className="text-danger font-weight-bold">- {x}</div>
							)
						} />
					</div>
				}
				<div className="text-light ml-3">
					<div className="max-width-1075">
						<span style={{ fontSize: 22 }}>Dnes je {formatting.formatDayName(new Date())}, {formatting.formatDate(new Date(), { format: "long" })}</span> <span style={{ fontSize: 12 }}>(Svátek má {formatting.getNameDay()}</span>)
						{/* <span className="float-right cursor-pointer">
						<span className="mr-3">Přidat widget</span>
						<img className="page-menu-add-img" src="/images/Component 46 – 3.svg" alt="Component 46 – 3" />
					</span> */}
					</div>
				</div>
				<div className="min-width-1075 homepage-responsive-title">
					Moje nástěnka
				</div>
				<div className="homepage-widgets">
					<div className={"pl-1 pr-1 home-widgets-responsive"}>
						<div className="row mr-0 ml-0">
							{widgetIds.includes(apiAuth.Widget.LastMeasurement) &&
								<div className={widgetWidth + " last-measurement-page-responsive"}>
									<div className="homepage-widget last-measurement-page">
										<LastMeasurementPage />
									</div>
								</div>
							}
							{widgetIds.includes(apiAuth.Widget.LastRecords) &&
								<div className={widgetWidth + " last-records-page-responsive"}>
									<div className="homepage-widget last-records-page">
										<LastRecords />
									</div>
								</div>
							}
							{widgetIds.includes(apiAuth.Widget.Calendar) &&
								<div className={widgetWidth + " "}>
									<div className="calendar-page-responsive">
										<div className="homepage-widget calendar-page">
											<CalendarPage />
										</div>
									</div>
								</div>
							}
							{widgetIds.includes(apiAuth.Widget.Pilulkoid) &&
								<div className={widgetWidth + " ilulkoid-page-responsive"}>
									<div className="homepage-widget pilulkoid-page">
										<PilulkoidPage />
									</div>
								</div>
							}
							{widgetIds.includes(apiAuth.Widget.PCardPreview) &&
								<div className={widgetWidth + " p-card-preview-page-responsive"}>
									<div className="homepage-widget pain-monitor">
										<PainMonitor />
									</div>
								</div>
							}
							{widgetIds.includes(apiAuth.Widget.ContentShowCase) &&
								<div className={widgetWidth + " p-card-preview-page-responsive"}>
									<div className="homepage-widget pain-monitor">
										<DoctorsPage />
									</div>
								</div>
							}
						</div>
					</div>
				</div>
			</div>
		</Page >
	);
}

export default state.bindContainers(
	HomePage,
	context => context.home
);