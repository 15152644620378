/**
 * Widget Last records
 */
import React from "react";
import * as apiHome from "../../pages/home/api-home";
import * as state from "../../../lib/state";
import * as api from "../../../lib/api";

interface Props {
	content: apiHome.Content;
	showOtherContent: boolean;
	ids?: number[];
	setShowList?: (id: string) => void;
}

export default function ContentItems(props: Props) {
	const { contentDialog } = state.useStateContext();
	const { apiImages } = state.useStateContext();
	const item = props.content.items[0];

	function handleContent(item: apiHome.ContentItem) {
		const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
		contentDialog.openDialog(item, isMobile, props.content);
	}

	return (
		<>
			<div className="mt-3 content-items-panel">
				{!props.ids?.includes(props.content.id) &&
					<>
						{props.content.show_one_entry &&
							<div onClick={() => handleContent(item)} className="cursor-pointer pl-2 pr-2">
								<img
									className="content-items-item"
									style={{ height: "175px", width: "100%" }}
									alt="content"
									src={item?.id_image == api.emptyGuid ? "/images/man-holding-newspaper.jpg" : apiImages.getImageUrl(item?.id_image)}
								/>
								<div className="mt-2">
									{item?.title}
								</div>
							</div>

						}
						{!props.content.show_all && !props.content.show_one_entry &&
							<>
								{props.content.items.slice(0, 3).map((i, index) =>
									<>
										<div onClick={() => handleContent(i)} key={index} className="mb-2 content-items-row">
											<div>
												<img
													className="content-items-item"
													style={{ width: 75, height: 70 }}
													alt="content"
													src={i?.id_image == api.emptyGuid ? "/images/man-holding-newspaper.jpg" : apiImages.getImageUrl(i?.id_image)}
												/>
											</div>
											<div className="col content-items-text">
												{i?.title}
											</div>
										</div>
									</>
								)}
							</>
						}
					</>
				}
				{(props.ids?.includes(props.content.id) || props.content.show_all) &&
					<>
						{props.content.items.map((i, index) =>
							<>
								<div onClick={() => handleContent(i)} style={{ alignItems: "baseline" }} className="content-items-row mt-1" key={index}>
									<div>
										<img
											className="content-items-item"
											style={{ width: 30, height: 30 }}
											alt="content"
											src={i?.id_image == api.emptyGuid ? "/images/man-holding-newspaper.jpg" : apiImages.getImageUrl(i?.id_image)}
										/>
									</div>
									<div className="content-items-text content-items-text-list">{i.title}</div>
								</div>
							</>
						)}
					</>
				}
			</div>
			{props.showOtherContent && !props.content.show_all &&
				<div className="content-items-footer">
					{!props.ids?.includes(props.content.id) &&
						<span onClick={() => props.setShowList!(props.content.id)} className="cursor-pointer">další články</span>
					}
					{props.ids?.includes(props.content.id) &&
						<span onClick={() => props.setShowList!(props.content.id)} className="cursor-pointer">zpět</span>
					}
				</div>
			}
		</>
	);
}