/**
 * MedicalRecordsExamination
 */
import React, { useRef } from "react";
import * as state from "../../../../lib/state";
import * as formatting from "../../../../lib/formatting";
import { GeneralGraph } from "../../../../modules/shared/graphs/GeneralGraph";
import FormsCheckBox from "../../../../components/controls/Forms/FormCheckBox";
import FormsSlider from "../../../../components/controls/Forms/FormsSlider";
import * as painMonitor from "../../home/widgets/pain-monitor/pain-monitor-dialog";
import Icon from "../../../../modules/system/icons/Icon";
import FormsEditableDiv from "../../../../components/controls/Forms/FormsEditableDiv";
import MedicalRecordHideSwitch from "./MedicalRecordHideSwitch";

export default function MedicalRecordsPain() {
	const { medicalRecords } = state.useStateContext();
	const form = medicalRecords.form;
	const container = medicalRecords.getStateContainer();
	const generalRef = useRef<any>(null);
	const generalRef2 = useRef<any>(null);
	const generalRef3 = useRef<any>(null);

	return (
		<>
			{!container.types.includes(6) &&
				<div className={"medical-records-panel medical-records-examination " + (container.types.includes(6) ? "print-hide" : "")}>
					<div className="medical-records-panel-header">
						<div className="medical-records-panel-page">
							<img width={120} src="/images/Group 655.svg" alt="group7" />
						</div>
						<div className="medical-records-panel-header-page">
							{medicalRecords.getPageSize(4)}
						</div>
						<div>
							<div>Datum záznamu: {formatting.formatDate(form.getField("record_date").value)}</div>
							<div className="float-right">Datum hovoru: {formatting.formatDate(form.getField("call_date").value)}</div>
						</div>
					</div>
					<div className="text-center">
						<MedicalRecordHideSwitch type={6} />
						<h2 className="medical-records-panel-title font-weight-bold">Tele vyšetření - subjektivní hodnocení pacienta</h2>
					</div>

					<div className="medical-records-panel-blue medical-records-panel-main text-center medical-records-panel-blue-text mb-4 mt-5">
						Subjektivní pocit zdraví
					</div>
					<div className="d-flex mb-4">
						<div style={{ width: "45%" }} className="pr-5">
							<div className="medical-records-list-item  medical-records-list-ex-item-pain medical-records-labels p-2">
								<FormsEditableDiv
									field="subjective_feeling_of_health_description"
									form={medicalRecords.form}
									className="medical-records-panel-blue medical-records-panel-main mb-4 medical-records-examination-panel-height"
								/>
								{painMonitor.labels.find(x => x.id == painMonitor.PainType.SubjectiveFeelingOfHealth)?.items.map((i, index) =>
									<div key={index} className="d-flex mb-2 mt-2">
										<div className="medical-records-examination-order">
											{index + 1}
										</div>
										<div>
											{i}
										</div>
									</div>
								)}
							</div>
						</div>
						<div style={{ width: "55%" }}>
							<div>
								<div className="mr-3">
									<FormsSlider form={form} field="subjective_feeling_of_health" maxValue={5} />
								</div>
							</div>
							<div className="d-flex mt-3">
								<div>Trend</div>
							</div>
							<div className="d-flex">
								<div style={{ width: "100%" }}>
									<GeneralGraph
										ref={generalRef}
										showWeek={container.showWeek}
										date_from={container.evaluation_date_from}
										date_to={container.evaluation_date_to}
										type={{ id: 101, name: "" }}
										saving={container.saving}
										hideHeader={true}
										height="230px"
									/>
								</div>
							</div>
						</div>
					</div>

					<div className="medical-records-panel-blue medical-records-panel-main text-center medical-records-panel-blue-text mb-4 mt-5">
						Subjektivní pocit energie
					</div>
					<div className="d-flex mb-4">
						<div style={{ width: "45%" }} className="pr-5">
							<div className="medical-records-list-item  medical-records-list-ex-item-pain medical-records-labels p-2">
								<FormsEditableDiv
									field="subjective_feeling_of_energy_description"
									form={medicalRecords.form}
									className="medical-records-panel-blue medical-records-panel-main mb-4 medical-records-examination-panel-height"
								/>
								{painMonitor.labels.find(x => x.id == painMonitor.PainType.SubjectiveFeelingOfEnergy)?.items.map((i, index) =>
									<div key={index} className="d-flex mb-2 mt-2">
										<div className="medical-records-examination-order">
											{index + 1}
										</div>
										<div>
											{i}
										</div>
									</div>
								)}
							</div>
						</div>
						<div style={{ width: "55%" }}>
							<div>
								<div className="mr-3">
									<FormsSlider form={form} field="subjective_feeling_of_energy" maxValue={5} />
								</div>
							</div>
							<div className="d-flex mt-3">
								<div>Trend</div>
							</div>
							<div className="d-flex">
								<div style={{ width: "100%" }}>
									<GeneralGraph
										ref={generalRef2}
										showWeek={container.showWeek}
										date_from={container.evaluation_date_from}
										date_to={container.evaluation_date_to}
										type={{ id: 102, name: "" }}
										saving={container.saving}
										hideHeader={true}
										height="230px"
									/>
								</div>
							</div>
						</div>
					</div>

					<div className="medical-records-panel-blue medical-records-panel-main text-center medical-records-panel-blue-text mb-4 mt-5">
						Subjektivní pocit nálady
					</div>
					<div className="d-flex mb-4">
						<div style={{ width: "45%" }} className="pr-5">
							<div className="medical-records-list-item medical-records-list-ex-item-pain medical-records-labels p-2">
								<FormsEditableDiv
									field="subjective_mood_feeling_description"
									form={medicalRecords.form}
									className="medical-records-panel-blue medical-records-panel-main mb-4 medical-records-examination-panel-height"
								/>
								{painMonitor.labels.find(x => x.id == painMonitor.PainType.SubjectiveMoodFeeling)?.items.map((i, index) =>
									<div key={index} className="d-flex mb-2 mt-2">
										<div className="medical-records-examination-order">
											{index + 1}
										</div>
										<div>
											{i}
										</div>
									</div>
								)}
							</div>
						</div>
						<div style={{ width: "55%" }}>
							<div>
								<div className="mr-3">
									<FormsSlider form={form} field="subjective_mood_feeling" maxValue={5} />
								</div>
							</div>
							<div className="d-flex mt-3">
								<div>Trend</div>
							</div>
							<div className="d-flex">
								<div style={{ width: "100%" }}>
									<GeneralGraph
										ref={generalRef3}
										showWeek={container.showWeek}
										date_from={container.evaluation_date_from}
										date_to={container.evaluation_date_to}
										type={{ id: 103, name: "" }}
										saving={container.saving}
										hideHeader={true}
										height="230px"
									/>
								</div>
							</div>
						</div>
					</div>

					<div style={{ height: 45 }} className="medical-records-panel-blue text-center medical-records-panel-blue-text">
						<FormsCheckBox className="medical-records-checkbox" form={form} field="no_daily_difficulty" />
					</div>

					<div className="medical-records-panel-footer">
						{medicalRecords.getPageSize(4)}
					</div>
				</div >
			}
		</>
	);
}
