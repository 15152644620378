/*
 * Komponenta checkboxu a radio tlačítka.
 */
import * as React from "react";

export interface CheckBoxRadioProps {
	className?: string;
	type: "checkbox" | "radio";
	title?: React.ReactNode | string;
	checked?: boolean;
	onCheckedChanged?: (value: boolean) => void;
	small?: boolean;
	readOnly?: boolean;
	field?: any;
	onSave?: (field?: any, value?: string) => void;
}

export default function CheckboxOriginal(props: CheckBoxRadioProps) {
	function handleOnChange(e: any) {
		if (props.onCheckedChanged) {
			props.onCheckedChanged(e.target.checked);
		}
		if (props.onSave) {
			props.onSave!(props.field, e.target.checked);
		}
	}

	return (
		<label className={
			"checkbox-radio checkbox-radio--" + (props.type) + " "
			+ (props.small ? "checkbox-radio--small " : "")
			+ (props.className || "")}
		>
			<input
				className="checkbox-radio__input"
				type={props.type} checked={props.checked ? true : false}
				disabled={props.readOnly}
				onChange={handleOnChange}>
			</input>
			<span className={"checkbox-radio__checkmark " + (props.readOnly ? "checkbox-radio__checkmark--readonly " : "")}></span>
			{props.title &&
				<span className="checkbox-radio__title">{props.title}</span>
			}
		</label>
	);
}