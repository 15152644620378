/**
 * Stránka 404
 */
import * as React from "react";

import Page from "../../../components/templates/Page";
import Button from "../../../components/controls/Button";
import Link from "../../../components/controls/Link";

export default class RegistrationStarPage extends React.Component {
	render() {
		return (
			<Page title="Stránka neexistuje" description="">
				<main className="page-content-container" role="main">
					<div className="row ml-2 mt-1">
						<div className="col col-xl-5">
							<h1 className="text-light mt-1 mb-3 font-weight-bold">Stránka nenalezena</h1>
							<p className="text-light">
								Stránku se nepodařilo nalézt. Je možné, že stránka byla odstraněna, přejmenována nebo není dočasně dostupná. Zkontrolujte prosím, zda nemáte zastaralé záložky, popř. zda jste nezadali špatnou adresu stránky.
							</p>
							<Link to="/" noStyle>
								<Button className="page-404__button-home">
									Domů
								</Button>
							</Link>
						</div>
					</div>
				</main>
			</Page >
		);
	}
}
