/*
 * API obrázků
 */
import * as context from "../../../context";
import * as api from "../../../lib/api";

const route = "/notifications";

export class Api {
	constructor(private context: context.StateContext) { }

	/**
	 * Načte notifikace pro uživatele
	 */
	loadUserNotifications(userId: string, query: api.Query<Notification>) {
		return this.context.api.loadList<Notification>("/notifications", { sort_fields_desc: ["sys_date_create" as any], sort_fields: ["sys_date_create" as any] }) as any;
	}

	/**
	 * Nastaví notifikaci jako přečtenou
	 */
	markAsRead(item: any) {
		return this.context.api.put("/notifications/" + item.id, item);
	}
}