/**
 * Seznam uživatelů
 */

import * as state from "../../../../lib/state";
import * as api from "../../../../lib/api";
import * as context from "../../../../context";
import * as lists from "../../../system/lists/lists";
import * as apiContents from "./api-waiting-room";

export interface WaitingRoomItemList {
	id: string;
	title: string;
	description: string;
	id_image: string;
	id_main_image: string;
	id_content: string;
	deleted: boolean;
}

/**
 * Stav modulu
 */
export interface State {
}

/**
 * Vytvoří nový seznam.
 */
export function createList(context: context.StateContext, filter?: any) {
	return new lists.StandardList<WaitingRoomItemList>({
		context: context,
		title: "Seznam uživatelů",
		quickFilterPlaceholder: "Vyhledejte záznam...",
		filterSystem: _ => [
			api.qp("id_content" as any, "=", context.waitingRoomDetail.form.getField("id").value),
		],
		itemActionsList: { actions: [] },
		standardEntityApi: context.apiContents.waiting_room_item as any,
		sortingFields: [],
		grid: {
			minWidth: "1000px",
			columns: [
				{
					field: "title" as any,
					type: "string",
					title: "Název",
				},
				{
					field: "action" as any,
					width: "80px",
					type: "string",
					title: "Akce",
				},
			]
		},
		defaultPageSize: 40,
		hideTabFilter: true,
		hideQueryMode: true
	});
}

export class Model implements state.StateModel {
	list: lists.StandardList<apiContents.ContentItem>;
	stateContainer: state.StateContainer<State>;

	constructor(private context: context.StateContext) {
		this.list = createList(context);
		this.stateContainer = new state.StateContainer<State>({}, context);
	}

	/**
	* Vrací kolekci stavových kontejnerů
	*/
	getStateContainers = () => [
		this.stateContainer,
		...this.list.getStateContainers(),
	]

	loadData = async () => {
		await this.list.loadWithResetPage();
	}

	remove = async (id: string) => {
		await this.context.apiContents.removeItem(id);
		await this.list.load();
	}
}