import * as dialogs from "../../../../shared/dialogs/dialogs";
import * as context from "../../../../../context";
import * as forms from "../../../../../lib/forms";
import * as api from "../../../../../lib/api";
import * as notification from "../../../../../lib/notification";

export interface State {
	items: api.CodeBookItemWithDate[];
	currentDate?: Date;
}

export class Model {
	public dialog: dialogs.Dialog<undefined, State>;

	constructor(private context: context.StateContext) {
		this.dialog = new dialogs.Dialog<undefined, State>({
			defaultCustomFields: {
				items: [],
				currentDate: undefined
			}
		}, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			...this.dialog.getStateContainers()
		];
	}

	openDialog = (items: api.CodeBookItemWithDate[], currentDate: Date) => {
		this.dialog.setCustomField("items", items);
		this.dialog.setCustomField("currentDate", currentDate);
		return this.dialog.open();
	}
}