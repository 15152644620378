/**
 * Vybrání měření
 */
import React from "react";
import * as state from "../../../../../lib/state"
import Spinner from "../../../../../components/controls/Spinner";
import CheckBoxRadioFrame from "../../../../../components/controls/CheckBoxRadioFrame";
import RadioOriginal from "../../../../../components/controls/RadioOriginal";
import Dialog from "../../../../shared/dialogs/Dialog";
import Button from "../../../../../components/controls/Button";

function LastMeasurementWidgetSelect() {
	const { lastMeasurementSelectDialog } = state.useStateContext();
	const sid = lastMeasurementSelectDialog.getValue()?.sid;
	const id = lastMeasurementSelectDialog.getValue()?.id;

	return (
		<Dialog className="last-measurement-dialog" dialog={lastMeasurementSelectDialog.dialog}>
			<div>
				<h2 className="pb-3 dialog-title"><img className="d-inline mr-2" src="/images/chart.svg" alt="chart" /> Výběr měření za posledních 24 hodin</h2>
				<hr className="dialog-title-hr" />
				<div className="">
					{lastMeasurementSelectDialog.getLoading() &&
						<Spinner small />
					}
					{lastMeasurementSelectDialog.getData().length > 0 &&
						<CheckBoxRadioFrame>
							{lastMeasurementSelectDialog.getData().map((i, index) =>
								<RadioOriginal
									key={index}
									onCheckedChanged={() => lastMeasurementSelectDialog.setValue(i as any)}
									checked={!sid ? id == i.id : sid == i.sid as any}
									title={i.type == 5 ? i.data : "Datum: " + i.data + ", hodnota: " + i.value + (i.value2 ? ", hodnota2: " + i.value2 : "")} />
							)}
						</CheckBoxRadioFrame>
					}
					{!lastMeasurementSelectDialog.getLoading() && lastMeasurementSelectDialog.getData().length == 0 &&
						<div className="font-weight-bold">Nejsou k dispozici žádna data.</div>
					}
				</div >
				{lastMeasurementSelectDialog.getValue() &&
					<div className="mt-4 text-center">
						<Button onClick={e => lastMeasurementSelectDialog.save()} className="pt-2 pb-2 float-right">
							<strong>Vybrat</strong>
						</Button>
					</div>
				}
			</div>
		</Dialog >
	);
}

export default state.bindContainers(
	LastMeasurementWidgetSelect,
	c => c.lastMeasurementSelectDialog.dialog
);