import * as React from "react";
import * as forms from "../../../lib/forms";

import FormItem, { FormItemOptions } from "./FormItem";
import Radio, { RadioProps } from "../Radio";


export interface FormsInputProps<TFormFields, TField extends keyof TFormFields> {
	form: forms.Form<TFormFields>;
	field: TField;
	option: TFormFields[TField];
	className?: string;
	checkBoxProps?: RadioProps;
	formItemOptions?: FormItemOptions;
	title?: React.ReactNode;
}

export default function FormsRadio<TFormFields, TField extends keyof TFormFields>(props: FormsInputProps<TFormFields, TField>) {
	const field = props.form.getField(props.field);
	const fieldOptions = props.form.getFieldOptions(props.field);

	return (
		<>
			<div className="checkbox-radio-new-radio-title">
				{fieldOptions.title}
			</div>
			<div className="d-inline-block float-right checkbox-radio-container">
				<FormItem
					form={props.form}
					field={props.field}
					options={{ ...props.formItemOptions, hideTitle: true }}
					className={props.className}
				>
					<Radio
						{...props.checkBoxProps}
						checked={field.value as any}
						readOnly={field.readOnly}
						onCheckedChanged={(value: boolean) => props.form.setField(props.field, value as any)}
					/>
				</FormItem>
			</div>
		</>
	);
}