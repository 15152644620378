/**
 * Detail čekárny
 */
import React from "react";
import * as navigation from "../../../../lib/navigation";
import * as state from "../../../../lib/state";
import * as api from "../../../../lib/api";
import Page from "../../../../components/templates/Page";
import FormsInput from "../../../../components/controls/Forms/FormsInput";
import FormsCheckBox from "../../../../components/controls/Forms/FormCheckBox";
import Button from "../../../../components/controls/Button";
import WaitingRoomItemListPage from "./WaitingRoomItemListPage";

interface Props {
	id: string;
}

function WaitingRoomDetailPage(props: Props) {
	const { authorization } = state.useStateContext();
	const { waitingRoomDetail } = state.useStateContext();
	const form = waitingRoomDetail.form;

	return (
		<Page title="Čekárna - detail" description="">
			{authorization.isAdminOrOperator() &&
				<div className="page-content-container">
					<div className="ml-3 mb-3 text-dark">
						<div className="homepage-responsive-title d-inline">
							Čekárna - detail
						</div>
					</div>
					<div className="content-container m-3">
						<div className="row p-2">
							<div className="col-9">
								<FormsInput form={form} field="name" />
							</div>
						</div>
						<div className="row">
							<div className="col-3">
								<FormsCheckBox className="mt-1" form={form} field="show_one_entry" />
							</div>
							<div className="col-3">
								<FormsCheckBox className="mt-1" form={form} field="show_all" />
							</div>
						</div>
						{props.id != api.emptyGuid &&
							<WaitingRoomItemListPage />
						}

						<div className="row p-2 mt-3">
							<div className="col">
								<div className="mt-3 d-inline">
									<Button className="font-weight-bold" onClick={waitingRoomDetail.save}>
										Uložit
									</Button>
								</div>
								<div className="d-inline float-right">
									<Button className="font-weight-bold" onClick={() => navigation.to("/administrace/cekarna")}>
										Zpět
									</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
			}
		</Page >
	);
}

export default state.bindContainers(
	WaitingRoomDetailPage,
	context => context.waitingRoomDetail
);