import * as api from "../../../../lib/api";
import * as context from "../../../../context";
import * as apiCodeList from "../../../system/code-list/api-code-list";

export interface Email extends api.EntityReadBase {
	id: string;
	subject: string;
	mail_from: string;
	mail_to: string;
	error: string;
	body: string;
	date_of_create: Date;
	date_of_sent: Date;
}

export class Api {
	emails: api.EntityApi<Email, Email>;
	userEmails: api.EntityApi<Email, Email>;

	constructor(private context: context.StateContext) {
		this.emails = new api.EntityApi<Email, Email>({
			api: context.api,
			path: "/emails"
		});
		this.userEmails = new api.EntityApi<Email, Email>({
			api: context.api,
			path: "/emails/users"
		});
	}

	loadData = () => {
		return this.context.api.loadList<apiCodeList.CodeList>("/emails", {
			per_page: Number.MAX_SAFE_INTEGER
		});
	}

	loadDetail(id: string) {
		return this.context.api.get<Email>("/emails/" + id);
	}
}