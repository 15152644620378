/**
 * Seznam emailů
 */
import React from "react";
import * as state from "../../../../lib/state";
import * as formatting from "../../../../lib/formatting";
import Page from "../../../../components/templates/Page";
import List from "../../../../modules/system/lists/List";
import Link from "../../../../components/controls/Link";

function EmailListPage() {
	const { emailsList } = state.useStateContext();
	const { authorization } = state.useStateContext();

	return (
		<Page title="Seznam emailů" description="">
			{authorization.isAdminOrOperator() &&
				<div className="page-content-container users-list-page">
					<div className="text-light ml-3 mb-4">
						<div className="homepage-responsive-title d-inline">
							Seznam emailů
						</div>
					</div>
					<div className="ml-3 mr-3">
						<div className="mt-3 mb-3">
							<div className="form-filter u-mb-md">
								<div id="records" className="form-filter__inner row">
									<div className="col-md-7 col-xs-12 mb-3">
										<label htmlFor="filter-search" className="inp-label u-vhide">
											Zadejte název
										</label>
										<span className="inp-fix">
											<input
												type="text"
												id="inp-filter-search"
												placeholder={emailsList.list.options.quickFilterPlaceholder}
												className="inp-text inp-text--filter"
												value={emailsList.list.getFilterFulltextPhrase()}
												onChange={(e) => emailsList.list.setFilterFulltextPhrase(e.target.value)}
											/>
										</span>
									</div>
									<div className="col-xs-4 col-12 col-md-auto u-mb-md ml-md-auto">
										<div className="d-inline mr-2">
											<button type="submit" className="btn btn--filter" onClick={emailsList.list.loadWithResetPage}>
												<span className="btn__text">
													Vyhledat
												</span>
											</button>
										</div>
										<div className="d-inline">
											<button type="submit" className="btn btn--filter" onClick={emailsList.list.clearFilter}>
												<span className="btn__text">
													Reset
												</span>
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
						<List
							itemNode={(item, field) => {
								switch (field) {
									case "mail_from": return <Link to={"/administrace/emaily/" + item.id}>{item[field]}</Link>;
									case "date_of_create": return formatting.formatDateTime(item.date_of_create);
									case "date_of_sent": return formatting.formatDateTime(item.date_of_sent).includes("0001") ? "" : formatting.formatDateTime(item.date_of_sent);
									default: return undefined;
								}
							}}
							simpleTable
							list={emailsList.list} />
					</div>
				</div>
			}
		</Page >
	);
}

export default state.bindContainers(
	EmailListPage,
	context => context.emailsList
);