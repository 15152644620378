
/**
 * MedicalRecordsInfo
 */
import React, { useRef } from "react";
import Icon from "../../../../modules/system/icons/Icon";
import * as state from "../../../../lib/state";
import * as api from "../../../../lib/api";
import * as formatting from "../../../../lib/formatting";
import * as pCardWidget from "../../p-card-admin/widgets/p-card-widget/p-card-widget";
import Checkbox from "../../../../components/controls/Checkbox";
import PieChartGraph from "../../../../modules/shared/graphs/PieChart";
import { LastMeasurementWidget } from "../../home/widgets/last-measurement/LastMeasurementWidget";
interface Props {
	isSecondPage?: boolean;
}

export default function IndividualReportInfoPage(props: Props) {
	const { individualReport } = state.useStateContext();
	const { authorization } = state.useStateContext();
	const measurementRef = useRef<any>(null);
	const { apiImages } = state.useStateContext();
	const profile = authorization.getUserProfile()?.profile;
	const container = individualReport.getStateContainer();
	const image = profile?.id_dms_file_image;
	const birthdate = profile?.birthdate ? formatting.formatDate(profile?.birthdate) : profile?.personal_identification_number ? formatting.formatDate(formatting.parseDateStringFormat(profile?.personal_identification_number!, "YYMMDD")) : "";

	function handleRefresh() {
		measurementRef.current.loadWidgets();
	}

	return (
		<>
			<div className="medical-records-panel">
				<div className="medical-records-panel-header">
					<div className="medical-records-panel-page">
						<img width={120} src="/images/Group 655.svg" alt="group7" />
					</div>
					<div className="medical-records-panel-header-page">
						{individualReport.getPageSize(1)}
					</div>
				</div>
				<div className="text-center">
					<h2 style={{ width: "60 %" }} className="medical-records-panel-title font-weight-bold">Individuální report</h2>
				</div>
				<div className="medical-records-panel-blue medical-records-panel-main text-center medical-records-panel-blue-text mb-4">
					DZK údaje
				</div>
				<div className="d-flex mt-1">
					<div className="mr-3" style={{ width: "33%" }}>
						<div style={{ width: 360 }} className="d-flex">
							<div style={{ width: " 80%" }}>
								<div className={"medical-records-name "}>
									{profile?.full_name}
								</div>
								<div className={"medical-records-name-age d-inline "}>
									{birthdate &&
										<>
											{birthdate} ({formatting.calculateAge(new Date(profile?.birthdate ? profile?.birthdate : birthdate))} let)
										</>
									}
								</div>
							</div>
							<div className="medical-records-list-img">
								<img style={{ height: 60 }} width={60} height={60} alt="user" src={image == null || image == api.emptyGuid ? "/images/user.jpg" : apiImages.getImageUrl(profile?.id_dms_file_image!)} />
							</div>
						</div>
						<div className="d-flex mt-5">
							<div className="mr-2" style={{ width: 110 }}>
								<div className="medical-records-list-item">
									<Icon className="medical-records-list-item-icon" size="lg" picture="Child" />
									<div className={"medical-records-list-item-text "}>{profile?.height} cm</div>
								</div>
							</div>
							<div className="mr-2" style={{ width: 110 }}>
								<div className="medical-records-list-item">
									<Icon className="medical-records-list-item-icon" picture="Weight" />
									<div className={"medical-records-list-item-text"}>{profile?.weight} kg</div>
								</div>
							</div>
							<div className="mr-2" style={{ width: 110 }}>
								<div className="medical-records-list-item">
									<img className="medical-records-list-item-icon" src="/images/droplet-fill.svg" alt="droplet-fill" />
									<div className={"medical-records-list-item-text "}>{profile?.ab}</div>
								</div>
							</div>
						</div>
					</div>
					<div className="mr-3" style={{ width: "33%" }}>
						<div style={{ width: 343 }} className="medical-records-list-item-row">
							<div>
								<div style={{ height: 90 }} className="medical-records-list-item">
									<div className="medical-records-list-item-title">Adresa:</div>
									<div className={"medical-records-list-item-text "}>{profile?.address}</div></div>
							</div>
						</div>
						<div style={{ width: 343 }} className="medical-records-list-item-row mt-3">
							<div>
								<div className="medical-records-list-item">
									<div className="medical-records-list-item-title">Rodinné zázemí:</div>
									<div className={"medical-records-list-item-text "}>{pCardWidget.UserType.title(profile?.type!)}</div></div>
							</div>
						</div>
					</div>
					<div className="mr-3" style={{ width: "33%" }}>
						<div className="medical-records-list-item-row">
							<div>
								<div className="medical-records-list-item">
									<div className="medical-records-list-item-title">Rodné číslo:</div>
									<div className={"medical-records-list-item-text "}>{(profile?.personal_identification_number!) + (profile?.personal_identification_number_sufix)}</div></div>
							</div>
						</div>
						<div className="medical-records-list-item-row mb-3">
							<div>
								<div className="medical-records-list-item">
									<div className="medical-records-list-item-title">Krizový kontakt:</div>
									<div className={"medical-records-list-item-text "}>{profile?.crisis_name}</div>
								</div>
							</div>
						</div>
						<div className="medical-records-list-item-row mb-3">
							<div>
								<div className="medical-records-list-item">
									<div className="medical-records-list-item-title">Krizový telefon:</div>
									<div className={"medical-records-list-item-text "}>{profile?.crisis_phone}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="d-flex mt-2">
					<div style={{ width: "33%" }} className="mb-3">
						<div style={{ width: 343 }} className="medical-records-list-item">
							<div style={{ height: 175 }} className="individual-report-panel-item-container">
								<div className="medical-records-list-item-title mb-1">Alergie:</div>
								<div className="d-block">
									{container.allergies.map((x, index) =>
										<div key={index}>{x.name}</div>
									)}
								</div>
							</div>
						</div>
					</div>
					<div className=" mr-3" style={{ width: "33%" }}>
						<div className="medical-records-list-item-row mb-3">
							<div style={{ width: 360 }}>
								<Checkbox
									title={"Bez dlouhodobé medikace"}
									checked={profile?.no_long_term_medication}
									readOnly={true}
								/>
								<div style={{ height: 157 }} className="medical-records-list-item">
									<div className="individual-report-panel-item-container">
										<div className="medical-records-list-item-title mb-1">Užívaná medikace:</div>
										<div className="d-block">
											{container.drugs.map((x, index) =>
												<div key={index}>{x.name}</div>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div style={{ width: "33%" }} className="mb-3">
						<Checkbox
							title={"Bez dlouhodobého onemocnění"}
							checked={profile?.no_long_term_medication}
							readOnly={true}
						/>
						<div style={{ height: 157 }} className="medical-records-list-item">
							<div className="individual-report-panel-item-container">
								<div className="medical-records-list-item-title mb-1">Probíhající onemocnění:</div>
								<div className="d-block">
									{container.ongoing_treatments.map((x, index) =>
										<div key={index}>{x.name}</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="d-flex mt-2">
					<div style={{ width: "33%" }} className="mb-3 mr-3">
						<div style={{ width: 343 }} className="medical-records-list-item">
							<div style={{ height: 175 }} className="individual-report-panel-item-container">
								<div className="medical-records-list-item-title mb-1">Zdravotní záznamy:</div>
								<div className="d-block">
									{container.operations.map((x, index) =>
										<div key={index}>{x.name}</div>
									)}
								</div>
							</div>
						</div>
					</div>
					<div style={{ width: "33%" }} className="mb-3 mr-3">
						<div style={{ width: 360 }} className="medical-records-list-item">
							<div style={{ height: 175 }} className="individual-report-panel-item-container">
								<div className="medical-records-list-item-title mb-1">Ošetřující lékaři:</div>
								<div className="d-block">
									{container.doctors.map((x, index) =>
										<div key={index}>{x.name}</div>
									)}
								</div>
							</div>
						</div>
					</div>
					<div style={{ width: "33%" }} className="mb-3">
						<div style={{ width: 360 }} className="medical-records-list-item">
							<div style={{ height: 175 }} className="individual-report-panel-item-container">
								<div className="medical-records-list-item-title mb-1">Očkování:</div>
								<div className="d-block">
									{container.vaccination.map((x, index) =>
										<div key={index}>{x.name}</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="medical-records-panel-blue medical-records-panel-main text-center medical-records-panel-blue-text mb-4 mt-3">
					Počet měření za poslední měsíc
				</div>
				<div className="d-flex mt-2 mb-2">
					<div>
						<PieChartGraph loading={container.loadingMeasurements} data={container.all_feeling_meassurements} />
					</div>
					<div>
						<PieChartGraph loading={container.loadingMeasurements} data={container.all_meassurements} />
					</div>
				</div>
				<hr className="mt-0" />
				<h3 className="mt-1 mb-1">Poslední měření<span onClick={handleRefresh} className="d-inline ml-2 color-primary cursor-pointer"><Icon picture="Refresh" /></span></h3>
				<div className="d-flex medical-records-info-widgets-container">
					<div style={{ width: "50%" }}>
						<div className="medical-records-info-widgets">
							<LastMeasurementWidget ref={measurementRef} isRecord={true} />
						</div>
					</div>
				</div>
				<div className="medical-records-panel-footer">
					{individualReport.getPageSize(1)}
				</div>
			</div>
		</>
	);
}