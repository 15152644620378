import * as dialogs from "../../../shared/dialogs/dialogs";
import * as context from "../../../../context";
import * as forms from "../../../../lib/forms";

export interface FormFields {
	types: number[];
}

export class Model {
	public dialog: dialogs.Dialog<number[], FormFields>;
	public form: forms.Form<FormFields>;

	constructor(private context: context.StateContext) {
		this.dialog = new dialogs.Dialog<number[], FormFields>({
			defaultCustomFields: {
				types: [],
			}
		}, context);
		this.form = new forms.Form<FormFields>({
			fields: {
				types: {
					title: "",
					defaultValue: [],
				},
			}
		}, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			...this.form.getStateContainers(),
			...this.dialog.getStateContainers()
		];
	}

	openDialog = async (types: number[]) => {
		this.dialog.setCustomField("types", types);
		return this.dialog.open();
	}

	save = async () => {
		const types = this.dialog.getCustomField("types");
		this.context.medicalRecords.stateContainer.merge((prev) => ({
			types: types
		}));
		return this.dialog.close(types);
	}
}