/**
 * Domovská stránka
 */
import * as React from "react";
import * as state from "../../../lib/state";

import Dialog from "./Dialog";
import Button from "../../../components/controls/Button";
import EnterPressArea from "../../../components/controls/EnterPressArea";
import HtmlContent from "../../../components/controls/HtmlContent";

function InformationDialog() {
	const { standardDialogs } = state.useStateContext();
	const dialog = standardDialogs.informationDialog;

	return (
		<Dialog dialog={dialog} limitedWidth>
			<EnterPressArea onEnter={dialog.close}>
				<div className="text-center">
					<HtmlContent content={dialog.getCustomField("message")} />
				</div>
				<div className="mt-4 text-center">
					<Button onClick={e => standardDialogs.informationDialog.close()} className="pt-2 pb-2">
						<strong>{dialog.getCustomField("buttonOkTitle")}</strong>
					</Button>
				</div>
			</EnterPressArea>
		</Dialog >
	);
}

export default state.bindContainers(InformationDialog,
	context => context.standardDialogs.informationDialog
);