/**
 * Widget Last records
 */
import * as React from "react";
import * as state from "../../../../../lib/state";
import * as lastRecords from "./last-records";
import * as apiHome from "../../api-home";
import Slider from "react-slick";
import storage from "store";
import MyRequestAction from "../../../../../components/controls/MyRequestAction";
import Icon from "../../../../../modules/system/icons/Icon";
import Help from "../../../../../components/controls/Help";

export function LastRecords() {
	const { lastRecords: model } = state.useStateContext();
	const { addLastMeasurementDialog } = state.useStateContext();
	const { api } = state.useStateContext();
	const currentIndex = model.form.getField("index").value;
	const { standardDialogs } = state.useStateContext();

	function handleOpen(data: any) {
		const item = Object.assign({}, data);
		item.type = { id: item.type, name: lastRecords.MyRecordType.title(item.type) };
		addLastMeasurementDialog.openDialog(undefined, item, undefined, item.type.id == 6 && !storage.get("delegateIdUser") ? true : false);

	}

	function handleIndex(index: number) {
		model.form.setField("index", index);
	}

	var settings = {
		slidesToShow: 3,
		slidesToScroll: 1,
		arrows: false,
		swipeToSlide: true,
		focusOnSelect: true,
		speed: 250,
		afterChange: handleIndex
	};

	const index = model.form.getField("index").value;

	const actions = [
		{
			title: "Přidat",
			action: async () => {

			},
		}
	];

	async function handleAdd() {
		await addLastMeasurementDialog.openDialog({ id: currentIndex == 0 ? 6 : currentIndex, name: lastRecords.MyRecordType.title(currentIndex) }, undefined, /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) as any;
		const value = addLastMeasurementDialog.form.getField("file").value;
		if (value) {
			model.loadData();
		}
	}

	async function removeItem(id: string) {
		const result = await api.del("/treatments/" + id);
		if (result) {
			model.loadData();
		}
	}

	async function handleRemove(id: string) {
		await standardDialogs.openYesNoDialog("Opravdu chcete smazat záznam?") === "yes"
			? removeItem(id)
			: undefined
	}

	return (
		<div className="last-records-page-container">
			<div className="homepage-widget-header">
				<img className="d-inline mr-2" src="/images/folder-open-o.svg" alt="folders" />
				<h2 className="d-inline">Moje zdravotní záznamy</h2>
				<Help id="last-records-help" text="Zde si uložte všechny Vaše lékařské zprávy, najdete zde i záznamy od Dr. Digital." />
				{/* <MyRequestAction actions={actions as any} /> */}
				<span onClick={handleAdd} className="cursor-pointer float-right color-primary"><Icon picture="Add" /></span>
			</div>
			<div className="slider-container mt-2">
				<Slider {...settings}>
					<div>
						<div className={"last-records-item " + (index == 0 ? "last-records-item-active" : "")}>
							Záznamy Dr. Digital
						</div>
					</div>
					<div>
						<div className={"last-records-item " + (index == 1 ? "last-records-item-active" : "")}>
							Nemoci
						</div>
					</div>
					<div>
						<div className={"last-records-item " + (index == 2 ? "last-records-item-active" : "")}>
							Operace
						</div>
					</div>
					<div>
						<div className={"last-records-item " + (index == 3 ? "last-records-item-active" : "")}>
							Záznamy
						</div>
					</div>
					<div>
						<div className={"last-records-item " + (index == 4 ? "last-records-item-active" : "")}>
							Alergie
						</div>
					</div>
					<div>
						<div className={"last-records-item " + (index == 5 ? "last-records-item-active" : "")}>
							Očkování
						</div>
					</div>
				</Slider>
			</div>
			<div className="mt-3 last-records-page-list-container pain-monitor-list-container">
				<div className="row">
					<div className="col-sm-12 mb-3">
						<div>
							{model.form.getField("last_records").value.map((i, index) =>
								<React.Fragment key={index}>
									{(i.type == 6 && currentIndex == 0 ? true : i.type == currentIndex) &&
										<div className="d-flex">
											<div onClick={() => handleOpen(i)} className="last-records-list-item-text">
												{i.name}
											</div>
											<div onClick={() => handleRemove(i.id)} className="d-inline widget-list-panel-remove mr-3 d-inline cursor-pointer"><Icon picture="Remove" /></div>
										</div>
									}
								</React.Fragment>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default state.bindContainers(
	LastRecords,
	c => c.lastRecords
);