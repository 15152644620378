import * as api from "../../../../lib/api";
import * as context from "../../../../context";
import * as apiCodeList from "../../../system/code-list/api-code-list";

export interface Content extends api.EntityReadBase {
	id: string;
	name: string;
}

export interface ContentItem extends api.EntityReadBase {
	id: string;
	title: string;
	description: string;
	id_image: string;
	id_main_image: string;
	id_content: string;
}

export class Api {
	waiting_room: api.EntityApi<Content, Content>;
	waiting_room_item: api.EntityApi<ContentItem, ContentItem>;

	constructor(private context: context.StateContext) {
		this.waiting_room = new api.EntityApi<Content, Content>({
			api: context.api,
			path: "/contents"
		});
		this.waiting_room_item = new api.EntityApi<ContentItem, ContentItem>({
			api: context.api,
			path: "/content_items"
		});
	}

	loadData = () => {
		return this.context.api.loadList<apiCodeList.CodeList>("/contents", { per_page: Number.MAX_SAFE_INTEGER });
	}

	remove = (id: string) => {
		return this.context.api.del<apiCodeList.CodeList>("/contents/" + id);
	}

	removeItem = (id: string) => {
		return this.context.api.del<apiCodeList.CodeList>("/content_items/" + id);
	}

	removeList = (id: string) => {
		return this.context.api.del<apiCodeList.CodeList>("/contents/" + id);
	}

	loadDetail(id: string) {
		return this.context.api.get<Content>("/content_items/" + id);
	}

	saveContentItem(id: string, user: Content) {
		return this.context.api.put<Content, Content>("/content_item/" + id, user);
	}
}