/*
 * API obrázků
 */
import * as context from "../../../context";
import * as api from "../../../lib/api";
import * as common from "../../../lib/common";

export interface Crop {
	x: number;
	y: number;
	width: number;
	height: number;
	use_percent: boolean;
	aspect_ratio?: string;
}

/**
 * Volby pro načtení obrázku
 */
export interface ImageSrcOptions {

	/**
	 * Rozlišení obrázku
	 */
	resolution?: "low" | "normal" | "high" | "original";

	/**
	 * Poměr stran
	 */
	aspectRatio?: AspectRatio | {

		/**
		 * Poměr stran šířka/výška
		 */
		value: AspectRatio;

		/**
		 * Algoritmus transformace velikosti:
		 *
		 *		"resize":		(výchozí), dojde ke zvětšení/zmenšení na danou velikost
		 *		"crop":			obrázek bude rovnoměrně ze všech stran oříznut na danou velikost
		 *		"resize-crop":	inteligentní ořez z potřebných stran na požadovaný formát a
		 *						poté zvětšení/zmenšení na požadované rozměry
		 */
		transformAlgorithm?: "resize" | "crop" | "resize-crop";
	};

	hash?: string;
	showBtn: boolean;
	defaultSrc?: string;
	classNameIcon?: string;
}

export type AspectRatio = "1:1" | "3:2" | "4:3" | "16:9" | "24:10";

/**
 * Metadata obrázku
 */
export interface Metadata {

	/**
	 * Barva pozadí, která je automaticky aplikována při ořezu s doplněním na pevný poměr stran
	 */
	background?: string;
}

const imagesRoute = "/images";

export class Api {
	constructor(private context: context.StateContext) { }


	/**
	 * Provede upload obrázku.
	 */
	upload = (imgFile: File, aspectRatio?: string) => {
		let formData = new FormData();
		formData.append("image", imgFile);
		if (aspectRatio) {
			formData.append("aspect_ratio", aspectRatio);
		}
		return this.context.api.post<FormData>(imagesRoute + "/" + api.emptyGuid, formData);
	}

	/**
	 * Vrací ořez daného obrázku
	 */
	getCrop = (imageId: string, usePercent: boolean = true, defaultAspectRatio: AspectRatio | undefined = undefined) => {
		let urlParams: string[] = [];

		if (usePercent) {
			urlParams.push("use_percent=true");
		}

		if (defaultAspectRatio) {
			urlParams.push("default_aspect_ratio=" + encodeURIComponent(defaultAspectRatio));
		}

		const urlParamsString = urlParams.push.length > 0 ? "?" + urlParams.join("&") : "";
		return this.context.api.get<{}, Crop>(imagesRoute + "/" + imageId + "/crop" + urlParamsString, {});
	}

	/**
	 * Nastaví ořez daného obrázku
	 */
	setCrop = (imageId: string, cropping: Crop) => {
		return this.context.api.post(imagesRoute + "/" + imageId + "/crop", cropping);
	}

	/**
	 * Vrací metadata obrázku
	 */
	getMetadata = (imageId: string) => {
		return this.context.api.get<{}, Metadata>(imagesRoute + "/" + imageId + "/metadata", {});
	}

	/**
	 * Nastaví metadata obrázku
	 */
	setMetadata = (imageId: string, metadata: Metadata) => {
		return this.context.api.post<Metadata>(imagesRoute + "/" + imageId + "/metadata", metadata);
	}

	/**
	 * Zkopíruje obrázek
	 */
	copyImage = (imageId: string) => {
		return this.context.api.post<{}, api.CodeBookItem>(imagesRoute + "/" + imageId + "/copy", {});
	}

	/**
	 * Vrátí URL obrázku.
	 */
	// getImageUrl = (id: string, options?: ImageSrcOptions) => {

	// 	function getTransformWidth() {
	// 		if (!options) return null;

	// 		// Hodnoty jsou navázané na breakponty bootstrapu. Nastaveno jako nějaká hodnota
	// 		// breakpointu - 30px, tj. dvakrát padding bootstrap třídy "container". Pouze 
	// 		// "high" odpovídá přesně velikosti breakpointu. Počítá se ní pro obrázky na
	// 		// celou šířku obrazovky
	// 		switch (options.resolution) {
	// 			case "low": return 430;
	// 			case "normal": return 738;
	// 			case "high": return 1920;
	// 			case "original": return null;
	// 			default: return 738;
	// 		}
	// 	}

	// 	function getTransformHeight() {
	// 		const aspectRatio = getAspectRatioValue();
	// 		if (!aspectRatio) return null;

	// 		const transformWidth = getTransformWidth();
	// 		if (transformWidth === null) return null;

	// 		return transformWidth / aspectRatio;
	// 	}

	// 	function aspectRatioToNumber(aspectRatio: AspectRatio) {
	// 		switch (aspectRatio) {
	// 			case "1:1": return 1;
	// 			case "3:2": return 3 / 2;
	// 			case "4:3": return 4 / 3;
	// 			case "16:9": return 16 / 9;
	// 			case "24:10": return 24 / 10;
	// 			default: common.unwanted(aspectRatio);
	// 		}
	// 	}

	// 	function getAspectRatioValue() {
	// 		if (options?.aspectRatio === undefined) return null;

	// 		if (typeof (options.aspectRatio) === "string") {
	// 			return aspectRatioToNumber(options.aspectRatio);
	// 		}

	// 		return aspectRatioToNumber(options.aspectRatio.value);
	// 	}

	// 	function getTransformAlgorithm() {
	// 		if (options?.aspectRatio === undefined) return null;

	// 		if (typeof (options.aspectRatio) === "string") {
	// 			return "resize-crop";
	// 		}
	// 		if (options.aspectRatio.transformAlgorithm === undefined) return null;

	// 		return options.aspectRatio.transformAlgorithm;
	// 	}

	// 	let url = this.context.api + imagesRoute + "/" + id;

	// 	url += "?";
	// 	const width = getTransformWidth();
	// 	const height = getTransformHeight();
	// 	const transform = getTransformAlgorithm();

	// 	if (width !== null) {
	// 		url += "width=" + width + "&";
	// 	}

	// 	if (height !== null) {
	// 		url += "height=" + height + "&";
	// 	}

	// 	if (transform !== null) {
	// 		url += "transform=" + transform + "&";
	// 	}

	// 	if (options?.resolution == "original") {
	// 		url += "original_size=true&";
	// 	}

	// 	if (options?.hash) {
	// 		url += "v=" + options?.hash + "&";
	// 	}

	// 	/* ted url konci bud ? nebo &, proto smazu posledni znak */
	// 	url = url.substring(0, url.length - 1);
	// 	return url;
	// }

	deleteImage = async (imageId: string) => {
		return await this.context.api.del(imagesRoute + imageId);
	}

	bulkDeleteImages = async (imageIds: api.BulkDelete) => {
		return await this.context.api.post<api.BulkDelete>(imagesRoute + "/bulk/delete", imageIds);
	}

	restoreImage = async (imageId: string) => {
		return await this.context.api.post(imagesRoute + imageId + "/restore", {});
	}

	bulkRestoreImages = async (imageIds: api.BulkRestore) => {
		return await this.context.api.post<api.BulkRestore>(imagesRoute + "/bulk/restore", imageIds);
	}

	/**
	 * Vrátí URL obrázku.
	 */
	getImageUrl = (id: string) => {
		return this.context.api.options.endpoint + imagesRoute + "/" + id;
	}
}