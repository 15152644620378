import * as React from "react";
import Spinner from "./Spinner";

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
	theme?: "primary" | "secondary";
	spinner?: boolean;
};

export default function Button(props: ButtonProps) {
	const { theme, spinner, className, children, ...rest } = props;
	return (
		<button
			className={"button button-" + (theme ?? "secondary") + " " + (className ?? "")}
			{...rest}
		>
			{spinner &&
				<div className="button__spinner-container">
					<Spinner className="button__spinner" />
				</div>
			}
			{children}
		</button >
	);
}