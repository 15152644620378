import * as dialogs from "../../../../shared/dialogs/dialogs";
import * as context from "../../../../../context";

interface File {
	id: string;
	name: string;
	text_content?: string;
}

interface State {
	file?: File;
	fullscreen: boolean;
}

export class Model {
	public dialog: dialogs.Dialog<undefined, State>;

	constructor(private context: context.StateContext) {
		this.dialog = new dialogs.Dialog<undefined, State>({
			defaultCustomFields: {
				file: undefined,
				fullscreen: false
			}
		}, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			...this.dialog.getStateContainers()
		];
	}

	openDialog = async (file: File) => {
		this.dialog.setCustomField("file", file);
		this.dialog.setCustomField("fullscreen", false);
		return this.dialog.open();
	}

	save = async () => {
		return this.dialog.close();
	}
}