/**
 * Widget Doctors
 */
import * as React from "react";
import * as state from "../../../../../lib/state";
import Slider from "react-slick";
import MyRequestAction from "../../../../../components/controls/MyRequestAction";
import Icon from "../../../../../modules/system/icons/Icon";
import Help from "../../../../../components/controls/Help";


export function DoctorsPage() {
	const { doctors: model } = state.useStateContext();
	const { api } = state.useStateContext();
	const { doctorDialog } = state.useStateContext();
	const { standardDialogs } = state.useStateContext();
	const currentIndex = model.form.getField("index").value;

	const actions = [{
		title: "Přidat záznam",
		icon: "Add",
		action: async () => {
			handleAdd();
		},
	}];

	function handleAdd() {
		doctorDialog.openDialog({ type: currentIndex + 1 } as any);
	}

	function handleDialog(i: any) {
		doctorDialog.openDialog(i);
	}

	function handleIndex(index: number) {
		model.form.setField("index", index);
	}

	async function removeItem(id: string) {
		const result = await api.del("/user_doctors/" + id);
		if (result) {
			model.loadData();
		}
	}

	async function handleRemove(id: string) {
		await standardDialogs.openYesNoDialog("Opravdu chcete smazat záznam?") === "yes"
			? removeItem(id)
			: undefined
	}

	var settings = {
		slidesToShow: 3,
		slidesToScroll: 1,
		arrows: false,
		swipeToSlide: true,
		focusOnSelect: true,
		speed: 250,
		afterChange: handleIndex
	};

	return (
		<div>
			<div className="pain-monitor-container">
				<div className="homepage-widget-header">
					<img className="d-inline mr-2" src="/images/TEMP  SVG.svg" alt="qr-code" />
					<h2 className="d-inline">Moji lékaři</h2>
					<Help id="my-doctors-help" text="Vytvořte si adresář Vašich lékařů." />
					{/* <MyRequestAction actions={actions as any} /> */}
					<span onClick={handleAdd} className="cursor-pointer float-right color-primary"><Icon picture="Add" /></span>
				</div>
				<div className="slider-container mt-2">
					<Slider {...settings}>
						<div>
							<div className={"last-records-item " + (currentIndex == 0 ? "last-records-item-active" : "")}>
								Praktický lékař
							</div>
						</div>
						<div>
							<div className={"last-records-item " + (currentIndex == 1 ? "last-records-item-active" : "")}>
								Specialista
							</div>
						</div>
						<div>
							<div className={"last-records-item " + (currentIndex == 2 ? "last-records-item-active" : "")}>
								Zubař
							</div>
						</div>
						<div>
							<div className={"last-records-item " + (currentIndex == 3 ? "last-records-item-active" : "")}>
								Ostatní
							</div>
						</div>
						<div>
							<div className={"last-records-item " + (currentIndex == 4 ? "last-records-item-active" : "")}>
								Vše
							</div>
						</div>
					</Slider>
				</div>
				<div className="mt-3 pain-monitor-list-container">
					{model.form.getField("items").value.map((i, index) =>
						<React.Fragment key={index}>
							{((i.type == currentIndex + 1) || currentIndex == 4) &&
								<div className="d-flex">
									<div onClick={() => handleDialog(i as any)} key={index} className="pain-monitor-list-item d-inline">
										<div className="pain-monitor-list-item-text d-inline">{!i.name ? i.doctor.name : i.name}</div>
									</div>
									<div onClick={() => handleRemove(i.id)} className="d-inline widget-list-panel-remove mr-3 d-inline cursor-pointer"><Icon picture="Remove" /></div>
								</div>
							}
						</React.Fragment>
					)}
				</div>
			</div>
		</div>
	);
}

export default state.bindContainers(
	DoctorsPage,
	c => c.doctors
);