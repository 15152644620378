/**
 * Widget optional view
 */
import * as React from "react";
import * as state from "../../../../../lib/state";
import { QRCode } from "@progress/kendo-react-barcodes";
import * as api from "../../../../../lib/api";
import WidgetListPanel from "../../../../../components/controls/widget-list-panel/WidgetListPanel";

interface Props {
	isEdit: boolean;
	isView: boolean;
}

function ExpandedViewWidget(props: Props) {
	const { expandedViewWidget: model } = state.useStateContext();
	const { pCardWidget } = state.useStateContext();
	const form = pCardWidget.form;
	const { images } = state.useStateContext();
	const { showImageDialog } = state.useStateContext();

	function handleCertificate() {
		const imageId = form.getField("id_covid_certificate").value;
		if (imageId == api.emptyGuid || !imageId) {
			return;
		}
		//images.open(imageId);
		showImageDialog.openDialog("Covid certifikát", imageId);
	}

	async function handleUploadCertificate() {
		const value = form.getField("id_covid_certificate").value;
		await images.setDialogImageId(!value ? api.emptyGuid : value);
		const result = await images.dialog.open() as any;
		if (result) {
			await form.setField("id_covid_certificate", result.imageId);
			pCardWidget.saveUser("id_covid_certificate", result.imageId);
		}
	}

	return (<div>
		<div className="p-card-widget-page-container">
			<div className="homepage-widget-header p-card-widget-header">
				<img className="d-inline-block mr-2" src="/images/list-check.svg" alt="qr-code" />
				<h2 className="d-inline">Rozšířené zobrazení</h2>
			</div>
			<WidgetListPanel isEdit={props.isEdit} title="Zdravotní záznamy" index="operations" indexSwitch="showOperations" form={model.form} />
			<WidgetListPanel isEdit={props.isEdit} title="Ošetřující lékaři" index="doctors" form={model.form} />
			<WidgetListPanel isEdit={props.isEdit} title="Očkování" index="vaccination" form={model.form} />
			{!props.isEdit &&
				< div onClick={handleCertificate} className="mt-3 mb-3 pt-2 pb-2 text-center p-card-widget-show-covid-crt">
					Zobrazit Covid certifikát
				</div>
			}
			{props.isEdit &&
				<div onClick={handleUploadCertificate} className="mt-3 mb-3 pt-2 pb-2 text-center p-card-widget-show-covid-crt">
					Nahrát Covid certifikát
				</div>
			}
			{/* <QRCode
				value="https://www.google.com"
				errorCorrection="M"
			/> */}
		</div>
	</div>
	);
}

export default state.bindContainers(
	ExpandedViewWidget,
	c => c.expandedViewWidget
);