/*
 * API pro autorizaci
 */
import * as apiLib from "../../../lib/api";
import * as context from "../../../context";

/**
 * Přihlašovací informace
 */
export interface Credentials {
	username: string;
	password: string;
}

/**
 * Požadavek na změnu hesla
 */
export interface ChangePasswordRequest {
	old_password: string;
	new_password: string;
}

export enum Widget {
	LastMeasurement = 1,
	LastRecords = 2,
	Calendar = 4,
	Pilulkoid = 5,
	PCardPreview = 6,
	ContentShowCase = 7
}

export interface WidgetItem {
	id: Widget;
	name: string;
}

export interface UserProfile {
	full_name: string;
	first_name: string;
	last_name: string;
	height: string;
	weight: string;
	ab: string;
	birthdate: Date;
	personal_identification_number: string;
	personal_identification_number_sufix: string;
	crisis_name: string;
	crisis_phone: string;
	phone: string;
	id_covid_certificate: string;
	id_insurance_card: string;
	role: apiLib.CodeBookItemWithNumberId;
	no_long_term_medication: boolean;
	no_long_term_illness: boolean;
	type: number;
	address: string;
	id_dms_file_image: string;
}

export interface Profile {
	id: string;
	full_name: string;
	first_name: string;
	last_name: string;
	widgets: WidgetItem[];
	profile: UserProfile;
	ab: string;
	demo_account: boolean;
	email: string;
}

export interface Statistics {
	id: string;
	fullname_filled: boolean;
	birthdate_filled: boolean;
	height_weight_filled: boolean;
	ab_filled: boolean;
	rc_filled: boolean;
	crisis_contact_phone_filled: boolean;
	address_filled: boolean;
	type_filled: boolean;
	doctor_filled: boolean;
	insurance_card_filled: boolean;
}

/**
 * Výsledek autorizace
 */
export interface AccessTokenResponse {
	access_token: string;
	id_role: number;
}

export class Api {
	constructor(private context: context.StateContext) { }

	/**
	 * Přihlásí uživatele pomocí uživatelského jména a hesla
	 */
	async loginWithCredentials(credentials: Credentials) {
		return await this.context.api.post<Credentials, AccessTokenResponse>("/auth/token", credentials);
	}

	/**
	 * Odhlásí uživatele
	 */
	async logout() {
		return await this.context.api.post("/auth/logout", {});
	}

	/**
	 * Změní heslo uživatele
	 */
	async changePassword(request: ChangePasswordRequest) {
		return await this.context.api.post<ChangePasswordRequest>("/auth/change_password", request);
	}

	/**
	 * Požadavek na obnovu hesla
	 */
	async passwordRestoreRequest(email: string) {
		return await this.context.api.post<{ email: string }>("/forgot_password", { email });
	}

	/**
	 * Kontrola tokenu pro obnovu hesla
	 */
	async checkPasswordRestoreToken(token: string) {
		return await this.context.api.post<{}, boolean>("/auth/token/" + token + "/is_valid", {});
	}

	/**
	 * Obnova hesla
	 */
	async passwordRestore(token: string, newPassword: string) {
		return await this.context.api.post<{ new_password: string }>("/auth/change_password?token=" + token, { new_password: newPassword });
	}

	/**
	 * Načte profil uživatele
	 */
	loadUserProfile() {
		return this.context.api.get<Profile>("/auth/info");
	}
}