import * as dialogs from "../../shared/dialogs/dialogs";
import * as context from "../../../context";
import * as forms from "../../../lib/forms";
import * as navigation from "../../../lib/navigation";
import * as validation from "../../../lib/validations";


interface State { }
export interface FormFields {
	password: string;
	confirm_password: string;
	old_password: string;
}

export class Model {
	public dialog: dialogs.Dialog<undefined, State>;
	public form: forms.Form<FormFields>;

	constructor(private context: context.StateContext) {
		this.dialog = new dialogs.Dialog<undefined, State>({
			defaultCustomFields: {
			}
		}, context);
		this.form = new forms.Form<FormFields>({
			fields: {
				password: {
					defaultValue: "",
					title: "Nové heslo",
					validate: value => this.validatePassword(value)
				},
				confirm_password: {
					defaultValue: "",
					title: "Potvrďte heslo",
					validate: value => this.validateConfirmPassword(value)
				},
				old_password: {
					defaultValue: "",
					title: "Staré heslo",
					validate: value => this.validateOldPassword(value)
				}
			}
		}, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			...this.form.getStateContainers(),
			...this.dialog.getStateContainers()
		];
	}

	/**
	 * Validuje password
	 */
	validateOldPassword = (value: string) => {
		return value.trim().length == 0 ? "Vyplňte prosím heslo" : "";
	}

	/**
	 * Validuje password
	 */
	validatePassword = (value: string) => {
		return validation.isValidPassword(value);
	}

	/**
	 * Validuje password
	 */
	validateConfirmPassword = (value: string) => {
		if (value != this.form.getField("password").value) {
			return "Heslo pro potvrzení musí být stejné";
		}

		return value.trim().length == 0 ? "Vyplňte prosím heslo" : "";
	}

	openDialog = async () => {
		this.form.clearFields();
		return this.dialog.open();
	}

	save = async () => {
		await this.form.validate();
		if (this.form.isValid()) {
			const data = this.form.getFields();
			const result = await this.context.api.post("/auth/change_password", { new_password: this.form.getField("password").value, old_password: this.form.getField("old_password").value });
			if (result) {
				await this.context.standardDialogs.openInformationDialog("Heslo bylo změněno.");
				this.dialog.close();
			}
		} else {
			await this.context.standardDialogs.openInformationDialog("Nejdříve je třeba potvrdit všechny kontroly.");
		}
	}
}